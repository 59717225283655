import React, {useEffect} from 'react';
import cx from 'classnames';
import {Trans, useTranslation} from 'react-i18next';

import {
    FontVariant,
    Modal,
    ModalType,
    Spinner,
    Text,
    TextHeading,
    ThemeProvider,
    useModalHandle,
} from '@pexip/components';
import type {ModalProps} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import {SearchRow} from '../SearchRow/SearchRow.view';
import {InvitationSearchInput} from '../InvitationSearchInput/InvitationSearchInput.view';

import styles from './InvitationModal.module.scss';

export const ManualInvite: React.FC<
    React.ComponentProps<'div'> & {
        canInvite: boolean;
        inviteLinkBox: React.ReactNode;
    }
> = ({canInvite, className, inviteLinkBox}) => {
    const {t} = useTranslation();
    return (
        <div className={cx('m-4', className)}>
            {canInvite ? (
                <Text fontVariant={FontVariant.Body}>
                    <Trans t={t} i18nKey="invite.send-invite-link">
                        or send an invite link
                    </Trans>
                </Text>
            ) : (
                <>
                    <TextHeading
                        htmlTag="h5"
                        fontVariant={FontVariant.H5}
                        className="mb-2"
                    >
                        <Trans t={t} i18nKey="invite.invite-someone">
                            Invite someone to the meeting
                        </Trans>
                    </TextHeading>
                    <Text fontVariant={FontVariant.Body} htmlTag="p">
                        <Trans t={t} i18nKey="invite.instructions">
                            Send the meeting link below to the person you’d like
                            to add
                        </Trans>
                    </Text>
                </>
            )}
            {inviteLinkBox}
        </div>
    );
};

export const InviteeResults: React.FC<
    React.ComponentProps<'div'> & {
        invitees: Array<{identity: string; name: string}>;
        loading?: boolean;
        onInviteeAdd: (identity: string) => void;
        searched?: boolean;
    }
> = ({invitees, loading, searched = false, onInviteeAdd}) => {
    const {t} = useTranslation();

    return (
        <div
            className={cx(styles.results, {
                [styles.emptyResults]: !invitees.length,
            })}
        >
            {loading ? (
                <Spinner
                    className={styles.spinner}
                    colorScheme="dark"
                    sizeModifier={'medium'}
                />
            ) : invitees.length > 0 ? (
                invitees.map(({identity, name}) => (
                    <SearchRow
                        key={identity}
                        addMember={onInviteeAdd}
                        displayName={name}
                        identity={identity}
                    />
                ))
            ) : (
                <Text fontVariant={FontVariant.Body}>
                    {searched
                        ? t(
                              'invite.cant-find-anyone',
                              'Can’t find anyone with that name',
                          )
                        : t(
                              'invite.click-search',
                              'Click on the search field above to search',
                          )}
                </Text>
            )}
        </div>
    );
};

export const InviteeSearch: React.FC<
    React.ComponentProps<'div'> & {
        searchValue: string;
        onSearchValueChange: (value: string) => void;
    }
> = ({children, className, searchValue, onSearchValueChange, ...props}) => {
    const {t} = useTranslation();
    return (
        <div className={cx('mt-4 ml-4', className)} {...props}>
            <TextHeading htmlTag="h5" fontVariant={FontVariant.H5}>
                <Trans t={t} i18nKey="invite.invite-someone">
                    Invite someone to the meeting
                </Trans>
            </TextHeading>
            <div className="mt-4 mr-4">
                <InvitationSearchInput
                    value={searchValue}
                    onValueChange={onSearchValueChange}
                />
            </div>
            {children}
        </div>
    );
};

export const InvitationModal: React.FC<
    ModalProps & {modalType?: ModalType}
> = ({
    children,
    className,
    isOpen,
    modalType = ModalType.Positioned,
    onClose,
    ...props
}) => {
    const {t} = useTranslation();
    const {modalHandleRef, focusModal} = useModalHandle();

    useEffect(() => {
        if (isOpen) {
            focusModal();
        }
    }, [focusModal, isOpen]);

    return (
        <ThemeProvider colorScheme="light">
            <Modal
                ref={modalHandleRef}
                className={className}
                data-testid={TestId.ModalInvitation}
                isOpen={isOpen}
                modalType={modalType}
                onClose={onClose}
                padding="none"
                testIdCloseButton={TestId.ButtonCloseInvitationModal}
                uniqueTitle={t(
                    'invite.meeting-invitation-modal',
                    'Meeting Invitation Modal',
                )}
                withCloseButton
                withMask={false}
                {...props}
            >
                {children}
            </Modal>
        </ThemeProvider>
    );
};

export const InvitationModalWithTransform: React.FC<
    ModalProps & {shouldTransform: boolean}
> = ({children, shouldTransform, ...props}) => (
    <InvitationModal
        className={cx(styles.inMeetingModal, {
            [styles.shouldTransform]: shouldTransform,
        })}
        {...props}
    >
        {children}
    </InvitationModal>
);
