import type {Logger} from './baseLogger';
import {createConsoleLogger} from './baseLogger';

export let logger = createConsoleLogger();

export function setLogger(newLogger: Logger) {
    logger = newLogger;
}

interface Flags {
    debug?: boolean;
}
export const flags: Flags = {};

export function setFlags(newFlags: Flags) {
    Object.assign(flags, newFlags);
}
