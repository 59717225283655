import {useEffect} from 'react';

import type {BreakoutParticipants, RoomTuple} from '../types';
import {BreakoutRoomAssignmentMode} from '../types';
import {MainBreakoutRoomId} from '../constants';
import type {ShuffleBreakoutParticipants} from '../utils';
import {shuffleBreakoutParticipants} from '../utils';

export const useBreakoutAssignParticipantsByMode = ({
    assignmentMode,
    myIdentity,
    numberOfRooms,
    setParticipants,
    shuffle = shuffleBreakoutParticipants,
}: {
    assignmentMode: BreakoutRoomAssignmentMode;
    myIdentity?: string;
    numberOfRooms: number;
    setParticipants: React.Dispatch<React.SetStateAction<BreakoutParticipants>>;
    shuffle?: ShuffleBreakoutParticipants;
}) =>
    useEffect(() => {
        switch (assignmentMode) {
            case BreakoutRoomAssignmentMode.Manually:
                setParticipants(prevParticipants => {
                    const mainParticipants = [
                        ...(prevParticipants.get(MainBreakoutRoomId) ?? []),
                    ];
                    const mainRoom: RoomTuple = [
                        MainBreakoutRoomId,
                        mainParticipants,
                    ];
                    const rooms = Array.from<unknown, RoomTuple>(
                        {length: numberOfRooms},
                        (_, k) => [`Room ${k + 1}`, []],
                    );

                    rooms.unshift(mainRoom);
                    return new Map(rooms);
                });
                break;
            case BreakoutRoomAssignmentMode.Automatically:
                setParticipants(prevParticipants => {
                    const mainParticipants = [
                        ...(prevParticipants.get(MainBreakoutRoomId) ?? []),
                    ];

                    const allParticipants = new Map(
                        Array.from<unknown, RoomTuple>(
                            {length: numberOfRooms},
                            (_, i) => [`Room ${i + 1}`, []],
                        ),
                    );
                    allParticipants.set(MainBreakoutRoomId, mainParticipants);

                    return shuffle({
                        allParticipants,
                        identity: myIdentity,
                        shuffleAssigned: true,
                    });
                });
                break;
        }
    }, [assignmentMode, myIdentity, numberOfRooms, setParticipants, shuffle]);
