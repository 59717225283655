import type {KeyboardEvent} from 'react';
import {useKeyboard} from '@react-aria/interactions';

export const useEnter = <T extends Element>(
    fn: (e: KeyboardEvent<T>) => void,
) => {
    const result = useKeyboard({
        onKeyDown(e) {
            if (e.key === 'Enter') {
                fn(e);
            }
        },
    });
    return {onKeyDown: result.keyboardProps.onKeyDown};
};
