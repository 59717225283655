import React from 'react';
import cx from 'classnames';

import styles from './MeetingStageContainer.module.scss';

export const MeetingStageContainer = React.forwardRef<
    HTMLDivElement,
    {
        children: React.ReactNode;
        className?: string;
        isPrimaryVideoExpanded?: boolean;
        isSidePanelVisible?: boolean;
    }
>(
    (
        {
            children,
            className,
            isPrimaryVideoExpanded,
            isSidePanelVisible,
            ...props
        },
        ref,
    ) => {
        return (
            <div
                className={cx(styles.container, className, {
                    [styles.withSidePanel]: !!isSidePanelVisible,
                    [styles.expanded]: !!isPrimaryVideoExpanded,
                })}
                {...props}
                ref={ref}
            >
                {children}
            </div>
        );
    },
);

MeetingStageContainer.displayName = 'MeetingStageContainer';

export type MeetingStageContainerProps = React.ComponentProps<
    typeof MeetingStageContainer
>;
