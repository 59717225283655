import React from 'react';
import {useTranslation} from 'react-i18next';

import type {ButtonProps, LogoSizeModifier} from '@pexip/components';
import {Icon, IconTypes} from '@pexip/components';

import {InMeetingInputControl} from '../../InputControl/InMeetingInputControl.view';
import {MuteInputTooltipContent} from '../../MuteInputTooltip/MuteInputTooltipContent.view';
import {TestId} from '../../../../test/testIds';

export const AudioButton: React.FC<
    ButtonProps & {
        isMicrophoneMuted: boolean;
        onAudioBtnClick: () => void;
        isAudioBtnDisabled?: boolean;
        iconSize: LogoSizeModifier;
        tooltipClassName?: string;
    }
> = ({
    isMicrophoneMuted,
    onAudioBtnClick,
    iconSize,
    isAudioBtnDisabled = false,
    tooltipClassName,
    ...props
}) => {
    const {t} = useTranslation();
    const muteMicText = isMicrophoneMuted
        ? t('media.unmute-microphone', 'Unmute microphone')
        : t('media.mute-microphone', 'Mute microphone');

    const testId = isMicrophoneMuted
        ? TestId.ButtonMeetingAudioInputMuted
        : TestId.ButtonMeetingAudioInput;

    return (
        <InMeetingInputControl
            isDisabled={isAudioBtnDisabled}
            isActive={isMicrophoneMuted}
            onClick={onAudioBtnClick}
            tooltipPosition="top"
            tooltipText={
                <MuteInputTooltipContent
                    isAudioMuted={isMicrophoneMuted}
                    muteText={muteMicText}
                />
            }
            tooltipClassName={tooltipClassName}
            data-testid={testId}
            aria-label={muteMicText}
            icon={
                isMicrophoneMuted ? (
                    <Icon
                        source={IconTypes.IconMicrophoneOff}
                        size={iconSize}
                    />
                ) : (
                    <Icon source={IconTypes.IconMicrophoneOn} size={iconSize} />
                )
            }
            {...props}
        />
    );
};

export type AudioButtonProps = React.ComponentProps<typeof AudioButton>;
