import {StreamQuality} from '../types';

export const qualityToMediaConstraints = (quality: StreamQuality) => {
    switch (quality) {
        case StreamQuality.Low:
            return getConstraints(960, 540);
        case StreamQuality.VeryHigh:
            return getConstraints(1920, 1080);
        default:
            return getConstraints(1280, 720);
    }
};

const getConstraints = (width: number, height: number) => ({
    width: {ideal: width, max: 1920},
    height: {ideal: height, max: 1080},
});
