import {useCallback, useState} from 'react';

export const useIsInWindowRightHalf = () => {
    const [isInRightHalf, setIsInRightHalf] = useState(true);

    const update = useCallback((el: HTMLElement) => {
        const rightHalf =
            el.offsetLeft + el.offsetWidth / 2 > window.innerWidth / 2;
        setIsInRightHalf(rightHalf);
    }, []);

    return {isInRightHalf, update};
};
