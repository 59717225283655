import React from 'react';

import type {IconSource} from '@pexip/components';
import {Icon, IconTypes, Text} from '@pexip/components';

export const MicrophoneMuteMessage: React.FC<{
    text: string;
    source?: IconSource;
}> = ({text, source = IconTypes.IconMicrophoneOn}) => (
    <div className="d-flex align-items-center">
        <Icon source={source} size="compact" className="mr-1" />{' '}
        <Text colorScheme="light">{text}</Text>
    </div>
);
