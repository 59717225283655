import React, {forwardRef, useId} from 'react';
import type {HTMLProps} from 'react';
import cx from 'classnames';

import {ThemeConsumer} from '../../../themes/ThemeContext';
import type {ColorScheme} from '../../../types/variants';
import {InputLabel} from '../InputLabel/InputLabel';

import styles from './RadioButton.module.scss';

export const RadioButton = forwardRef<
    HTMLInputElement,
    Omit<HTMLProps<HTMLInputElement>, 'type' | 'name' | 'disabled'> & {
        checked?: boolean;
        className?: string;
        colorScheme?: ColorScheme;
        groupName?: string;
        id?: string;
        isDisabled?: boolean;
        label: string;
        labelModifier?: 'default' | 'hidden' | 'spaced';
        labelPosition?: 'start' | 'end';
        value: string;
        onValueChange?: (selected: boolean) => void;
    }
>(
    (
        {
            checked,
            className,
            colorScheme,
            groupName,
            id,
            isDisabled,
            label,
            labelModifier,
            labelPosition = 'end',
            value,
            onChange,
            onValueChange,
            ...props
        },
        ref,
    ) => {
        const defaultId = useId();
        const effectiveId = id ?? defaultId;

        const isLabelHidden = labelModifier === 'hidden';

        const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
            onValueChange?.(e.currentTarget.checked);
            onChange?.(e);
        };

        return (
            <ThemeConsumer>
                {({colorScheme: defaultColorScheme}) => (
                    <label
                        className={cx(
                            styles.wrap,
                            styles[colorScheme ?? defaultColorScheme],
                            styles[labelPosition],
                            {
                                [styles.disabled]: isDisabled,
                                [styles.spaced]: labelModifier === 'spaced',
                            },
                            className,
                        )}
                        title={isLabelHidden ? label : undefined}
                    >
                        <input
                            checked={checked}
                            className={styles.input}
                            name={groupName}
                            onChange={handleChange}
                            value={value}
                            ref={ref}
                            {...props}
                            id={effectiveId}
                            type="radio"
                            disabled={isDisabled}
                        />
                        <div
                            aria-hidden="true"
                            role="presentation"
                            className={cx(styles.indicator, {
                                [styles.checked]: checked,
                            })}
                        >
                            <div role="none" className={styles.dot} />
                        </div>
                        <InputLabel
                            className={cx(styles.label, 'mb-0')}
                            isLabelHidden={isLabelHidden}
                            text={label}
                            variant={isDisabled ? 'disabled' : 'standard'}
                            id={effectiveId}
                        />
                    </label>
                )}
            </ThemeConsumer>
        );
    },
);

RadioButton.displayName = 'RadioButton';
