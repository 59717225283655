import React from 'react';
import cx from 'classnames';

import type {ExtendedSizeModifier} from '../../../types/sizes';
import {sizeToPadding} from '../../../utils/helpers';

import styles from './BoxFooter.module.scss';

export const BoxFooter: React.FC<
    React.ComponentProps<'div'> & {
        padding?: ExtendedSizeModifier;
        hasFooterShadow?: boolean;
    }
> = ({className, padding = 'small', hasFooterShadow = false, children}) => (
    <div
        className={cx(
            className,
            styles.boxFooter,
            styles[sizeToPadding(padding)],
            {[styles.hasShadow]: hasFooterShadow},
        )}
    >
        {children}
    </div>
);

export type BoxFooterProps = React.ComponentProps<typeof BoxFooter>;
