import {useState, useEffect} from 'react';

export const useDisplayedNumber = (number: number) => {
    const [displayedNumber, setDisplayedNumber] = useState(number);

    // We don't want to display the number when it's 0.
    // The badge should fade out with the current (old) number.
    useEffect(() => {
        if (number > 0) {
            setDisplayedNumber(number);
        }
    }, [number]);

    return displayedNumber;
};
