import {useState, useEffect} from 'react';

export const useBadgeCounterAnimation = (
    number: number,
    shouldAnimate: boolean,
) => {
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        if (shouldAnimate && number > 0) {
            setIsAnimating(true);
        }
    }, [number, shouldAnimate]);

    useEffect(() => {
        let timeoutId: number;

        if (shouldAnimate && isAnimating) {
            timeoutId = window.setTimeout(() => {
                setIsAnimating(false);
            }, 800);
        }

        return () => clearTimeout(timeoutId);
    }, [isAnimating, shouldAnimate]);

    return isAnimating;
};
