import React from 'react';
import cx from 'classnames';

import {Scrim} from '@pexip/components';

import styles from './MeetingScrim.module.scss';

export const MeetingScrim: React.FC<React.ComponentProps<typeof Scrim>> = ({
    position = 'top',
    ...props
}) => (
    <Scrim
        className={cx(styles.scrim, {
            [styles.scrimTop]: position === 'top',
        })}
        position={position}
        {...props}
    />
);
