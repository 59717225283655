import React, {Children, useEffect, useState} from 'react';
import cx from 'classnames';

import {Text} from '../../elements/Text/Text';
import type {ColorScheme} from '../../../types/variants';
import {ThemeConsumer, ThemeProvider} from '../../../themes/ThemeContext';
import {Divider} from '../../elements/Divider/Divider';
import type {ExtendedSizeModifier} from '../../../types/sizes';
import {TestId} from '../../../utils/testIds';

import styles from './Tabs.module.scss';

export const Tabs: React.FC<
    React.ComponentProps<'div'> & {
        children:
            | React.ReactElement<TabProps>
            | Array<false | React.ReactElement<TabProps>>;
        colorScheme?: ColorScheme;
        customTabNameComponent?: React.FC<TabNameProps>;
        hasDivider?: boolean;
        noTabButtonPadding?: boolean;
        initialActiveTabIndex?: number;
        onActiveTabChange?: (activeTabIndex: number) => void;
        'data-testid'?: string;
    }
> = ({
    children,
    className,
    colorScheme,
    customTabNameComponent,
    hasDivider = true,
    noTabButtonPadding,
    initialActiveTabIndex = 0,
    onActiveTabChange,
    ...props
}) => {
    const [activeTab, setActiveTab] = useState(initialActiveTabIndex);
    const tabs = Children.toArray(children).filter(Boolean);

    const TabNameComponent = customTabNameComponent ?? TabName;

    useEffect(() => {
        setActiveTab(initialActiveTabIndex);
    }, [initialActiveTabIndex]);

    useEffect(() => {
        if (onActiveTabChange) {
            onActiveTabChange(activeTab);
        }
    }, [activeTab, onActiveTabChange]);

    const tabList = tabs.map(
        (tab, i) =>
            React.isValidElement<TabProps>(tab) && (
                <TabNameComponent
                    aria-selected={activeTab === i}
                    data-active={activeTab === i}
                    data-testid={
                        tab.props['data-testid'] ?? `${TestId.Tab}-${i}`
                    }
                    key={`tab-${i}`}
                    onClick={() => setActiveTab(i)}
                    role="tab"
                >
                    <div className={styles.tabNameContent}>
                        {tab.props.tabEnhancerStart && (
                            <div>{tab.props.tabEnhancerStart}</div>
                        )}
                        <div>{tab.props.title}</div>
                        {tab.props.tabEnhancerEnd && (
                            <div>{tab.props.tabEnhancerEnd}</div>
                        )}
                    </div>
                </TabNameComponent>
            ),
    );

    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <ThemeProvider colorScheme={colorScheme ?? defaultColorScheme}>
                    <div className={className} {...props}>
                        <div
                            role="tablist"
                            className={cx(styles.tabList, {
                                [styles.tabButtonPadding]: !noTabButtonPadding,
                            })}
                        >
                            {tabList}
                        </div>
                        {hasDivider && <Divider className={styles.divider} />}
                        {tabs.find((_tab, i) => activeTab === i)}
                    </div>
                </ThemeProvider>
            )}
        </ThemeConsumer>
    );
};

export const Tab: React.FC<
    React.ComponentProps<'div'> & {
        title: string;
        contentPadding?: ExtendedSizeModifier;
        tabEnhancerStart?: React.ReactNode;
        tabEnhancerEnd?: React.ReactNode;
        'data-testid'?: string;
    }
> = ({children, className, contentPadding = 'medium', ...props}) => {
    const {
        title,
        tabEnhancerStart: _tabEnhancerStart,
        tabEnhancerEnd: _tabEnhancerEnd,
        'data-testid': _testId,
        ...tabProps
    } = {...props};

    return (
        <div
            aria-label={title}
            role="tabpanel"
            className={cx(styles.tabContent, styles[contentPadding], className)}
            {...tabProps}
        >
            {children}
        </div>
    );
};

export type TabProps = React.ComponentProps<typeof Tab>;

export const TabName: React.FC<React.ComponentProps<'button'>> = ({
    children,
    className,
    ...props
}) => (
    <button className={cx(styles.tabLink, className)} {...props}>
        <Text className={styles.tabName}>{children}</Text>
    </button>
);

export type TabNameProps = React.ComponentProps<typeof TabName>;
