import React from 'react';

import {BrandedFullSizeWindow} from '../viewModels/BrandedFullSizeWindow.viewModel';
import {NonMeetingHeader} from '../viewModels/NonMeetingHeader.viewModel';
import {PreflightLayout} from '../views/PreflightLayout/PreflightLayout.view';
import {BrandedInfoCard} from '../viewModels/BrandedInfoCard.viewModel';
import {TestId} from '../../test/testIds';
import {CustomStepViewModel} from '../viewModels/CustomStep.viewModel';

export const CustomStepPage: React.FC = () => (
    <BrandedFullSizeWindow
        padding="none"
        background="image"
        flexDirection="column"
        justifyContent="flexStart"
        data-testid={TestId.CustomStepPage}
    >
        <NonMeetingHeader />
        <PreflightLayout
            startContent={<BrandedInfoCard />}
            endContent={<CustomStepViewModel />}
        />
    </BrandedFullSizeWindow>
);
