import type {ReactElement} from 'react';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {
    Bar,
    Button,
    Box,
    FontVariant,
    List,
    SvgImage,
    Text,
    ThemeProvider,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import allow from './allow-permissions-illustration.svg';

import styles from './RequestAccess.module.scss';

export const RequestAccess: React.FC<{
    requestAccess: () => void;
    title: string;
    message: string | ReactElement;
}> = ({requestAccess, title, message}) => {
    const {t} = useTranslation();

    return (
        <ThemeProvider colorScheme="light">
            <Box padding="large" className={styles.boxWrapper}>
                <List spacing="none">
                    <SvgImage className={styles.svgWrapper} source={allow} />
                    <Text fontVariant={FontVariant.H3} className="mt-4">
                        {title}
                    </Text>
                    <Text className="mt-4">{message}</Text>
                    <Bar className="mt-7">
                        <Button
                            data-testid={TestId.ButtonRequestPermissions}
                            modifier="fullWidth"
                            onClick={requestAccess}
                            size="large"
                        >
                            <Trans t={t} i18nKey="common.ask-for-permission">
                                Request permission
                            </Trans>
                        </Button>
                    </Bar>
                </List>
            </Box>
        </ThemeProvider>
    );
};

export type RequestAccessProps = React.ComponentProps<typeof RequestAccess>;
