import {
    UserMediaStatus,
    isGrantedOnlyAudio,
    isGrantedOnlyVideo,
    isDeviceInUse,
    areBothGranted,
    isRejected,
} from '@pexip/media';

interface RequestedMedia {
    audio: boolean;
    video: boolean;
}

export const isVideoActive = (
    isVideoInputMuted: boolean,
    mediaStream?: MediaStream,
) =>
    !isVideoInputMuted &&
    mediaStream &&
    mediaStream?.getVideoTracks().length > 0;

export const isVideoMuted = (
    isVideoInputMuted: boolean,
    streamStatus?: UserMediaStatus,
) => {
    const grantedVideo =
        !!streamStatus &&
        (areBothGranted(streamStatus) || isGrantedOnlyVideo(streamStatus));
    const hasVideoAndMuted = isVideoInputMuted && grantedVideo;

    return (
        hasVideoAndMuted ||
        streamStatus !== UserMediaStatus.InitialPermissionsNotGranted
    );
};

export const shouldShowErrorBorder = (
    streamStatus: UserMediaStatus | undefined,
    {audio, video}: RequestedMedia,
) =>
    !!streamStatus &&
    (isRejected(streamStatus) ||
        (isGrantedOnlyAudio(streamStatus) && video) ||
        (isGrantedOnlyVideo(streamStatus) && audio) ||
        isDeviceInUse(streamStatus));
