import React from 'react';
import {useTranslation} from 'react-i18next';

import {Button} from '@pexip/components';

import styles from './MobileGetStartedStep.module.scss';

export const MobileGetStartedStep: React.FC<{
    brandedInfoCard: React.ReactElement;
    onNextClick: () => void;
}> = ({brandedInfoCard, onNextClick}) => {
    const {t} = useTranslation();
    return (
        <div className={styles.mobileGetStartedStep}>
            {brandedInfoCard}
            <Button
                onClick={onNextClick}
                modifier="fullWidth"
                className="mt-4"
                size="large"
            >
                {t('common.next', 'Next')}
            </Button>
        </div>
    );
};

export type MobileGetStartedStepProps = React.ComponentProps<
    typeof MobileGetStartedStep
>;
