import React from 'react';

import {UserNameStep} from '../UserNameStep/UserNameStep.view';
import type {UserNameStepProps} from '../UserNameStep/UserNameStep.view';
import {ResponsiveBox} from '../ResponsiveBox/ResponsiveBox.view';

export const UserNameCard: React.FC<UserNameStepProps> = props => (
    <ResponsiveBox padding="large">
        <UserNameStep {...props} />
    </ResponsiveBox>
);

export type UserNameCardProps = React.ComponentProps<typeof UserNameCard>;
