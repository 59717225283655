type AsyncCallback<A extends unknown[], R> = (...args: A) => Promise<R>;
type Cleanup<R> = (result: R) => Promise<void> | void;

/**
 * Wrapping provided async callback to allow to resolve the async operation with
 * cleanup when the cancel function is called
 *
 * @param callback - An async callback to execute
 * @param cleanup - A cleanup callback to execute when the provided async
 * callback is canceled
 */
export const cancellablePromise = <A extends unknown[], R>(
    callback: AsyncCallback<A, R>,
    cleanup?: Cleanup<R>,
) => {
    let cancelResolve: (() => void) | undefined;
    const wrap = (...args: A) => {
        let cancelled = false;
        const cancelPromise = new Promise<void>(resolve => {
            cancelResolve = resolve;
        });
        return Promise.race([
            callback(...args)
                .then(
                    result => {
                        if (cancelled && cleanup) {
                            return cleanup(result);
                        }
                        return result;
                    },
                    reason => {
                        if (!cancelled) {
                            throw reason;
                        }
                    },
                )
                .catch(error => {
                    if (!cancelled) {
                        throw error;
                    }
                }),
            cancelPromise.then(() => {
                cancelled = true;
            }),
        ]);
    };
    const cancel = () => {
        cancelResolve?.();
    };
    return [wrap, cancel] as const;
};
