/**
 * Sum an array of numbers
 *
 * @param nums - An array of numbers
 */
export const sum = (nums: number[]) =>
    nums.reduce((accm, num) => accm + num, 0);

/**
 * Average an array of numbers
 *
 * @param nums - An array of numbers
 */
export const avg = (nums: number[]) => {
    if (nums.length === 0) {
        return 0;
    }
    if (nums.length === 1) {
        return nums[0] ?? 0;
    }
    return sum(nums) / nums.length;
};

/**
 * pow function from Math in functional form `number -> number -> number`
 *
 * @param exponent - The exponent used for the expression
 * @param base - The base value to be powered
 *
 * @returns Math.pow(base, exponent)
 */
export const pow = (exponent: number) => (base: number) =>
    Math.pow(base, exponent);

/**
 * Calculate the Root Mean Square from provided numbers
 *
 * @param nums - An array of numbers
 */
export const rms = (nums: number[]) => {
    if (!nums || !Array.isArray(nums) || !nums.length) {
        return 0;
    }
    if (nums.length === 1 && nums[0] !== undefined) {
        return Math.abs(nums[0]);
    }
    return Math.sqrt(sum(nums.map(pow(2))) / nums.length);
};

/**
 * Round the floating point number away from zero, which is different from
 * `Math.round`
 *
 * @param num - The number to round
 *
 * @example
 *
 * ```typescript
 * round(0.5) // 1
 * round(-0.5) // -1
 * ```
 */
export const round = (num: number) =>
    num ? Math.round(num) : -Math.round(-num);
