import {identity} from './identity';
import type {Fn} from './types';

type Rn<R> = (a: R) => R;
type Rns<R> = Array<Rn<R>>;

/**
 * Pipes the value of an expression into a pipeline of functions.
 *
 * @example
 * ```typescript
 * const len = (s: string): number => s.length;
 * const double = (n: number): number => n * 2;
 *
 * // Without pipe
 *
 * const doubleLen = (s: string) => double(len(s));
 *
 * // With pipe
 * const doubleLen = pipe(len, double);
 * ```
 */
export const pipe = <T extends unknown[], R>(fn1: Fn<T, R>, ...fns: Rns<R>) => {
    const piped = fns.reduce(
        (prevFn, nextFn) => (value: R) => nextFn(prevFn(value)),
        identity,
    );
    return (...args: T) => piped(fn1(...args));
};
