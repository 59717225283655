import React, {createContext} from 'react';

import type {ColorScheme} from '../types/variants';

export interface ThemeContextProps {
    theme?: string;
    colorScheme: ColorScheme;
}

const ThemeContext = createContext<ThemeContextProps>({
    colorScheme: 'dark',
});

export const {Consumer: ThemeConsumer} = ThemeContext;

export const ThemeProvider: React.FC<
    React.PropsWithChildren<ThemeContextProps>
> = ({children, theme, colorScheme}) => (
    <ThemeContext.Provider value={{theme, colorScheme}}>
        {children}
    </ThemeContext.Provider>
);

export function withColorScheme<P extends object>(
    Component: React.ComponentType<P>,
    colorScheme: ColorScheme,
) {
    return function WrappedWithColorScheme(props: P) {
        return (
            <ThemeProvider colorScheme={colorScheme}>
                <Component {...props} />
            </ThemeProvider>
        );
    };
}
