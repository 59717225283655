import React from 'react';

import type {
    ButtonProps,
    IconSource,
    LogoSizeModifier,
} from '@pexip/components';
import {Button, Icon, useMdDown} from '@pexip/components';

export const ResponsiveButton: React.FC<
    ButtonProps & {
        iconSource?: IconSource;
        iconSize?: LogoSizeModifier;
        hideChildren?: boolean;
    }
> = ({
    iconSource,
    iconSize = 'compact',
    children,
    variant = 'translucent',
    hideChildren: hideChildrenProp,
    ...props
}) => {
    const isMdDown = useMdDown();
    const hasChildren = Boolean(children);
    const hideChildren = hideChildrenProp ?? isMdDown; // if hideChildren prop does not control hiding, then being below md breakpoint will hide them

    return (
        <Button
            variant={variant}
            hideChildren={hideChildren}
            noEnhancerPadding={!hasChildren || hideChildren}
            modifier={hideChildren ? 'square' : undefined}
            enhancerStart={
                iconSource && <Icon source={iconSource} size={iconSize} />
            }
            {...props}
        >
            {children}
        </Button>
    );
};

export type ResponsiveButtonProps = React.ComponentProps<
    typeof ResponsiveButton
>;
