import type {Fn} from './types';

/**
 * Creates a throttled function that only invokes func at most once per every wait milliseconds
 *
 * @param fn - The function to throttle.
 * @param wait - The number of milliseconds to throttle invocations to.
 */
export const throttle = <T extends unknown[], R>(fn: Fn<T, R>, wait = 0) => {
    let lastFunc: number;
    let lastRan: number;

    return function (...args: T) {
        if (!lastRan) {
            // eslint-disable-next-line prefer-spread --- prefer apply
            fn.apply(undefined, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);

            lastFunc = window.setTimeout(
                () => {
                    if (Date.now() - lastRan >= wait) {
                        // eslint-disable-next-line prefer-spread --- prefer apply
                        fn.apply(undefined, args);
                        lastRan = Date.now();
                    }
                },
                wait - (Date.now() - lastRan),
            );
        }
    };
};
