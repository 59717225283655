import type {DisplayMediaOptions} from '@pexip/media-control';
import {
    limitSharingMonitorInterface,
    stopMediaStream,
} from '@pexip/media-control';
import {cancellablePromise} from '@pexip/utils';

export const createGetDisplayMedia = (
    getDefaultConstraints: () => DisplayMediaOptions,
    getDisplayMedia: MediaDevices['getDisplayMedia'] = constraints =>
        navigator.mediaDevices.getDisplayMedia(constraints),
) => {
    const props = {
        requesting: false,
    };
    return async (constraints?: DisplayMediaOptions) => {
        const [getCancelableDisplayMedia, cancel] = cancellablePromise(
            getDisplayMedia,
            stream => {
                stopMediaStream(stream);
            },
        );
        if (props.requesting) {
            // Cancel the previous request which has not been finished
            cancel();
        }
        try {
            props.requesting = true;
            const mergedConstraints = {
                ...getDefaultConstraints(),
                ...constraints,
            };
            const stream = await getCancelableDisplayMedia(mergedConstraints);
            if (!stream) {
                return;
            }
            return limitSharingMonitorInterface(mergedConstraints, stream);
        } finally {
            props.requesting = false;
        }
    };
};
export type GetDisplayMedia = ReturnType<typeof createGetDisplayMedia>;
