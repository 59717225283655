import React from 'react';
import cx from 'classnames';

import {Text} from '../../elements/Text/Text';

import styles from './Captions.module.scss';

export const Captions = React.forwardRef<
    HTMLSpanElement,
    {
        captions?: string;
        className?: string;
    }
>(({captions, className, ...props}, ref) => {
    if (captions) {
        return (
            <Text
                variant="inherit"
                htmlTag="span"
                className={cx(styles.captions, className)}
                ref={ref}
                {...props}
            >
                {captions}
            </Text>
        );
    }

    return null;
});

Captions.displayName = 'Captions';

export type CaptionsProps = React.ComponentProps<typeof Captions>;
