import React, {useMemo, useState} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {noop} from '@pexip/utils';
import type {MenuItem, AriaMenuItem} from '@pexip/components';
import {Button, Icon, IconTypes, Tooltip, AriaMenu} from '@pexip/components';

import type {InMeetingParticipant} from '../../types';
import {SpeakingIndicator} from '../SpeakingIndicator/SpeakingIndicator.view';
import {TestId} from '../../../test/testIds';

import type {RowActionState} from './ParticipantRowActions.module';
import {
    getShouldShowSelfActions,
    getShouldShowHostActions,
    getShouldShowIndicators,
    displayMuteHandCursor,
} from './ParticipantRowActions.module';

import styles from './ParticipantRowActions.module.scss';

const AdditionalActions: React.FC<{
    handleTransfer?: (identity: string) => void;
    handleDTMF?: (identity: string) => void;
    handleFecc?: (identity: string) => void;
    isDirectMedia?: boolean;
    isCurrentPeer: boolean;
    isSpotlight?: boolean;
    participant: InMeetingParticipant;
    additionalMenuContent: MenuItem[];
    button: React.ReactElement<typeof Button>;
    onOpenChange?: (state: boolean) => void;
}> = ({
    handleTransfer,
    handleDTMF,
    handleFecc,
    isDirectMedia,
    isCurrentPeer,
    isSpotlight,
    participant,
    additionalMenuContent,
    button,
    onOpenChange,
}) => {
    const {t} = useTranslation();

    const menuContent: AriaMenuItem[] = useMemo(() => {
        return [
            !isDirectMedia &&
                Boolean(participant.spotlight) &&
                participant.canSpotlight &&
                ({
                    label: isSpotlight
                        ? t('media.remove-spotlight', 'Remove spotlight')
                        : t('media.spotlight', 'Spotlight'),
                    onItemClick: () =>
                        participant.spotlight && participant.spotlight(),
                    testId: isSpotlight
                        ? TestId.ActionRemoveSpotlight
                        : TestId.ActionSpotlight,
                    closeMenuOnItemClick: false,
                    key: 'spotlight',
                } as AriaMenuItem),
            Boolean(handleTransfer) &&
                participant.canTransfer &&
                ({
                    label: t('media.transfer', 'Transfer'),
                    onItemClick: () => handleTransfer?.(participant.identity),
                    testId: TestId.ActionTransfer,
                    key: 'transfer',
                } as AriaMenuItem),
            Boolean(participant.setRole && !isCurrentPeer) &&
                !participant.isExternal &&
                ({
                    label: participant.isHost
                        ? t('meeting.make-guest', 'Make guest')
                        : t('meeting.make-host', 'Make host'),
                    onItemClick: () =>
                        participant.setRole && participant.setRole(),
                    testId: participant.isHost
                        ? TestId.ActionMakeGuest
                        : TestId.ActionMakeHost,
                    key: 'setRole',
                    closeMenuOnItemClick: false,
                } as AriaMenuItem),
            !isDirectMedia &&
                Boolean(handleDTMF) &&
                !participant.isConnecting &&
                ({
                    label: t('dtmf.enter-dtmf', 'Enter DTMF'),
                    onItemClick: () => handleDTMF?.(participant.identity),
                    testId: TestId.ActionEnterDTMF,
                    key: 'dtmf',
                } as AriaMenuItem),
            Boolean(handleFecc) &&
                participant.canFecc &&
                ({
                    label: t('fecc.camera-control', 'Camera control'),
                    onItemClick: () =>
                        handleFecc && handleFecc(participant.identity),
                    testId: TestId.ActionFecc,
                    key: 'fecc',
                } as AriaMenuItem),
            Boolean(participant.lowerHand) &&
                !isCurrentPeer &&
                participant.canRaiseHand &&
                participant.raisedHand && {
                    label: t('meeting.lower-hand', 'Lower hand'),
                    onItemClick: () =>
                        participant.lowerHand && participant.lowerHand(),
                    testId: TestId.ActionFecc,
                    key: 'lowerHand',
                    closeMenuOnItemClick: false,
                },
            ...additionalMenuContent.map(item => ({
                label: item.label,
                onItemClick: item.clickAction,
                key: item.label,
            })),
        ].filter(Boolean) as AriaMenuItem[];
    }, [
        isDirectMedia,
        participant,
        isSpotlight,
        t,
        handleTransfer,
        isCurrentPeer,
        handleDTMF,
        handleFecc,
        additionalMenuContent,
    ]);

    return (
        <AriaMenu
            onOpenChange={onOpenChange}
            button={button}
            items={menuContent}
            testId="kebab-menu"
        />
    );
};

export const ParticipantRowActions: React.FC<
    Omit<RowActionState, 'isMuted' | 'isCameraMuted' | 'isPresenting'> & {
        isDirectMedia?: boolean;
        displayName: string;
        handleRemoveUserRequest: (
            participant: InMeetingParticipant,
            displayName: string,
        ) => void;
        handleTransfer?: (identity: string) => void;
        handleDTMF?: (identity: string) => void;
        handleFecc?: (identity: string) => void;
        isCurrentPeer: boolean;
        isHost: boolean;
        participant: InMeetingParticipant;
        onMouseLeave: () => void;
        additionalMenuContent: MenuItem[];
    }
> = ({
    isDirectMedia,
    displayName,
    handleRemoveUserRequest,
    handleTransfer,
    handleDTMF,
    handleFecc,
    hasChildCalls,
    isCurrentPeer,
    canPerformHostActions,
    isHost,
    isHover,
    isSpeaking,
    participant,
    onMouseLeave,
    additionalMenuContent,
}) => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const {isMuted, isCameraMuted, isPresenting, isSpotlight} = participant;

    const isInteracted = isHover || isOpen;

    const shouldShowIndicators = getShouldShowIndicators({
        canPerformHostActions,
        isInteracted,
    });
    const shouldShowHostActions = getShouldShowHostActions({
        canPerformHostActions,
        isCurrentPeer,
        isHost,
        isInteracted,
    });
    const shouldShowHostActionsForCurrentPeer = getShouldShowHostActions({
        canPerformHostActions,
        isCurrentPeer: false,
        isHost,
        isInteracted,
    });
    const shouldShowSelfActions = getShouldShowSelfActions(
        isCurrentPeer,
        isInteracted,
    );

    const shouldShowActionOrIndicator =
        shouldShowIndicators || shouldShowHostActions;

    const hasRosterCapabilities = useMemo(
        () => displayMuteHandCursor(isHost, isCurrentPeer),
        [isCurrentPeer, isHost],
    );

    const hasAdditionalActions =
        participant.spotlight ??
        participant.setRole ??
        participant.lowerHand ??
        handleTransfer ??
        handleDTMF ??
        handleFecc;
    const shouldShowAdditionalActions =
        shouldShowHostActionsForCurrentPeer &&
        hasAdditionalActions &&
        (participant.canSpotlight || participant.canTransfer);

    const menuButton = (
        <Button
            aria-label="Open menu"
            role="button"
            className={styles.indicator}
            colorScheme="dark"
            isActive={isOpen}
            variant="tertiary"
            modifier="square"
            size="compact"
            data-testid={TestId.ParticipantMenuButton}
        >
            <Icon
                size="small"
                className={styles.actionButtonIcon}
                source={IconTypes.IconMoreHorizontal}
            />
        </Button>
    );

    return (
        <>
            {shouldShowAdditionalActions && (
                <AdditionalActions
                    handleTransfer={handleTransfer}
                    handleDTMF={handleDTMF}
                    handleFecc={handleFecc}
                    isDirectMedia={isDirectMedia}
                    isCurrentPeer={isCurrentPeer}
                    participant={participant}
                    isSpotlight={isSpotlight}
                    additionalMenuContent={additionalMenuContent}
                    button={menuButton}
                    onOpenChange={state => {
                        setIsOpen(state);
                        if (!state) {
                            onMouseLeave();
                        }
                    }}
                />
            )}
            {shouldShowHostActions && participant.canDisconnect && (
                <Tooltip text={t('common.remove')} position="top">
                    <Button
                        className={styles.indicator}
                        aria-label={t(
                            'meeting.remove-participant',
                            'Remove participant',
                        )}
                        role="button"
                        colorScheme="dark"
                        modifier="square"
                        onClick={() => {
                            handleRemoveUserRequest(participant, displayName);
                        }}
                        variant="tertiary"
                        size="compact"
                        data-testid={TestId.ButtonKickOffParticipant}
                    >
                        <Icon
                            className={styles.actionButtonIcon}
                            source={IconTypes.IconClose}
                            size="small"
                        />
                    </Button>
                </Tooltip>
            )}
            {isPresenting && shouldShowActionOrIndicator && (
                <Tooltip
                    text={t('media.presentation.presenting', 'Presenting')}
                    position="top"
                >
                    <Button
                        aria-label={t(
                            'media.presentation.presenting',
                            'Presenting',
                        )}
                        role="status"
                        className={cx(styles.indicator, {
                            [styles.noneRosterCanMuteButton]:
                                !hasRosterCapabilities,
                        })}
                        isActive
                        modifier="square"
                        onClick={noop}
                        size="compact"
                        variant="transparent"
                        data-testid={TestId.ButtonPeerPresenting}
                        noHover
                        htmlTag="div"
                    >
                        <Icon
                            className={styles.actionButtonIcon}
                            source={IconTypes.IconPresentationOn}
                            size="small"
                        />
                    </Button>
                </Tooltip>
            )}
            {isCameraMuted && !shouldShowSelfActions && (
                <Tooltip
                    text={t('media.camera-turned-off', 'Camera turned off')}
                    position="top"
                >
                    <Button
                        className={cx(styles.indicator, {
                            [styles.noneRosterCanMuteButton]:
                                !hasRosterCapabilities,
                        })}
                        isActive
                        aria-label={t(
                            'media.camera-turned-off',
                            'Camera turned off',
                        )}
                        role="status"
                        modifier="square"
                        onClick={noop}
                        size="compact"
                        variant="transparent"
                        data-testid={TestId.ButtonPeerMutedCamera}
                        noHover
                        htmlTag="div"
                    >
                        <Icon
                            className={styles.actionButtonIcon}
                            source={IconTypes.IconVideoOff}
                            size="small"
                        />
                    </Button>
                </Tooltip>
            )}
            {isCameraMuted && shouldShowSelfActions && (
                <Tooltip
                    text={t('media.turn-camera-on', 'Turn camera on')}
                    position="top"
                >
                    <Button
                        aria-label={t('media.turn-camera-on', 'Turn camera on')}
                        role="button"
                        className={styles.indicator}
                        colorScheme="dark"
                        modifier="square"
                        onClick={participant.muteVideo}
                        variant="tertiary"
                        size="compact"
                        data-testid={TestId.ButtonPeerUnmuteCamera}
                    >
                        <Icon
                            className={styles.actionButtonIcon}
                            source={IconTypes.IconVideoOff}
                            size="small"
                        />
                    </Button>
                </Tooltip>
            )}
            {isMuted && !hasChildCalls && shouldShowIndicators && (
                <Tooltip text={t('media.muted', 'Muted')} position="top">
                    <Button
                        aria-label={t('media.muted', 'Muted')}
                        role="button"
                        className={cx(styles.indicator, {
                            [styles.noneRosterCanMuteButton]:
                                !hasRosterCapabilities,
                        })}
                        isActive
                        modifier="square"
                        onClick={noop}
                        size="compact"
                        variant="transparent"
                        data-testid={TestId.ButtonPeerMuted}
                        noHover
                        htmlTag="div"
                    >
                        <Icon
                            className={styles.actionButtonIcon}
                            source={IconTypes.IconMicrophoneOff}
                            size="small"
                        />
                    </Button>
                </Tooltip>
            )}
            {isSpeaking && !isMuted && shouldShowIndicators && (
                <SpeakingIndicator
                    data-testid={TestId.SpeakingIndicator}
                    className={styles.indicator}
                />
            )}
            {(shouldShowHostActions ||
                (isMuted && shouldShowHostActionsForCurrentPeer)) &&
                participant.canMute && (
                    <Tooltip
                        text={
                            !shouldShowSelfActions
                                ? isMuted
                                    ? t(
                                          'media.unmute-participant',
                                          'Unmute participant',
                                      )
                                    : t(
                                          'media.mute-participant',
                                          'Mute participant',
                                      )
                                : isMuted
                                  ? t(
                                        'media.unmute-microphone',
                                        'Unmute microphone',
                                    )
                                  : t(
                                        'media.mute-microphone',
                                        'Mute microphone',
                                    )
                        }
                        position="top"
                    >
                        <Button
                            aria-label={
                                isMuted
                                    ? t(
                                          'media.unmute-participant',
                                          'Unmute participant',
                                      )
                                    : t(
                                          'media.mute-participant',
                                          'Mute participant',
                                      )
                            }
                            role="button"
                            className={styles.indicator}
                            colorScheme="dark"
                            modifier="square"
                            onClick={participant.mute}
                            variant="tertiary"
                            size="compact"
                            data-testid={TestId.ButtonMuteParticipant}
                        >
                            <Icon
                                className={styles.actionButtonIcon}
                                source={
                                    isMuted
                                        ? IconTypes.IconMicrophoneOff
                                        : IconTypes.IconMicrophoneOn
                                }
                                size="small"
                            />
                        </Button>
                    </Tooltip>
                )}
        </>
    );
};

export type ParticipantRowActionsProps = React.ComponentProps<
    typeof ParticipantRowActions
>;
