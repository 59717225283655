import {useRef, useState} from 'react';

export const usePresentationPoppedOut = () => {
    const remotePopOutRef = useRef<Window>();

    const [isPresentationPoppedOut, setIsPresentationPoppedOut] =
        useState(false);

    const toggleIsPresentationPoppedOut = () => {
        let newWindow: Window | null;
        if (!isPresentationPoppedOut) {
            const windowFeatures = 'width=848,height=480';
            newWindow = window.open('', '', windowFeatures);
            if (newWindow) {
                remotePopOutRef.current = newWindow;
            }
        }
        setIsPresentationPoppedOut(value => !value);
    };

    return {
        isPresentationPoppedOut,
        remotePopOutRef,
        setIsPresentationPoppedOut,
        toggleIsPresentationPoppedOut,
    };
};
