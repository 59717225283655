import React, {useEffect, useRef} from 'react';

import {noop} from '@pexip/utils';

export interface AudioElement extends HTMLAudioElement {
    setSinkId: (sinkId: string) => void;
}

export const Audio: React.FC<
    React.ComponentProps<'audio'> & {
        src?: string;
        srcObject?: MediaStream;
        play?: boolean;
        controls?: boolean;
        captionsSrc?: string;
        captionsSrcLang?: string;
        onFailure?: (reason: unknown) => void;
        onSuccess?: (audio: HTMLAudioElement | null) => void;
        sinkId?: string;
    }
> = ({
    src,
    srcObject,
    captionsSrc,
    captionsSrcLang,
    sinkId,
    play,
    controls = false,
    onFailure = noop,
    onSuccess = noop,
    ...props
}) => {
    const audioRef = useRef<AudioElement>(null);

    useEffect(() => {
        audioRef.current?.setSinkId?.(sinkId ?? '');
    }, [sinkId]);

    useEffect(() => {
        if (audioRef.current && srcObject) {
            audioRef.current.srcObject = srcObject;
            audioRef.current.setSinkId?.(sinkId ?? '');
        }
    }, [sinkId, srcObject]);

    useEffect(() => {
        const playAudio = async (play: boolean) => {
            try {
                if (play) {
                    await audioRef.current?.play();
                    audioRef.current && onSuccess(audioRef.current);
                } else {
                    audioRef.current?.pause();
                }
            } catch (error) {
                onFailure(error);
            }
        };
        if (play !== undefined) {
            void playAudio(play);
        }
    }, [onFailure, onSuccess, play]);

    return (
        <audio ref={audioRef} src={src} controls={controls} {...props}>
            {captionsSrc && (
                <track
                    kind="captions"
                    src={captionsSrc}
                    srcLang={captionsSrcLang}
                />
            )}
        </audio>
    );
};
