import React from 'react';
import {useTranslation} from 'react-i18next';

import {AboutText, ThemeProvider, Text} from '@pexip/components';
import {SettingsPanel, PanelHeader} from '@pexip/media-components';

import {APP_VERSION, VERSION, DIRTY, PLUGIN_API_VERSION} from '../constants';
import {applicationConfig} from '../applicationConfig';

export const About: React.FC<{onBackClick: () => void}> = ({onBackClick}) => {
    const {t, i18n} = useTranslation();
    return (
        <SettingsPanel
            headerContent={
                <ThemeProvider colorScheme="light">
                    <PanelHeader
                        title={`${t('settings.about', 'About')}`}
                        onBackClick={onBackClick}
                    />
                </ThemeProvider>
            }
        >
            <ThemeProvider colorScheme="light">
                <AboutText
                    className=""
                    headingText=""
                    spacing="none"
                    version={`${APP_VERSION}+${VERSION}${DIRTY}`}
                    enhancerEnd={
                        <Text>Plugin API Version: {PLUGIN_API_VERSION}</Text>
                    }
                    privacyPolicyUrl=""
                    privacyPolicyLinkText=""
                    termsOfServiceUrl={
                        applicationConfig.termsAndConditions?.[i18n.language] ??
                        applicationConfig.termsAndConditions?.en
                    }
                    termsOfServiceLinkText={t(
                        'common.terms-of-service',
                        'Terms of Service',
                    )}
                />
            </ThemeProvider>
        </SettingsPanel>
    );
};
