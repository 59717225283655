import {IconTypes} from '@pexip/components';

import {NetworkState} from '../../types';
import {TestId} from '../../../test/testIds';

export const stateToProps = {
    [NetworkState.Reconnecting]: {
        iconSrc: IconTypes.IconAlert,
        testId: TestId.NetworkReconnectingState,
    },
    [NetworkState.Reconnected]: {
        iconSrc: IconTypes.IconCheckmark,
        testId: TestId.NetworkReconnectedState,
    },
    // we want this to be the last state's UI so its preserved whilst fading out
    [NetworkState.Connected]: {
        iconSrc: IconTypes.IconCheckmark,
        testId: TestId.NetworkConnectedState,
    },
};
