import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {FontVariant, List, Text} from '@pexip/components';

import {useResponsiveStatsGrid} from '../../hooks/useResponsiveStatsGrid';

import styles from './SecureCheckCode.module.scss';

export const SecureCheckCode: React.FC<{
    secureCheckCode?: string;
}> = ({secureCheckCode}) => {
    const {isGridXs} = useResponsiveStatsGrid();
    const {t} = useTranslation();

    return secureCheckCode ? (
        <List>
            <Text
                fontVariant={
                    isGridXs ? FontVariant.XSmallBold : FontVariant.BodyBold
                }
            >
                <Trans t={t} i18nKey="stats.secure-check-code">
                    Secure check code
                </Trans>
            </Text>
            <Text
                className={styles.secureCheckCode}
                fontVariant={isGridXs ? FontVariant.XSmall : FontVariant.Small}
            >
                {secureCheckCode}
            </Text>
        </List>
    ) : (
        <></>
    );
};
