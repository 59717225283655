import {StreamQuality} from '../types';
import {INITIAL_STREAM_QUALITY} from '../constants';
import {logger} from '../logger';

export const getInitialStreamQuality = () => {
    if (
        !('connection' in navigator) ||
        !(navigator.connection && 'effectiveType' in navigator.connection)
    ) {
        return INITIAL_STREAM_QUALITY;
    }

    logger.info(navigator.connection, 'User connection');

    switch (navigator.connection.effectiveType) {
        case 'slow-2g':
        case '2g':
            return StreamQuality.Low;
        case '3g':
            return StreamQuality.Medium;
        default:
            return INITIAL_STREAM_QUALITY;
    }
};

declare global {
    interface Navigator {
        connection?: {
            effectiveType?: 'slow-2g' | '2g' | '3g' | '4g';
        };
    }
}
