import React from 'react';
import cx from 'classnames';

import type {ColorScheme} from '../../../types/variants';
import {ThemeConsumer} from '../../../themes/ThemeContext';

import styles from './RangeSlider.module.scss';

export const RangeSlider: React.FC<{
    max?: number;
    min?: number;
    step?: number;
    selectedValue?: number;
    colorScheme?: ColorScheme;
    className?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({
    colorScheme,
    className,
    max,
    min,
    step,
    selectedValue = 0,
    onChange,
}) => (
    <ThemeConsumer>
        {({colorScheme: defaultColorScheme}) => (
            <input
                type="range"
                className={cx(
                    styles.slider,
                    styles[colorScheme ?? defaultColorScheme],
                    className,
                )}
                max={max}
                min={min}
                step={step}
                value={selectedValue}
                onChange={onChange}
            />
        )}
    </ThemeConsumer>
);

export type RangeSliderProps = React.ComponentProps<typeof RangeSlider>;
