import React from 'react';

import {BrandedFullSizeWindow} from '../../viewModels/BrandedFullSizeWindow.viewModel';
import {MobileGetStarted as MobileGetStartedViewModel} from '../../viewModels/MobileGetStarted.viewModel';
import {NonMeetingHeader} from '../../viewModels/NonMeetingHeader.viewModel';

export const MobileGetStarted: React.FC = () => (
    <BrandedFullSizeWindow
        padding="none"
        background="image"
        flexDirection="column"
        justifyContent="flexStart"
    >
        <NonMeetingHeader />
        <MobileGetStartedViewModel />
    </BrandedFullSizeWindow>
);

export type MobileGetStartedProps = React.ComponentProps<
    typeof MobileGetStarted
>;
