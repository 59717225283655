import type React from 'react';
import {useEffect, useState} from 'react';

import {lastOr} from '@pexip/utils';

import type {ChatMessage} from '../types';
import {calculateHowManyUnreadMessages} from '../utils/chat';

import type {NewUnreadChatMessageActions} from './useChat';

export const useNewChatMessageCounter = (
    state: {
        isMessageByCurrentUser: boolean;
        isLastMessageVisible: boolean;
        messages: ChatMessage[];
    },
    setNewMessagesCount: (
        newMessagesCount: number,
    ) => NewUnreadChatMessageActions,
    dispatch: React.Dispatch<NewUnreadChatMessageActions>,
) => {
    const [lastVisibleMessageId, setLastVisibleMessageId] = useState('');
    const {isMessageByCurrentUser, isLastMessageVisible, messages} = state;

    useEffect(() => {
        const {id} = lastOr(messages, {
            id: '',
        } as ChatMessage);
        if (isLastMessageVisible && id) {
            setLastVisibleMessageId(id);
        } else if (!isMessageByCurrentUser) {
            dispatch(
                setNewMessagesCount(
                    calculateHowManyUnreadMessages(
                        messages,
                        lastVisibleMessageId,
                    ),
                ),
            );
        }
    }, [
        messages,
        isLastMessageVisible,
        lastVisibleMessageId,
        isMessageByCurrentUser,
        setNewMessagesCount,
        dispatch,
    ]);
};
