import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {
    ModalCloseButton as CloseButton,
    Icon,
    IconTypes,
    InteractiveElement,
    Pill,
    Text,
} from '@pexip/components';
import type {ClickEventHandler} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './NewChatMessagePill.module.scss';

export const NewChatMessagePill: React.FC<{
    newMessagesCount: number;
    closeNewChatMessage: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
    scrollToLastMessage: () => void;
}> = ({newMessagesCount, closeNewChatMessage, scrollToLastMessage}) => {
    const {t} = useTranslation();

    const message = useMemo(
        () =>
            newMessagesCount > 1
                ? t('meeting.chat-new-messages', {
                      defaultValue: '{{newMessagesCount}} new messages',
                      newMessagesCount,
                  })
                : t('meeting.chat-new-message', 'new message'),
        [newMessagesCount, t],
    );

    return (
        <div className={styles.newMessagesPillWrapper}>
            <InteractiveElement
                aria-label={t(
                    'meeting.chat-scroll-last-message',
                    'Scroll to last chat message',
                )}
                onClick={scrollToLastMessage}
                data-testid={TestId.PillNewChatMessage}
            >
                <Pill className={styles.newMessagesPill}>
                    <div className={styles.newMessagesPillContent}>
                        <Icon source={IconTypes.IconArrowDown} size="compact" />
                        <div className="ml-2 mr-2">
                            <Text>{message}</Text>
                        </div>
                    </div>
                    <div className="pr-3" />
                </Pill>
            </InteractiveElement>
            <CloseButton
                onClose={closeNewChatMessage as ClickEventHandler}
                aria-label={t(
                    'meeting.close-new-chat-alert',
                    'Close new chat message alert',
                )}
                className={styles.closeButton}
            />
        </div>
    );
};

export type NewChatMessagePillProps = React.ComponentProps<
    typeof NewChatMessagePill
>;
