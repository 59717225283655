import React from 'react';
import cx from 'classnames';

import {Bar, Group} from '@pexip/components';

import styles from './PreflightControlsWrapper.module.scss';

export const PreflightControlsWrapper: React.FC<
    React.PropsWithChildren<{
        isPhone: boolean;
        position: 'relative' | 'absoluteBottom' | 'absoluteTop';
        shouldCenter: boolean;
    }>
> = ({children, isPhone, position, shouldCenter}) => (
    <Bar
        className={cx(styles.wrapper, {
            [styles.phone]: isPhone,
            'justify-content-center': shouldCenter,
        })}
        padding={position === 'relative' && !isPhone ? 'medium' : 'small'}
        position={position}
    >
        {shouldCenter ? (
            <Group spacing="compact" justifyContent="center">
                {children}
            </Group>
        ) : (
            children
        )}
    </Bar>
);

export type PreflightControlsWrapperProps = React.ComponentProps<
    typeof PreflightControlsWrapper
>;
