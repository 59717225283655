export type UserAgents =
    | 'Chrome'
    | 'Edge Chromium'
    | 'Firefox'
    | 'Safari Mac'
    | 'Safari iPad'
    | 'Safari iPhone'
    | 'Android'
    | 'Internet Explorer 10'
    | 'Internet Explorer 11'
    | 'EdgeHTML (Microsoft Edge Legacy)';

/**
 * Extract browser version out of the provided user agent string.
 *
 * @param userAgentString - userAgent string.
 * @param expr - Regular expression used as match criteria.
 * @param pos - position in the version string to be returned.
 * @returns browser version.
 */
export const extractVersion = (
    userAgentString: string,
    expr: RegExp,
    pos: number,
): number => {
    const match = userAgentString.match(expr);
    return (
        (match && match.length >= pos && parseInt(match[pos] ?? '0', 10)) || 0
    );
};

interface BrowserDetails {
    browser:
        | ''
        | 'Not a browser.'
        | 'chrome'
        | 'firefox'
        | 'safari'
        | 'Not a supported browser.';
    version: number;
    supportsUnifiedPlan: boolean;
}
type WebRTC = Pick<
    typeof window,
    'navigator' | 'RTCPeerConnection' | 'RTCRtpTransceiver'
>;
type WebRTCTransceiver = Pick<typeof window, 'RTCRtpTransceiver'>;
type SecureContext = Pick<typeof window, 'isSecureContext'>;
interface FirefoxWebRTCFeatures extends WebRTC {
    mozGetUserMedia?: MediaDevices;
}
interface ChromiumWebRTC extends SecureContext {
    webkitGetUserMedia?: MediaDevices;
    webkitRTCPeerConnection?: RTCPeerConnection;
    RTCIceGatherer?: never;
}
type SafariWebRTC = WebRTC;

export const isWebRTCBrowser = (win: WebRTC = window) => {
    if (
        !win?.navigator ||
        !('RTCPeerConnection' in win) ||
        !('RTCRtpTransceiver' in win)
    ) {
        return false;
    }
    return true;
};

// Feature detections
export const isFirefoxWebRTC = (win: FirefoxWebRTCFeatures = window) =>
    'mozGetUserMedia' in win ||
    'peerIdentity' in win.RTCPeerConnection.prototype;
/**
 * Chrome, Chromium, Webview, Opera.
 * Version matches Chrome/WebRTC version.
 * Chrome 74 removed webkitGetUserMedia on http as well so we need the
 * more complicated fallback to webkitRTCPeerConnection.
 */
export const isChromiumWebRTC = (win: ChromiumWebRTC = window) =>
    'webkitGetUserMedia' in navigator ||
    (!win.isSecureContext &&
        'webkitRTCPeerConnection' in win &&
        !('RTCIceGatherer' in win));
export const isSafariWebRTC = (win: SafariWebRTC = window) =>
    Boolean(
        win.RTCPeerConnection &&
            navigator.userAgent.match(/AppleWebKit\/(\d+)\./),
    );

export const supportUnifiedPlan = (win: Partial<WebRTCTransceiver> = window) =>
    Boolean(
        win.RTCRtpTransceiver &&
            'currentDirection' in win.RTCRtpTransceiver.prototype,
    );

interface BrowserDetectionDependencies {
    hasWebRTC: typeof isWebRTCBrowser;
    isFirefox: typeof isFirefoxWebRTC;
    isChromium: typeof isChromiumWebRTC;
    isSafari: typeof isSafariWebRTC;
    hasUnifiedPlan: typeof supportUnifiedPlan;
}

/**
 * Browser detector from webrtc-adapter
 *
 * https://github.com/webrtcHacks/adapter/blob/18a8b4127cbc1376320cac5742d817b5b7dd0085/src/js/utils.js#L151
 */
export function detectBrowser({
    hasWebRTC = isWebRTCBrowser,
    isFirefox = isFirefoxWebRTC,
    isChromium = isChromiumWebRTC,
    isSafari = isSafariWebRTC,
    hasUnifiedPlan = supportUnifiedPlan,
}: Partial<BrowserDetectionDependencies> = {}) {
    // Returned result object.
    const result: BrowserDetails = {
        browser: '',
        version: 0,
        supportsUnifiedPlan: false,
    };

    // Fail early if it's not a browser
    if (!hasWebRTC()) {
        result.browser = 'Not a browser.';
        return result;
    }

    if (isFirefox()) {
        // Firefox.
        result.browser = 'firefox';
        result.version = extractVersion(
            navigator.userAgent,
            /Firefox\/(\d+)\./,
            1,
        );
        result.supportsUnifiedPlan = hasUnifiedPlan();
    } else if (isChromium()) {
        result.browser = 'chrome';
        result.version = extractVersion(
            navigator.userAgent,
            /Chrom(e|ium)\/(\d+)\./,
            2,
        );
        result.supportsUnifiedPlan = hasUnifiedPlan();
    } else if (isSafari()) {
        // Safari.
        result.browser = 'safari';
        result.version = extractVersion(
            navigator.userAgent,
            /AppleWebKit\/(\d+)\./,
            1,
        );
        result.supportsUnifiedPlan = hasUnifiedPlan();
    } else {
        // Default fallthrough: not supported.
        result.browser = 'Not a supported browser.';
        return result;
    }

    return result;
}

/**
 * returns the browser name based on given userAgent.
 *
 * `adapter.browserDetails.browser` does not support all browser (eg. Edge Chromium).
 * this detection can be extended when needed for more specific browsers (eg. Safari IOS)
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
 */
export const getBrowserName = (userAgent: string): UserAgents | undefined => {
    if (userAgent.includes('Edg/')) {
        return 'Edge Chromium';
    }

    if (userAgent.includes('Firefox/')) {
        return 'Firefox';
    }

    if (userAgent.includes('Chrome/') || userAgent.includes('CriOS/')) {
        return 'Chrome';
    }

    if (userAgent.includes('Android')) {
        return 'Android';
    }

    if (userAgent.includes('Safari/') && !userAgent.includes('Mobile/')) {
        return 'Safari Mac';
    }

    if (userAgent.includes('Safari/') && userAgent.includes('iPad')) {
        return 'Safari iPad';
    }

    if (userAgent.includes('Safari/') && userAgent.includes('iPhone')) {
        return 'Safari iPhone';
    }
};

const browser = detectBrowser();

/**
 * Detects weather the current browser is supported
 *
 * The detection logic based on `webrtc-adapter` library, which will return browser name or `Not a browser.` or `Not a supported browser.` message.
 */
export const isBrowserSupported = (detect = detectBrowser) =>
    !detect().browser.includes('Not a');

export const isCurrentBrowserSupported = isBrowserSupported();
export const isScreenShareSupported =
    navigator?.mediaDevices && 'getDisplayMedia' in navigator.mediaDevices;
export const isFirefox = browser.browser === 'firefox';
export const browserVersion = browser.version;

export const currentBrowserName = getBrowserName(navigator?.userAgent);
