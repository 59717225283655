import {useCallback, useEffect} from 'react';

import {createSignal} from '@pexip/signal';

import {useFullscreenApi} from './useFullscreenApi';

export const fullscreenSignal = createSignal<boolean>({
    name: 'fullscreen:state',
});

/**
 * a boolean that indicates whether the document is in full-screen mode.
 */
export const isFullscreen = (fullscreenElement: Element | null) =>
    fullscreenElement !== null ? true : false;

const emitFullscreenState = (fullscreenElement: Element | null) => {
    if (fullscreenElement !== null) {
        fullscreenSignal.emit(true);
    } else {
        fullscreenSignal.emit(false);
    }
};

/**
 * `useCallback` to toggle browser's fullscreen.
 */
export const useToggleFullscreen = () => {
    const fullscreenApi = useFullscreenApi();

    const toggleFullscreen = useCallback(() => {
        if (isFullscreen(fullscreenApi.fullscreenElement)) {
            void fullscreenApi.exitFullscreen?.();
        } else {
            void fullscreenApi?.requestFullscreen();
        }
    }, [fullscreenApi]);

    useEffect(() => {
        const callback = () =>
            emitFullscreenState(fullscreenApi.fullscreenElement);

        document.addEventListener(fullscreenApi.fullscreenchange, callback);

        return () =>
            document.removeEventListener(
                fullscreenApi.fullscreenchange,
                callback,
            );
    }, [fullscreenApi.fullscreenElement, fullscreenApi.fullscreenchange]);

    return toggleFullscreen;
};
