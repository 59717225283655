import {useCallback, useEffect, useRef, useState} from 'react';

import type {BreakoutParticipants} from '../types';
import type {ShuffleBreakoutParticipants} from '../utils';
import {shuffleBreakoutParticipants} from '../utils';

export const useBreakoutShuffleParticipants = ({
    setParticipants,
    myIdentity,
    shuffle = shuffleBreakoutParticipants,
}: {
    myIdentity?: string;
    setParticipants: React.Dispatch<React.SetStateAction<BreakoutParticipants>>;
    shuffle?: ShuffleBreakoutParticipants;
}) => {
    const shuffleTimeout = useRef<number>();
    const [isShuffling, setIsShuffling] = useState(false);

    const doShuffle = useCallback(() => {
        setIsShuffling(true);
        setParticipants(prevParticipants =>
            shuffle({allParticipants: prevParticipants, identity: myIdentity}),
        );
        shuffleTimeout.current = window.setTimeout(() => {
            // minimum time to show that shuffling is ongoing
            setIsShuffling(false);
        }, 500);
    }, [myIdentity, setParticipants, shuffle]);

    useEffect(() => {
        return () => {
            clearTimeout(shuffleTimeout.current);
        };
    }, []);

    return {isShuffling, doShuffle};
};
