import {MIN_VIEWPORT_WIDTH} from '../constants';

import {getBrowserName} from './detectedBrowser';

export enum Platform {
    IOS,
    Android,
    WebOS,
    BlackBerry,
    WindowsPhone,
    Mac,
    Windows,
    Linux,
    Unknown,
}

const IOS_PATTERNS = [/iPhone/i, /iPad/i, /iPod/i];

const MOBILE_PATTERNS = [
    /Android/i,
    /webOS/i,
    /BlackBerry/i,
    /Windows Phone/i,
    ...IOS_PATTERNS,
];

export const execRegex = (target: string, pattern: string | RegExp) => {
    const regexPattern = new RegExp(pattern);
    return !!regexPattern.exec(target);
};

export const isTouchScreen = () => {
    if ('matchMedia' in window) {
        const media = '(any-pointer: fine)';
        const query = matchMedia(media);
        if (query.matches) {
            return false;
        }
    }

    if ('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0) {
        return true;
    }

    // Deprecated but still useful
    if ('orientation' in window) {
        return true;
    }

    return false;
};

export const isPortrait = (width: number, height: number) => width < height;

export const isSmallScreen = (
    width: number,
    height: number,
    minWidth = MIN_VIEWPORT_WIDTH,
) => (isPortrait(width, height) ? width < minWidth : height < minWidth);

export const getPlatform = (userAgent = navigator.userAgent) => {
    const isIpadOS = execRegex(userAgent, /Macintosh/i) && isTouchScreen();

    if (
        IOS_PATTERNS.some(pattern => execRegex(userAgent, pattern)) ||
        isIpadOS
    ) {
        return Platform.IOS;
    }
    if (execRegex(userAgent, /Windows Phone/i)) {
        return Platform.WindowsPhone;
    }
    // An Android useragent string also contains
    // 'Linux', but we return early here. Match Linux
    // only later.
    if (execRegex(userAgent, /Android/i)) {
        return Platform.Android;
    }
    if (execRegex(userAgent, /webOS/i)) {
        return Platform.WebOS;
    }
    if (execRegex(userAgent, /BlackBerry/i)) {
        return Platform.BlackBerry;
    }
    if (execRegex(userAgent, /Macintosh/i)) {
        return Platform.Mac;
    }
    if (execRegex(userAgent, /Windows/i)) {
        return Platform.Windows;
    }
    if (execRegex(userAgent, /Linux/i)) {
        return Platform.Linux;
    }

    return Platform.Unknown;
};

export const isMicrosoftSurface =
    getPlatform() === Platform.Windows && isTouchScreen();

export const isMobileDevice = (userAgent = navigator.userAgent) => {
    if (isMicrosoftSurface) {
        return false;
    }

    if (isTouchScreen()) {
        return true;
    }

    return MOBILE_PATTERNS.some(pattern => execRegex(userAgent, pattern));
};

export const isPhone =
    isMobileDevice() &&
    isSmallScreen(window.screen.width, window.screen.height);

export const isTablet =
    isMobileDevice() &&
    !isSmallScreen(window.screen.width, window.screen.height) &&
    !isMicrosoftSurface;

export const isCompatibleMobileDevice =
    getPlatform() !== Platform.IOS ||
    (getPlatform() === Platform.IOS &&
        execRegex(getBrowserName(navigator.userAgent) || '', /Safari/i));

export const platform = getPlatform();
