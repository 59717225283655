// Prime numbers
const p1 = 24317;
const p2 = 32141;
const p3 = 63629; // for shuffling 60,000+ indexes (only needs 32bit unsigned math)

/**
 * Pseudorandom index generator implemented with
 * Miller Shuffle Algorithm D variant     NEW April 2023
 * aka:   MillerShuffleAlgo_d
 *
 * It produces a shuffled Index given a base Index, a shuffle ID "seed" and the length of the list being
 * indexed. For each inx: 0 to listSize-1, unique indexes are returned in a pseudo "random" order.
 * Utilizes minimum resources. As such there is no better choice for a playlist shuffle.
 *
 * @see {@link https://en.wikipedia.org/wiki/Pseudorandom_index_generator}
 * @see {@link https://github.com/RondeSC/Miller_Shuffle_Algo}
 *
 * @param baseIndex - The index to be shuffled
 * @param shuffleID - The seed number
 * @param listSize - The size of the list
 *
 * @returns shuffled index
 */
export function prig(
    baseIndex: number,
    shuffleID: number,
    listSize: number,
): number {
    // local randomizer copy
    const randR = shuffleID + 131 * Math.floor(baseIndex / listSize); //  have inx overflow effect the mix

    let si = (baseIndex + randR) % listSize;

    const r1 = randR % p1; // shuffle rx fixed values are not super important
    const r2 = ((randR * 0x89) ^ r1) % p2;
    const r3 = (r1 + r2 + p3) % listSize;
    const r4 = r1 ^ r2 ^ r3;

    // perform the conditional multi-faceted mathematical spin-mixing
    if (si % 3 === 0) {
        si = (((si / 3) * p1 + r1) % Math.floor((listSize + 2) / 3)) * 3; // spin multiples of 3
    }
    if (si % 2 === 0) {
        si = (((si / 2) * p2 + r2) % Math.floor((listSize + 1) / 2)) * 2; // spin multiples of 2
    }
    if (si < Math.floor(listSize / 2)) {
        si = (si * p3 + r4) % Math.floor(listSize / 2);
    }
    si = (si * p3 + r3) % listSize; // relatively prime gears turning operation

    return si; // return 'Shuffled' index
}
