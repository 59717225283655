import React from 'react';

import {Button, Icon, IconTypes} from '@pexip/components';

import styles from './PanelHeaderWrapper.module.scss';

export const PanelHeaderWrapper: React.FC<
    React.PropsWithChildren & {
        onCloseClick?: () => void;
        closeButtonAriaLabel?: string;
    }
> = ({children, closeButtonAriaLabel = 'Close panel', onCloseClick}) => (
    <div className={styles.container}>
        <div className={styles.title}>{children}</div>
        {onCloseClick && (
            <Button
                aria-label={closeButtonAriaLabel}
                onClick={onCloseClick}
                onPointerDownCapture={(e: React.MouseEvent) =>
                    e.stopPropagation()
                }
                size="compact"
                variant="translucent"
            >
                <Icon size="compact" source={IconTypes.IconClose}></Icon>
            </Button>
        )}
    </div>
);
