import React, {useMemo} from 'react';

import {TextLink} from '../TextLink/TextLink';

import {generateURLsFromText} from './urlToLink.utils';

export const URLToLink: React.FC<{text: string}> = ({
    text,
    ...textLinkProps
}) => {
    const finalTextBlocks = useMemo(() => generateURLsFromText(text), [text]);

    return (
        <>
            {finalTextBlocks.map(link => {
                if (link.linkType !== 'text') {
                    return (
                        <TextLink
                            key={link.id}
                            href={link.url}
                            target="_blank"
                            {...textLinkProps}
                        >
                            {link.value}
                        </TextLink>
                    );
                }

                return link.value;
            })}
        </>
    );
};
