import React from 'react';

export const Form: React.FC<React.ComponentProps<'form'>> = ({
    children,
    className,
    ...props
}) => (
    <form className={className} {...props}>
        {children}
    </form>
);
