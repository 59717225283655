import React from 'react';
import cx from 'classnames';

import {InteractiveElement} from '../../elements/InteractiveElement/InteractiveElement';
import {Icon, IconTypes} from '../../elements/Icon/Icon';
import {Text} from '../../elements/Text/Text';
import {onEnter} from '../../../utils/onEnter';
import {FontVariant} from '../../../../design-tokens/constants';

import styles from './Accordion.module.scss';

const AccordionSummary: React.FC<
    React.PropsWithChildren<{
        className?: string;
        canOnlyToggleWithTitle?: boolean;
        isExpanded: boolean;
        onExpand: (event: React.SyntheticEvent<HTMLElement>) => void;
    }>
> = ({
    canOnlyToggleWithTitle,
    children,
    className,
    isExpanded,
    onExpand,
    ...props
}) => {
    const summaryClassName = cx(styles.summary, className);

    return canOnlyToggleWithTitle ? (
        <div className={summaryClassName}>{children}</div>
    ) : (
        <InteractiveElement
            className={summaryClassName}
            aria-expanded={isExpanded}
            onClick={onExpand}
            onKeyDown={onEnter(onExpand)}
            {...props}
        >
            {children}
        </InteractiveElement>
    );
};

const AccordionChevronIcon: React.FC<{isExpanded: boolean}> = ({
    isExpanded,
}) => {
    const iconType = isExpanded
        ? IconTypes.IconChevronDown
        : IconTypes.IconChevronRight;
    return (
        <Icon
            size="compact"
            source={iconType}
            className={cx(styles.chevron, 'mr-2')}
        />
    );
};

const AccordionButtonTitle: React.FC<
    React.PropsWithChildren<{
        canOnlyToggleWithTitle?: boolean;
        isExpanded: boolean;
        onExpand: (event: React.SyntheticEvent<HTMLElement>) => void;
    }>
> = ({children, isExpanded, canOnlyToggleWithTitle, onExpand}) => {
    const Title = () => (
        <>
            <AccordionChevronIcon isExpanded={isExpanded} />
            {children}
        </>
    );

    return canOnlyToggleWithTitle ? (
        <InteractiveElement
            className={styles.accordionButton}
            aria-expanded={isExpanded}
            onClick={onExpand}
            onKeyDown={onEnter(onExpand)}
        >
            <Title />
        </InteractiveElement>
    ) : (
        <div className={styles.accordionButton}>
            <Title />
        </div>
    );
};

const AccordionButtonMeta: React.FC<{meta?: string | React.ReactNode}> = ({
    meta,
}) => {
    const metaClassName = 'd-inline ml-2';
    return typeof meta === 'string' ? (
        <Text
            fontVariant={FontVariant.BodyBold}
            className={cx(styles.meta, metaClassName)}
        >
            {meta}
        </Text>
    ) : (
        <div className={metaClassName}>{meta}</div>
    );
};

export const AccordionButton: React.FC<{
    canOnlyToggleWithTitle?: boolean;
    children: React.ReactNode;
    className?: string;
    enhancerEnd?: React.ReactNode;
    isExpanded: boolean;
    meta?: string | React.ReactNode;
    onExpand: (event: React.SyntheticEvent<HTMLElement>) => void;
}> = ({
    canOnlyToggleWithTitle,
    children,
    enhancerEnd,
    meta,
    isExpanded,
    onExpand,
    className,
    ...props
}) => {
    return (
        <AccordionSummary
            canOnlyToggleWithTitle={canOnlyToggleWithTitle}
            className={className}
            isExpanded={isExpanded}
            onExpand={onExpand}
            {...props}
        >
            <div className="d-flex align-items-center">
                <AccordionButtonTitle
                    canOnlyToggleWithTitle={canOnlyToggleWithTitle}
                    isExpanded={isExpanded}
                    onExpand={onExpand}
                >
                    {children}
                </AccordionButtonTitle>
                <AccordionButtonMeta meta={meta} />
                <div className="ml-auto">{enhancerEnd}</div>
            </div>
        </AccordionSummary>
    );
};
