import React from 'react';
import cx from 'classnames';

import {ModalCloseButton} from '../CloseButton/ModalCloseButton.view';
import type {ClickEventHandler} from '../../../types/events';

import styles from './Sheet.module.scss';

const handleClick: ClickEventHandler = e => e.stopPropagation();

export const Sheet: React.FC<
    React.ComponentProps<'div'> & {
        closeButtonAriaLabel?: string;
        isOpen: boolean;
        onClose: () => void;
        withCloseButton?: boolean;
    }
> = ({
    isOpen = false,
    closeButtonAriaLabel = 'Close sheet',
    onClose,
    children,
    withCloseButton = false,
    className,
}) => {
    return (
        <div
            className={cx(styles.wrapper, {[styles.open]: isOpen})}
            onClick={onClose}
            role="none"
        >
            <div
                className={cx(styles.sheet, className)}
                onClick={handleClick}
                onKeyDown={handleClick}
                role="none"
            >
                <div className={styles.sheetInner}>
                    {children}
                    {withCloseButton && (
                        <ModalCloseButton
                            aria-label={closeButtonAriaLabel}
                            onClose={onClose}
                            className={styles.closeButton}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
