/**
 * A Queue to serve first-in-first-out (FIFO)
 */
export interface Queue<T> {
    /**
     * Size of the queue
     */
    size: number;
    /**
     * Max size of the queue
     */
    maxSize: number;

    /**
     * Empty the queue
     */
    empty(): void;
    /**
     * Append the item to the end of the queue, drop when overflow
     */
    enqueue(item: T): number;
    /**
     * Put the item to the |start| position of the queue, drop when overflow
     */
    enqueueAt(start: number, item: T, replace: boolean): number;
    /**
     * Remove the first item from the queue and return it
     */
    dequeue(): T | undefined;

    /**
     * Remove all items from the queue and return them as an Array
     */
    dequeueAll(): T[];
}

export const QUEUE_SIZE = 5;
export const QUEUE_DROP_LAST = false;

export const createQueue = <T>(
    maxSize: number = QUEUE_SIZE,
    initial = [] as T[],
    dropLast = QUEUE_DROP_LAST,
): Queue<T> => {
    if (initial.length > maxSize || 0 >= maxSize) {
        throw new Error('InvalidQueueSize');
    }
    let queue = initial;

    const enqueue: Queue<T>['enqueue'] = item => {
        if (queue.length >= maxSize) {
            if (dropLast) {
                queue.splice(-1, 1, item);
            } else {
                queue.shift();
                queue.push(item);
            }
            return queue.length;
        }
        return queue.push(item);
    };

    const enqueueAt: Queue<T>['enqueueAt'] = (start, item, replace = false) => {
        if (replace) {
            queue.splice(start, 1, item);
        } else {
            if (queue.length >= maxSize) {
                if (dropLast) {
                    queue.splice(-1, 1);
                } else {
                    queue.shift();
                }
            }
            queue.splice(start, 0, item);
        }
        return queue.length;
    };

    const dequeue: Queue<T>['dequeue'] = () => {
        return queue.shift();
    };

    const dequeueAll: Queue<T>['dequeueAll'] = () => {
        const tmp = queue;
        queue = [];
        return tmp;
    };

    const empty = () => {
        queue = [];
    };

    return {
        get size() {
            return queue.length;
        },
        get maxSize() {
            return maxSize;
        },
        empty,
        enqueue,
        enqueueAt,
        dequeue,
        dequeueAll,
    };
};
