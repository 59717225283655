import {ClientCallType} from '@pexip/infinity';

import {CALL_TYPE} from '../constants';

export const toClientCallType = (callType: string): ClientCallType => {
    switch (callType) {
        case 'none': {
            return ClientCallType.None;
        }
        case 'audioonly': {
            return ClientCallType.Audio;
        }
        case 'audiorecvonly': {
            return ClientCallType.AudioRecvOnly;
        }
        case 'audiosendonly': {
            return ClientCallType.AudioSendOnly;
        }
        case 'videoonly': {
            return ClientCallType.Video;
        }
        case 'videorecvonly': {
            return ClientCallType.VideoRecvOnly;
        }
        case 'videosendonly': {
            return ClientCallType.VideoSendOnly;
        }
        case 'audiovideosendonly': {
            return ClientCallType.AudioSendVideoSendOnly;
        }
        case 'audiovideorecvonly': {
            return ClientCallType.AudioRecvVideoRecvOnly;
        }
        case 'video': {
            return ClientCallType.AudioVideo;
        }
        default: {
            return CALL_TYPE;
        }
    }
};
