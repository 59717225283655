import {Trans, useTranslation} from 'react-i18next';
import React, {useMemo} from 'react';

import {UserMediaStatus} from '@pexip/media';

import type {DeviceStatusInfo} from '../types';

interface RequestedMedia {
    audio: boolean;
    video: boolean;
}

export const useDeviceStatusInfo = (
    status: UserMediaStatus | undefined,
    {audio, video}: RequestedMedia,
): DeviceStatusInfo => {
    const {t} = useTranslation();
    const DeviceStatusInfos: Record<UserMediaStatus, DeviceStatusInfo> =
        useMemo(() => {
            const requestPermissionTitle =
                audio && video
                    ? t(
                          'device-status.initial-permissions-not-granted.title',
                          'We need your permission to use your camera and microphone',
                      )
                    : audio
                      ? t(
                            'device-status.initial-permissions-not-granted.title-audioonly',
                            'We need your permission to use your microphone',
                        )
                      : video
                        ? t(
                              'device-status.initial-permissions-not-granted.title-videoonly',
                              'We need your permission to use your camera',
                          )
                        : '';
            return {
                [UserMediaStatus.Initial]: {
                    title: '',
                    message: '',
                },
                [UserMediaStatus.InitialPermissionsGranted]: {
                    title: t(
                        'device-status.initial-permissions-granted.title',
                        'Permissions granted',
                    ),
                    message: t(
                        'device-status.initial-permissions-granted.message',
                        'You have granted permissions to use your camera and microphone',
                    ),
                    controls: 'media',
                },
                [UserMediaStatus.InitialPermissionsVideoInputDenied]: {
                    title: t(
                        'device-status.initial-permissions-video-input-denied.title',
                        'You have not allowed access to your microphone',
                    ),
                    message: t(
                        'device-status.initial-permissions-video-input-denied.message',
                        'You have denied permission to use your camera',
                    ),
                    controls: 'media',
                },
                [UserMediaStatus.InitialPermissionsAudioInputDenied]: {
                    title: t(
                        'device-status.videoinput-granted-audioinput-denied.title',
                        'You have not allowed access to your microphone',
                    ),
                    message: t(
                        'device-status.videoinput-granted-audioinput-denied.message',
                        'Access to your microphone is required if you want others to hear you in the call',
                    ),
                    controls: 'media',
                },
                [UserMediaStatus.InitialPermissionsVideoInputGranted]: {
                    title: t(
                        'device-status.initial-permissions-video-input-granted.title',
                        'Permissions partially granted',
                    ),
                    message: t(
                        'device-status.initial-permissions-video-input-granted.message',
                        'You have not granted permissions to use your microphone',
                    ),
                    controls: 'media',
                },
                [UserMediaStatus.InitialPermissionsAudioInputGranted]: {
                    title: t(
                        'device-status.initial-permissions-audio-input-granted.title',
                        'Permissions partially granted',
                    ),
                    message: t(
                        'device-status.initial-permissions-audio-input-granted.message',
                        'You have not granted permissions to use your camera',
                    ),
                    controls: 'media',
                },
                [UserMediaStatus.InitialPermissionsGrantedAudioInputDenied]: {
                    title: t(
                        'device-status.videoinput-granted-audioinput-denied.title',
                        'You have not allowed access to your microphone',
                    ),
                    message: t(
                        'device-status.videoinput-granted-audioinput-denied.message',
                        'Access to your microphone is required if you want others to hear you in the call',
                    ),
                    controls: 'media',
                },
                [UserMediaStatus.InitialPermissionsGrantedVideoInputDenied]: {
                    title: t(
                        'device-status.audioinput-granted-videoinput-denied.title',
                        'You have not allowed access to your camera',
                    ),
                    message: t(
                        'device-status.audioinput-granted-videoinput-denied.message',
                        'Access to your camera is required if you want others to see you in the call',
                    ),
                    controls: 'media',
                },

                [UserMediaStatus.NoDevicesFound]: {
                    title: t(
                        'device-status.no-devices-found.title',
                        'There’s no connected camera and microphone',
                    ),
                    message: t(
                        'device-status.no-devices-found.message',
                        'Your camera and microphone seem to be unavailable. Please check if they are plugged in and set up correctly',
                    ),
                    controls: 'media',
                    warning: 'audio-video',
                    error: 'audio-video',
                    distinctiveTitles: {
                        videoError: {
                            title: t(
                                'device-status.no-video-devices-found.title',
                                'There’s no connected camera',
                            ),
                            description: t(
                                'device-status.no-video-devices-found.message',
                                'Your camera seems to be unavailable. Please check if it is plugged in and set up correctly',
                            ),
                        },
                        audioError: {
                            title: t(
                                'device-status.no-audio-devices-found.title',
                                'There’s no connected microphone',
                            ),
                            description: t(
                                'device-status.no-audio-devices-found.message',
                                'Your microphone seems to be unavailable. Please check if it is plugged in and set up correctly',
                            ),
                        },
                    },
                },
                [UserMediaStatus.NoVideoDevicesFound]: {
                    title: t(
                        'device-status.no-video-devices-found.title',
                        'There’s no connected camera',
                    ),
                    message: t(
                        'device-status.no-video-devices-found.message',
                        'Your camera seems to be unavailable. Please check if it is plugged in and set up correctly',
                    ),
                    controls: 'media',
                    warning: 'video',
                    error: 'video',
                },
                [UserMediaStatus.NoAudioDevicesFound]: {
                    title: t(
                        'device-status.no-audio-devices-found.title',
                        'There’s no connected microphone',
                    ),
                    message: t(
                        'device-status.no-audio-devices-found.message',
                        'Your microphone seems to be unavailable. Please check if it is plugged in and set up correctly',
                    ),
                    controls: 'media',
                    warning: 'audio',
                    error: 'audio',
                },
                [UserMediaStatus.AudioVideoDevicesNotFound]: {
                    title: t(
                        'device-status.audio-video-devices-not-found.title',
                        'We can’t find your camera and microphone',
                    ),
                    message: t(
                        'device-status.audio-video-devices-not-found.message',
                        'Your preferred camera and microphone are unavailable',
                    ),
                    controls: 'media',
                    warning: 'audio-video',
                    error: 'audio-video',
                    distinctiveTitles: {
                        videoError: {
                            title: t(
                                'device-status.video-device-not-found.title',
                                'We can’t find your camera',
                            ),
                            description: t(
                                'device-status.video-device-not-found.message',
                                'Your preferred camera is unavailable',
                            ),
                        },
                        audioError: {
                            title: t(
                                'device-status.audio-device-not-found.title',
                                'We can’t find your microphone',
                            ),
                            description: t(
                                'device-status.audio-device-not-found.message',
                                'Your preferred microphone is unavailable',
                            ),
                        },
                    },
                },
                [UserMediaStatus.VideoDeviceNotFound]: {
                    title: t(
                        'device-status.video-device-not-found.title',
                        'We can’t find your camera',
                    ),
                    message: t(
                        'device-status.video-device-not-found.message',
                        'Your preferred camera is unavailable',
                    ),
                    controls: 'media',
                    warning: 'video',
                    error: 'video',
                },
                [UserMediaStatus.AudioDeviceNotFound]: {
                    title: t(
                        'device-status.audio-device-not-found.title',
                        'We can’t find your microphone',
                    ),
                    message: t(
                        'device-status.audio-device-not-found.message',
                        'Your preferred microphone is unavailable',
                    ),
                    controls: 'media',
                    warning: 'audio',
                    error: 'audio',
                },
                [UserMediaStatus.InitialPermissionsNotGranted]: {
                    title: requestPermissionTitle,
                    message: (
                        <Trans
                            i18nKey="device-status.initial-permissions-not-granted.message"
                            t={t}
                        >
                            Please select <strong>Allow</strong> in the next
                            step when prompted by your browser. We will only
                            have access for the duration of the meeting.
                        </Trans>
                    ),
                    controls: 'permissions-dismiss',
                },
                [UserMediaStatus.PermissionsGranted]: {
                    title: '',
                    message: '',
                    controls: 'media',
                },
                [UserMediaStatus.PermissionsGrantedFallback]: {
                    title: t(
                        'device-status.permissions-granted-fallback-devices.title',
                        'Unable to use your preferred camera and microphone',
                    ),
                    message: t(
                        'device-status.permissions-granted-fallback-devices.message',
                        'We are unable to use your preferred camera and microphone',
                    ),
                    controls: 'media',
                },
                [UserMediaStatus.PermissionsGrantedFallbackAudioinput]: {
                    title: t(
                        'device-status.permissions-granted-fallback-audioinput.title',
                        'Unable to use your preferred microphone',
                    ),
                    message: t(
                        'device-status.permissions-granted-fallback-audioinput.message',
                        'We are unable to use your preferred microphone',
                    ),
                    controls: 'media',
                },
                [UserMediaStatus.PermissionsGrantedFallbackVideoinput]: {
                    title: t(
                        'device-status.permissions-granted-fallback-videoinput.title',
                        'Unable to use your preferred camera',
                    ),
                    message: t(
                        'device-status.permissions-granted-fallback-videoinput.message',
                        'We are unable to use your preferred camera',
                    ),
                    controls: 'media',
                },
                [UserMediaStatus.PermissionsRejected]: {
                    title: t(
                        'device-status.permissions-rejected.title',
                        'Did you mean to block access to your camera or microphone?',
                    ),
                    message: t(
                        'device-status.permissions-rejected.message',
                        'You blocked access to your camera or microphone. To grant access follow the steps in this short video',
                    ),
                    controls: 'permissions',
                    warning: 'audio-video',
                    error: 'audio-video',
                    distinctiveTitles: {
                        videoError: {
                            title: t(
                                'device-status.permissions-rejected-videoinput.title',
                                'Camera permission not granted',
                            ),
                            description: t(
                                'device-status.permissions-rejected-videoinput.message',
                                'You have previously denied permission to use your camera. To grant access follow the steps in this short video',
                            ),
                        },
                        audioError: {
                            title: t(
                                'device-status.permissions-rejected-audioinput.title',
                                'Microphone permission not granted',
                            ),
                            description: t(
                                'device-status.permissions-rejected-audioinput.message',
                                'You have previously denied permission to use your microphone. To grant access follow the steps in this short video',
                            ),
                        },
                    },
                },
                [UserMediaStatus.PermissionsRejectedVideoInput]: {
                    title: t(
                        'device-status.permissions-rejected-videoinput.title',
                        'Camera permission not granted',
                    ),
                    message: t(
                        'device-status.permissions-rejected-videoinput.message',
                        'You have denied permission to use your camera. It will not be available for this meeting',
                    ),
                    controls: 'media',
                    warning: 'video',
                    error: 'video',
                },
                [UserMediaStatus.PermissionsRejectedAudioInput]: {
                    title: t(
                        'device-status.permissions-rejected-audioinput.title',
                        'Microphone permission not granted',
                    ),
                    message: t(
                        'device-status.permissions-rejected-audioinput.message',
                        'You have denied permission to use your microphone. It will not be available for this meeting',
                    ),
                    controls: 'media',
                    warning: 'audio',
                    error: 'audio',
                },
                [UserMediaStatus.PermissionsOnlyAudioinput]: {
                    title: t(
                        'device-status.permissions-only-audioinput.title',
                        'Camera inactive',
                    ),
                    message: t(
                        'device-status.permissions-only-audioinput.message',
                        'You have previously denied permission to use your camera. To grant access follow the steps in this short video',
                    ),
                    controls: 'media',
                    warning: 'video',
                    error: 'video',
                },
                [UserMediaStatus.PermissionsOnlyAudioinputNoVideoDevices]: {
                    title: t(
                        'device-status.permissions-only-audioinput-no-video-devices.title',
                        'Your camera seems to be unavailable',
                    ),
                    message: t(
                        'device-status.permissions-only-audioinput-no-video-devices.message',
                        'Please check if it is plugged in and set up correctly',
                    ),
                    controls: 'media',
                    warning: 'video',
                    error: 'video',
                },
                [UserMediaStatus.PermissionsOnlyAudioinputFallback]: {
                    title: t(
                        'device-status.permissions-only-fallback-audioinput.title',
                        'Camera and preferred microphone are unavailable',
                    ),
                    message: t(
                        'device-status.permissions-only-fallback-audioinput.message',
                        "We can't use any camera, and your preferred microphone is unavailable",
                    ),
                    controls: 'media',
                },
                [UserMediaStatus.PermissionsOnlyAudioinputFallbackNoVideoDevices]:
                    {
                        title: t(
                            'device-status.permissions-only-fallback-audioinput-no-video-devices.title',
                            'No camera available and preferred microphone is unavailable',
                        ),
                        message: t(
                            'device-status.permissions-only-fallback-audioinput-no-video-devices.message',
                            "We can't find any camera, and your preferred microphone is unavailable",
                        ),
                        controls: 'media',
                        warning: 'video',
                        error: 'video',
                    },
                [UserMediaStatus.PermissionsOnlyVideoinput]: {
                    title: t(
                        'device-status.permissions-only-videoinput.title',
                        'Microphone inactive',
                    ),
                    message: t(
                        'device-status.permissions-only-videoinput.message',
                        'You have previously denied permission to use your microphone. To grant access follow the steps in this short video',
                    ),
                    controls: 'media',
                    warning: 'audio',
                    error: 'audio',
                },
                [UserMediaStatus.PermissionsOnlyVideoinputNoAudioDevices]: {
                    title: t(
                        'device-status.permissions-only-videoinput-no-audio-devices.title',
                        'Your microphone seems to be unavailable',
                    ),
                    message: t(
                        'device-status.permissions-only-videoinput-no-audio-devices.message',
                        'Please check if it is plugged in and set up correctly',
                    ),
                    controls: 'media',
                    warning: 'audio',
                    error: 'audio',
                },
                [UserMediaStatus.PermissionsOnlyVideoinputFallback]: {
                    title: t(
                        'device-status.permissions-only-fallback-videoinput.title',
                        'Microphone and preferred camera are unavailable',
                    ),
                    message: t(
                        'device-status.permissions-only-fallback-videoinput.message',
                        "We can't use any microphone, and your preferred camera is unavailable",
                    ),
                    controls: 'media',
                },
                [UserMediaStatus.PermissionsOnlyVideoinputFallbackNoAudioDevices]:
                    {
                        title: t(
                            'device-status.permissions-only-fallback-videoinput-no-audio-devices.title',
                            'No microphone and preferred camera is unavailable',
                        ),
                        message: t(
                            'device-status.permissions-only-fallback-videoinput-no-audio-devices.message',
                            "We can't find any microphone, and your preferred camera is unavailable",
                        ),
                        controls: 'media',
                        warning: 'audio',
                        error: 'audio',
                    },
                [UserMediaStatus.DevicesInUse]: {
                    title: t(
                        'device-status.devices-in-use.title',
                        'Camera and microphone unavailable',
                    ),
                    message: t(
                        'device-status.devices-in-use.message',
                        'Another application (e.g. Teams or Slack) is using your camera. To use it on Pexip, please find that application and turn it off',
                    ),
                    controls: 'media',
                    warning: 'audio-video',
                    error: 'audio-video',
                    distinctiveTitles: {
                        videoError: {
                            title: t(
                                'device-status.video-device-in-use.title',
                                'Camera unavailable',
                            ),
                            description: t(
                                'device-status.video-device-in-use.message',
                                'Another application (e.g. Teams or Slack) is using your camera. To use it on Pexip, please find that application and turn it off',
                            ),
                        },
                        audioError: {
                            title: t(
                                'device-status.audio-device-in-use.title',
                                'Microphone unavailable',
                            ),
                            description: t(
                                'device-status.audio-device-in-use.message',
                                'Another application (e.g. Teams or Slack) is using your microphone. To use it here, please find that application and turn it off',
                            ),
                        },
                    },
                },
                [UserMediaStatus.VideoDeviceInUse]: {
                    title: t(
                        'device-status.video-device-in-use.title',
                        'Camera unavailable',
                    ),
                    message: t(
                        'device-status.video-device-in-use.message',
                        'Another application (e.g. Teams or Slack) is using your camera. To use it on Pexip, please find that application and turn it off',
                    ),
                    controls: 'media',
                    warning: 'video',
                    error: 'video',
                },
                [UserMediaStatus.AudioDeviceInUse]: {
                    title: t(
                        'device-status.audio-device-in-use.title',
                        'Microphone unavailable',
                    ),
                    message: t(
                        'device-status.audio-device-in-use.message',
                        'Another application (e.g. Teams or Slack) is using your microphone. To use it here, please find that application and turn it off',
                    ),
                    controls: 'media',
                    warning: 'audio',
                    error: 'audio',
                },
                [UserMediaStatus.InvalidConstraints]: {
                    title: t(
                        'device-status.invalid-constraints.title',
                        'Camera and microphone are not configured properly',
                    ),
                    message: t(
                        'device-status.invalid-constraints.message',
                        'Try clearing your browser cache',
                    ),
                    controls: 'media',
                    warning: 'audio-video',
                    error: 'audio-video',
                    distinctiveTitles: {
                        videoError: {
                            title: t(
                                'device-status.invalid-video-constraints.title',
                                'Camera is not configured properly',
                            ),
                            description: t(
                                'device-status.invalid-video-constraints.message',
                                'Try clearing your browser cache',
                            ),
                        },
                        audioError: {
                            title: t(
                                'device-status.invalid-audio-constraints.title',
                                'Microphone is not configured properly',
                            ),
                            description: t(
                                'device-status.invalid-audio-constraints.message',
                                'Try clearing your browser cache',
                            ),
                        },
                    },
                },
                [UserMediaStatus.InvalidVideoConstraints]: {
                    title: t(
                        'device-status.invalid-video-constraints.title',
                        'Camera is not configured properly',
                    ),
                    message: t(
                        'device-status.invalid-video-constraints.message',
                        'Try clearing your browser cache',
                    ),
                    controls: 'media',
                    warning: 'video',
                    error: 'video',
                },
                [UserMediaStatus.InvalidAudioConstraints]: {
                    title: t(
                        'device-status.invalid-audio-constraints.title',
                        'Microphone is not configured properly',
                    ),
                    message: t(
                        'device-status.invalid-audio-constraints.message',
                        'Try clearing your browser cache',
                    ),
                    controls: 'media',
                    warning: 'audio',
                    error: 'audio',
                },
                [UserMediaStatus.Overconstrained]: {
                    title: t(
                        'device-status.overconstrained.title',
                        'Camera and microphone are overly constrained',
                    ),
                    message: t(
                        'device-status.overconstrained.message',
                        'Try lowering your quality settings',
                    ),
                    controls: 'media',
                    warning: 'audio-video',
                    error: 'audio-video',
                    distinctiveTitles: {
                        videoError: {
                            title: t(
                                'device-status.video-overconstrained.title',
                                'Camera is overly constrained',
                            ),
                            description: t(
                                'device-status.video-overconstrained.message',
                                'Try lowering your quality settings',
                            ),
                        },
                        audioError: {
                            title: t(
                                'device-status.audio-overconstrained.title',
                                'Microphone is overly constrained',
                            ),
                            description: t(
                                'device-status.audio-overconstrained.message',
                                'Try lowering your quality settings',
                            ),
                        },
                    },
                },
                [UserMediaStatus.VideoOverconstrained]: {
                    title: t(
                        'device-status.video-overconstrained.title',
                        'Camera is overly constrained',
                    ),
                    message: t(
                        'device-status.video-overconstrained.message',
                        'Try lowering your quality settings',
                    ),
                    controls: 'media',
                    warning: 'video',
                    error: 'video',
                },
                [UserMediaStatus.AudioOverconstrained]: {
                    title: t(
                        'device-status.audio-overconstrained.title',
                        'Microphone is overly constrained',
                    ),
                    message: t(
                        'device-status.audio-overconstrained.message',
                        'Try lowering your quality settings',
                    ),
                    controls: 'media',
                    warning: 'audio',
                    error: 'audio',
                },
                [UserMediaStatus.NotSupportedError]: {
                    title: t(
                        'device-status.not-supported-error.title',
                        'Browser does not support',
                    ),
                    message: t(
                        'device-status.not-supported-error.message',
                        "We can't get media from your browser. Try to use another modern browser.",
                    ),
                    controls: 'media',
                    warning: 'audio-video',
                    error: 'audio-video',
                    distinctiveTitles: {
                        videoError: {
                            title: t(
                                'device-status.not-supported-error-only-audio.title',
                                'Browser does not support',
                            ),
                            description: t(
                                'device-status.not-supported-error-only-audio.message',
                                "We can't get media from your browser. Try to use another modern browser.",
                            ),
                        },
                        audioError: {
                            title: t(
                                'device-status.not-supported-error-only-video.title',
                                'Browser does not support',
                            ),
                            description: t(
                                'device-status.not-supported-error-only-video.message',
                                "We can't get media from your browser. Try to use another modern browser.",
                            ),
                        },
                    },
                },
                [UserMediaStatus.NotSupportedErrorOnlyAudioInput]: {
                    title: t(
                        'device-status.not-supported-error-only-audio.title',
                        'Browser does not support',
                    ),
                    message: t(
                        'device-status.not-supported-error-only-audio.message',
                        "We can't get media from your browser. Try to use another modern browser.",
                    ),
                    controls: 'media',
                    warning: 'audio',
                    error: 'audio',
                },
                [UserMediaStatus.NotSupportedErrorOnlyVideoInput]: {
                    title: t(
                        'device-status.not-supported-error-only-video.title',
                        'Browser does not support',
                    ),
                    message: t(
                        'device-status.not-supported-error-only-video.message',
                        "We can't get media from your browser. Try to use another modern browser.",
                    ),
                    controls: 'media',
                    warning: 'video',
                    error: 'video',
                },
                [UserMediaStatus.UnknownError]: {
                    title: t(
                        'device-status.unknown-error.title',
                        'Camera and microphone unavailable',
                    ),
                    message: t(
                        'device-status.unknown-error.message',
                        'We are not able to get media from your devices. Try to reconnect your devices or use different ones',
                    ),
                    controls: 'media',
                    warning: 'audio-video',
                    error: 'audio-video',
                    distinctiveTitles: {
                        videoError: {
                            title: t(
                                'device-status.unknown-error-only-audioinput.title',
                                'Camera unavailable',
                            ),
                            description: t(
                                'device-status.unknown-error-only-audioinput.message',
                                'We are not able to use your camera. Try to reconnect your device or use a different one',
                            ),
                        },
                        audioError: {
                            title: t(
                                'device-status.unknown-error-only-videoinput.title',
                                'Microphone unavailable',
                            ),
                            description: t(
                                'device-status.unknown-error-only-videoinput.message',
                                'We are not able to use your microphone. Try to reconnect your device or use a different one',
                            ),
                        },
                    },
                },
                [UserMediaStatus.UnknownErrorOnlyAudioinput]: {
                    title: t(
                        'device-status.unknown-error-only-audioinput.title',
                        'Camera unavailable',
                    ),
                    message: t(
                        'device-status.unknown-error-only-audioinput.message',
                        'We are not able to use your camera. Try to reconnect your device or use a different one',
                    ),
                    controls: 'media',
                    warning: 'video',
                    error: 'video',
                },
                [UserMediaStatus.UnknownErrorOnlyVideoinput]: {
                    title: t(
                        'device-status.unknown-error-only-videoinput.title',
                        'Microphone unavailable',
                    ),
                    message: t(
                        'device-status.unknown-error-only-videoinput.message',
                        'We are not able to use your microphone. Try to reconnect your device or use a different one',
                    ),
                    controls: 'media',
                    warning: 'audio',
                    error: 'audio',
                },
            };
        }, [t, audio, video]);

    if (status) {
        return DeviceStatusInfos[status];
    }

    return {
        title: '',
        message: '',
    };
};
