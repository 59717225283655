import type {AnalyzerNodeInit} from '@pexip/media-processor';
import {isAudioNodeInit} from '@pexip/media-processor';

import type {Media, AudioContentHint, VideoContentHint} from './types';
import {
    UserMediaStatus,
    AUDIO_CONTENT_HINTS,
    VIDEO_CONTENT_HINTS,
} from './types';

export const isNonNullObject = (
    value: unknown,
): value is Record<string, unknown> => {
    if (typeof value === 'object' && value !== null) {
        return true;
    }
    return false;
};

export const isUserMediaStatus = (value: unknown): value is UserMediaStatus => {
    if (value && typeof value === 'string') {
        return Object.values(UserMediaStatus).includes(
            value as unknown as UserMediaStatus,
        );
    }
    return false;
};

export const isMedia = (value: unknown): value is Media => {
    if (isNonNullObject(value) && 'release' in value) {
        return true;
    }
    return false;
};

export const isAnalyzerNodeInitProp = (
    value: unknown,
): value is AnalyzerNodeInit | undefined => {
    if (value === undefined || isAudioNodeInit(value)) {
        return true;
    }
    return false;
};

export const isAudioContentHint = (
    value: unknown,
): value is AudioContentHint => {
    if (
        typeof value === 'string' &&
        Object.values(AUDIO_CONTENT_HINTS).includes(value as AudioContentHint)
    ) {
        return true;
    }
    return false;
};

export const isVideoContentHint = (
    value: unknown,
): value is VideoContentHint => {
    if (
        typeof value === 'string' &&
        Object.values(VIDEO_CONTENT_HINTS).includes(value as VideoContentHint)
    ) {
        return true;
    }
    return false;
};
