import React from 'react';
import cx from 'classnames';

import type {MeetingVideoProps} from './MeetingVideo.view';
import {MeetingVideo} from './MeetingVideo.view';

import styles from './MeetingVideo.module.scss';

export const ExternalVideo: React.FC<MeetingVideoProps> = props => (
    <div className={cx(styles.container, styles.external)}>
        <MeetingVideo {...props} />
    </div>
);
