import {useEffect, useState} from 'react';

import type {Signal} from '@pexip/signal';

export const createVADHook =
    (
        setVad: (value: boolean) => void,
        subscribeVAD: Signal<undefined>['add'],
    ) =>
    (isAudioInputMuted: boolean) => {
        const [voiceDetected, setVoiceDetected] = useState(false);

        useEffect(() => {
            const vad = () => setVoiceDetected(true);

            return subscribeVAD(vad);
        }, []);

        useEffect(() => {
            setVad(isAudioInputMuted);

            return () => setVad(false);
        }, [isAudioInputMuted]);

        return [voiceDetected, setVoiceDetected] as const;
    };
