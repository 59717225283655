export enum MimeTypes {
    TextPlain = 'text/plain',
    TextHTML = 'text/html',
}

const createClipboardItem = <T extends string>(
    mimeType: MimeTypes,
    promise: Promise<T>,
) => {
    return new window.ClipboardItem({
        [mimeType]: promise,
    });
};

interface CopyToClipboardItemOptions {
    clipboardWrite?: Clipboard['write'];
    createItem?: typeof createClipboardItem;
}

export const copyToClipboardItem = <T extends string>(
    mimeType: MimeTypes,
    promise: Promise<T>,
    {
        clipboardWrite = navigator.clipboard.write.bind(
            window.navigator.clipboard,
        ),
        createItem = createClipboardItem,
    }: CopyToClipboardItemOptions = {},
) => clipboardWrite([createItem(mimeType, promise)]);

export const copyTextToClipboard = (
    text: string,
    clipboardWriteText = window.navigator.clipboard.writeText.bind(
        window.navigator.clipboard,
    ),
) => clipboardWriteText(text);

export const readTextFromClipboard = (
    clipboardReadText = window.navigator.clipboard.readText.bind(
        window.navigator.clipboard,
    ),
) => clipboardReadText();

export const statefulCopyLinkToClipboard = async (
    meetingLink: string,
    setIsCopied: (value: React.SetStateAction<boolean>) => void,
) => {
    await copyTextToClipboard(meetingLink);
    setIsCopied(true);
};
