import {useEffect} from 'react';

import type {MediaSignals} from '@pexip/media';

import {logger} from '../logger';

const logTrack = (key: string) => (track: MediaStreamTrack) =>
    logger.debug({track, key}, 'Track event');

export const useLogTracks = ({
    onStreamTrackEnabled,
    onStreamTrackEnded,
    onStreamTrackMuted,
    onStreamTrackUnmuted,
}: Pick<
    Required<MediaSignals>,
    | 'onStreamTrackMuted'
    | 'onStreamTrackUnmuted'
    | 'onStreamTrackEnded'
    | 'onStreamTrackEnabled'
>) => {
    useEffect(
        () => onStreamTrackEnabled.add(logTrack('enabled')),
        [onStreamTrackEnabled],
    );
    useEffect(
        () => onStreamTrackEnded.add(logTrack('ended')),
        [onStreamTrackEnded],
    );
    useEffect(
        () => onStreamTrackMuted.add(logTrack('muted')),
        [onStreamTrackMuted],
    );
    useEffect(
        () => onStreamTrackUnmuted.add(logTrack('unmuted')),
        [onStreamTrackUnmuted],
    );
};
