/**
 * A no-operation function.
 *
 * @example
 * ```typescript
 * const object = { 'name': 'fred' };
 * noop(object) === undefined;
 * // => true
 * ```
 */
export const noop = () => undefined;
