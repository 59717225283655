export type SelfviewDisplayMode = 'expanded' | 'collapsed';

export const calculateDisplayMode = (
    expandSelfViewConfig: boolean,
    isVideoMutedOrMissing: boolean,
): SelfviewDisplayMode => {
    if (isVideoMutedOrMissing || !expandSelfViewConfig) {
        return 'collapsed';
    }

    return 'expanded';
};
