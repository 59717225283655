import React from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

import type {
    TooltipPosition,
    NotificationTooltipPosition,
} from '@pexip/components';
import {Box, IconTypes, NotificationTooltip} from '@pexip/components';

import {TestId} from '../../../../test/testIds';

import {FoldedSelfviewButton} from './FoldedSelfviewButton.view';

import styles from './FoldedSelfview.module.scss';

export const FoldedSelfview: React.FC<{
    isAudioInputMuted: boolean;
    isVideoInputMuted: boolean;
    isAudioInputMissing?: boolean;
    isVideoInputMissing?: boolean;
    onExpandSelfview: () => void;
    onToggleVideoClick: () => void;
    onToggleAudioClick: () => void;
    showSelfviewTooltip: boolean;
    setShowSelfviewTooltip: (shouldShow: boolean) => void;
    notificationTooltipPos?: NotificationTooltipPosition;
    selfviewBtnTooltipPos?: TooltipPosition;
}> = ({
    isAudioInputMuted,
    isVideoInputMuted,
    isAudioInputMissing,
    isVideoInputMissing,
    onToggleVideoClick,
    onExpandSelfview,
    onToggleAudioClick,
    showSelfviewTooltip,
    setShowSelfviewTooltip,
    notificationTooltipPos = 'bottomLeft',
    selfviewBtnTooltipPos = 'topLeft',
}) => {
    const {t} = useTranslation();

    const muteMicText = isAudioInputMuted
        ? t('media.unmute-microphone', 'Unmute microphone')
        : t('media.mute-microphone', 'Mute microphone');
    const showSelfviewText = t('meeting.show-self-view', 'Show self-view');
    const turnOnCameraText = t('media.turn-camera-on', 'Turn camera on');
    const dragText = t('meeting.folded-selfview.drag', 'Move self-view');

    return (
        <NotificationTooltip
            controlledIsVisible={
                showSelfviewTooltip &&
                !isVideoInputMuted &&
                !isVideoInputMissing
            }
            controlledOnChange={setShowSelfviewTooltip}
            header={t('settings.selfview-hidden', 'Self-view hidden')}
            position={notificationTooltipPos}
            testIdInner={TestId.NotificationTooltipSelfviewHidden}
            text={t(
                'settings.collapsed-selfview-tooltip-description',
                'You’re still sending video to other people in the meeting',
            )}
            closeButtonAriaLabel={t('common.close-tooltip', 'Close tooltip')}
            stopPointerDownPropagation
            className={styles.notificationTooltipInner}
        >
            <Box
                className={cx(styles.foldedSelfview, 'foldedSelfviewGlobal')}
                data-testid={TestId.FoldedSelfview}
            >
                <FoldedSelfviewButton
                    data-testid={TestId.ButtonDragFoldedSelfview}
                    icon={IconTypes.IconDraggable}
                    aria-label={dragText}
                    className={styles.inheritCursor}
                />
                <FoldedSelfviewButton
                    onClick={onToggleAudioClick}
                    data-testid={TestId.ButtonToggleAudioSelfview}
                    tooltipText={muteMicText}
                    tooltipPosition={selfviewBtnTooltipPos}
                    aria-label={muteMicText}
                    isDisabled={isAudioInputMissing}
                    icon={
                        isAudioInputMuted || isAudioInputMissing
                            ? IconTypes.IconMicrophoneOff
                            : IconTypes.IconMicrophoneOn
                    }
                />
                {isVideoInputMuted || isVideoInputMissing ? (
                    <FoldedSelfviewButton
                        onClick={onToggleVideoClick}
                        data-testid={TestId.ButtonToggleVideoSelfview}
                        tooltipText={turnOnCameraText}
                        tooltipPosition={selfviewBtnTooltipPos}
                        aria-label={turnOnCameraText}
                        icon={IconTypes.IconVideoOff}
                        isDisabled={isVideoInputMissing}
                    />
                ) : (
                    <FoldedSelfviewButton
                        onClick={onExpandSelfview}
                        data-testid={TestId.ButtonExpandSelfview}
                        tooltipText={showSelfviewText}
                        tooltipPosition={selfviewBtnTooltipPos}
                        aria-label={showSelfviewText}
                        icon={IconTypes.IconFullscreenOn}
                    />
                )}
            </Box>
        </NotificationTooltip>
    );
};

export type FoldedSelfviewProps = React.ComponentProps<typeof FoldedSelfview>;
