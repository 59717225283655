import {applicationConfig} from '../applicationConfig';

export const useFeedback = () => {
    const feedbackConfig = applicationConfig.feedback;

    const openFeedbackForm = () => {
        if (window !== null && window !== undefined) {
            window.open(feedbackConfig.url, '_blank')?.focus();
        }
    };
    return {isFeedbackEnabled: feedbackConfig.enable, openFeedbackForm};
};
