import {useMemo} from 'react';

type DocumentKeys = keyof Document;

type VendorKeys = keyof typeof browserApi;

type BrowserApi = FullscreenMethods | Record<string, unknown>;

type ExitFullscreen = typeof document.exitFullscreen;

type FullscreenElement = typeof document.fullscreenElement;

export interface FullscreenApi {
    fullscreenEnabled: boolean;
    exitFullscreen: () => Promise<void>;
    readonly fullscreenElement: Element | null;
    requestFullscreen: (
        element?: HTMLElement,
        options?: FullscreenOptions | undefined,
    ) => Promise<void>;
    fullscreenchange: keyof DocumentEventMap;
}

type FullscreenMethods = {
    exitFullscreen: string;
    fullscreenchange: string;
    fullscreenElement: string;
    fullscreenEnabled: string;
    requestFullscreen: string;
};

const standard: FullscreenMethods = {
    exitFullscreen: 'exitFullscreen',
    fullscreenchange: 'fullscreenchange',
    fullscreenElement: 'fullscreenElement',
    fullscreenEnabled: 'fullscreenEnabled',
    requestFullscreen: 'requestFullscreen',
};

const webkit: FullscreenMethods = {
    exitFullscreen: 'webkitExitFullscreen',
    fullscreenchange: 'webkitfullscreenchange',
    fullscreenElement: 'webkitFullscreenElement',
    fullscreenEnabled: 'webkitFullscreenEnabled',
    requestFullscreen: 'webkitRequestFullscreen',
};

const moz: FullscreenMethods = {
    exitFullscreen: 'mozCancelFullScreen',
    fullscreenchange: 'mozfullscreenchange',
    fullscreenElement: 'mozFullScreenElement',
    fullscreenEnabled: 'mozFullScreenEnabled',
    requestFullscreen: 'mozRequestFullScreen',
};

export const browserApi: BrowserApi =
    (standard.fullscreenEnabled in document && standard) ||
    (webkit.fullscreenEnabled in document && webkit) ||
    (moz.fullscreenEnabled in document && moz) ||
    {};

const getBrowserAPIMethodName = <T>(methodName: string) =>
    browserApi[methodName as VendorKeys] as T;

/**
 * returns a Browser API agnostic access to the Fullscreen API.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API/Guide#prefixing
 */
export const useFullscreenApi = (): FullscreenApi =>
    useMemo(() => {
        const fullscreenEnabled = getBrowserAPIMethodName<DocumentKeys>(
            standard.fullscreenEnabled,
        );
        const exitFullscreen = getBrowserAPIMethodName<DocumentKeys>(
            standard.exitFullscreen,
        );
        const fullscreenElement = getBrowserAPIMethodName<DocumentKeys>(
            standard.fullscreenElement,
        );

        const requestFullscreen = getBrowserAPIMethodName<
            keyof typeof HTMLElement
        >(standard.requestFullscreen);

        return {
            fullscreenEnabled: document[fullscreenEnabled] as boolean,
            exitFullscreen: (document[exitFullscreen] as ExitFullscreen)?.bind(
                document,
            ),
            get fullscreenElement() {
                return document[fullscreenElement] as FullscreenElement;
            },
            requestFullscreen: (
                element = document.documentElement,
                options?: FullscreenOptions | undefined,
            ) =>
                (
                    element[
                        requestFullscreen as keyof typeof element
                    ] as typeof element.requestFullscreen
                )?.bind(element)(options),
            fullscreenchange: browserApi[
                standard.fullscreenchange as VendorKeys
            ] as keyof DocumentEventMap,
        };
    }, []);
