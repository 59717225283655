import type {Segmenter} from '../types';
import type {
    Delegate,
    SegmenterOptions,
    ImageSegmenterOptions,
    SegmentationModelAsset,
} from '../../../common/types/segmentation';
import type {
    ProcessorEvents,
    ProcessorOptions,
    ProcessorWorkerEvents as WorkerEvents,
} from '../../../common/types/processor';
import type {ImageRecord, VideoFrameLike} from '../../../common/types/media';
import type {
    ExtractMessageEventType,
    IncludeMessageEventDataType,
} from '../../../common/types/utils';
import type {ProcessStatus} from '../../../common/constants';
import {
    PROCESS_STATUS,
    PROCESSING_WIDTH,
    PROCESSING_HEIGHT,
} from '../../../common/constants';

type WorkerEventTypeNoError = Exclude<
    ExtractMessageEventType<WorkerEvents>,
    'error'
>;

interface Options extends Omit<ImageSegmenterOptions, 'delegate'> {
    /**
     * Processing width for the processing canvas.
     * @defaultValue 768
     */
    processingWidth?: number;
    /**
     * Processing height for the processing canvas.
     * @defaultValue 432
     */
    processingHeight?: number;
    /**
     * The URL for the worker script.
     */
    workerScriptUrl?: URL;
    /**
     * Request Credentials for the worker.
     *
     * @defaultValue 'same-origin'
     */
    credentials?: RequestCredentials;
    /**
     * @see `SegmentationModelAsset`
     */
    modelAsset: SegmentationModelAsset;

    delegate?: () => Delegate;
}

const cloneImageRecord = async (image: ImageRecord) => {
    const cloned = await createImageBitmap(image.image);
    return {image: cloned, key: image.key};
};

export const createSegmenter = (
    /**
     * A base path to specify the directory the Wasm files should be loaded
     * from. If not provided, it will be loaded from the host's root directory.
     *
     * @see {@link
     * https://ai.google.dev/edge/api/mediapipe/js/tasks-vision.filesetresolver#filesetresolverforvisiontasks | FileResolver}
     */
    basePath: SegmenterOptions['basePath'] = '/',
    {
        processingWidth = PROCESSING_WIDTH,
        processingHeight = PROCESSING_HEIGHT,
        workerScriptUrl = new URL(
            '../../../workers/mediaWorker.js',
            import.meta.url,
        ),
        credentials = 'same-origin',
        delegate,
        ...options
    }: Options,
): Segmenter => {
    const props: {
        mediaWorker?: Worker;
        status: ProcessStatus;
        output?: OffscreenCanvas | null;
        modelAsset: SegmentationModelAsset;
    } = {status: PROCESS_STATUS.New, modelAsset: options.modelAsset};

    const initWorker = () => {
        const mediaWorker = new Worker(workerScriptUrl, {
            type: 'classic',
            credentials,
        });

        mediaWorker.addEventListener('error', error => {
            throw error.error;
        });
        mediaWorker.addEventListener(
            'message',
            (event: MessageEvent<WorkerEvents>) => {
                const {type} = event.data;
                if (type === 'error') {
                    throw event.data.error;
                }
            },
        );

        return mediaWorker;
    };

    function postMsg(
        expectedResponseType: 'processed',
        message: ProcessorEvents,
        transfer?: Transferable[],
    ): Promise<VideoFrameLike | undefined>;
    function postMsg(
        expectedResponseType: 'opened' | undefined,
        message: ProcessorEvents,
        transfer?: Transferable[],
    ): Promise<void>;
    async function postMsg<T extends WorkerEventTypeNoError>(
        expectedResponseType: T | undefined,
        message: ProcessorEvents,
        transfer?: Transferable[],
        // eslint-disable-next-line @typescript-eslint/no-invalid-void-type --- necessary to get it work work promise void
    ): Promise<void | VideoFrameLike | undefined> {
        if (!expectedResponseType) {
            return props.mediaWorker?.postMessage(message, transfer ?? []);
        }
        return await new Promise<IncludeMessageEventDataType<WorkerEvents, T>>(
            (resolve, reject) => {
                const handleMessage = (ev: MessageEvent<WorkerEvents>) => {
                    removeListener();
                    switch (ev.data.type) {
                        case expectedResponseType: {
                            // @ts-expect-error --- Call `resolve` is expecting to pass an argument or nothing
                            resolve(ev.data.data);
                            break;
                        }
                        case 'error': {
                            reject(ev.data.error);
                            break;
                        }
                        default: {
                            throw new Error(`UnhandledEvent: ${ev.data.type}`);
                        }
                    }
                };
                const handleError = (error: ErrorEvent) => {
                    removeListener();
                    reject(error.error);
                };
                const removeListener = () => {
                    props.mediaWorker?.removeEventListener(
                        'message',
                        handleMessage,
                    );
                    props.mediaWorker?.removeEventListener(
                        'error',
                        handleError,
                    );
                };
                props.mediaWorker?.addEventListener('message', handleMessage);
                props.mediaWorker?.addEventListener('error', handleError);
                props.mediaWorker?.postMessage(message, transfer ?? []);
            },
        );
    }

    const open = async (processorOptions?: ProcessorOptions) => {
        props.status = PROCESS_STATUS.Opening;
        props.mediaWorker = initWorker();
        props.output = processorOptions?.output;

        const backgroundImage =
            processorOptions?.backgroundImage &&
            (await cloneImageRecord(processorOptions.backgroundImage));

        await postMsg(
            'opened',
            {
                type: 'open',
                data: {
                    basePath,
                    processingWidth,
                    processingHeight,
                    ...processorOptions,
                    imageSegmenterOptions: {
                        ...options,
                        delegate: delegate?.(),
                        ...processorOptions?.imageSegmenterOptions,
                    },
                    backgroundImage,
                },
            },
            [processorOptions?.output, backgroundImage?.image].filter(
                Boolean,
            ) as Transferable[],
        );
        props.status = PROCESS_STATUS.Opened;
    };
    const process: Segmenter['process'] = async (input, options) => {
        props.status = PROCESS_STATUS.Processing;
        const resultFrame = await postMsg(
            'processed',
            {
                type: 'process',
                data: {videoFrame: input, renderOptions: options},
            },
            [input.frame],
        );
        if (props.status === PROCESS_STATUS.Processing) {
            props.status = PROCESS_STATUS.Idle;
        }
        return resultFrame;
    };
    const update: Segmenter['update'] = async options => {
        const backgroundImage =
            options.backgroundImage &&
            (await cloneImageRecord(options.backgroundImage));

        const transfer = backgroundImage ? [backgroundImage.image] : [];
        await postMsg(
            undefined,
            {
                type: 'update',
                data: {
                    ...options,
                    backgroundImage,
                },
            },
            transfer,
        );
    };
    const close = () => {
        props.status = PROCESS_STATUS.Closing;
        void postMsg(undefined, {type: 'close'});
        props.status = PROCESS_STATUS.Closed;
    };
    const destroy = async () => {
        props.status = PROCESS_STATUS.Destroying;
        await postMsg(undefined, {type: 'destroy'});
        props.mediaWorker?.terminate();
        props.mediaWorker = undefined;
        props.status = PROCESS_STATUS.Destroyed;
        return Promise.resolve();
    };

    return {
        get modelAsset() {
            return props.modelAsset;
        },
        set modelAsset(asset) {
            props.modelAsset = asset;
        },
        get status() {
            return props.status;
        },
        get width() {
            return processingWidth;
        },
        get height() {
            return processingHeight;
        },
        open,
        update,
        process,
        close,
        destroy,
    };
};
