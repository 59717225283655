import cx from 'classnames';
import React from 'react';

import {useWideContainerObserver} from '@pexip/hooks';

import type {AutoHideButtonCallbacks} from '../../types';
import {StreamStatus} from '../../types';
import {isPhone} from '../../utils';
import {InMeetingDraggable} from '../InMeetingDraggable/InMeetingDraggable.view';

import type {MeetingVideoProps, MeetingVideoRef} from './MeetingVideo.view';
import {MeetingVideo} from './MeetingVideo.view';

import styles from './MeetingVideo.module.scss';

export const DraggableMeetingVideo = React.forwardRef<
    MeetingVideoRef,
    Omit<MeetingVideoProps, 'isContainerWide'> & {
        draggableAriaLabel: string;
        autoHideProps?: AutoHideButtonCallbacks;
        isSidePanelVisible: boolean;
        floatRoot?: React.RefObject<HTMLDivElement>;
        videoElementId?: string;
    }
>(
    (
        {
            autoHideProps,
            draggableAriaLabel,
            isSidePanelVisible,
            floatRoot,
            videoElementId,
            ...props
        },
        ref,
    ) => {
        const {containerRef, isContainerWide} = useWideContainerObserver();

        const isPip = props.status === StreamStatus.Pip;
        const isBrowserPip = props.status === StreamStatus.BrowserPip;
        const isDeemphasized = props.status === StreamStatus.Deemphasized;
        const isEmphasized = props.status === StreamStatus.Emphasized;

        return (
            <InMeetingDraggable
                draggableAriaLabel={draggableAriaLabel}
                autoHideProps={isPip ? autoHideProps : undefined}
                isDisabled={!isPip}
                isSidePanelVisible={isSidePanelVisible}
                isSidePanelTransformEnabled={isPip}
                floatRoot={floatRoot}
                center={true}
                className={cx(styles.container, {
                    [styles.pip]: isPip,
                    [styles.emphasized]: isEmphasized,
                    [styles.deemphasized]: isDeemphasized,
                    [styles.browserPip]: isBrowserPip,
                    ['m-0']: isPhone && !isPip,
                })}
                ref={containerRef}
            >
                <MeetingVideo
                    {...props}
                    ref={ref}
                    isContainerWide={isContainerWide}
                    videoElementId={videoElementId}
                />
            </InMeetingDraggable>
        );
    },
);

DraggableMeetingVideo.displayName = 'DraggableMeetingVideo';

export type DraggableMeetingVideoProps = React.ComponentProps<
    typeof DraggableMeetingVideo
>;
