import {useSyncExternalStore} from 'react';

import {callSignals} from '../signals/Call.signals';
import {useMeetingContext} from '../hooks/meeting';

const hideWhenTranscoding = (code: string, isDirectMedia?: boolean) =>
    isDirectMedia ? code : '';
export const useSecureCheckCode = () => {
    const meeting = useMeetingContext();
    const secureCheckCode = useSyncExternalStore(
        callSignals.onSecureCheckCode.add,
        () =>
            hideWhenTranscoding(
                meeting.getSecureCheckCode(),
                meeting.getConferenceFeatureFlags()?.isDirectMedia,
            ),
    );
    return secureCheckCode;
};
