import React, {useCallback, useLayoutEffect, useRef} from 'react';
import cx from 'classnames';

import {useIsInWindowBottomHalf, useIsInWindowRightHalf} from '@pexip/hooks';
import type {TooltipPosition} from '@pexip/components';

import type {InMeetingDraggablePosition} from '../../InMeetingDraggable/InMeetingDraggable.view';
import {InMeetingDraggable} from '../../InMeetingDraggable/InMeetingDraggable.view';
import type {FoldedSelfviewProps} from '../FoldedSelfview/FoldedSelfview.view';
import {FoldedSelfview} from '../FoldedSelfview/FoldedSelfview.view';
import type {InMeetingSelfviewProps} from '../InMeetingSelfview/InMeetingSelfview.view';
import {InMeetingSelfview} from '../InMeetingSelfview/InMeetingSelfview.view';
import type {AutoHideButtonCallbacks} from '../../../types';

import styles from './DraggableFoldableInMeetingSelfview.module.scss';

export const DraggableFoldableInMeetingSelfview: React.FC<
    InMeetingSelfviewProps &
        FoldedSelfviewProps & {
            autoHideProps: AutoHideButtonCallbacks;
            draggableAriaLabel: string;
            isFolded: boolean;
            floatRoot?: React.RefObject<HTMLDivElement>;
            isSidePanelVisible: boolean;
        }
> = ({
    isFolded,
    draggableAriaLabel,
    floatRoot,
    isSidePanelVisible,
    autoHideProps,
    ...props
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const alignWithFloatRoot = useRef<(() => void) | null>(null);

    const {isInBottomHalf, update: updateIsInBottomHalf} =
        useIsInWindowBottomHalf();
    const {isInRightHalf, update: updateIsInRightHalf} =
        useIsInWindowRightHalf();

    const onPositionChangeCb = useCallback(
        (el: HTMLDivElement, position: InMeetingDraggablePosition) => {
            updateIsInBottomHalf(el);
            updateIsInRightHalf(el);
            if (position.floatRootOverflow.right) {
                el.style.removeProperty('left');
            }
        },
        [updateIsInBottomHalf, updateIsInRightHalf],
    );

    useLayoutEffect(() => {
        // using layout effect here because we are fiddling with the element's dimensions
        // https://kentcdodds.com/blog/useeffect-vs-uselayouteffect
        if (ref.current && floatRoot?.current && alignWithFloatRoot.current) {
            alignWithFloatRoot.current();
        }
    }, [floatRoot, isFolded]);

    const calculateOrientation = () => {
        return isInBottomHalf
            ? isInRightHalf
                ? 'topLeft'
                : 'topRight'
            : isInRightHalf
              ? 'bottomLeft'
              : 'bottomRight';
    };

    const getClassNames = () =>
        isFolded
            ? {[styles.foldedSelfviewWrapper]: true}
            : {
                  [styles.pip]: true,
              };

    const notificationTooltipPos: TooltipPosition = calculateOrientation();

    const selfviewBtnTooltipPos: TooltipPosition = calculateOrientation();

    return (
        <InMeetingDraggable
            draggableAriaLabel={draggableAriaLabel}
            isMoveableWithArrowKeys
            showFocus={false}
            floatRoot={floatRoot}
            isSidePanelVisible={isSidePanelVisible}
            isPhone={props.isMobileDevice}
            onRepositionSideEffect={onPositionChangeCb}
            shouldCaptureClick={false}
            className={cx(styles.inMeetingSelfviewPosition, getClassNames())}
            ref={ref}
            alignWithFloatRootTrigger={alignWithFloatRoot}
            autoHideProps={autoHideProps}
        >
            {isFolded ? (
                <FoldedSelfview
                    {...props}
                    notificationTooltipPos={notificationTooltipPos}
                    selfviewBtnTooltipPos={selfviewBtnTooltipPos}
                />
            ) : (
                <InMeetingSelfview {...props} />
            )}
        </InMeetingDraggable>
    );
};

export type DraggableFoldableInMeetingSelfviewProps = React.ComponentProps<
    typeof DraggableFoldableInMeetingSelfview
>;
