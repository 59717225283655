import React from 'react';

import type {UserMediaStatus} from '@pexip/media';

import type {
    BlockedBrowserPermissionsInfoType,
    DeviceError,
    DeviceStatusInfo,
} from '../../types';
import {PreflightInputControls} from '../InputControls/PreflightInputControls.view';

import {PreflightControlsWrapper} from './PreflightControlsWrapper.view';

export const PreflightMediaControls: React.FC<{
    audioInputControl?: React.ReactNode;
    inputStatusInfo: DeviceStatusInfo;
    isAudioInputMuted: boolean;
    isAudioInputUnavailable: boolean;
    isBlurred?: boolean;
    isMobileDevice: boolean;
    isVideoInputMuted: boolean;
    isVideoInputUnavailable: boolean;
    requestMediaPermissions: () => void;
    segmentationEnabled?: boolean;
    shouldCenter: boolean;
    shouldShowVideoStatus?: boolean;
    silentTooltipInfo?: DeviceStatusInfo;
    streamStatus?: UserMediaStatus;
    toggleAudioInput?: () => void;
    toggleBlur?: () => void;
    toggleVideoInput?: () => void;
    toggleFacingMode?: () => void;
    deviceError: DeviceError;
    setShowHelpVideo: React.Dispatch<React.SetStateAction<boolean>>;
    learnHowToGrantAccessURL?: string;
    permissionInfoType: BlockedBrowserPermissionsInfoType;
}> = ({
    audioInputControl,
    inputStatusInfo,
    isAudioInputMuted,
    isAudioInputUnavailable,
    isVideoInputUnavailable,
    isBlurred,
    isMobileDevice,
    isVideoInputMuted,
    requestMediaPermissions,
    segmentationEnabled,
    shouldCenter,
    shouldShowVideoStatus,
    silentTooltipInfo,
    streamStatus,
    toggleAudioInput,
    toggleBlur,
    toggleVideoInput,
    toggleFacingMode,
    deviceError,
    setShowHelpVideo,
    learnHowToGrantAccessURL,
    permissionInfoType,
}) => (
    <PreflightControlsWrapper
        position={shouldShowVideoStatus ? 'relative' : 'absoluteBottom'}
        isPhone={isMobileDevice}
        shouldCenter={shouldCenter}
    >
        <PreflightInputControls
            inputStatusInfo={inputStatusInfo}
            isAudioInputMuted={isAudioInputMuted}
            isAudioInputUnavailable={isAudioInputUnavailable}
            isVideoInputMuted={isVideoInputMuted}
            isVideoInputUnavailable={isVideoInputUnavailable}
            requestMediaPermissions={requestMediaPermissions}
            silentTooltipInfo={silentTooltipInfo}
            streamStatus={streamStatus}
            toggleAudioInput={toggleAudioInput}
            toggleVideoInput={toggleVideoInput}
            audioInputControl={audioInputControl}
            segmentationEnabled={segmentationEnabled}
            toggleBlur={toggleBlur}
            isBlurred={isBlurred}
            isMobileDevice={isMobileDevice}
            toggleFacingMode={toggleFacingMode}
            deniedDevice={deviceError.deniedDevice}
            setShowHelpVideo={setShowHelpVideo}
            learnHowToGrantAccessURL={learnHowToGrantAccessURL}
            permissionInfoType={permissionInfoType}
        />
    </PreflightControlsWrapper>
);

export type PreflightMediaControlsProps = React.ComponentProps<
    typeof PreflightMediaControls
>;
