import React from 'react';
import cx from 'classnames';

import type {ColorScheme} from '../../../types/variants';
import type {StandardSizeModifier} from '../../../types/sizes';
import {SvgImage} from '../SvgImage/SvgImage';

import PexipLogoSvg from './pexipLogo.svg';

import styles from './PexipLogo.module.scss';

export const PexipLogo: React.FC<
    React.ComponentProps<'svg'> & {
        colorScheme?: ColorScheme;
        size?: StandardSizeModifier | 'full';
        position?: 'absoluteCenter';
    }
> = ({colorScheme, size, position, className, ...props}) => {
    const isCenter = position === 'absoluteCenter';
    return (
        <SvgImage
            source={PexipLogoSvg}
            className={cx(
                styles.logo,
                colorScheme && styles[colorScheme],
                size && styles[size],
                className,
                {
                    [styles.absoluteCenter]: isCenter,
                },
            )}
            {...props}
        />
    );
};

export type PexipLogoProps = React.ComponentProps<typeof PexipLogo>;
