import React from 'react';
import cx from 'classnames';

import {FontVariant} from '../../../../design-tokens/constants';
import {ThemeConsumer, ThemeProvider} from '../../../themes/ThemeContext';
import type {ColorScheme} from '../../../types/variants';
import {Divider} from '../Divider/Divider';
import {Text} from '../Text/Text';

import styles from './ActionsRow.module.scss';

export const ActionsRow: React.FC<
    Omit<React.ComponentProps<'div'>, 'title'> & {
        children?: React.ReactNode;
        title: string | React.ReactNode;
        subtitle?: string;
        className?: string;
        colorScheme?: ColorScheme;
    }
> = ({children, className, colorScheme, title, subtitle, ...props}) => (
    <ThemeConsumer>
        {({colorScheme: defaultColorScheme}) => {
            const activeColorScheme = colorScheme ?? defaultColorScheme;
            return (
                <ThemeProvider colorScheme={activeColorScheme}>
                    <div className={cx(className, styles.wrapper)} {...props}>
                        <div>
                            <div>
                                {typeof title === 'string' ? (
                                    <Text
                                        fontVariant={
                                            subtitle
                                                ? FontVariant.BodyBold
                                                : FontVariant.Body
                                        }
                                    >
                                        {title}
                                    </Text>
                                ) : (
                                    title
                                )}
                            </div>
                            <Text
                                fontVariant={FontVariant.Small}
                                colorScheme={activeColorScheme}
                            >
                                {subtitle}
                            </Text>
                        </div>
                        {children && <div className="ml-auto">{children}</div>}
                    </div>
                    <Divider />
                </ThemeProvider>
            );
        }}
    </ThemeConsumer>
);

export type ActionsRowProps = React.ComponentProps<typeof ActionsRow>;
