import React from 'react';
import cx from 'classnames';

import styles from './Grid.module.scss';

export const Grid: React.FC<
    React.ComponentProps<'div'> & {hasCellVerticalGutter?: boolean}
> = ({className, children, hasCellVerticalGutter, ...props}) => (
    <div
        className={cx(styles.grid, {hasCellVerticalGutter}, className)}
        {...props}
    >
        {children}
    </div>
);
