import React from 'react';

import {InfoCard, InfoCardType} from '@pexip/media-components';

import {useBrandImage} from '../hooks/useBrandImage';
import {useBranding} from '../branding/Context';

export const BrandedInfoCard: React.FC<
    Omit<React.ComponentProps<typeof InfoCard>, 'type'> & {type?: InfoCardType}
> = ({type = InfoCardType.Meeting, ...props}) => {
    const jumbotron = useBrandImage('jumbotron');
    const logo = useBrandImage('logo');
    const brandName = useBranding('brandName');

    return (
        <InfoCard
            type={type}
            imageUrl={jumbotron}
            brandName={brandName}
            logoUrl={logo}
            {...props}
        />
    );
};
