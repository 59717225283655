import React from 'react';
import cx from 'classnames';

import styles from './AspectRatio.module.scss';

export interface Dimensions {
    width: number;
    height: number;
}

const initialDimensions: Dimensions = {width: 16, height: 9};

/**
 * A wrapper that makes its children element bound to an aspect ratio.
 * Default ration is 16:9, `{width: 16, height: 9}`
 */
export const AspectRatio: React.FC<
    React.ComponentProps<'div'> & {
        dimensions?: Dimensions;
        isActive?: boolean;
    }
> = ({
    children,
    className,
    dimensions = initialDimensions,
    isActive = true,
    style,
    ...props
}) => {
    const aspectRatioProperties = isActive
        ? ({
              '--aspect-ratio-height': dimensions.height,
              '--aspect-ratio-width': dimensions.width,
          } as React.CSSProperties)
        : undefined;

    return (
        <div
            style={{...style, ...aspectRatioProperties}}
            className={cx(styles.wrapper, className)}
            {...props}
        >
            {children}
        </div>
    );
};
