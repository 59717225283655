import React from 'react';
import cx from 'classnames';
import {Trans, useTranslation} from 'react-i18next';

import {Button, FontVariant, FullscreenLayout, Text} from '@pexip/components';

import styles from './PoppedOutEmptyPresentation.module.scss';

export const PoppedOutEmptyPresentation: React.FC<{
    onClose?: () => void;
}> = ({onClose}) => {
    const {t} = useTranslation();
    return (
        <FullscreenLayout>
            <Text fontVariant={FontVariant.H3}>
                <Trans t={t} i18nKey="media.presentation.nobody-sharing-screen">
                    No-one is currently sharing their screen
                </Trans>
            </Text>
            <Text fontVariant={FontVariant.H6} className="mt-2">
                <Trans
                    t={t}
                    i18nKey="media.presentation.share-screen-placeholder"
                >
                    When someone shares their screen it’ll appear here
                </Trans>
            </Text>
            <Button
                className={cx('mt-5', styles.closeButton)}
                modifier="fullWidth"
                onClick={onClose}
                variant="tertiary"
            >
                <Trans t={t} i18nKey="common.close-window">
                    Close window
                </Trans>
            </Button>
        </FullscreenLayout>
    );
};
