import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
    Button,
    DropContent,
    Icon,
    IconTypes,
    withTransition,
} from '@pexip/components';

import type {ChatMessage} from '../../types';
import {ChatPanelContentWrapper} from '../ChatPanel/ChatPanelContentWrapper.view';
import {ChatPanelMessages} from '../ChatMessage/ChatPanelMessages.view';
import {ChatScrollbars} from '../ChatScrollbars/ChatScrollbars.view';
import {isMobileDevice} from '../../utils';
import {TestId} from '../../../test/testIds';

import styles from './ChatHistory.module.scss';

const AnimatedChatHistory = withTransition(ChatPanelContentWrapper, 300);

export const ChatHistoryView: React.FC<{
    chatMessages: ChatMessage[];
    isOpen?: boolean;
}> = ({chatMessages, isOpen = false}) => {
    const {t} = useTranslation();
    const [isPanelVisible, setIsPanelVisible] = useState(false);
    return (
        <DropContent
            isOpen={isOpen}
            buttonLabel="Chat history"
            dropContentRole="region"
            position="topLeft"
            content={() => (
                <AnimatedChatHistory
                    isVisible={isPanelVisible}
                    isShown={isPanelVisible}
                >
                    <div
                        data-testid={TestId.ChatPanel}
                        className={styles.chatHistoryWrapper}
                    >
                        <ChatScrollbars hasInput={false}>
                            <ChatPanelMessages messages={chatMessages} />
                        </ChatScrollbars>
                    </div>
                </AnimatedChatHistory>
            )}
            onContentVisibilityChange={isVisible =>
                setIsPanelVisible(isVisible)
            }
        >
            {(isOpen: boolean) => (
                <Button
                    data-testid={TestId.ButtonChat}
                    variant={isOpen ? 'primary' : 'tertiary'}
                    htmlTag="div"
                    enhancerStart={
                        <Icon
                            source={IconTypes.IconChat}
                            size={isMobileDevice() ? 'small' : 'compact'}
                        />
                    }
                    modifier={isMobileDevice() ? 'square' : undefined}
                    noEnhancerPadding={isMobileDevice()}
                    aria-label={t('meeting.toggle-chat', 'Toggle chat panel')}
                >
                    <span className="hideOnMobile">
                        {t('meeting.chat-history', 'Chat history')}
                    </span>
                </Button>
            )}
        </DropContent>
    );
};
