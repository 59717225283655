import type {
    RPCCall,
    RPCCalls,
    Event,
    RPCReply,
    FormElement,
    SelectElement,
    ChecklistElement,
    InputElement,
    TextArea,
    RadioGroupElement,
} from './types';

export function isEvent(data: unknown): data is Event {
    return Boolean(
        data && typeof data === 'object' && 'chanId' in data && 'event' in data,
    );
}

export function isRPCCall(data: unknown): data is RPCCall {
    if (data && typeof data === 'object' && 'chanId' in data && 'rpc' in data) {
        return true;
    }

    return false;
}

export function isRPCReply(data: unknown): data is RPCReply {
    if (
        data &&
        typeof data === 'object' &&
        'chanId' in data &&
        'replyTo' in data
    ) {
        return true;
    }

    return false;
}

export function isRPCCallType<T extends keyof RPCCalls>(
    type: T,
    data: RPCCall<T>,
): data is RPCCall<T> {
    if (typeof data !== 'object' || !data) {
        return false;
    }
    return (
        data.rpc === type && typeof data.id === 'string' && 'payload' in data
    );
}

export const isFormSelectElement = (
    element: FormElement,
): element is SelectElement => element.type === 'select';

export const isFormChecklistElement = (
    element: FormElement,
): element is ChecklistElement => element.type === 'checklist';

export const isFormInputElement = (
    element: FormElement,
): element is InputElement | TextArea =>
    ['number', 'text', 'password', 'email', 'tel', 'url', 'textarea'].includes(
        element.type as string,
    );

export const isFormRadioGroupElement = (
    element: FormElement,
): element is RadioGroupElement => element.type === 'radio';

export const isFormRangeElement = (
    element: FormElement,
): element is FormElement => element.type === 'range';
