import React from 'react';

import {usePreviewController} from '../services/Media.service';

import {MediaControl} from './MediaControl.viewModel';

export const VideoAndSound: React.FC<{
    onBackClick: () => void;
    close: (e: React.SyntheticEvent<HTMLElement>) => void;
}> = ({close, onBackClick}) => {
    const controller = usePreviewController(true);
    return controller ? (
        <MediaControl
            controller={controller}
            close={close}
            onBackClick={onBackClick}
        />
    ) : null;
};
