import React from 'react';
import cx from 'classnames';

import {Box, ThemeProvider} from '@pexip/components';

import styles from './ResponsiveBox.module.scss';

export const ResponsiveBox: React.FC<
    React.PropsWithChildren<
        React.ComponentProps<typeof Box> & {
            className?: string;
            style?: React.CSSProperties;
        }
    >
> = ({padding = 'medium', children, className, ...props}) => (
    <ThemeProvider colorScheme="light">
        <Box
            padding={padding}
            className={cx(styles.responsiveBoxWrapper, className)}
            {...props}
        >
            {children}
        </Box>
    </ThemeProvider>
);
