import React from 'react';
import cx from 'classnames';

import {Box} from '@pexip/components';

import styles from './MicrophoneMonitorModal.module.scss';

export const MicrophoneMonitorContentBox: React.FC<
    React.PropsWithChildren<{showBorder?: boolean}>
> = ({children, showBorder}) => (
    <Box
        className={cx(styles.contentBoxWrapper)}
        isWithShadow={false}
        isWithBorder={showBorder}
    >
        {children}
    </Box>
);
