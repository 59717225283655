import React from 'react';
import {useTranslation} from 'react-i18next';

import {Toast} from '@pexip/components';

export const ToastSpeakingWhileMuted: React.FC<{
    voiceDetected: boolean;
    setVoiceDetected: (isVoidDetected: boolean) => void;
}> = ({voiceDetected, setVoiceDetected}) => {
    const {t} = useTranslation();

    return (
        <Toast
            message={t(
                'media.trying-to-speak',
                'Trying to speak? Your microphone is muted',
            )}
            isDisplayed={voiceDetected}
            isVisible={voiceDetected}
            onDismiss={() => setVoiceDetected(false)}
            position="bottomCenter"
            colorScheme="light"
        />
    );
};
