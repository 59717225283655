import React from 'react';
import cx from 'classnames';

import {Text} from '../../elements/Text/Text';
import {Avatar} from '../Avatar/Avatar';
import type {AvatarType} from '../Avatar/Avatar.types';
import {FontVariant} from '../../../../design-tokens/generated/constants';
import type {ColorScheme} from '../../../types/variants';
import {ThemeConsumer, ThemeProvider} from '../../../themes/ThemeContext';
import {TestId} from '../../../utils/testIds';
import type {ExtendedFixedSizeModifier} from '../../../types/sizes';

import styles from './User.module.scss';

export const User: React.FC<{
    avatarType: AvatarType;
    className?: string;
    email?: string;
    displayName: string;
    imageUrl?: string;
    order?: 'text-right' | 'text-left';
    colorScheme?: ColorScheme;
    usernameFontVariant?: FontVariant;
    textItemClassName?: string;
    size?: ExtendedFixedSizeModifier;
}> = ({
    avatarType,
    email,
    displayName,
    imageUrl,
    order = 'text-right',
    colorScheme,
    usernameFontVariant = FontVariant.BodyBold,
    className,
    textItemClassName,
    size = 'small',
}) => (
    <ThemeConsumer>
        {({colorScheme: defaultColorScheme}) => (
            <ThemeProvider colorScheme={colorScheme ?? defaultColorScheme}>
                <div
                    className={cx(
                        styles.user,
                        {
                            [styles.textLeft]: order === 'text-left',
                        },
                        className,
                    )}
                    data-testid={TestId.UserView}
                >
                    <Avatar
                        type={avatarType}
                        displayName={displayName}
                        translate="no"
                        imageUrl={imageUrl}
                        className={styles.userAvatar}
                        sizeModifier={size}
                    />

                    <div className={styles.textWrapper}>
                        <div className={cx(styles.textItem, textItemClassName)}>
                            <Text
                                fontVariant={usernameFontVariant}
                                isTruncated
                                translate="no"
                                title={displayName}
                            >
                                {displayName}
                            </Text>
                        </div>
                        {email && (
                            <div
                                className={cx(
                                    styles.textItem,
                                    textItemClassName,
                                )}
                            >
                                <Text
                                    fontVariant={FontVariant.Body}
                                    isTruncated
                                >
                                    {email}
                                </Text>
                            </div>
                        )}
                    </div>
                </div>
            </ThemeProvider>
        )}
    </ThemeConsumer>
);

export type UserProps = React.ComponentProps<typeof User>;
