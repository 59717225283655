import type {MediaDeviceInfoLike} from '@pexip/media-control';
import type {PreviewInput} from '@pexip/media';

import type {DeviceError} from '../types';

export const onDeviceSelectChange =
    (
        setInputError: React.Dispatch<React.SetStateAction<DeviceError>>,
        updateInput?: (input: PreviewInput) => void,
    ) =>
    (device: MediaDeviceInfoLike) => {
        setInputError({
            title: '',
        });
        updateInput?.(device);
    };
