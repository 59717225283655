import React from 'react';
import type {SyntheticEvent} from 'react';

import type {ModalProps} from '../Modal/Modal';
import {Modal} from '../Modal/Modal';
import type {ListProps} from '../List/List';
import {List} from '../List/List';
import {Row} from '../List/Row';
import {TextHeading} from '../../elements/TextHeading/TextHeading';
import {TextLink} from '../../elements/TextLink/TextLink';
import {Divider} from '../../elements/Divider/Divider';
import {Text} from '../../elements/Text/Text';
import {withColorScheme} from '../../../themes/ThemeContext';
import {FontVariant} from '../../../../design-tokens/constants';
import {TestId} from '../../../utils/testIds';

export const AboutText: React.FC<
    {
        enhancerEnd?: React.ReactNode;
        version: string;
        headingText?: string;
        privacyPolicyLinkText?: string;
        privacyPolicyUrl?: string;
        termsOfServiceLinkText?: string;
        termsOfServiceUrl?: string;
    } & ListProps
> = ({
    enhancerEnd,
    version,
    headingText = 'About Pexip',
    privacyPolicyLinkText = 'Privacy Policy',
    privacyPolicyUrl = 'https://pexip.com/privacy-policy/',
    termsOfServiceLinkText = 'Terms of Service',
    termsOfServiceUrl = 'https://pexip.com/terms-of-service/',
    ...props
}) => (
    <List className="ml-3 mr-3 mb-2" {...props}>
        {headingText && (
            <Row>
                <TextHeading
                    className="mb-3 mt-2"
                    fontVariant={FontVariant.H5}
                    htmlTag="h1"
                >
                    {headingText}
                </TextHeading>
            </Row>
        )}

        {privacyPolicyUrl && privacyPolicyLinkText && (
            <Row spacing="small">
                <TextLink
                    href={privacyPolicyUrl}
                    target="_blank"
                    data-testid={TestId.PrivacyPolicyLink}
                >
                    {privacyPolicyLinkText}
                </TextLink>
            </Row>
        )}

        {termsOfServiceLinkText && termsOfServiceUrl && (
            <Row spacing="small">
                <TextLink
                    href={termsOfServiceUrl}
                    target="_blank"
                    data-testid={TestId.TermsOfServiceLink}
                >
                    {termsOfServiceLinkText}
                </TextLink>
            </Row>
        )}

        <Divider className="mt-5 mb-3" />
        <Text data-testid={TestId.Version}>Version: {version}</Text>
        {enhancerEnd}
    </List>
);

const ModalLight = withColorScheme(Modal, 'light');

export const AboutModal: React.FC<
    ModalProps & {
        isOpen: boolean;
        onClose: (e: SyntheticEvent) => void;
        version: string;
        headingText?: string;
        privacyPolicyUrl?: string;
        privacyPolicyLinkText?: string;
        termsOfServiceUrl?: string;
        termsOfServiceLinkText?: string;
        aboutTextEnhancerEnd?: React.ReactNode;
    }
> = ({
    className,
    isOpen = false,
    onClose,
    version,
    headingText,
    privacyPolicyUrl,
    privacyPolicyLinkText,
    termsOfServiceUrl,
    termsOfServiceLinkText,
    aboutTextEnhancerEnd,
    ...props
}) => {
    return (
        <ModalLight
            padding="none"
            onClose={onClose}
            isOpen={isOpen}
            withCloseButton
            className={className}
            {...props}
        >
            <AboutText
                version={version}
                headingText={headingText}
                privacyPolicyUrl={privacyPolicyUrl}
                privacyPolicyLinkText={privacyPolicyLinkText}
                termsOfServiceUrl={termsOfServiceUrl}
                termsOfServiceLinkText={termsOfServiceLinkText}
                enhancerEnd={aboutTextEnhancerEnd}
            />
        </ModalLight>
    );
};
