import {isArray, isString} from './types';

const merger = <T>(acc: string[], cur: T) => {
    if (isArray(cur)) {
        return acc.concat(cur);
    }
    if (isString(cur)) {
        return acc.concat([cur]);
    }
    return acc;
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set#Remove_duplicate_elements_from_the_array
const uniqueArray = <T>(...args: T[]) => [
    ...new Set(args.reduce(merger, [] as string[])),
];

const sorter = (a: string, b: string) => a.localeCompare(b);

export const mergeSortArray = <T>(...args: T[]) =>
    uniqueArray(...args).sort(sorter);
