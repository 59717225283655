import React from 'react';
import {useTranslation} from 'react-i18next';

import {useConfig} from '../config';
import {renderUserNamePageSignal} from '../signals/MeetingFlow.signals';
import {UserNameCard} from '../views/UserNameCard/UserNameCard.view';
import {applicationConfig} from '../applicationConfig';

export const DisplayName: React.FC = () => {
    const {i18n} = useTranslation();
    const [displayName, setDisplayName] = useConfig('displayName');

    const onSubmit = (displayName: string) => {
        if (displayName.length) {
            renderUserNamePageSignal.emit(false);
            setDisplayName(displayName, true);
        }
    };

    return (
        <UserNameCard
            defaultName={displayName}
            onSubmit={onSubmit}
            termsUrl={
                applicationConfig.termsAndConditions?.[i18n.language] ??
                applicationConfig.termsAndConditions?.en
            }
        />
    );
};
