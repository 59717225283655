import React, {useRef} from 'react';
import cx from 'classnames';
import {useTooltipTriggerState} from '@react-stately/tooltip';
import {useTooltipTrigger} from '@react-aria/tooltip';
import {Overlay, useOverlayPosition} from '@react-aria/overlays';

import {Text} from '../Text/Text';
import type {ColorScheme} from '../../../types/variants';
import type {TooltipPosition} from '../../../types/propTypes';
import {Box} from '../Box/Box';
import {ThemeProvider} from '../../../themes/ThemeContext';

import {mapTooltipPosition} from './Tooltip.utils';

import styles from './Tooltip.module.scss';

export const Tooltip: React.FC<
    React.ComponentProps<'div'> & {
        colorScheme?: ColorScheme;
        isTooltipEnabled?: boolean;
        position?: TooltipPosition;
        text: React.ReactNode;
        tooltipContainerClassName?: string;
        delay?: number;
        closeDelay?: number;
    }
> = ({
    children,
    className,
    colorScheme = 'light',
    isTooltipEnabled = true,
    text,
    tooltipContainerClassName,
    position = 'top',
    delay = 500,
    closeDelay = 0,
    ...props
}) => {
    const state = useTooltipTriggerState({delay, closeDelay});
    const targetRef = useRef(null);
    const overlayRef = useRef(null);
    const {triggerProps, tooltipProps} = useTooltipTrigger(
        {},
        state,
        targetRef,
    );

    const {overlayProps} = useOverlayPosition({
        targetRef,
        overlayRef,
        placement: mapTooltipPosition(position),
        isOpen: state.isOpen,
    });

    if (!isTooltipEnabled) {
        return <>{children}</>;
    }

    return (
        <div
            className={cx(styles.tooltipContainer, tooltipContainerClassName)}
            ref={targetRef}
            {...props}
        >
            {React.Children.map(children, child => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, {
                        ...triggerProps,
                    } as React.Attributes);
                }
                return child;
            })}
            {state.isOpen && (
                <Overlay>
                    <div
                        {...tooltipProps}
                        ref={overlayRef}
                        {...overlayProps}
                        role="tooltip"
                    >
                        <ThemeProvider colorScheme={colorScheme}>
                            <Box
                                className={cx(
                                    styles.tooltip,
                                    styles.tooltipControl,
                                    styles[position],
                                    className,
                                )}
                                data-testid="tooltip-inner"
                            >
                                {typeof text === 'string' ? (
                                    <Text>{text}</Text>
                                ) : (
                                    text
                                )}
                            </Box>
                        </ThemeProvider>
                    </div>
                </Overlay>
            )}
        </div>
    );
};

export type TooltipProps = React.ComponentProps<typeof Tooltip>;
