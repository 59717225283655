'use strict';
export const validateBreakoutsBody = validate11;
const schema12 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                name: {
                    type: 'string',
                    description:
                        'Unused for now, but could be used as overlay text etc.',
                },
                description: {
                    type: 'string',
                    description:
                        'Unused for now, but could be used as overlay text or passed to client to display somehow',
                },
                duration: {
                    type: 'number',
                    description:
                        'Optional timer for how long the breakout is open (not present or 0 = indefinitely)',
                },
                end_action: {
                    type: 'string',
                    enum: ['disconnect', 'transfer'],
                    default: 'transfer',
                    description:
                        'When timer expires, or the breakout is closed, what do we do with participants: Disconnect them all, or transfer them back to the main room (default transfer)',
                },
                participants: {
                    type: 'object',
                    description:
                        'Optional list of participants to transfer to the breakout on creation. Record<FromBreakoutRoomUuid, ParticipantUuid[]>',
                    additionalProperties: {
                        type: 'array',
                        items: {type: 'string'},
                    },
                },
                guests_allowed_to_leave: {
                    type: 'boolean',
                    description:
                        'Are guests allowed to leave a breakout by themselves',
                },
            },
            required: ['name'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {$ref: '#/definitions/breakouts/properties/Body/definitions/TopLevel'},
    ],
};
const schema13 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        name: {
            type: 'string',
            description:
                'Unused for now, but could be used as overlay text etc.',
        },
        description: {
            type: 'string',
            description:
                'Unused for now, but could be used as overlay text or passed to client to display somehow',
        },
        duration: {
            type: 'number',
            description:
                'Optional timer for how long the breakout is open (not present or 0 = indefinitely)',
        },
        end_action: {
            type: 'string',
            enum: ['disconnect', 'transfer'],
            default: 'transfer',
            description:
                'When timer expires, or the breakout is closed, what do we do with participants: Disconnect them all, or transfer them back to the main room (default transfer)',
        },
        participants: {
            type: 'object',
            description:
                'Optional list of participants to transfer to the breakout on creation. Record<FromBreakoutRoomUuid, ParticipantUuid[]>',
            additionalProperties: {type: 'array', items: {type: 'string'}},
        },
        guests_allowed_to_leave: {
            type: 'boolean',
            description: 'Are guests allowed to leave a breakout by themselves',
        },
    },
    required: ['name'],
    title: 'TopLevel',
};
function validate11(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (data.name === undefined && (missing0 = 'name')) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakouts/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'name' ||
                            key0 === 'description' ||
                            key0 === 'duration' ||
                            key0 === 'end_action' ||
                            key0 === 'participants' ||
                            key0 === 'guests_allowed_to_leave'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.name !== undefined) {
                        const _errs5 = errors;
                        if (typeof data.name !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/name',
                                schemaPath:
                                    '#/definitions/breakouts/properties/Body/definitions/TopLevel/properties/name/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.description !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.description !== 'string') {
                                const err2 = {
                                    instancePath: instancePath + '/description',
                                    schemaPath:
                                        '#/definitions/breakouts/properties/Body/definitions/TopLevel/properties/description/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err2];
                                } else {
                                    vErrors.push(err2);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                        if (valid2) {
                            if (data.duration !== undefined) {
                                const _errs9 = errors;
                                if (!(typeof data.duration == 'number')) {
                                    const err3 = {
                                        instancePath:
                                            instancePath + '/duration',
                                        schemaPath:
                                            '#/definitions/breakouts/properties/Body/definitions/TopLevel/properties/duration/type',
                                        keyword: 'type',
                                        params: {type: 'number'},
                                        message: 'must be number',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err3];
                                    } else {
                                        vErrors.push(err3);
                                    }
                                    errors++;
                                }
                                var valid2 = _errs9 === errors;
                            } else {
                                var valid2 = true;
                            }
                            if (valid2) {
                                if (data.end_action !== undefined) {
                                    let data3 = data.end_action;
                                    const _errs11 = errors;
                                    if (typeof data3 !== 'string') {
                                        const err4 = {
                                            instancePath:
                                                instancePath + '/end_action',
                                            schemaPath:
                                                '#/definitions/breakouts/properties/Body/definitions/TopLevel/properties/end_action/type',
                                            keyword: 'type',
                                            params: {type: 'string'},
                                            message: 'must be string',
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err4];
                                        } else {
                                            vErrors.push(err4);
                                        }
                                        errors++;
                                    }
                                    if (
                                        !(
                                            data3 === 'disconnect' ||
                                            data3 === 'transfer'
                                        )
                                    ) {
                                        const err5 = {
                                            instancePath:
                                                instancePath + '/end_action',
                                            schemaPath:
                                                '#/definitions/breakouts/properties/Body/definitions/TopLevel/properties/end_action/enum',
                                            keyword: 'enum',
                                            params: {
                                                allowedValues:
                                                    schema13.properties
                                                        .end_action.enum,
                                            },
                                            message:
                                                'must be equal to one of the allowed values',
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err5];
                                        } else {
                                            vErrors.push(err5);
                                        }
                                        errors++;
                                    }
                                    var valid2 = _errs11 === errors;
                                } else {
                                    var valid2 = true;
                                }
                                if (valid2) {
                                    if (data.participants !== undefined) {
                                        let data4 = data.participants;
                                        const _errs13 = errors;
                                        if (errors === _errs13) {
                                            if (
                                                data4 &&
                                                typeof data4 == 'object' &&
                                                !Array.isArray(data4)
                                            ) {
                                                const _errs15 = errors;
                                                for (const key1 in data4) {
                                                    let data5 = data4[key1];
                                                    const _errs16 = errors;
                                                    if (errors === _errs16) {
                                                        if (
                                                            Array.isArray(data5)
                                                        ) {
                                                            var valid4 = true;
                                                            const len0 =
                                                                data5.length;
                                                            for (
                                                                let i0 = 0;
                                                                i0 < len0;
                                                                i0++
                                                            ) {
                                                                const _errs18 =
                                                                    errors;
                                                                if (
                                                                    typeof data5[
                                                                        i0
                                                                    ] !==
                                                                    'string'
                                                                ) {
                                                                    const err6 =
                                                                        {};
                                                                    if (
                                                                        vErrors ===
                                                                        null
                                                                    ) {
                                                                        vErrors =
                                                                            [
                                                                                err6,
                                                                            ];
                                                                    } else {
                                                                        vErrors.push(
                                                                            err6,
                                                                        );
                                                                    }
                                                                    errors++;
                                                                }
                                                                var valid4 =
                                                                    _errs18 ===
                                                                    errors;
                                                                if (!valid4) {
                                                                    break;
                                                                }
                                                            }
                                                        } else {
                                                            const err7 = {};
                                                            if (
                                                                vErrors === null
                                                            ) {
                                                                vErrors = [
                                                                    err7,
                                                                ];
                                                            } else {
                                                                vErrors.push(
                                                                    err7,
                                                                );
                                                            }
                                                            errors++;
                                                        }
                                                    }
                                                    var valid3 =
                                                        _errs16 === errors;
                                                    if (!valid3) {
                                                        errors = _errs15;
                                                        if (vErrors !== null) {
                                                            if (_errs15) {
                                                                vErrors.length =
                                                                    _errs15;
                                                            } else {
                                                                vErrors = null;
                                                            }
                                                        }
                                                        delete data4[key1];
                                                    }
                                                }
                                            } else {
                                                const err8 = {
                                                    instancePath:
                                                        instancePath +
                                                        '/participants',
                                                    schemaPath:
                                                        '#/definitions/breakouts/properties/Body/definitions/TopLevel/properties/participants/type',
                                                    keyword: 'type',
                                                    params: {type: 'object'},
                                                    message: 'must be object',
                                                };
                                                if (vErrors === null) {
                                                    vErrors = [err8];
                                                } else {
                                                    vErrors.push(err8);
                                                }
                                                errors++;
                                            }
                                        }
                                        var valid2 = _errs13 === errors;
                                    } else {
                                        var valid2 = true;
                                    }
                                    if (valid2) {
                                        if (
                                            data.guests_allowed_to_leave !==
                                            undefined
                                        ) {
                                            const _errs20 = errors;
                                            if (
                                                typeof data.guests_allowed_to_leave !==
                                                'boolean'
                                            ) {
                                                const err9 = {
                                                    instancePath:
                                                        instancePath +
                                                        '/guests_allowed_to_leave',
                                                    schemaPath:
                                                        '#/definitions/breakouts/properties/Body/definitions/TopLevel/properties/guests_allowed_to_leave/type',
                                                    keyword: 'type',
                                                    params: {type: 'boolean'},
                                                    message: 'must be boolean',
                                                };
                                                if (vErrors === null) {
                                                    vErrors = [err9];
                                                } else {
                                                    vErrors.push(err9);
                                                }
                                                errors++;
                                            }
                                            var valid2 = _errs20 === errors;
                                        } else {
                                            var valid2 = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            const err10 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakouts/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err10];
            } else {
                vErrors.push(err10);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err11 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err11];
        } else {
            vErrors.push(err11);
        }
        errors++;
        validate11.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate11.errors = vErrors;
    return errors === 0;
}
export const validateBreakouts200 = validate12;
const schema14 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    $ref: '#/definitions/breakouts/properties/200/definitions/Result',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Result: {
            type: 'object',
            additionalProperties: false,
            properties: {breakout_uuid: {type: 'string'}},
            required: ['breakout_uuid'],
            title: 'Result',
        },
    },
    oneOf: [{$ref: '#/definitions/breakouts/properties/200/definitions/200'}],
};
const schema15 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            $ref: '#/definitions/breakouts/properties/200/definitions/Result',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema16 = {
    type: 'object',
    additionalProperties: false,
    properties: {breakout_uuid: {type: 'string'}},
    required: ['breakout_uuid'],
    title: 'Result',
};
function validate13(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate13.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate13.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success')) {
                            validate13.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            const _errs5 = errors;
                            if (errors === _errs5) {
                                if (
                                    data1 &&
                                    typeof data1 == 'object' &&
                                    !Array.isArray(data1)
                                ) {
                                    let missing1;
                                    if (
                                        data1.breakout_uuid === undefined &&
                                        (missing1 = 'breakout_uuid')
                                    ) {
                                        validate13.errors = [
                                            {
                                                instancePath:
                                                    instancePath + '/result',
                                                schemaPath:
                                                    '#/definitions/breakouts/properties/200/definitions/Result/required',
                                                keyword: 'required',
                                                params: {
                                                    missingProperty: missing1,
                                                },
                                                message:
                                                    "must have required property '" +
                                                    missing1 +
                                                    "'",
                                            },
                                        ];
                                        return false;
                                    } else {
                                        const _errs7 = errors;
                                        for (const key1 in data1) {
                                            if (!(key1 === 'breakout_uuid')) {
                                                delete data1[key1];
                                            }
                                        }
                                        if (_errs7 === errors) {
                                            if (
                                                data1.breakout_uuid !==
                                                undefined
                                            ) {
                                                if (
                                                    typeof data1.breakout_uuid !==
                                                    'string'
                                                ) {
                                                    validate13.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/result/breakout_uuid',
                                                            schemaPath:
                                                                '#/definitions/breakouts/properties/200/definitions/Result/properties/breakout_uuid/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'string',
                                                            },
                                                            message:
                                                                'must be string',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    validate13.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/result',
                                            schemaPath:
                                                '#/definitions/breakouts/properties/200/definitions/Result/type',
                                            keyword: 'type',
                                            params: {type: 'object'},
                                            message: 'must be object',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate13.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate13.errors = vErrors;
    return errors === 0;
}
function validate12(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate13(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate13.errors
                : vErrors.concat(validate13.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate12.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate12.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutsMessageBody = validate15;
const schema17 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                type: {
                    type: 'string',
                    enum: ['text/plain', 'application/json'],
                    description: 'The MIME Content-Type.',
                },
                payload: {type: 'string', description: 'Message to send.'},
            },
            required: ['type', 'payload'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakouts_message/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema18 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        type: {
            type: 'string',
            enum: ['text/plain', 'application/json'],
            description: 'The MIME Content-Type.',
        },
        payload: {type: 'string', description: 'Message to send.'},
    },
    required: ['type', 'payload'],
    title: 'TopLevel',
};
function validate15(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.type === undefined && (missing0 = 'type')) ||
                (data.payload === undefined && (missing0 = 'payload'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'type' || key0 === 'payload')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.type !== undefined) {
                        let data0 = data.type;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/type',
                                schemaPath:
                                    '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/properties/type/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (
                            !(
                                data0 === 'text/plain' ||
                                data0 === 'application/json'
                            )
                        ) {
                            const err2 = {
                                instancePath: instancePath + '/type',
                                schemaPath:
                                    '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/properties/type/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema18.properties.type.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.payload !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.payload !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/payload',
                                    schemaPath:
                                        '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/properties/payload/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate15.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate15.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutsMessage200 = validate16;
const schema19 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    type: 'boolean',
                    description:
                        'The result is true if successful, false otherwise.',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {$ref: '#/definitions/breakout_ack/properties/200/definitions/200'},
    ],
};
const schema20 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            type: 'boolean',
            description: 'The result is true if successful, false otherwise.',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate16(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate16.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate16.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutsDisconnect200 = validate17;
const schema21 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {type: 'null'},
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakouts_disconnect/properties/200/definitions/200',
        },
    ],
};
const schema22 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {type: 'null'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate17(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakouts_disconnect/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakouts_disconnect/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakouts_disconnect/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema22.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakouts_disconnect/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakouts_disconnect/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate17.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate17.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutsEmpty200 = validate18;
function validate18(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate18.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate18.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutDisconnect200 = validate19;
function validate19(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate19.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate19.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutTransformLayoutBody = validate20;
const schema27 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                transforms: {
                    $ref: '#/definitions/breakout_transform_layout/properties/Body/definitions/Transforms',
                },
            },
            required: ['transforms'],
            title: 'TopLevel',
        },
        Transforms: {
            type: 'object',
            additionalProperties: false,
            properties: {
                layout: {
                    type: 'string',
                    description:
                        'In VMRs the layout for Hosts and Guests is controlled by the layout parameter.',
                },
                host_layout: {
                    type: 'string',
                    description:
                        'In Virtual Auditoriums the Host layout is controlled by the host_layout parameter',
                },
                guest_layout: {
                    type: 'string',
                    description:
                        'In Virtual Auditoriums the Guest layout is controlled by the guest_layout parameter',
                },
                enable_extended_ac: {
                    type: 'boolean',
                    description:
                        'This enables an extended Adaptive Composition (AC) layout that can contain more video participants than the standard AC layout.',
                },
                streaming_indicator: {
                    type: 'boolean',
                    description:
                        'Determines whether the streaming indicator icon is disabled (false) or enabled (true).',
                },
                recording_indicator: {
                    type: 'boolean',
                    description:
                        'Determines whether the recording indicator icon is disabled (false) or enabled (true).',
                },
                enable_active_speaker_indication: {
                    type: 'boolean',
                    description:
                        'Determines whether active speaker indication is disabled (false) or enabled (true).',
                },
                enable_overlay_text: {
                    type: 'boolean',
                    description:
                        'Determines whether active speaker indication is disabled (false) or enabled (true).',
                },
                streaming: {
                    $ref: '#/definitions/breakout_transform_layout/properties/Body/definitions/Streaming',
                },
                free_form_overlay_text: {
                    type: 'array',
                    description:
                        'Allows you to customize (override) the overlay text. It contains a list of strings to apply to each participant in the order in which the participants are displayed.',
                    items: {type: 'string'},
                },
            },
            required: [],
            title: 'Transforms',
        },
        Streaming: {
            type: 'object',
            additionalProperties: false,
            properties: {
                layout: {
                    type: 'string',
                    description:
                        'Sets the layout seen by the streaming participant (regardless of Host or Guest role).',
                },
                waiting_screen_enabled: {
                    type: 'boolean',
                    default: false,
                    description:
                        'Determines whether the stream_waiting splash screen is displayed (true) or not (false).',
                },
                plus_n_pip_enabled: {
                    type: 'boolean',
                    default: true,
                    description:
                        'Controls whether the `plus n indicator` i.e. the number of participants present in the conference, is displayed (true) or not (false).',
                },
                indicators_enabled: {
                    type: 'boolean',
                    default: true,
                    description:
                        'Controls whether the in-conference indicators (audio participant indicators, conference locked indicators etc.) are displayed (true) or not (false).',
                },
            },
            required: [],
            title: 'Streaming',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_transform_layout/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema28 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        transforms: {
            $ref: '#/definitions/breakout_transform_layout/properties/Body/definitions/Transforms',
        },
    },
    required: ['transforms'],
    title: 'TopLevel',
};
const schema29 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        layout: {
            type: 'string',
            description:
                'In VMRs the layout for Hosts and Guests is controlled by the layout parameter.',
        },
        host_layout: {
            type: 'string',
            description:
                'In Virtual Auditoriums the Host layout is controlled by the host_layout parameter',
        },
        guest_layout: {
            type: 'string',
            description:
                'In Virtual Auditoriums the Guest layout is controlled by the guest_layout parameter',
        },
        enable_extended_ac: {
            type: 'boolean',
            description:
                'This enables an extended Adaptive Composition (AC) layout that can contain more video participants than the standard AC layout.',
        },
        streaming_indicator: {
            type: 'boolean',
            description:
                'Determines whether the streaming indicator icon is disabled (false) or enabled (true).',
        },
        recording_indicator: {
            type: 'boolean',
            description:
                'Determines whether the recording indicator icon is disabled (false) or enabled (true).',
        },
        enable_active_speaker_indication: {
            type: 'boolean',
            description:
                'Determines whether active speaker indication is disabled (false) or enabled (true).',
        },
        enable_overlay_text: {
            type: 'boolean',
            description:
                'Determines whether active speaker indication is disabled (false) or enabled (true).',
        },
        streaming: {
            $ref: '#/definitions/breakout_transform_layout/properties/Body/definitions/Streaming',
        },
        free_form_overlay_text: {
            type: 'array',
            description:
                'Allows you to customize (override) the overlay text. It contains a list of strings to apply to each participant in the order in which the participants are displayed.',
            items: {type: 'string'},
        },
    },
    required: [],
    title: 'Transforms',
};
const schema30 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        layout: {
            type: 'string',
            description:
                'Sets the layout seen by the streaming participant (regardless of Host or Guest role).',
        },
        waiting_screen_enabled: {
            type: 'boolean',
            default: false,
            description:
                'Determines whether the stream_waiting splash screen is displayed (true) or not (false).',
        },
        plus_n_pip_enabled: {
            type: 'boolean',
            default: true,
            description:
                'Controls whether the `plus n indicator` i.e. the number of participants present in the conference, is displayed (true) or not (false).',
        },
        indicators_enabled: {
            type: 'boolean',
            default: true,
            description:
                'Controls whether the in-conference indicators (audio participant indicators, conference locked indicators etc.) are displayed (true) or not (false).',
        },
    },
    required: [],
    title: 'Streaming',
};
const func2 = Object.prototype.hasOwnProperty;
function validate22(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            const _errs1 = errors;
            for (const key0 in data) {
                if (!func2.call(schema29.properties, key0)) {
                    delete data[key0];
                }
            }
            if (_errs1 === errors) {
                if (data.layout !== undefined) {
                    const _errs2 = errors;
                    if (typeof data.layout !== 'string') {
                        validate22.errors = [
                            {
                                instancePath: instancePath + '/layout',
                                schemaPath: '#/properties/layout/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            },
                        ];
                        return false;
                    }
                    var valid0 = _errs2 === errors;
                } else {
                    var valid0 = true;
                }
                if (valid0) {
                    if (data.host_layout !== undefined) {
                        const _errs4 = errors;
                        if (typeof data.host_layout !== 'string') {
                            validate22.errors = [
                                {
                                    instancePath: instancePath + '/host_layout',
                                    schemaPath: '#/properties/host_layout/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs4 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.guest_layout !== undefined) {
                            const _errs6 = errors;
                            if (typeof data.guest_layout !== 'string') {
                                validate22.errors = [
                                    {
                                        instancePath:
                                            instancePath + '/guest_layout',
                                        schemaPath:
                                            '#/properties/guest_layout/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    },
                                ];
                                return false;
                            }
                            var valid0 = _errs6 === errors;
                        } else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.enable_extended_ac !== undefined) {
                                const _errs8 = errors;
                                if (
                                    typeof data.enable_extended_ac !== 'boolean'
                                ) {
                                    validate22.errors = [
                                        {
                                            instancePath:
                                                instancePath +
                                                '/enable_extended_ac',
                                            schemaPath:
                                                '#/properties/enable_extended_ac/type',
                                            keyword: 'type',
                                            params: {type: 'boolean'},
                                            message: 'must be boolean',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs8 === errors;
                            } else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.streaming_indicator !== undefined) {
                                    const _errs10 = errors;
                                    if (
                                        typeof data.streaming_indicator !==
                                        'boolean'
                                    ) {
                                        validate22.errors = [
                                            {
                                                instancePath:
                                                    instancePath +
                                                    '/streaming_indicator',
                                                schemaPath:
                                                    '#/properties/streaming_indicator/type',
                                                keyword: 'type',
                                                params: {type: 'boolean'},
                                                message: 'must be boolean',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid0 = _errs10 === errors;
                                } else {
                                    var valid0 = true;
                                }
                                if (valid0) {
                                    if (
                                        data.recording_indicator !== undefined
                                    ) {
                                        const _errs12 = errors;
                                        if (
                                            typeof data.recording_indicator !==
                                            'boolean'
                                        ) {
                                            validate22.errors = [
                                                {
                                                    instancePath:
                                                        instancePath +
                                                        '/recording_indicator',
                                                    schemaPath:
                                                        '#/properties/recording_indicator/type',
                                                    keyword: 'type',
                                                    params: {type: 'boolean'},
                                                    message: 'must be boolean',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid0 = _errs12 === errors;
                                    } else {
                                        var valid0 = true;
                                    }
                                    if (valid0) {
                                        if (
                                            data.enable_active_speaker_indication !==
                                            undefined
                                        ) {
                                            const _errs14 = errors;
                                            if (
                                                typeof data.enable_active_speaker_indication !==
                                                'boolean'
                                            ) {
                                                validate22.errors = [
                                                    {
                                                        instancePath:
                                                            instancePath +
                                                            '/enable_active_speaker_indication',
                                                        schemaPath:
                                                            '#/properties/enable_active_speaker_indication/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'boolean',
                                                        },
                                                        message:
                                                            'must be boolean',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid0 = _errs14 === errors;
                                        } else {
                                            var valid0 = true;
                                        }
                                        if (valid0) {
                                            if (
                                                data.enable_overlay_text !==
                                                undefined
                                            ) {
                                                const _errs16 = errors;
                                                if (
                                                    typeof data.enable_overlay_text !==
                                                    'boolean'
                                                ) {
                                                    validate22.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/enable_overlay_text',
                                                            schemaPath:
                                                                '#/properties/enable_overlay_text/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'boolean',
                                                            },
                                                            message:
                                                                'must be boolean',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid0 = _errs16 === errors;
                                            } else {
                                                var valid0 = true;
                                            }
                                            if (valid0) {
                                                if (
                                                    data.streaming !== undefined
                                                ) {
                                                    let data8 = data.streaming;
                                                    const _errs18 = errors;
                                                    const _errs19 = errors;
                                                    if (errors === _errs19) {
                                                        if (
                                                            data8 &&
                                                            typeof data8 ==
                                                                'object' &&
                                                            !Array.isArray(
                                                                data8,
                                                            )
                                                        ) {
                                                            const _errs21 =
                                                                errors;
                                                            for (const key1 in data8) {
                                                                if (
                                                                    !(
                                                                        key1 ===
                                                                            'layout' ||
                                                                        key1 ===
                                                                            'waiting_screen_enabled' ||
                                                                        key1 ===
                                                                            'plus_n_pip_enabled' ||
                                                                        key1 ===
                                                                            'indicators_enabled'
                                                                    )
                                                                ) {
                                                                    delete data8[
                                                                        key1
                                                                    ];
                                                                }
                                                            }
                                                            if (
                                                                _errs21 ===
                                                                errors
                                                            ) {
                                                                if (
                                                                    data8.layout !==
                                                                    undefined
                                                                ) {
                                                                    const _errs22 =
                                                                        errors;
                                                                    if (
                                                                        typeof data8.layout !==
                                                                        'string'
                                                                    ) {
                                                                        validate22.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/streaming/layout',
                                                                                    schemaPath:
                                                                                        '#/definitions/breakout_transform_layout/properties/Body/definitions/Streaming/properties/layout/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'string',
                                                                                    },
                                                                                    message:
                                                                                        'must be string',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid2 =
                                                                        _errs22 ===
                                                                        errors;
                                                                } else {
                                                                    var valid2 =
                                                                        true;
                                                                }
                                                                if (valid2) {
                                                                    if (
                                                                        data8.waiting_screen_enabled !==
                                                                        undefined
                                                                    ) {
                                                                        const _errs24 =
                                                                            errors;
                                                                        if (
                                                                            typeof data8.waiting_screen_enabled !==
                                                                            'boolean'
                                                                        ) {
                                                                            validate22.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/streaming/waiting_screen_enabled',
                                                                                        schemaPath:
                                                                                            '#/definitions/breakout_transform_layout/properties/Body/definitions/Streaming/properties/waiting_screen_enabled/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'boolean',
                                                                                        },
                                                                                        message:
                                                                                            'must be boolean',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                        var valid2 =
                                                                            _errs24 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid2 =
                                                                            true;
                                                                    }
                                                                    if (
                                                                        valid2
                                                                    ) {
                                                                        if (
                                                                            data8.plus_n_pip_enabled !==
                                                                            undefined
                                                                        ) {
                                                                            const _errs26 =
                                                                                errors;
                                                                            if (
                                                                                typeof data8.plus_n_pip_enabled !==
                                                                                'boolean'
                                                                            ) {
                                                                                validate22.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/streaming/plus_n_pip_enabled',
                                                                                            schemaPath:
                                                                                                '#/definitions/breakout_transform_layout/properties/Body/definitions/Streaming/properties/plus_n_pip_enabled/type',
                                                                                            keyword:
                                                                                                'type',
                                                                                            params: {
                                                                                                type: 'boolean',
                                                                                            },
                                                                                            message:
                                                                                                'must be boolean',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                            var valid2 =
                                                                                _errs26 ===
                                                                                errors;
                                                                        } else {
                                                                            var valid2 =
                                                                                true;
                                                                        }
                                                                        if (
                                                                            valid2
                                                                        ) {
                                                                            if (
                                                                                data8.indicators_enabled !==
                                                                                undefined
                                                                            ) {
                                                                                const _errs28 =
                                                                                    errors;
                                                                                if (
                                                                                    typeof data8.indicators_enabled !==
                                                                                    'boolean'
                                                                                ) {
                                                                                    validate22.errors =
                                                                                        [
                                                                                            {
                                                                                                instancePath:
                                                                                                    instancePath +
                                                                                                    '/streaming/indicators_enabled',
                                                                                                schemaPath:
                                                                                                    '#/definitions/breakout_transform_layout/properties/Body/definitions/Streaming/properties/indicators_enabled/type',
                                                                                                keyword:
                                                                                                    'type',
                                                                                                params: {
                                                                                                    type: 'boolean',
                                                                                                },
                                                                                                message:
                                                                                                    'must be boolean',
                                                                                            },
                                                                                        ];
                                                                                    return false;
                                                                                }
                                                                                var valid2 =
                                                                                    _errs28 ===
                                                                                    errors;
                                                                            } else {
                                                                                var valid2 =
                                                                                    true;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        } else {
                                                            validate22.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/streaming',
                                                                        schemaPath:
                                                                            '#/definitions/breakout_transform_layout/properties/Body/definitions/Streaming/type',
                                                                        keyword:
                                                                            'type',
                                                                        params: {
                                                                            type: 'object',
                                                                        },
                                                                        message:
                                                                            'must be object',
                                                                    },
                                                                ];
                                                            return false;
                                                        }
                                                    }
                                                    var valid0 =
                                                        _errs18 === errors;
                                                } else {
                                                    var valid0 = true;
                                                }
                                                if (valid0) {
                                                    if (
                                                        data.free_form_overlay_text !==
                                                        undefined
                                                    ) {
                                                        let data13 =
                                                            data.free_form_overlay_text;
                                                        const _errs30 = errors;
                                                        if (
                                                            errors === _errs30
                                                        ) {
                                                            if (
                                                                Array.isArray(
                                                                    data13,
                                                                )
                                                            ) {
                                                                var valid3 =
                                                                    true;
                                                                const len0 =
                                                                    data13.length;
                                                                for (
                                                                    let i0 = 0;
                                                                    i0 < len0;
                                                                    i0++
                                                                ) {
                                                                    const _errs32 =
                                                                        errors;
                                                                    if (
                                                                        typeof data13[
                                                                            i0
                                                                        ] !==
                                                                        'string'
                                                                    ) {
                                                                        validate22.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/free_form_overlay_text/' +
                                                                                        i0,
                                                                                    schemaPath:
                                                                                        '#/properties/free_form_overlay_text/items/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'string',
                                                                                    },
                                                                                    message:
                                                                                        'must be string',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid3 =
                                                                        _errs32 ===
                                                                        errors;
                                                                    if (
                                                                        !valid3
                                                                    ) {
                                                                        break;
                                                                    }
                                                                }
                                                            } else {
                                                                validate22.errors =
                                                                    [
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/free_form_overlay_text',
                                                                            schemaPath:
                                                                                '#/properties/free_form_overlay_text/type',
                                                                            keyword:
                                                                                'type',
                                                                            params: {
                                                                                type: 'array',
                                                                            },
                                                                            message:
                                                                                'must be array',
                                                                        },
                                                                    ];
                                                                return false;
                                                            }
                                                        }
                                                        var valid0 =
                                                            _errs30 === errors;
                                                    } else {
                                                        var valid0 = true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            validate22.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate22.errors = vErrors;
    return errors === 0;
}
function validate21(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (data.transforms === undefined && (missing0 = 'transforms')) {
                validate21.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'transforms')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.transforms !== undefined) {
                        if (
                            !validate22(data.transforms, {
                                instancePath: instancePath + '/transforms',
                                parentData: data,
                                parentDataProperty: 'transforms',
                                rootData,
                            })
                        ) {
                            vErrors =
                                vErrors === null
                                    ? validate22.errors
                                    : vErrors.concat(validate22.errors);
                            errors = vErrors.length;
                        }
                    }
                }
            }
        } else {
            validate21.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate21.errors = vErrors;
    return errors === 0;
}
function validate20(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate21(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate21.errors
                : vErrors.concat(validate21.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate20.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate20.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutTransformLayout200 = validate25;
function validate25(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate25.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate25.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMuteguests200 = validate26;
function validate26(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate26.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate26.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUnmuteguests200 = validate27;
function validate27(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate27.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate27.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutLock200 = validate28;
function validate28(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate28.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate28.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUnlock200 = validate29;
function validate29(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate29.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate29.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMoveParticipantsFromMainBody = validate30;
const schema41 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                breakout_uuid: {
                    type: 'string',
                    description:
                        'The breakout room you send the participants to. If the string is empty, the participants will be sent to the main room.',
                },
                participants: {
                    type: 'array',
                    description:
                        'The participants to move from the main room or breakout room.',
                    items: {type: 'string'},
                },
            },
            required: ['breakout_uuid', 'participants'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema42 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        breakout_uuid: {
            type: 'string',
            description:
                'The breakout room you send the participants to. If the string is empty, the participants will be sent to the main room.',
        },
        participants: {
            type: 'array',
            description:
                'The participants to move from the main room or breakout room.',
            items: {type: 'string'},
        },
    },
    required: ['breakout_uuid', 'participants'],
    title: 'TopLevel',
};
function validate30(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.breakout_uuid === undefined &&
                    (missing0 = 'breakout_uuid')) ||
                (data.participants === undefined && (missing0 = 'participants'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (
                        !(key0 === 'breakout_uuid' || key0 === 'participants')
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.breakout_uuid !== undefined) {
                        const _errs5 = errors;
                        if (typeof data.breakout_uuid !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/breakout_uuid',
                                schemaPath:
                                    '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/properties/breakout_uuid/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.participants !== undefined) {
                            let data1 = data.participants;
                            const _errs7 = errors;
                            if (errors === _errs7) {
                                if (Array.isArray(data1)) {
                                    var valid3 = true;
                                    const len0 = data1.length;
                                    for (let i0 = 0; i0 < len0; i0++) {
                                        const _errs9 = errors;
                                        if (typeof data1[i0] !== 'string') {
                                            const err2 = {
                                                instancePath:
                                                    instancePath +
                                                    '/participants/' +
                                                    i0,
                                                schemaPath:
                                                    '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/properties/participants/items/type',
                                                keyword: 'type',
                                                params: {type: 'string'},
                                                message: 'must be string',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err2];
                                            } else {
                                                vErrors.push(err2);
                                            }
                                            errors++;
                                        }
                                        var valid3 = _errs9 === errors;
                                        if (!valid3) {
                                            break;
                                        }
                                    }
                                } else {
                                    const err3 = {
                                        instancePath:
                                            instancePath + '/participants',
                                        schemaPath:
                                            '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/properties/participants/type',
                                        keyword: 'type',
                                        params: {type: 'array'},
                                        message: 'must be array',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err3];
                                    } else {
                                        vErrors.push(err3);
                                    }
                                    errors++;
                                }
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate30.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate30.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMoveParticipantsFromMain200 = validate31;
function validate31(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate31.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate31.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMoveParticipantsFromMain403 = validate32;
const schema45 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '403': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success', 'failed'],
                },
                result: {type: 'string', description: 'Error strings'},
            },
            required: ['status', 'result'],
            title: 'Status403',
        },
    },
    oneOf: [
        {$ref: '#/definitions/breakout_ack/properties/403/definitions/403'},
    ],
};
const schema46 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success', 'failed'],
        },
        result: {type: 'string', description: 'Error strings'},
    },
    required: ['status', 'result'],
    title: 'Status403',
};
function validate32(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate32.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate32.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMoveParticipantsBody = validate33;
function validate33(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.breakout_uuid === undefined &&
                    (missing0 = 'breakout_uuid')) ||
                (data.participants === undefined && (missing0 = 'participants'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (
                        !(key0 === 'breakout_uuid' || key0 === 'participants')
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.breakout_uuid !== undefined) {
                        const _errs5 = errors;
                        if (typeof data.breakout_uuid !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/breakout_uuid',
                                schemaPath:
                                    '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/properties/breakout_uuid/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.participants !== undefined) {
                            let data1 = data.participants;
                            const _errs7 = errors;
                            if (errors === _errs7) {
                                if (Array.isArray(data1)) {
                                    var valid3 = true;
                                    const len0 = data1.length;
                                    for (let i0 = 0; i0 < len0; i0++) {
                                        const _errs9 = errors;
                                        if (typeof data1[i0] !== 'string') {
                                            const err2 = {
                                                instancePath:
                                                    instancePath +
                                                    '/participants/' +
                                                    i0,
                                                schemaPath:
                                                    '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/properties/participants/items/type',
                                                keyword: 'type',
                                                params: {type: 'string'},
                                                message: 'must be string',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err2];
                                            } else {
                                                vErrors.push(err2);
                                            }
                                            errors++;
                                        }
                                        var valid3 = _errs9 === errors;
                                        if (!valid3) {
                                            break;
                                        }
                                    }
                                } else {
                                    const err3 = {
                                        instancePath:
                                            instancePath + '/participants',
                                        schemaPath:
                                            '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/properties/participants/type',
                                        keyword: 'type',
                                        params: {type: 'array'},
                                        message: 'must be array',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err3];
                                    } else {
                                        vErrors.push(err3);
                                    }
                                    errors++;
                                }
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_move_participants/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate33.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate33.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMoveParticipants200 = validate34;
function validate34(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate34.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate34.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMoveParticipants403 = validate35;
function validate35(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate35.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate35.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutCallsWebrtcParticipantBody = validate36;
const schema53 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                call_type: {
                    type: 'string',
                    enum: ['WEBRTC', 'RTMP'],
                    description:
                        '`WEBRTC` for a WebRTC call and `RTMP` for an RTMP call.',
                },
                sdp: {
                    type: 'string',
                    description: 'Contains the SDP of the sender.',
                },
                present: {
                    type: 'string',
                    enum: ['send', 'receive'],
                    description:
                        'Optional field. Contains `send` or `receive` to act as a presentation stream rather than a main audio/video stream.',
                },
                fecc_supported: {
                    type: 'boolean',
                    default: false,
                    description:
                        'Set to true if this participant can be sent FECC messages; false if not.',
                },
                media_type: {
                    type: 'string',
                    enum: ['video', 'video-only', 'audio'],
                    description:
                        'The type of media that this client intends to send.',
                },
            },
            required: ['call_type', 'sdp'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema54 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        call_type: {
            type: 'string',
            enum: ['WEBRTC', 'RTMP'],
            description:
                '`WEBRTC` for a WebRTC call and `RTMP` for an RTMP call.',
        },
        sdp: {type: 'string', description: 'Contains the SDP of the sender.'},
        present: {
            type: 'string',
            enum: ['send', 'receive'],
            description:
                'Optional field. Contains `send` or `receive` to act as a presentation stream rather than a main audio/video stream.',
        },
        fecc_supported: {
            type: 'boolean',
            default: false,
            description:
                'Set to true if this participant can be sent FECC messages; false if not.',
        },
        media_type: {
            type: 'string',
            enum: ['video', 'video-only', 'audio'],
            description: 'The type of media that this client intends to send.',
        },
    },
    required: ['call_type', 'sdp'],
    title: 'TopLevel',
};
function validate36(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.call_type === undefined && (missing0 = 'call_type')) ||
                (data.sdp === undefined && (missing0 = 'sdp'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'call_type' ||
                            key0 === 'sdp' ||
                            key0 === 'present' ||
                            key0 === 'fecc_supported' ||
                            key0 === 'media_type'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.call_type !== undefined) {
                        let data0 = data.call_type;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/call_type',
                                schemaPath:
                                    '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/call_type/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'WEBRTC' || data0 === 'RTMP')) {
                            const err2 = {
                                instancePath: instancePath + '/call_type',
                                schemaPath:
                                    '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/call_type/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema54.properties.call_type.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.sdp !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.sdp !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/sdp',
                                    schemaPath:
                                        '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/sdp/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                        if (valid2) {
                            if (data.present !== undefined) {
                                let data2 = data.present;
                                const _errs9 = errors;
                                if (typeof data2 !== 'string') {
                                    const err4 = {
                                        instancePath: instancePath + '/present',
                                        schemaPath:
                                            '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/present/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err4];
                                    } else {
                                        vErrors.push(err4);
                                    }
                                    errors++;
                                }
                                if (
                                    !(data2 === 'send' || data2 === 'receive')
                                ) {
                                    const err5 = {
                                        instancePath: instancePath + '/present',
                                        schemaPath:
                                            '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/present/enum',
                                        keyword: 'enum',
                                        params: {
                                            allowedValues:
                                                schema54.properties.present
                                                    .enum,
                                        },
                                        message:
                                            'must be equal to one of the allowed values',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err5];
                                    } else {
                                        vErrors.push(err5);
                                    }
                                    errors++;
                                }
                                var valid2 = _errs9 === errors;
                            } else {
                                var valid2 = true;
                            }
                            if (valid2) {
                                if (data.fecc_supported !== undefined) {
                                    const _errs11 = errors;
                                    if (
                                        typeof data.fecc_supported !== 'boolean'
                                    ) {
                                        const err6 = {
                                            instancePath:
                                                instancePath +
                                                '/fecc_supported',
                                            schemaPath:
                                                '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/fecc_supported/type',
                                            keyword: 'type',
                                            params: {type: 'boolean'},
                                            message: 'must be boolean',
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err6];
                                        } else {
                                            vErrors.push(err6);
                                        }
                                        errors++;
                                    }
                                    var valid2 = _errs11 === errors;
                                } else {
                                    var valid2 = true;
                                }
                                if (valid2) {
                                    if (data.media_type !== undefined) {
                                        let data4 = data.media_type;
                                        const _errs13 = errors;
                                        if (typeof data4 !== 'string') {
                                            const err7 = {
                                                instancePath:
                                                    instancePath +
                                                    '/media_type',
                                                schemaPath:
                                                    '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/media_type/type',
                                                keyword: 'type',
                                                params: {type: 'string'},
                                                message: 'must be string',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err7];
                                            } else {
                                                vErrors.push(err7);
                                            }
                                            errors++;
                                        }
                                        if (
                                            !(
                                                data4 === 'video' ||
                                                data4 === 'video-only' ||
                                                data4 === 'audio'
                                            )
                                        ) {
                                            const err8 = {
                                                instancePath:
                                                    instancePath +
                                                    '/media_type',
                                                schemaPath:
                                                    '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/properties/media_type/enum',
                                                keyword: 'enum',
                                                params: {
                                                    allowedValues:
                                                        schema54.properties
                                                            .media_type.enum,
                                                },
                                                message:
                                                    'must be equal to one of the allowed values',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err8];
                                            } else {
                                                vErrors.push(err8);
                                            }
                                            errors++;
                                        }
                                        var valid2 = _errs13 === errors;
                                    } else {
                                        var valid2 = true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            const err9 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_calls_webrtc_participant/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err9];
            } else {
                vErrors.push(err9);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err10 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err10];
        } else {
            vErrors.push(err10);
        }
        errors++;
        validate36.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate36.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutCallsWebrtcParticipant200 = validate37;
const schema55 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    $ref: '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Result',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Result: {
            type: 'object',
            additionalProperties: false,
            properties: {
                call_uuid: {
                    type: 'string',
                    description: '`call_uuid` is used to control the call',
                },
                sdp: {type: 'string', description: 'SDP answer from the mcu'},
                offer_ignored: {
                    type: 'boolean',
                    description:
                        'When the call is direct-media and the offer is ignored',
                },
                turn: {
                    type: 'array',
                    items: {
                        $ref: '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Turn',
                    },
                },
            },
            required: ['call_uuid', 'sdp'],
            title: 'Result',
        },
        Turn: {
            type: 'object',
            additionalProperties: false,
            properties: {
                urls: {type: 'array', items: {type: 'string'}},
                username: {type: 'string'},
                credential: {type: 'string'},
            },
            required: ['urls', 'username', 'credential'],
            title: 'Turn',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/200',
        },
    ],
};
const schema56 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            $ref: '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Result',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema57 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        call_uuid: {
            type: 'string',
            description: '`call_uuid` is used to control the call',
        },
        sdp: {type: 'string', description: 'SDP answer from the mcu'},
        offer_ignored: {
            type: 'boolean',
            description:
                'When the call is direct-media and the offer is ignored',
        },
        turn: {
            type: 'array',
            items: {
                $ref: '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Turn',
            },
        },
    },
    required: ['call_uuid', 'sdp'],
    title: 'Result',
};
const schema58 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        urls: {type: 'array', items: {type: 'string'}},
        username: {type: 'string'},
        credential: {type: 'string'},
    },
    required: ['urls', 'username', 'credential'],
    title: 'Turn',
};
function validate39(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.call_uuid === undefined && (missing0 = 'call_uuid')) ||
                (data.sdp === undefined && (missing0 = 'sdp'))
            ) {
                validate39.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'call_uuid' ||
                            key0 === 'sdp' ||
                            key0 === 'offer_ignored' ||
                            key0 === 'turn'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.call_uuid !== undefined) {
                        const _errs2 = errors;
                        if (typeof data.call_uuid !== 'string') {
                            validate39.errors = [
                                {
                                    instancePath: instancePath + '/call_uuid',
                                    schemaPath: '#/properties/call_uuid/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.sdp !== undefined) {
                            const _errs4 = errors;
                            if (typeof data.sdp !== 'string') {
                                validate39.errors = [
                                    {
                                        instancePath: instancePath + '/sdp',
                                        schemaPath: '#/properties/sdp/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    },
                                ];
                                return false;
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.offer_ignored !== undefined) {
                                const _errs6 = errors;
                                if (typeof data.offer_ignored !== 'boolean') {
                                    validate39.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/offer_ignored',
                                            schemaPath:
                                                '#/properties/offer_ignored/type',
                                            keyword: 'type',
                                            params: {type: 'boolean'},
                                            message: 'must be boolean',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs6 === errors;
                            } else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.turn !== undefined) {
                                    let data3 = data.turn;
                                    const _errs8 = errors;
                                    if (errors === _errs8) {
                                        if (Array.isArray(data3)) {
                                            var valid1 = true;
                                            const len0 = data3.length;
                                            for (let i0 = 0; i0 < len0; i0++) {
                                                let data4 = data3[i0];
                                                const _errs10 = errors;
                                                const _errs11 = errors;
                                                if (errors === _errs11) {
                                                    if (
                                                        data4 &&
                                                        typeof data4 ==
                                                            'object' &&
                                                        !Array.isArray(data4)
                                                    ) {
                                                        let missing1;
                                                        if (
                                                            (data4.urls ===
                                                                undefined &&
                                                                (missing1 =
                                                                    'urls')) ||
                                                            (data4.username ===
                                                                undefined &&
                                                                (missing1 =
                                                                    'username')) ||
                                                            (data4.credential ===
                                                                undefined &&
                                                                (missing1 =
                                                                    'credential'))
                                                        ) {
                                                            validate39.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/turn/' +
                                                                            i0,
                                                                        schemaPath:
                                                                            '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Turn/required',
                                                                        keyword:
                                                                            'required',
                                                                        params: {
                                                                            missingProperty:
                                                                                missing1,
                                                                        },
                                                                        message:
                                                                            "must have required property '" +
                                                                            missing1 +
                                                                            "'",
                                                                    },
                                                                ];
                                                            return false;
                                                        } else {
                                                            const _errs13 =
                                                                errors;
                                                            for (const key1 in data4) {
                                                                if (
                                                                    !(
                                                                        key1 ===
                                                                            'urls' ||
                                                                        key1 ===
                                                                            'username' ||
                                                                        key1 ===
                                                                            'credential'
                                                                    )
                                                                ) {
                                                                    delete data4[
                                                                        key1
                                                                    ];
                                                                }
                                                            }
                                                            if (
                                                                _errs13 ===
                                                                errors
                                                            ) {
                                                                if (
                                                                    data4.urls !==
                                                                    undefined
                                                                ) {
                                                                    let data5 =
                                                                        data4.urls;
                                                                    const _errs14 =
                                                                        errors;
                                                                    if (
                                                                        errors ===
                                                                        _errs14
                                                                    ) {
                                                                        if (
                                                                            Array.isArray(
                                                                                data5,
                                                                            )
                                                                        ) {
                                                                            var valid4 =
                                                                                true;
                                                                            const len1 =
                                                                                data5.length;
                                                                            for (
                                                                                let i1 = 0;
                                                                                i1 <
                                                                                len1;
                                                                                i1++
                                                                            ) {
                                                                                const _errs16 =
                                                                                    errors;
                                                                                if (
                                                                                    typeof data5[
                                                                                        i1
                                                                                    ] !==
                                                                                    'string'
                                                                                ) {
                                                                                    validate39.errors =
                                                                                        [
                                                                                            {
                                                                                                instancePath:
                                                                                                    instancePath +
                                                                                                    '/turn/' +
                                                                                                    i0 +
                                                                                                    '/urls/' +
                                                                                                    i1,
                                                                                                schemaPath:
                                                                                                    '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Turn/properties/urls/items/type',
                                                                                                keyword:
                                                                                                    'type',
                                                                                                params: {
                                                                                                    type: 'string',
                                                                                                },
                                                                                                message:
                                                                                                    'must be string',
                                                                                            },
                                                                                        ];
                                                                                    return false;
                                                                                }
                                                                                var valid4 =
                                                                                    _errs16 ===
                                                                                    errors;
                                                                                if (
                                                                                    !valid4
                                                                                ) {
                                                                                    break;
                                                                                }
                                                                            }
                                                                        } else {
                                                                            validate39.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/turn/' +
                                                                                            i0 +
                                                                                            '/urls',
                                                                                        schemaPath:
                                                                                            '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Turn/properties/urls/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'array',
                                                                                        },
                                                                                        message:
                                                                                            'must be array',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                    }
                                                                    var valid3 =
                                                                        _errs14 ===
                                                                        errors;
                                                                } else {
                                                                    var valid3 =
                                                                        true;
                                                                }
                                                                if (valid3) {
                                                                    if (
                                                                        data4.username !==
                                                                        undefined
                                                                    ) {
                                                                        const _errs18 =
                                                                            errors;
                                                                        if (
                                                                            typeof data4.username !==
                                                                            'string'
                                                                        ) {
                                                                            validate39.errors =
                                                                                [
                                                                                    {
                                                                                        instancePath:
                                                                                            instancePath +
                                                                                            '/turn/' +
                                                                                            i0 +
                                                                                            '/username',
                                                                                        schemaPath:
                                                                                            '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Turn/properties/username/type',
                                                                                        keyword:
                                                                                            'type',
                                                                                        params: {
                                                                                            type: 'string',
                                                                                        },
                                                                                        message:
                                                                                            'must be string',
                                                                                    },
                                                                                ];
                                                                            return false;
                                                                        }
                                                                        var valid3 =
                                                                            _errs18 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid3 =
                                                                            true;
                                                                    }
                                                                    if (
                                                                        valid3
                                                                    ) {
                                                                        if (
                                                                            data4.credential !==
                                                                            undefined
                                                                        ) {
                                                                            const _errs20 =
                                                                                errors;
                                                                            if (
                                                                                typeof data4.credential !==
                                                                                'string'
                                                                            ) {
                                                                                validate39.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/turn/' +
                                                                                                i0 +
                                                                                                '/credential',
                                                                                            schemaPath:
                                                                                                '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Turn/properties/credential/type',
                                                                                            keyword:
                                                                                                'type',
                                                                                            params: {
                                                                                                type: 'string',
                                                                                            },
                                                                                            message:
                                                                                                'must be string',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                            var valid3 =
                                                                                _errs20 ===
                                                                                errors;
                                                                        } else {
                                                                            var valid3 =
                                                                                true;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    } else {
                                                        validate39.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/turn/' +
                                                                    i0,
                                                                schemaPath:
                                                                    '#/definitions/breakout_calls_webrtc_participant/properties/200/definitions/Turn/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'object',
                                                                },
                                                                message:
                                                                    'must be object',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                }
                                                var valid1 = _errs10 === errors;
                                                if (!valid1) {
                                                    break;
                                                }
                                            }
                                        } else {
                                            validate39.errors = [
                                                {
                                                    instancePath:
                                                        instancePath + '/turn',
                                                    schemaPath:
                                                        '#/properties/turn/type',
                                                    keyword: 'type',
                                                    params: {type: 'array'},
                                                    message: 'must be array',
                                                },
                                            ];
                                            return false;
                                        }
                                    }
                                    var valid0 = _errs8 === errors;
                                } else {
                                    var valid0 = true;
                                }
                            }
                        }
                    }
                }
            }
        } else {
            validate39.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate39.errors = vErrors;
    return errors === 0;
}
function validate38(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate38.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate38.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success')) {
                            validate38.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema56.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            const _errs4 = errors;
                            if (
                                !validate39(data.result, {
                                    instancePath: instancePath + '/result',
                                    parentData: data,
                                    parentDataProperty: 'result',
                                    rootData,
                                })
                            ) {
                                vErrors =
                                    vErrors === null
                                        ? validate39.errors
                                        : vErrors.concat(validate39.errors);
                                errors = vErrors.length;
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate38.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate38.errors = vErrors;
    return errors === 0;
}
function validate37(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate38(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate38.errors
                : vErrors.concat(validate38.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate37.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate37.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutCallsWebrtcParticipant400 = validate42;
const schema59 = {type: 'string'};
function validate42(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate42.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate42.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutCallsWebrtcParticipant403 = validate43;
function validate43(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate43.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate43.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutCallsWebrtcParticipant500 = validate44;
const schema62 = {type: 'string'};
function validate44(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate44.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate44.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutCallsWebrtcParticipant504 = validate45;
const schema63 = {type: 'string'};
function validate45(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate45.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate45.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMuteParticipant200 = validate46;
function validate46(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate46.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate46.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMuteParticipant403 = validate47;
function validate47(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate47.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate47.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUnmuteParticipant200 = validate48;
function validate48(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate48.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate48.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUnmuteParticipant403 = validate49;
function validate49(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate49.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate49.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutVideoMuteParticipant200 = validate50;
function validate50(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate50.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate50.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutVideoMuteParticipant403 = validate51;
function validate51(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate51.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate51.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutVideoUnmuteParticipant200 = validate52;
function validate52(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate52.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate52.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutVideoUnmuteParticipant403 = validate53;
function validate53(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate53.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate53.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutOverlaytextParticipantBody = validate54;
const schema80 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                text: {
                    type: 'string',
                    description:
                        'Text to use as the participant name overlay text.',
                },
            },
            required: ['text'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_overlaytext_participant/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema81 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        text: {
            type: 'string',
            description: 'Text to use as the participant name overlay text.',
        },
    },
    required: ['text'],
    title: 'TopLevel',
};
function validate54(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (data.text === undefined && (missing0 = 'text')) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_overlaytext_participant/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'text')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.text !== undefined) {
                        if (typeof data.text !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/text',
                                schemaPath:
                                    '#/definitions/breakout_overlaytext_participant/properties/Body/definitions/TopLevel/properties/text/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                    }
                }
            }
        } else {
            const err2 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_overlaytext_participant/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err2];
            } else {
                vErrors.push(err2);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err3 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err3];
        } else {
            vErrors.push(err3);
        }
        errors++;
        validate54.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate54.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutOverlaytextParticipant200 = validate55;
function validate55(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate55.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate55.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutOverlaytextParticipant403 = validate56;
function validate56(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate56.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate56.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutTakeFloor200 = validate57;
const schema86 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {type: 'null'},
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_take_floor/properties/200/definitions/200',
        },
    ],
};
const schema87 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {type: 'null'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate57(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_take_floor/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_take_floor/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_take_floor/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema87.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_take_floor/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_take_floor/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate57.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate57.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutTakeFloor403 = validate58;
function validate58(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate58.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate58.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutReleaseFloor200 = validate59;
const schema90 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {type: 'null'},
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_release_floor/properties/200/definitions/200',
        },
    ],
};
const schema91 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {type: 'null'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate59(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_release_floor/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_release_floor/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_release_floor/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema91.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_release_floor/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_release_floor/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate59.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate59.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutReleaseFloor403 = validate60;
function validate60(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate60.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate60.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutAckBody = validate61;
const schema94 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                sdp: {
                    type: 'string',
                    description: 'Session Description Protocol',
                },
                offer_ignored: {type: 'boolean'},
            },
            required: [],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_ack/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema95 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        sdp: {type: 'string', description: 'Session Description Protocol'},
        offer_ignored: {type: 'boolean'},
    },
    required: [],
    title: 'TopLevel',
};
function validate61(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            const _errs4 = errors;
            for (const key0 in data) {
                if (!(key0 === 'sdp' || key0 === 'offer_ignored')) {
                    delete data[key0];
                }
            }
            if (_errs4 === errors) {
                if (data.sdp !== undefined) {
                    const _errs5 = errors;
                    if (typeof data.sdp !== 'string') {
                        const err0 = {
                            instancePath: instancePath + '/sdp',
                            schemaPath:
                                '#/definitions/breakout_ack/properties/Body/definitions/TopLevel/properties/sdp/type',
                            keyword: 'type',
                            params: {type: 'string'},
                            message: 'must be string',
                        };
                        if (vErrors === null) {
                            vErrors = [err0];
                        } else {
                            vErrors.push(err0);
                        }
                        errors++;
                    }
                    var valid2 = _errs5 === errors;
                } else {
                    var valid2 = true;
                }
                if (valid2) {
                    if (data.offer_ignored !== undefined) {
                        const _errs7 = errors;
                        if (typeof data.offer_ignored !== 'boolean') {
                            const err1 = {
                                instancePath: instancePath + '/offer_ignored',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/Body/definitions/TopLevel/properties/offer_ignored/type',
                                keyword: 'type',
                                params: {type: 'boolean'},
                                message: 'must be boolean',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        var valid2 = _errs7 === errors;
                    } else {
                        var valid2 = true;
                    }
                }
            }
        } else {
            const err2 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err2];
            } else {
                vErrors.push(err2);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err3 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err3];
        } else {
            vErrors.push(err3);
        }
        errors++;
        validate61.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate61.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutAck200 = validate62;
function validate62(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate62.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate62.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutAck403 = validate63;
function validate63(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate63.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate63.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutDisconnectCall200 = validate64;
function validate64(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakouts_disconnect/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakouts_disconnect/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakouts_disconnect/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema22.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakouts_disconnect/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakouts_disconnect/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate64.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate64.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutDisconnectCall403 = validate65;
function validate65(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate65.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate65.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutDisconnectCall502 = validate66;
const schema104 = {type: 'string'};
function validate66(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate66.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate66.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutDisconnectCall503 = validate67;
const schema105 = {type: 'string'};
function validate67(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate67.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate67.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutNewCandidateBody = validate68;
const schema106 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                candidate: {
                    type: 'string',
                    description:
                        'Representation of address in candidate-attribute format as per RFC5245.',
                },
                mid: {
                    type: 'string',
                    description: 'The media stream identifier tag.',
                },
                ufrag: {
                    type: 'string',
                    description:
                        'The randomly generated username fragment of the ICE credentials.',
                },
                pwd: {
                    type: 'string',
                    description:
                        'The randomly generated password of the ICE credentials.',
                },
            },
            required: ['candidate', 'mid'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_new_candidate/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema107 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        candidate: {
            type: 'string',
            description:
                'Representation of address in candidate-attribute format as per RFC5245.',
        },
        mid: {type: 'string', description: 'The media stream identifier tag.'},
        ufrag: {
            type: 'string',
            description:
                'The randomly generated username fragment of the ICE credentials.',
        },
        pwd: {
            type: 'string',
            description:
                'The randomly generated password of the ICE credentials.',
        },
    },
    required: ['candidate', 'mid'],
    title: 'TopLevel',
};
function validate68(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.candidate === undefined && (missing0 = 'candidate')) ||
                (data.mid === undefined && (missing0 = 'mid'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_new_candidate/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'candidate' ||
                            key0 === 'mid' ||
                            key0 === 'ufrag' ||
                            key0 === 'pwd'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.candidate !== undefined) {
                        const _errs5 = errors;
                        if (typeof data.candidate !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/candidate',
                                schemaPath:
                                    '#/definitions/breakout_new_candidate/properties/Body/definitions/TopLevel/properties/candidate/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.mid !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.mid !== 'string') {
                                const err2 = {
                                    instancePath: instancePath + '/mid',
                                    schemaPath:
                                        '#/definitions/breakout_new_candidate/properties/Body/definitions/TopLevel/properties/mid/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err2];
                                } else {
                                    vErrors.push(err2);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                        if (valid2) {
                            if (data.ufrag !== undefined) {
                                const _errs9 = errors;
                                if (typeof data.ufrag !== 'string') {
                                    const err3 = {
                                        instancePath: instancePath + '/ufrag',
                                        schemaPath:
                                            '#/definitions/breakout_new_candidate/properties/Body/definitions/TopLevel/properties/ufrag/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err3];
                                    } else {
                                        vErrors.push(err3);
                                    }
                                    errors++;
                                }
                                var valid2 = _errs9 === errors;
                            } else {
                                var valid2 = true;
                            }
                            if (valid2) {
                                if (data.pwd !== undefined) {
                                    const _errs11 = errors;
                                    if (typeof data.pwd !== 'string') {
                                        const err4 = {
                                            instancePath: instancePath + '/pwd',
                                            schemaPath:
                                                '#/definitions/breakout_new_candidate/properties/Body/definitions/TopLevel/properties/pwd/type',
                                            keyword: 'type',
                                            params: {type: 'string'},
                                            message: 'must be string',
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err4];
                                        } else {
                                            vErrors.push(err4);
                                        }
                                        errors++;
                                    }
                                    var valid2 = _errs11 === errors;
                                } else {
                                    var valid2 = true;
                                }
                            }
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_new_candidate/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate68.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate68.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutNewCandidate200 = validate69;
const schema108 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {type: 'null'},
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_new_candidate/properties/200/definitions/200',
        },
    ],
};
const schema109 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {type: 'null'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate69(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_new_candidate/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_new_candidate/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_new_candidate/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema109.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_new_candidate/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_new_candidate/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate69.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate69.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutNewCandidate403 = validate70;
function validate70(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate70.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate70.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutNewCandidate502 = validate71;
const schema112 = {type: 'string'};
function validate71(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate71.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate71.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUpdateBody = validate72;
const schema113 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                sdp: {type: 'string', description: 'The new SDP.'},
                fecc_supported: {
                    type: 'boolean',
                    default: false,
                    description:
                        'Set to true if this participant can be sent FECC messages; false if not.',
                },
            },
            required: [],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_update/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema114 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        sdp: {type: 'string', description: 'The new SDP.'},
        fecc_supported: {
            type: 'boolean',
            default: false,
            description:
                'Set to true if this participant can be sent FECC messages; false if not.',
        },
    },
    required: [],
    title: 'TopLevel',
};
function validate72(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            const _errs4 = errors;
            for (const key0 in data) {
                if (!(key0 === 'sdp' || key0 === 'fecc_supported')) {
                    delete data[key0];
                }
            }
            if (_errs4 === errors) {
                if (data.sdp !== undefined) {
                    const _errs5 = errors;
                    if (typeof data.sdp !== 'string') {
                        const err0 = {
                            instancePath: instancePath + '/sdp',
                            schemaPath:
                                '#/definitions/breakout_update/properties/Body/definitions/TopLevel/properties/sdp/type',
                            keyword: 'type',
                            params: {type: 'string'},
                            message: 'must be string',
                        };
                        if (vErrors === null) {
                            vErrors = [err0];
                        } else {
                            vErrors.push(err0);
                        }
                        errors++;
                    }
                    var valid2 = _errs5 === errors;
                } else {
                    var valid2 = true;
                }
                if (valid2) {
                    if (data.fecc_supported !== undefined) {
                        const _errs7 = errors;
                        if (typeof data.fecc_supported !== 'boolean') {
                            const err1 = {
                                instancePath: instancePath + '/fecc_supported',
                                schemaPath:
                                    '#/definitions/breakout_update/properties/Body/definitions/TopLevel/properties/fecc_supported/type',
                                keyword: 'type',
                                params: {type: 'boolean'},
                                message: 'must be boolean',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        var valid2 = _errs7 === errors;
                    } else {
                        var valid2 = true;
                    }
                }
            }
        } else {
            const err2 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_update/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err2];
            } else {
                vErrors.push(err2);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err3 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err3];
        } else {
            vErrors.push(err3);
        }
        errors++;
        validate72.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate72.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUpdate200 = validate73;
const schema115 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success', 'failed'],
                },
                result: {
                    oneOf: [
                        {
                            type: 'string',
                            description: 'SDP answer from the mcu',
                        },
                        {
                            $ref: '#/definitions/breakout_update/properties/200/definitions/Result',
                        },
                    ],
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Result: {
            type: 'object',
            additionalProperties: false,
            properties: {
                call_uuid: {type: 'string'},
                sdp: {type: 'string', description: 'SDP answer from the mcu'},
            },
            required: ['call_uuid', 'sdp'],
            title: 'Result',
        },
    },
    oneOf: [
        {$ref: '#/definitions/breakout_update/properties/200/definitions/200'},
    ],
};
const schema116 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success', 'failed'],
        },
        result: {
            oneOf: [
                {type: 'string', description: 'SDP answer from the mcu'},
                {
                    $ref: '#/definitions/breakout_update/properties/200/definitions/Result',
                },
            ],
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema117 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        call_uuid: {type: 'string'},
        sdp: {type: 'string', description: 'SDP answer from the mcu'},
    },
    required: ['call_uuid', 'sdp'],
    title: 'Result',
};
function validate74(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate74.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate74.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            validate74.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema116.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            const _errs5 = errors;
                            let valid1 = false;
                            let passing0 = null;
                            const _errs6 = errors;
                            if (typeof data1 !== 'string') {
                                const err0 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/properties/result/oneOf/0/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err0];
                                } else {
                                    vErrors.push(err0);
                                }
                                errors++;
                            }
                            var _valid0 = _errs6 === errors;
                            if (_valid0) {
                                valid1 = true;
                                passing0 = 0;
                            }
                            const _errs8 = errors;
                            const _errs9 = errors;
                            if (errors === _errs9) {
                                if (
                                    data1 &&
                                    typeof data1 == 'object' &&
                                    !Array.isArray(data1)
                                ) {
                                    let missing1;
                                    if (
                                        (data1.call_uuid === undefined &&
                                            (missing1 = 'call_uuid')) ||
                                        (data1.sdp === undefined &&
                                            (missing1 = 'sdp'))
                                    ) {
                                        const err1 = {
                                            instancePath:
                                                instancePath + '/result',
                                            schemaPath:
                                                '#/definitions/breakout_update/properties/200/definitions/Result/required',
                                            keyword: 'required',
                                            params: {missingProperty: missing1},
                                            message:
                                                "must have required property '" +
                                                missing1 +
                                                "'",
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err1];
                                        } else {
                                            vErrors.push(err1);
                                        }
                                        errors++;
                                    } else {
                                        const _errs11 = errors;
                                        for (const key1 in data1) {
                                            if (
                                                !(
                                                    key1 === 'call_uuid' ||
                                                    key1 === 'sdp'
                                                )
                                            ) {
                                                delete data1[key1];
                                            }
                                        }
                                        if (_errs11 === errors) {
                                            if (data1.call_uuid !== undefined) {
                                                const _errs12 = errors;
                                                if (
                                                    typeof data1.call_uuid !==
                                                    'string'
                                                ) {
                                                    const err2 = {
                                                        instancePath:
                                                            instancePath +
                                                            '/result/call_uuid',
                                                        schemaPath:
                                                            '#/definitions/breakout_update/properties/200/definitions/Result/properties/call_uuid/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'string',
                                                        },
                                                        message:
                                                            'must be string',
                                                    };
                                                    if (vErrors === null) {
                                                        vErrors = [err2];
                                                    } else {
                                                        vErrors.push(err2);
                                                    }
                                                    errors++;
                                                }
                                                var valid3 = _errs12 === errors;
                                            } else {
                                                var valid3 = true;
                                            }
                                            if (valid3) {
                                                if (data1.sdp !== undefined) {
                                                    const _errs14 = errors;
                                                    if (
                                                        typeof data1.sdp !==
                                                        'string'
                                                    ) {
                                                        const err3 = {
                                                            instancePath:
                                                                instancePath +
                                                                '/result/sdp',
                                                            schemaPath:
                                                                '#/definitions/breakout_update/properties/200/definitions/Result/properties/sdp/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'string',
                                                            },
                                                            message:
                                                                'must be string',
                                                        };
                                                        if (vErrors === null) {
                                                            vErrors = [err3];
                                                        } else {
                                                            vErrors.push(err3);
                                                        }
                                                        errors++;
                                                    }
                                                    var valid3 =
                                                        _errs14 === errors;
                                                } else {
                                                    var valid3 = true;
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    const err4 = {
                                        instancePath: instancePath + '/result',
                                        schemaPath:
                                            '#/definitions/breakout_update/properties/200/definitions/Result/type',
                                        keyword: 'type',
                                        params: {type: 'object'},
                                        message: 'must be object',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err4];
                                    } else {
                                        vErrors.push(err4);
                                    }
                                    errors++;
                                }
                            }
                            var _valid0 = _errs8 === errors;
                            if (_valid0 && valid1) {
                                valid1 = false;
                                passing0 = [passing0, 1];
                            } else {
                                if (_valid0) {
                                    valid1 = true;
                                    passing0 = 1;
                                }
                            }
                            if (!valid1) {
                                const err5 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath: '#/properties/result/oneOf',
                                    keyword: 'oneOf',
                                    params: {passingSchemas: passing0},
                                    message:
                                        'must match exactly one schema in oneOf',
                                };
                                if (vErrors === null) {
                                    vErrors = [err5];
                                } else {
                                    vErrors.push(err5);
                                }
                                errors++;
                                validate74.errors = vErrors;
                                return false;
                            } else {
                                errors = _errs5;
                                if (vErrors !== null) {
                                    if (_errs5) {
                                        vErrors.length = _errs5;
                                    } else {
                                        vErrors = null;
                                    }
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate74.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate74.errors = vErrors;
    return errors === 0;
}
function validate73(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate74(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate74.errors
                : vErrors.concat(validate74.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate73.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate73.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUpdate403 = validate76;
const schema118 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '403': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success', 'failed'],
                },
                result: {
                    oneOf: [
                        {type: 'null'},
                        {
                            $ref: '#/definitions/breakout_ack/properties/403/definitions/403/properties/result',
                        },
                    ],
                },
            },
            required: ['status', 'result'],
            title: 'Status403',
        },
    },
    oneOf: [
        {$ref: '#/definitions/breakout_update/properties/403/definitions/403'},
    ],
};
const schema119 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success', 'failed'],
        },
        result: {
            oneOf: [
                {type: 'null'},
                {
                    $ref: '#/definitions/breakout_ack/properties/403/definitions/403/properties/result',
                },
            ],
        },
    },
    required: ['status', 'result'],
    title: 'Status403',
};
const schema120 = {type: 'string', description: 'Error strings'};
function validate77(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate77.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate77.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            validate77.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema119.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            const _errs5 = errors;
                            let valid1 = false;
                            let passing0 = null;
                            const _errs6 = errors;
                            if (data1 !== null) {
                                const err0 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/properties/result/oneOf/0/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err0];
                                } else {
                                    vErrors.push(err0);
                                }
                                errors++;
                            }
                            var _valid0 = _errs6 === errors;
                            if (_valid0) {
                                valid1 = true;
                                passing0 = 0;
                            }
                            const _errs8 = errors;
                            if (typeof data1 !== 'string') {
                                const err1 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err1];
                                } else {
                                    vErrors.push(err1);
                                }
                                errors++;
                            }
                            var _valid0 = _errs8 === errors;
                            if (_valid0 && valid1) {
                                valid1 = false;
                                passing0 = [passing0, 1];
                            } else {
                                if (_valid0) {
                                    valid1 = true;
                                    passing0 = 1;
                                }
                            }
                            if (!valid1) {
                                const err2 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath: '#/properties/result/oneOf',
                                    keyword: 'oneOf',
                                    params: {passingSchemas: passing0},
                                    message:
                                        'must match exactly one schema in oneOf',
                                };
                                if (vErrors === null) {
                                    vErrors = [err2];
                                } else {
                                    vErrors.push(err2);
                                }
                                errors++;
                                validate77.errors = vErrors;
                                return false;
                            } else {
                                errors = _errs5;
                                if (vErrors !== null) {
                                    if (_errs5) {
                                        vErrors.length = _errs5;
                                    } else {
                                        vErrors = null;
                                    }
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate77.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate77.errors = vErrors;
    return errors === 0;
}
function validate76(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate77(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate77.errors
                : vErrors.concat(validate77.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate76.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate76.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUpdate502 = validate79;
const schema121 = {type: 'string'};
function validate79(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate79.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate79.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUpdate503 = validate80;
const schema122 = {type: 'string'};
function validate80(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate80.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate80.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUpdate520 = validate81;
const schema123 = {type: 'string'};
function validate81(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (typeof data !== 'string') {
        validate81.errors = [
            {
                instancePath,
                schemaPath: '#/type',
                keyword: 'type',
                params: {type: 'string'},
                message: 'must be string',
            },
        ];
        return false;
    }
    validate81.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutShowLiveCaptions200 = validate82;
const schema124 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    type: 'null',
                    description: 'The results flag is not set.',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_show_live_captions/properties/200/definitions/200',
        },
    ],
};
const schema125 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {type: 'null', description: 'The results flag is not set.'},
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate82(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_show_live_captions/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_show_live_captions/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_show_live_captions/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema125.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_show_live_captions/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_show_live_captions/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate82.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate82.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutShowLiveCaptions403 = validate83;
function validate83(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate83.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate83.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutHideLiveCaptions200 = validate84;
function validate84(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_show_live_captions/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_show_live_captions/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_show_live_captions/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema125.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (data.result !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_show_live_captions/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_show_live_captions/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate84.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate84.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutHideLiveCaptions403 = validate85;
function validate85(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate85.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate85.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMessageConferenceBody = validate86;
function validate86(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.type === undefined && (missing0 = 'type')) ||
                (data.payload === undefined && (missing0 = 'payload'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'type' || key0 === 'payload')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.type !== undefined) {
                        let data0 = data.type;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/type',
                                schemaPath:
                                    '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/properties/type/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (
                            !(
                                data0 === 'text/plain' ||
                                data0 === 'application/json'
                            )
                        ) {
                            const err2 = {
                                instancePath: instancePath + '/type',
                                schemaPath:
                                    '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/properties/type/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema18.properties.type.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.payload !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.payload !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/payload',
                                    schemaPath:
                                        '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/properties/payload/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakouts_message/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate86.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate86.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutMessageConference200 = validate87;
function validate87(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate87.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate87.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUnlockParticipant200 = validate88;
function validate88(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate88.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate88.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutUnlockParticipant403 = validate89;
function validate89(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate89.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate89.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutDisconnectParticipant200 = validate90;
const schema140 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    oneOf: [
                        {type: 'null'},
                        {
                            type: 'boolean',
                            description:
                                'The result is true if successful, false otherwise.',
                        },
                    ],
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_disconnect_participant/properties/200/definitions/200',
        },
    ],
};
const schema141 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            oneOf: [
                {type: 'null'},
                {
                    type: 'boolean',
                    description:
                        'The result is true if successful, false otherwise.',
                },
            ],
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate90(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_disconnect_participant/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_disconnect_participant/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_disconnect_participant/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema141.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs7 = errors;
                            const _errs8 = errors;
                            let valid3 = false;
                            let passing1 = null;
                            const _errs9 = errors;
                            if (data1 !== null) {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_disconnect_participant/properties/200/definitions/200/properties/result/oneOf/0/type',
                                    keyword: 'type',
                                    params: {type: 'null'},
                                    message: 'must be null',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var _valid1 = _errs9 === errors;
                            if (_valid1) {
                                valid3 = true;
                                passing1 = 0;
                            }
                            const _errs11 = errors;
                            if (typeof data1 !== 'boolean') {
                                const err4 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_disconnect_participant/properties/200/definitions/200/properties/result/oneOf/1/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err4];
                                } else {
                                    vErrors.push(err4);
                                }
                                errors++;
                            }
                            var _valid1 = _errs11 === errors;
                            if (_valid1 && valid3) {
                                valid3 = false;
                                passing1 = [passing1, 1];
                            } else {
                                if (_valid1) {
                                    valid3 = true;
                                    passing1 = 1;
                                }
                            }
                            if (!valid3) {
                                const err5 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_disconnect_participant/properties/200/definitions/200/properties/result/oneOf',
                                    keyword: 'oneOf',
                                    params: {passingSchemas: passing1},
                                    message:
                                        'must match exactly one schema in oneOf',
                                };
                                if (vErrors === null) {
                                    vErrors = [err5];
                                } else {
                                    vErrors.push(err5);
                                }
                                errors++;
                            } else {
                                errors = _errs8;
                                if (vErrors !== null) {
                                    if (_errs8) {
                                        vErrors.length = _errs8;
                                    } else {
                                        vErrors = null;
                                    }
                                }
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err6 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_disconnect_participant/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err6];
            } else {
                vErrors.push(err6);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err7 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err7];
        } else {
            vErrors.push(err7);
        }
        errors++;
        validate90.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate90.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutDisconnectParticipant403 = validate91;
function validate91(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate91.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate91.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutBuzzParticipant200 = validate92;
function validate92(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate92.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate92.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutBuzzParticipant403 = validate93;
function validate93(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate93.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate93.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutClearbuzzParticipant200 = validate94;
function validate94(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate94.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate94.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutClearbuzzParticipant403 = validate95;
function validate95(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate95.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate95.errors = vErrors;
    return errors === 0;
}
export const validateGuestLeaveBreakout200 = validate96;
function validate96(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate96.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate96.errors = vErrors;
    return errors === 0;
}
export const validateHostBreakoutBuzz200 = validate97;
function validate97(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate97.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate97.errors = vErrors;
    return errors === 0;
}
export const validateHostClearBreakoutBuzz200 = validate98;
function validate98(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate98.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate98.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutTransferParticipantBody = validate99;
const schema158 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                role: {
                    type: 'string',
                    enum: ['host', 'guest'],
                    description:
                        '`host` = Host participant; `guest` = Guest participant',
                },
                conference_alias: {
                    type: 'string',
                    description: 'Target conference alias.',
                },
                pin: {
                    type: 'string',
                    description:
                        'PIN code for the specified role at the specified conference, if required.',
                },
            },
            required: ['role', 'conference_alias'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_transfer_participant/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema159 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        role: {
            type: 'string',
            enum: ['host', 'guest'],
            description:
                '`host` = Host participant; `guest` = Guest participant',
        },
        conference_alias: {
            type: 'string',
            description: 'Target conference alias.',
        },
        pin: {
            type: 'string',
            description:
                'PIN code for the specified role at the specified conference, if required.',
        },
    },
    required: ['role', 'conference_alias'],
    title: 'TopLevel',
};
function validate99(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.role === undefined && (missing0 = 'role')) ||
                (data.conference_alias === undefined &&
                    (missing0 = 'conference_alias'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_transfer_participant/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'role' ||
                            key0 === 'conference_alias' ||
                            key0 === 'pin'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.role !== undefined) {
                        let data0 = data.role;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/role',
                                schemaPath:
                                    '#/definitions/breakout_transfer_participant/properties/Body/definitions/TopLevel/properties/role/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'host' || data0 === 'guest')) {
                            const err2 = {
                                instancePath: instancePath + '/role',
                                schemaPath:
                                    '#/definitions/breakout_transfer_participant/properties/Body/definitions/TopLevel/properties/role/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema159.properties.role.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.conference_alias !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.conference_alias !== 'string') {
                                const err3 = {
                                    instancePath:
                                        instancePath + '/conference_alias',
                                    schemaPath:
                                        '#/definitions/breakout_transfer_participant/properties/Body/definitions/TopLevel/properties/conference_alias/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                        if (valid2) {
                            if (data.pin !== undefined) {
                                const _errs9 = errors;
                                if (typeof data.pin !== 'string') {
                                    const err4 = {
                                        instancePath: instancePath + '/pin',
                                        schemaPath:
                                            '#/definitions/breakout_transfer_participant/properties/Body/definitions/TopLevel/properties/pin/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err4];
                                    } else {
                                        vErrors.push(err4);
                                    }
                                    errors++;
                                }
                                var valid2 = _errs9 === errors;
                            } else {
                                var valid2 = true;
                            }
                        }
                    }
                }
            }
        } else {
            const err5 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_transfer_participant/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err5];
            } else {
                vErrors.push(err5);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err6 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err6];
        } else {
            vErrors.push(err6);
        }
        errors++;
        validate99.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate99.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutTransferParticipant200 = validate100;
function validate100(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate100.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate100.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutTransferParticipant403 = validate101;
function validate101(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate101.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate101.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutFeccParticipantBody = validate102;
const schema164 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                action: {
                    type: 'string',
                    enum: ['start', 'stop', 'continue'],
                    description: 'Either `start`, `stop`, or `continue`.',
                },
                target: {
                    type: 'string',
                    description:
                        'UUID of the target participant (from the participant list). Leave undefined for a gateway call.',
                },
                movement: {
                    type: 'array',
                    items: {
                        $ref: '#/definitions/breakout_fecc_participant/properties/Body/definitions/Movement',
                    },
                },
                timeout: {
                    type: 'number',
                    description:
                        'The duration for which to send the signal. Recommended values are 1000 (1 second) for initial `start` message; 200 for `continue` messages.',
                },
            },
            required: ['action', 'target', 'movement'],
            title: 'TopLevel',
        },
        Movement: {
            type: 'object',
            additionalProperties: false,
            properties: {
                axis: {
                    type: 'string',
                    enum: ['pan', 'tilt', 'zoom'],
                    description: 'Either `pan`, `tilt`, or `zoom`.',
                },
                direction: {
                    type: 'string',
                    enum: ['left', 'right', 'up', 'down', 'in', 'out'],
                    description:
                        'Use `left`, `right` for pan; `up`, `down` for tilt; or `in`, `out` for zoom.',
                },
            },
            required: ['axis', 'direction'],
            title: 'Endpoint',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_fecc_participant/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema165 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        action: {
            type: 'string',
            enum: ['start', 'stop', 'continue'],
            description: 'Either `start`, `stop`, or `continue`.',
        },
        target: {
            type: 'string',
            description:
                'UUID of the target participant (from the participant list). Leave undefined for a gateway call.',
        },
        movement: {
            type: 'array',
            items: {
                $ref: '#/definitions/breakout_fecc_participant/properties/Body/definitions/Movement',
            },
        },
        timeout: {
            type: 'number',
            description:
                'The duration for which to send the signal. Recommended values are 1000 (1 second) for initial `start` message; 200 for `continue` messages.',
        },
    },
    required: ['action', 'target', 'movement'],
    title: 'TopLevel',
};
const schema166 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        axis: {
            type: 'string',
            enum: ['pan', 'tilt', 'zoom'],
            description: 'Either `pan`, `tilt`, or `zoom`.',
        },
        direction: {
            type: 'string',
            enum: ['left', 'right', 'up', 'down', 'in', 'out'],
            description:
                'Use `left`, `right` for pan; `up`, `down` for tilt; or `in`, `out` for zoom.',
        },
    },
    required: ['axis', 'direction'],
    title: 'Endpoint',
};
function validate103(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.action === undefined && (missing0 = 'action')) ||
                (data.target === undefined && (missing0 = 'target')) ||
                (data.movement === undefined && (missing0 = 'movement'))
            ) {
                validate103.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'action' ||
                            key0 === 'target' ||
                            key0 === 'movement' ||
                            key0 === 'timeout'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.action !== undefined) {
                        let data0 = data.action;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate103.errors = [
                                {
                                    instancePath: instancePath + '/action',
                                    schemaPath: '#/properties/action/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (
                            !(
                                data0 === 'start' ||
                                data0 === 'stop' ||
                                data0 === 'continue'
                            )
                        ) {
                            validate103.errors = [
                                {
                                    instancePath: instancePath + '/action',
                                    schemaPath: '#/properties/action/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema165.properties.action.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.target !== undefined) {
                            const _errs4 = errors;
                            if (typeof data.target !== 'string') {
                                validate103.errors = [
                                    {
                                        instancePath: instancePath + '/target',
                                        schemaPath: '#/properties/target/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    },
                                ];
                                return false;
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.movement !== undefined) {
                                let data2 = data.movement;
                                const _errs6 = errors;
                                if (errors === _errs6) {
                                    if (Array.isArray(data2)) {
                                        var valid1 = true;
                                        const len0 = data2.length;
                                        for (let i0 = 0; i0 < len0; i0++) {
                                            let data3 = data2[i0];
                                            const _errs8 = errors;
                                            const _errs9 = errors;
                                            if (errors === _errs9) {
                                                if (
                                                    data3 &&
                                                    typeof data3 == 'object' &&
                                                    !Array.isArray(data3)
                                                ) {
                                                    let missing1;
                                                    if (
                                                        (data3.axis ===
                                                            undefined &&
                                                            (missing1 =
                                                                'axis')) ||
                                                        (data3.direction ===
                                                            undefined &&
                                                            (missing1 =
                                                                'direction'))
                                                    ) {
                                                        validate103.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/movement/' +
                                                                    i0,
                                                                schemaPath:
                                                                    '#/definitions/breakout_fecc_participant/properties/Body/definitions/Movement/required',
                                                                keyword:
                                                                    'required',
                                                                params: {
                                                                    missingProperty:
                                                                        missing1,
                                                                },
                                                                message:
                                                                    "must have required property '" +
                                                                    missing1 +
                                                                    "'",
                                                            },
                                                        ];
                                                        return false;
                                                    } else {
                                                        const _errs11 = errors;
                                                        for (const key1 in data3) {
                                                            if (
                                                                !(
                                                                    key1 ===
                                                                        'axis' ||
                                                                    key1 ===
                                                                        'direction'
                                                                )
                                                            ) {
                                                                delete data3[
                                                                    key1
                                                                ];
                                                            }
                                                        }
                                                        if (
                                                            _errs11 === errors
                                                        ) {
                                                            if (
                                                                data3.axis !==
                                                                undefined
                                                            ) {
                                                                let data4 =
                                                                    data3.axis;
                                                                const _errs12 =
                                                                    errors;
                                                                if (
                                                                    typeof data4 !==
                                                                    'string'
                                                                ) {
                                                                    validate103.errors =
                                                                        [
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/movement/' +
                                                                                    i0 +
                                                                                    '/axis',
                                                                                schemaPath:
                                                                                    '#/definitions/breakout_fecc_participant/properties/Body/definitions/Movement/properties/axis/type',
                                                                                keyword:
                                                                                    'type',
                                                                                params: {
                                                                                    type: 'string',
                                                                                },
                                                                                message:
                                                                                    'must be string',
                                                                            },
                                                                        ];
                                                                    return false;
                                                                }
                                                                if (
                                                                    !(
                                                                        data4 ===
                                                                            'pan' ||
                                                                        data4 ===
                                                                            'tilt' ||
                                                                        data4 ===
                                                                            'zoom'
                                                                    )
                                                                ) {
                                                                    validate103.errors =
                                                                        [
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/movement/' +
                                                                                    i0 +
                                                                                    '/axis',
                                                                                schemaPath:
                                                                                    '#/definitions/breakout_fecc_participant/properties/Body/definitions/Movement/properties/axis/enum',
                                                                                keyword:
                                                                                    'enum',
                                                                                params: {
                                                                                    allowedValues:
                                                                                        schema166
                                                                                            .properties
                                                                                            .axis
                                                                                            .enum,
                                                                                },
                                                                                message:
                                                                                    'must be equal to one of the allowed values',
                                                                            },
                                                                        ];
                                                                    return false;
                                                                }
                                                                var valid3 =
                                                                    _errs12 ===
                                                                    errors;
                                                            } else {
                                                                var valid3 =
                                                                    true;
                                                            }
                                                            if (valid3) {
                                                                if (
                                                                    data3.direction !==
                                                                    undefined
                                                                ) {
                                                                    let data5 =
                                                                        data3.direction;
                                                                    const _errs14 =
                                                                        errors;
                                                                    if (
                                                                        typeof data5 !==
                                                                        'string'
                                                                    ) {
                                                                        validate103.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/movement/' +
                                                                                        i0 +
                                                                                        '/direction',
                                                                                    schemaPath:
                                                                                        '#/definitions/breakout_fecc_participant/properties/Body/definitions/Movement/properties/direction/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'string',
                                                                                    },
                                                                                    message:
                                                                                        'must be string',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    if (
                                                                        !(
                                                                            data5 ===
                                                                                'left' ||
                                                                            data5 ===
                                                                                'right' ||
                                                                            data5 ===
                                                                                'up' ||
                                                                            data5 ===
                                                                                'down' ||
                                                                            data5 ===
                                                                                'in' ||
                                                                            data5 ===
                                                                                'out'
                                                                        )
                                                                    ) {
                                                                        validate103.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/movement/' +
                                                                                        i0 +
                                                                                        '/direction',
                                                                                    schemaPath:
                                                                                        '#/definitions/breakout_fecc_participant/properties/Body/definitions/Movement/properties/direction/enum',
                                                                                    keyword:
                                                                                        'enum',
                                                                                    params: {
                                                                                        allowedValues:
                                                                                            schema166
                                                                                                .properties
                                                                                                .direction
                                                                                                .enum,
                                                                                    },
                                                                                    message:
                                                                                        'must be equal to one of the allowed values',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid3 =
                                                                        _errs14 ===
                                                                        errors;
                                                                } else {
                                                                    var valid3 =
                                                                        true;
                                                                }
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    validate103.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/movement/' +
                                                                i0,
                                                            schemaPath:
                                                                '#/definitions/breakout_fecc_participant/properties/Body/definitions/Movement/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'object',
                                                            },
                                                            message:
                                                                'must be object',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                            }
                                            var valid1 = _errs8 === errors;
                                            if (!valid1) {
                                                break;
                                            }
                                        }
                                    } else {
                                        validate103.errors = [
                                            {
                                                instancePath:
                                                    instancePath + '/movement',
                                                schemaPath:
                                                    '#/properties/movement/type',
                                                keyword: 'type',
                                                params: {type: 'array'},
                                                message: 'must be array',
                                            },
                                        ];
                                        return false;
                                    }
                                }
                                var valid0 = _errs6 === errors;
                            } else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.timeout !== undefined) {
                                    const _errs16 = errors;
                                    if (!(typeof data.timeout == 'number')) {
                                        validate103.errors = [
                                            {
                                                instancePath:
                                                    instancePath + '/timeout',
                                                schemaPath:
                                                    '#/properties/timeout/type',
                                                keyword: 'type',
                                                params: {type: 'number'},
                                                message: 'must be number',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid0 = _errs16 === errors;
                                } else {
                                    var valid0 = true;
                                }
                            }
                        }
                    }
                }
            }
        } else {
            validate103.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate103.errors = vErrors;
    return errors === 0;
}
function validate102(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate103(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate103.errors
                : vErrors.concat(validate103.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate102.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate102.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutFeccParticipant200 = validate105;
function validate105(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate105.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate105.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutFeccParticipant403 = validate106;
function validate106(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate106.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate106.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutSpotlightonParticipant200 = validate107;
function validate107(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate107.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate107.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutSpotlightonParticipant403 = validate108;
function validate108(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate108.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate108.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutSpotlightoffParticipant200 = validate109;
function validate109(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate109.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate109.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutSpotlightoffParticipant403 = validate110;
function validate110(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate110.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate110.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutDtmfParticipantBody = validate111;
const schema179 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                digits: {
                    type: 'string',
                    description: 'The DTMF digits to send.',
                },
            },
            required: ['digits'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/breakout_dtmf_participant/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema180 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        digits: {type: 'string', description: 'The DTMF digits to send.'},
    },
    required: ['digits'],
    title: 'TopLevel',
};
function validate111(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (data.digits === undefined && (missing0 = 'digits')) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_dtmf_participant/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'digits')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.digits !== undefined) {
                        if (typeof data.digits !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/digits',
                                schemaPath:
                                    '#/definitions/breakout_dtmf_participant/properties/Body/definitions/TopLevel/properties/digits/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                    }
                }
            }
        } else {
            const err2 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_dtmf_participant/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err2];
            } else {
                vErrors.push(err2);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err3 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err3];
        } else {
            vErrors.push(err3);
        }
        errors++;
        validate111.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate111.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutDtmfParticipant200 = validate112;
function validate112(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema20.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate112.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate112.errors = vErrors;
    return errors === 0;
}
export const validateBreakoutDtmfParticipant403 = validate113;
function validate113(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/breakout_ack/properties/403/definitions/403/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/breakout_ack/properties/403/definitions/403/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema46.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/breakout_ack/properties/403/definitions/403/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/breakout_ack/properties/403/definitions/403/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate113.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate113.errors = vErrors;
    return errors === 0;
}
