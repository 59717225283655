import React from 'react';
import cx from 'classnames';

import {sizeToSpacing} from '../../../utils/helpers';
import type {ExtendedSizeModifier} from '../../../types/sizes';

import styles from './Group.module.scss';

export const Group: React.FC<
    React.ComponentProps<'div'> & {
        spacing?: ExtendedSizeModifier;
        modifier?: 'fullWidth';
        justifyContent?: 'spaceBetween' | 'center' | 'normal';
    }
> = ({
    children,
    className,
    modifier,
    spacing = 'medium',
    justifyContent = 'normal',
    ...props
}) => (
    <div
        className={cx(
            styles.group,
            styles[sizeToSpacing(spacing)],
            styles[justifyContent],
            modifier && styles[modifier],
            className,
        )}
        {...props}
    >
        {children}
    </div>
);

export type GroupProps = React.ComponentProps<typeof Group>;
