import React from 'react';
import cx from 'classnames';

import {Row} from '@pexip/components';

import {YouCanSayTitle} from './YouCanSayTitle.view';

export const RecordingAudio: React.FC<
    React.PropsWithChildren<{
        isRecordSuccess?: boolean;
    }>
> = ({children, isRecordSuccess}) => (
    <div>
        {!isRecordSuccess && <YouCanSayTitle className="mt-2 mb-4" />}
        <Row className={cx('mt-2', isRecordSuccess && 'mt-10')}>{children}</Row>
    </div>
);
