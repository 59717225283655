import React from 'react';
import cx from 'classnames';

import {TextHeading} from '../../elements/TextHeading/TextHeading';
import {FontVariant} from '../../../../design-tokens/constants';
import {List} from '../List/List';
import {Row} from '../List/Row';
import {Text} from '../../elements/Text/Text';
import {TextLink} from '../../elements/TextLink/TextLink';
import {CardLayout} from '../../foundation/CardLayout/CardLayout';

import styles from './ActivityCard.module.scss';

export type StatusItem = {label: string; content: string};
export type ActivityCardAction = {label: string; action?: () => void};

export const ActivityCard: React.FC<{
    actionButton: ActivityCardAction;
    disabled?: boolean;
    eventsItems: ActivityCardAction[];
    eventsTitle: string;
    statusItems: StatusItem[];
    title: string;
    titleEnhancerStart?: React.ReactElement;
}> = ({
    actionButton,
    disabled = false,
    eventsItems,
    eventsTitle,
    statusItems,
    title,
    titleEnhancerStart,
}) => {
    return (
        <CardLayout
            title={title}
            titleEnhancerStart={titleEnhancerStart}
            secondaryComponent={
                <div
                    className={cx(
                        styles.secondaryWrapper,
                        disabled && styles.disabled,
                        'p-4',
                    )}
                >
                    {statusItems.map(item => (
                        <div
                            className={styles.statusWrapper}
                            key={`${item.label}${item.content}`}
                        >
                            <Text
                                variant="inherit"
                                fontVariant={FontVariant.Body}
                                className={styles.statusItem}
                            >
                                {item.label}
                            </Text>
                            <Text
                                variant="inherit"
                                fontVariant={FontVariant.BodyBold}
                                className={styles.statusItem}
                            >
                                {item.content}
                            </Text>
                        </div>
                    ))}
                </div>
            }
        >
            <div
                className={cx(
                    styles.contentWrapper,
                    disabled && styles.disabled,
                    'p-4',
                )}
            >
                <TextHeading
                    className="mb-2"
                    htmlTag="h3"
                    variant="inherit"
                    fontVariant={FontVariant.H5}
                >
                    {eventsTitle}
                </TextHeading>

                <List spacing="none" className={styles.eventsList}>
                    {eventsItems.map(item => (
                        <Row key={item.label}>
                            <TextLink
                                onClick={item.action}
                                isUnderlined={!!item.action}
                                className={styles.eventItem}
                            >
                                {item.label}
                            </TextLink>
                        </Row>
                    ))}
                </List>

                <TextLink
                    variant="inherit"
                    fontVariant={FontVariant.BodyBold}
                    isDisabled
                    onClick={actionButton.action}
                >
                    {actionButton.label}
                </TextLink>
            </div>
        </CardLayout>
    );
};
