import React from 'react';

import {useMobileDevice} from '@pexip/components';

import {COMPACT_ICON_SIZE, SMALL_ICON_SIZE} from '../../constants';
import type {GroupedToolbarBtn, ToolbarBtn} from '../../types';

export const CustomIcon: React.FC<{button: GroupedToolbarBtn | ToolbarBtn}> = ({
    button,
}) => {
    const isMobileDevice = useMobileDevice();

    return typeof button.icon !== 'string' ? (
        <img
            className="mr-2"
            id={button.id}
            width={isMobileDevice ? SMALL_ICON_SIZE : COMPACT_ICON_SIZE}
            height={isMobileDevice ? SMALL_ICON_SIZE : COMPACT_ICON_SIZE}
            src={`data:image/svg+xml;utf8,${
                button.isActive
                    ? button.icon.custom.hover
                    : button.icon.custom.main
            }`}
            alt={button.tooltip}
        />
    ) : null;
};
