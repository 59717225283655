import {isDefined} from './isDefined';

/**
 * Inspired by lodash `nth` this will select the given index from an array, but throw if the value is undefined.
 * As with the lodash version of nth it will select from the end of the array given a negative index.
 * This is likely to be most useful in tests. You might want nthOr, firstOr, or lastOr instead
 */
export function nthThrows<T>(list: T[], index: number) {
    return isDefined(list[index >= 0 ? index : list.length + index]);
}

/**
 * Inspired by lodash `nth` this will select the given index from an array.
 * As with the lodash version of nth it will select from the end of the array given a negative index.
 *
 * @returns T - if list[index] === undefined, it returns defaultValue
 */
export function nthOr<T>(list: T[], index: number, defaultValue: T) {
    try {
        return nthThrows(list, index);
    } catch {
        return defaultValue;
    }
}

/**
 * Selects the first element in a list
 *
 * @returns T - if list[index] === undefined, it returns defaultValue
 */
export function firstOr<T>(list: T[], defaultValue: T) {
    return nthOr(list, 0, defaultValue);
}

/**
 * Selects the last element in a list
 *
 * @returns T - if list[index] === undefined, it returns defaultValue
 */
export function lastOr<T>(list: T[], defaultValue: T) {
    return nthOr(list, -1, defaultValue);
}
