import React from 'react';
import cx from 'classnames';

import {Bar} from '@pexip/components';

import styles from './DesktopMeetingToolbar.module.scss';

export const DesktopMeetingToolbar: React.FC<
    React.ComponentProps<typeof Bar> & {
        core?: React.ReactNode;
        withSidePanel?: boolean;
    }
> = ({core, withSidePanel = false, className, ...props}) => {
    return (
        <Bar
            flexDirection="column"
            className={cx(
                styles.toolbar,
                {
                    [styles.withSidePanel]: withSidePanel,
                },
                className,
            )}
            {...props}
        >
            {core && <div className={styles.core}>{core}</div>}
        </Bar>
    );
};
