import React from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {
    InteractiveElement,
    Text,
    Icon,
    IconTypes,
    Tooltip,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './ChatPanelInputButtons.module.scss';

export const ChatPanelInputSendButton: React.FC<{
    disabled: boolean;
}> = ({disabled}) => {
    const {t} = useTranslation();
    const buttonLabel = t('common.send', 'Send');
    const sendButton = (
        <InteractiveElement
            aria-label={buttonLabel}
            className={cx('pl-3', 'pr-3', styles.sendButton)}
            disabled={disabled}
            data-testid={TestId.ButtonChatSend}
            type="submit"
        >
            <Icon
                colorScheme="dark"
                source={IconTypes.IconSend}
                size="compact"
            />
        </InteractiveElement>
    );

    return disabled ? (
        sendButton
    ) : (
        <Tooltip
            text={<Text variant="standard">{buttonLabel}</Text>}
            position="top"
        >
            {sendButton}
        </Tooltip>
    );
};
