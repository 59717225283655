import React from 'react';
import {useTranslation} from 'react-i18next';

import {Button, FontVariant, IconTypes, Row, Text} from '@pexip/components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';

import {DeviceSelect} from '../DeviceSelect/DeviceSelect.view';
import {TestId} from '../../../test/testIds';

import styles from './MicrophoneMonitorModal.module.scss';

export const BeforeTestingYourMic: React.FC<{
    audioInputs: MediaDeviceInfoLike[];
    selectedAudioInput?: MediaDeviceInfoLike;
    onAudioInputChange: (device: MediaDeviceInfoLike) => void;
    startTest: () => void;
}> = ({audioInputs, selectedAudioInput, onAudioInputChange, startTest}) => {
    const {t} = useTranslation();

    return (
        <>
            <Row>
                <Text fontVariant={FontVariant.H6}>
                    {t(
                        'media.first-check-that-you-have-selected-microphone',
                        'First, check that you have selected the correct microphone and that your volume is turned up.',
                    )}
                </Text>
            </Row>
            <Row className="p-0 mt-4">
                <DeviceSelect
                    isDisabled={false}
                    onDeviceChange={onAudioInputChange}
                    mediaDeviceInfoLike={selectedAudioInput}
                    iconType={IconTypes.IconMicrophoneOn}
                    label={t(
                        'settings.device-select.select-microphone',
                        'Select microphone',
                    )}
                    devices={audioInputs}
                    className={styles.audioSelect}
                    sizeModifier="medium"
                    data-testid={TestId.SelectTestAudioInput}
                />
            </Row>
            <Row className="p-0 mt-10 justify-content-center">
                <Button
                    onClick={startTest}
                    size="large"
                    data-testid={TestId.ButtonTestAudioInputStart}
                >
                    {t('media.start-test', 'Start test')}
                </Button>
            </Row>
        </>
    );
};
