export class Backoff {
    private attempts = 0;
    private ms = 100;
    private max = 10_000;
    private factor = 2;
    private jitter = 0; //https://en.m.wikipedia.org/wiki/Thundering_herd_problem

    constructor(opts?: {
        min: number;
        max?: number;
        factor?: number;
        jitter?: number;
    }) {
        if (!opts) {
            return;
        }
        if (opts.min !== undefined) {
            this.ms = opts.min;
        }
        if (opts.max !== undefined) {
            this.max = opts.max;
        }
        if (opts.factor !== undefined) {
            this.factor = opts.factor;
        }
        if (opts.jitter !== undefined && opts.jitter > 0 && opts.jitter <= 1) {
            this.jitter = opts.jitter;
        }

        this.duration = this.duration.bind(this);
        this.reset = this.reset.bind(this);
    }

    duration() {
        let ms = this.ms * Math.pow(this.factor, this.attempts++);
        if (this.jitter) {
            const rand = Math.random();
            const deviation = Math.floor(rand * this.jitter * ms);
            ms =
                (Math.floor(rand * 10) & 1) === 0
                    ? ms - deviation
                    : ms + deviation;
        }
        return Math.min(ms, this.max) | 0;
    }

    promise() {
        return new Promise(res => setTimeout(res, this.duration()));
    }

    reset() {
        this.attempts = 0;
    }
}
