import {Backoff} from '@pexip/utils';

import {MAX_RECONNECT_ATTEMPTS, backoffBaseOptions} from './constants';
import {logger} from './logger';
import type {
    PresentationEvent,
    RTCParticipantEvent,
    MessageEvent,
    ConferenceStateEvent,
    RequestClient,
} from './types';
import {
    captureNoRequestClient,
    createEventSignals,
    normalizeParticipant,
    normalizeConferenceState,
    normalizePresentationEvent,
} from './utils';

interface SSE extends Event {
    data: unknown;
    lastEventId: string;
    origin: string;
}

declare global {
    interface EventSource {
        addEventListener(
            type: string,
            listener: (e: SSE) => void,
            options?: boolean | AddEventListenerOptions,
        ): void;
    }
}

export const eventSignals = createEventSignals([]);

export const EventSrcEvents = [
    /**
     * This marks the start of a presentation, and includes the information on which participant is presenting.
     *
     * Example data:
     * `{"presenter_name": "Bob", "presenter_uri": "bob@example.com", "presenter_uuid": "123e4567-e89b-12d3-a456-426614174000"}` @see ParticipantEvent
     */
    {
        type: 'presentation_start',
        fn: (event: PresentationEvent) => {
            eventSignals.onPresentationStart.emit(
                normalizePresentationEvent(event),
            );
        },
    },
    /**
     * The presentation has finished.
     *
     * Data: none
     */
    {
        type: 'presentation_stop',
        fn: eventSignals.onPresentationStop.emit,
    },
    {
        type: 'presentation_ended',
        fn: eventSignals.onPresentationEnded.emit,
    },
    /**
     * A new presentation frame is available at:
     *
     * `https://<node_address>/api/client/v2/conferences/<conference_alias>/presentation.jpeg`
     *
     * An alternative image at a higher resolution is also available at:
     *
     * `https://<node_address>/api/client/v2/conferences/<conference_alias>/presentation_high.jpeg`
     *
     * Note that these URLs require the token and the event ID of the presentation_frame event to be present as a header or a query parameter in order to download the presentation frame, for example:
     *
     * `https://10.0.0.1/api/client/v2/conferences/meet_alice/presentation.jpeg?id=MTAuNDQuOTkuMl8xOA==&token=b3duZXI9T...etc...2FmGzA%3D`
     *
     * Data: none
     */
    {
        type: 'presentation_frame',
        fn: ({lastEventId}: PresentationEvent) => {
            eventSignals.onPresentationFrame.emit(lastEventId ?? '');
        },
    },
    /**
     * A new participant has joined the conference.
     *
     * Data @see RTCParticipantEvent
     */
    {
        type: 'participant_create',
        fn: (event: RTCParticipantEvent) => {
            eventSignals.onParticipantCreate.emit(normalizeParticipant(event));
        },
    },
    /**
     * A participant's properties have changed.
     *
     * Data: a full JSON object is supplied, as for participant_create. @see RTCParticipantEvent
     */
    {
        type: 'participant_update',
        fn: (event: RTCParticipantEvent) => {
            eventSignals.onParticipantUpdate.emit(normalizeParticipant(event));
        },
    },
    /**
     * A participant has left the conference.
     *
     * Data: the JSON object contains the UUID of the deleted participant, for example:
     * `{"uuid": "65b4af2f-657a-4081-98a8-b17667628ce3"}`
     */
    {
        type: 'participant_delete',
        fn: ({uuid}: {uuid: string}) => {
            eventSignals.onParticipantDelete.emit(uuid);
        },
    },
    /**
     * A chat message has been broadcast to the conference.
     *
     * @see MessageEvent
     */
    {
        type: 'message_received',
        fn: eventSignals.onMessage.emit,
    },
    /**
     * Conference properties have been updated. Currently, the only conference properties available are the lock status of the conference,
     * whether Guests are muted, and if the conference has been started.
     *
     * For example:
     * `{"locked": false, "guests_muted": false, "started": true}` @see ConferenceStateEvent
     */
    {
        type: 'conference_update',
        fn: (event: ConferenceStateEvent) => {
            eventSignals.onConferenceUpdate.emit(
                normalizeConferenceState(event),
            );
        },
    },
    /**
     * An update to the "stage layout" is available. This declares the order of active speakers, and their voice activity.
     *
     * @see StageEvent
     */
    {
        type: 'stage',
        fn: eventSignals.onStageUpdate.emit,
    },
    /**
     * The stage layout has changed.
     *
     * @see LayoutEvent
     */
    {
        type: 'layout',
        fn: eventSignals.onLayoutUpdate.emit,
    },
    /**
     * This is sent when the participant is being disconnected from the Pexip side.
     *
     * Data: the reason parameter contains a reason for this disconnection, if available, e.g.:
     * `{"reason": "API initiated participant disconnect"}`
     */
    {
        type: 'disconnect',
        fn: eventSignals.onDisconnect.emit,
    },
    /**
     * This is sent when a child call has been disconnected
     * (e.g. when a screensharing child call has been closed if presentation has been stolen by another participant).
     *
     * Data: contains both the UUID of the child call being disconnected, and the reason for the disconnection if available, e.g.:
     *
     */
    {
        type: 'call_disconnected',
        fn: eventSignals.onCallDisconnected.emit,
    },
    /**
     * At the start of the EventSource connection, these two messages start and end the sending
     * of the complete participant list in the form of participant_create events.
     * This allows a participant that has been temporarily disconnected to re-sync the participant list.
     */
    {
        type: 'participant_sync_begin',
        fn: eventSignals.onParticipantSyncBegin.emit,
    },
    /**
     * At the start of the EventSource connection, these two messages start and end the sending
     * of the complete participant list in the form of participant_create events.
     * This allows a participant that has been temporarily disconnected to re-sync the participant list.
     */
    {
        type: 'participant_sync_end',
        fn: eventSignals.onParticipantSyncEnd.emit,
    },
    {
        type: 'refer',
        fn: eventSignals.onRefer.emit,
    },
    {
        type: 'on_hold',
        fn: eventSignals.onHold.emit,
    },
    {
        type: 'fecc',
        fn: eventSignals.onFecc.emit,
    },
    {
        type: 'refresh_token',
        fn: eventSignals.onRefreshToken.emit,
    },
    /**
     * Live captions are enabled, and new captions are available
     *
     * @see liveCaptionsEvent
     */
    {
        type: 'live_captions',
        fn: eventSignals.onLiveCaptions.emit,
    },
    {
        type: 'peer_disconnect',
        fn: eventSignals.onPeerDisconnect.emit,
    },
    {
        type: 'new_offer',
        fn: eventSignals.onNewOffer.emit,
    },
    {
        type: 'update_sdp',
        fn: eventSignals.onUpdateSdp.emit,
    },
    {
        type: 'new_candidate',
        fn: eventSignals.onNewCandidate.emit,
    },
    {
        type: 'splash_screen',
        fn: eventSignals.onSplashScreen.emit,
    },
    {
        type: 'breakout_begin',
        fn: eventSignals.onBreakoutBegin.emit,
    },
    {
        type: 'breakout_event',
        fn: eventSignals.onBreakoutEvent.emit,
    },
    {
        type: 'breakout_end',
        fn: eventSignals.onBreakoutEnd.emit,
    },
    {
        type: 'breakout_refer',
        fn: eventSignals.onBreakoutRefer.emit,
    },
] as const;

type InfinityEventsSourceEventsType = (typeof EventSrcEvents)[number];
type FnParams = Parameters<InfinityEventsSourceEventsType['fn']>;
type FnArg = FnParams[0];
type FnReturn = ReturnType<InfinityEventsSourceEventsType['fn']>;
type Fn = (arg: FnArg) => FnReturn;
interface EventSourceEventType {
    readonly type: InfinityEventsSourceEventsType['type'];
    readonly fn: Fn;
}

export function createEventSource({
    host,
    conferenceAlias,
    token,
}: {
    host: string;
    conferenceAlias: string;
    token: string;
}) {
    const eventSource = new EventSource(
        `${host}/api/client/v2/conferences/${conferenceAlias}/events?token=${token}`,
    );

    const listen = (
        eventType: InfinityEventsSourceEventsType['type'],
        fn: (e: FnArg) => void,
    ) =>
        eventSource?.addEventListener(
            eventType,
            ({data}) => {
                try {
                    if (data && typeof data === 'string') {
                        const eventParsed = JSON.parse(data) as FnArg;
                        logData(eventType, eventParsed);
                        fn(eventParsed);
                    }
                } catch (error: unknown) {
                    logger.error(
                        {error},
                        'Unable to parse EventSource message',
                    );
                }
            },
            false,
        );

    (EventSrcEvents as ReadonlyArray<Readonly<EventSourceEventType>>).forEach(
        event => listen(event.type, event.fn),
    );

    return eventSource;
}

export const logData = (
    eventType: InfinityEventsSourceEventsType['type'],
    data: FnArg,
) => {
    try {
        if (data instanceof Object) {
            if ('display_name' in data && data.display_name) {
                logger.redact(data.display_name);
            }
        }

        logger.debug(getLoggableEventSubset(data), `${eventType} received`);
    } catch (error) {
        logger.debug(error, 'Unable to redact values');
    }
};

export const getLoggableEventSubset = (data: FnArg) => {
    if (isChat(data)) {
        return {uuid: data.uuid};
    }

    return data;
};

export const isChat = (data: FnArg): data is MessageEvent =>
    data instanceof Object && 'payload' in data && 'uuid' in data;

export const createEventSourceManager = (requestClient: RequestClient) => {
    let eventSource: EventSource | undefined;
    let reconnectTimmer: number;
    let reconnectAttempt = 0;

    const _connect = (
        host: string,
        conferenceAlias: string,
        backoff = new Backoff(backoffBaseOptions),
    ) => {
        if (!requestClient) {
            captureNoRequestClient();
            return;
        }

        clearTimeout(reconnectTimmer);
        eventSource?.close();

        eventSource = createEventSource({
            conferenceAlias,
            host,
            token: requestClient.token,
        });
        eventSource.onopen = () => {
            logger.debug('Event Source opened');
            reconnectAttempt = 0;
            eventSignals.onConnected.emit();
            backoff.reset();
        };
        eventSource.onerror = error => {
            if (!eventSource) {
                // the call was disconnected cleanly
                return;
            }
            eventSource.close();
            if (reconnectAttempt < MAX_RECONNECT_ATTEMPTS) {
                logger.debug({error}, 'EventSource error, Trying to reconnect');
                reconnectAttempt += 1;
                reconnectTimmer = window.setTimeout(() => {
                    if (requestClient?.token) {
                        _connect(host, conferenceAlias, backoff);
                    }
                }, backoff.duration());
            } else {
                eventSignals.onError.emit();
            }
        };
    };

    return {
        get eventSource() {
            return eventSource;
        },
        connect: async (host: string, conferenceAlias: string) => {
            return new Promise((resolve, reject) => {
                eventSignals.onConnected.addOnce(resolve);
                eventSignals.onError.addOnce(reject);
                _connect(host, conferenceAlias);
            });
        },
        close: () => {
            eventSource?.close();
            clearTimeout(reconnectTimmer);
            eventSource = undefined;
        },
    };
};
