import {isDefined, prig} from '@pexip/utils';

import type {BreakoutParticipants, ParticipantList, RoomTuple} from '../types';
import {MainBreakoutRoomId} from '../constants';

/**
 * Shuffles the given map of breakout rooms.
 *
 * @param allParticipants - The breakout rooms to shuffle.
 * @param identity - Optional identity of the breakout host to place in the main room.
 * @param shuffleAssigned - Shuffles assigned participants only
 * @param shuffleId - The seed number
 *
 * @returns BreakoutParticipants - A Map object containing the rooms and their respective participants.
 */
export const shuffleBreakoutParticipants = ({
    allParticipants,
    identity,
    shuffleAssigned = false,
    shuffleId = Date.now() % 60000,
}: {
    allParticipants: BreakoutParticipants;
    identity?: string;
    shuffleAssigned?: boolean;
    shuffleId?: number;
}): BreakoutParticipants => {
    const breakoutRooms: string[] = [];
    const mainParticipants: ParticipantList = [];
    const restParticipants: ParticipantList = [];

    [...allParticipants.entries()].forEach(([roomId, participants]) => {
        const isMainRoom = roomId === MainBreakoutRoomId;
        if (!isMainRoom) {
            breakoutRooms.push(roomId);
        }
        if (!shuffleAssigned && isMainRoom) {
            mainParticipants.push(...participants);
        } else {
            restParticipants.push(...participants);
        }
    });
    const meIdx = restParticipants.findIndex(
        participant => participant.identity === identity,
    );
    if (meIdx > -1) {
        const me = restParticipants[meIdx];
        if (me) {
            mainParticipants.unshift(me);
        }
        restParticipants.splice(meIdx, 1);
    }

    const mainRoom: RoomTuple = ['main', mainParticipants];
    const rooms = Array.from<unknown, RoomTuple>(
        {length: breakoutRooms.length},
        (_, k) => [isDefined(breakoutRooms[k]), []],
    );

    for (let i = 0; i < restParticipants.length; i++) {
        const roomIdx = i % rooms.length;
        const [, participants] = rooms[roomIdx] ?? [];
        if (!participants) {
            break;
        }

        const p = restParticipants[prig(i, shuffleId, restParticipants.length)];
        if (!p) {
            break;
        }

        participants.push(p);
    }
    rooms.unshift(mainRoom);
    return new Map(rooms);
};

export type ShuffleBreakoutParticipants = typeof shuffleBreakoutParticipants;
