import React from 'react';
import cx from 'classnames';

import type {ColorScheme} from '../../../types/variants';
import {ThemeConsumer} from '../../../themes/ThemeContext';
import {ModalCloseButton} from '../../modules/CloseButton/ModalCloseButton.view';
import type {ClickEventHandler} from '../../../types/events';

import styles from './Pill.module.scss';

export const Pill: React.FC<
    React.ComponentProps<'div'> & {
        closeButtonAriaLabel?: string;
        colorScheme?: ColorScheme;
        isDanger?: boolean;
        onClose?: ClickEventHandler;
        testIdCloseButton?: string;
    }
> = ({
    children,
    className,
    closeButtonAriaLabel = 'Close tooltip',
    colorScheme,
    isDanger = false,
    onClose,
    testIdCloseButton,
    ...props
}) => (
    <ThemeConsumer>
        {({colorScheme: defaultColorScheme}) => (
            <div
                className={cx(
                    styles.pill,
                    styles[colorScheme ?? defaultColorScheme],
                    {
                        [styles.danger]: isDanger,
                    },
                    className,
                )}
                {...props}
            >
                {children}
                {onClose && (
                    <ModalCloseButton
                        className={styles.closeButton}
                        onClose={onClose}
                        data-testid={testIdCloseButton}
                        colorScheme={colorScheme}
                        aria-label={closeButtonAriaLabel}
                    />
                )}
            </div>
        )}
    </ThemeConsumer>
);

export type PillProps = React.ComponentProps<typeof Pill>;
