import React, {useMemo} from 'react';
import cx from 'classnames';

import styles from './CakeTimer.module.scss';

export const CakeTimer: React.FC<
    React.ComponentProps<'svg'> & {duration?: number}
> = ({duration: initialDuration = 60, className, ...props}) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we are only interested in initial duration time
    const duration = useMemo(() => initialDuration, []);

    return (
        <svg
            viewBox="0 0 32 32"
            className={cx(styles.svg, className)}
            {...props}
        >
            <circle
                r="16"
                cx="16"
                cy="16"
                className={styles.circle}
                style={{
                    animationDuration: `${duration}s`,
                }}
            />
        </svg>
    );
};

export type CakeTimerProps = React.ComponentProps<typeof CakeTimer>;
