import React, {useEffect, useState} from 'react';
import cx from 'classnames';

import {ThemeConsumer} from '../../../themes/ThemeContext';
import {Pill} from '../../elements/Pill/Pill';
import type {ColorScheme} from '../../../types/variants';
import {Text} from '../../elements/Text/Text';
import {withTransition} from '../TransitionContainer/withTransition';

import styles from './Toast.module.scss';

export const toastPositionValues = ['topCenter', 'bottomCenter'] as const;
export type ToastPosition = (typeof toastPositionValues)[number];

export interface ToastProps {
    className?: string;
    colorScheme?: ColorScheme;
    enhanceStart?: React.ReactNode;
    isClickable?: boolean;
    isDanger?: boolean;
    isDisplayed: boolean;
    message: React.ReactNode;
    onDismiss?: () => void;
    position?: ToastPosition;
    timeout?: number;
}

const Toast: React.FC<ToastProps> = ({
    className,
    colorScheme,
    enhanceStart,
    isClickable = false,
    isDisplayed = false,
    message,
    onDismiss,
    position = 'topCenter',
    timeout = 5000,
    ...props
}) => {
    const [shouldDisplay, setShouldDisplay] = useState(isDisplayed);

    useEffect(() => setShouldDisplay(isDisplayed), [isDisplayed]);

    useEffect(() => {
        let timerId: number;

        if (shouldDisplay && timeout > 0) {
            timerId = window.setTimeout(() => {
                setShouldDisplay(false);
                onDismiss?.();
            }, timeout);
        }

        return () => clearTimeout(timerId);
    }, [shouldDisplay, timeout, onDismiss]);

    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <div
                    className={cx(
                        styles.toast,
                        styles[position],
                        {
                            [styles.hidden]: !shouldDisplay,
                        },
                        className,
                    )}
                    role="status"
                >
                    <Pill
                        className={cx(
                            'd-flex align-items-center text-center pl-4 pr-4 ml-3 mr-3',
                            {
                                [styles.clickable]: isClickable || onDismiss,
                            },
                        )}
                        onClose={
                            onDismiss
                                ? () => {
                                      setShouldDisplay(false);
                                      onDismiss();
                                  }
                                : undefined
                        }
                        colorScheme={colorScheme ?? defaultColorScheme}
                        {...props}
                    >
                        {enhanceStart}
                        <Text variant="inherit">{message}</Text>
                    </Pill>
                </div>
            )}
        </ThemeConsumer>
    );
};

// type ToastProps = React.ComponentProps<typeof Toast>;

const WrappedToast = withTransition(Toast, 400);

export {WrappedToast as Toast};
