import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

import {
    Box,
    Text,
    Button,
    Icon,
    IconTypes,
    FontVariant,
} from '@pexip/components';

import type {BreakoutAskingForHelpStatus} from '../../types';

import styles from './BreakoutRoomsAskingForHelp.module.scss';

export const BreakoutRoomsAskingForHelp: React.FC<
    React.ComponentProps<typeof Box> & {
        breakoutsAskingForHelp: BreakoutAskingForHelpStatus[];
        onJoinRoom: (roomId: string) => void;
    }
> = ({breakoutsAskingForHelp, onJoinRoom, ...props}) => {
    const {t} = useTranslation();

    const [showingRooms, setShowingRooms] = useState(true);

    const ToggleBtn = () => (
        <Button
            variant="text"
            size="compact"
            enhancerStart={
                <Icon
                    className="mr-1"
                    source={
                        showingRooms
                            ? IconTypes.IconChevronUp
                            : IconTypes.IconChevronDown
                    }
                    size="mini"
                />
            }
            onClick={() =>
                setShowingRooms(currentlyShowing => !currentlyShowing)
            }
            underlineText={false}
        >
            <div className="d-flex align-items-center">
                <Text fontVariant={FontVariant.H5}>Asking for help</Text>
                <Text className="ml-2">{breakoutsAskingForHelp.length}</Text>
            </div>
        </Button>
    );

    if (breakoutsAskingForHelp.length === 0) {
        return null;
    }

    return (
        <Box padding="small" {...props} className={cx(styles.wrapper)}>
            <ToggleBtn />
            {showingRooms && (
                <div className="mt-4">
                    {breakoutsAskingForHelp.map(({breakoutName, roomId}) => (
                        <div
                            key={roomId}
                            className={cx('mt-2', styles.roomRow)}
                        >
                            <div className={styles.room}>
                                <Text
                                    fontVariant={FontVariant.Body}
                                    isTruncated
                                    title={breakoutName}
                                    translate="no"
                                >
                                    {breakoutName}
                                </Text>
                            </div>
                            <Button
                                size="small"
                                className="mr-2"
                                variant="success"
                                aria-label={t(
                                    'common.join.aria-label',
                                    'Join breakout room',
                                )}
                                onClick={() => onJoinRoom(roomId)}
                            >
                                {t('common.join', 'Join')}
                            </Button>
                        </div>
                    ))}
                </div>
            )}
        </Box>
    );
};

export type BreakoutRoomsAskingForHelpProps = React.ComponentProps<
    typeof BreakoutRoomsAskingForHelp
>;
