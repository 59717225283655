import {useEffect, useState} from 'react';

import type {Signal} from '@pexip/signal';

export type RemoveParticipantSignal = {
    isOpen: boolean;
    userName?: string;
    description?: string;
    onConfirm?: () => void;
};

const defaultState = {
    isOpen: false,
    userName: 'this user',
    description: '',
    onConfirm: undefined,
};

export const useRemoveParticipant = (
    signal: Signal<RemoveParticipantSignal>,
) => {
    const [{isOpen, userName, description, onConfirm}, setModalState] =
        useState<RemoveParticipantSignal>(defaultState);

    useEffect(() => signal.add(setModalState), [signal]);

    const onCancel = () => {
        setModalState(defaultState);
    };

    const handleConfirm = () => {
        onConfirm?.();
        setModalState({
            isOpen: false,
            userName,
            onConfirm,
        });
    };

    return {
        onConfirm: handleConfirm,
        onCancel,
        description,
        userName,
        isOpen,
    };
};
