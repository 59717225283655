export const createStreamTransformer = <I, O>(
    transformer?: Transformer<I, O>,
    writableStrategy?: QueuingStrategy<I>,
    readableStrategy?: QueuingStrategy<O>,
) => {
    const transformStream = new TransformStream(
        transformer,
        writableStrategy,
        readableStrategy,
    );
    return transformStream;
};
