import React from 'react';
import {t} from 'i18next';

import {ThemeProvider} from '@pexip/components';
import {SettingsPanel, PanelHeader, QualityList} from '@pexip/media-components';

import {getStreamQuality, setStreamQuality} from '../services/Media.service';
import {useConfig} from '../config';

export const Quality: React.FC<{onBackClick: () => void}> = ({onBackClick}) => {
    const [bandwidth] = useConfig('bandwidth');
    return (
        <SettingsPanel
            headerContent={
                <ThemeProvider colorScheme="light">
                    <PanelHeader
                        title={`${t('settings.quality', 'Quality')}`}
                        onBackClick={onBackClick}
                    />
                </ThemeProvider>
            }
        >
            <ThemeProvider colorScheme="light">
                <QualityList
                    streamQuality={getStreamQuality(bandwidth)}
                    setStreamQuality={setStreamQuality}
                />
            </ThemeProvider>
        </SettingsPanel>
    );
};
