import {StreamQuality} from './types';

export const CHARACTER_LIMIT = 10_000;

// matches grid-responsive medium (grid.tokens.scss)
export const MIN_VIEWPORT_WIDTH = 767;
export const MIN_VIEWPORT_HEIGHT = 498;

// Interfaces constants
export const interfacesMargin = 16;
export const sidePanelWidth = interfacesMargin + 344 + interfacesMargin; //margins + content width
export const desktopToolbarWidth = 72;
export const sidePanelPlusDesktopToolbarWidth =
    sidePanelWidth + desktopToolbarWidth;
export const INITIAL_STREAM_QUALITY = StreamQuality.High;

export const MainBreakoutRoomId = 'main';

export const KEYBOARD_EVENT_KEY = {
    enter: 'Enter',
    escape: 'Escape',
} as const;
