/**
 * exits PiP (picture-in-picture) mode if it supported by the browser and currently enabled
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Document/exitPictureInPicture
 */
export const exitPiP = async () => {
    if (document.pictureInPictureElement && document.exitPictureInPicture) {
        await document.exitPictureInPicture();
    }
};
