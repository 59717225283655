import {useCallback, useEffect, useReducer} from 'react';

import {stopMediaStream} from '@pexip/media-control';
import type {GetDisplayMedia} from '@pexip/media';
import type {Signal} from '@pexip/signal';

import type {PresentationEvent, PresentationState} from '../types';
import {logger} from '../logger';
import {
    PresentationEmphasis,
    PresentationSize,
    PresentationAction,
} from '../types';

import {usePresentationPoppedOut} from './usePresentationPoppedOut';

export const presentationReducer = (
    state: PresentationState,
    action: PresentationEvent,
): PresentationState => {
    switch (action.type) {
        case PresentationAction.SetLocalMediaStream:
            return {
                ...state,
                localMediaStream: action.mediaStream,
            };
        case PresentationAction.SetRemoteMediaStream:
            return {
                ...state,
                remoteMediaStream: action.mediaStream,
            };
        case PresentationAction.SetEmphasis:
            return {
                ...state,
                emphasis: action.emphasis,
            };
        case PresentationAction.SetExpandPrimary:
            return {
                ...state,
                expandPrimary: action.expandPrimary,
            };
        case PresentationAction.SetShowSteal:
            return {
                ...state,
                showSteal: action.showSteal,
            };
        case PresentationAction.SetSize:
            return {
                ...state,
                size: action.size,
            };
        case PresentationAction.SetPresenterName:
            return {
                ...state,
                presenterName: action.presenterName,
            };
        case PresentationAction.ActivityChange: {
            const currentSendActivity = state.activity?.send;
            const newSendActivity = action.activity.send;

            const currentRecvActivity = state.activity?.recv;
            const newRecvActivity = action.activity.recv;

            if (newSendActivity) {
                let remoteMediaStream = state.remoteMediaStream;
                if (!newRecvActivity && currentRecvActivity) {
                    remoteMediaStream = undefined;
                }
                return {
                    ...state,
                    emphasis: PresentationEmphasis.Secondary,
                    size: PresentationSize.Large,
                    remoteMediaStream,
                    activity: action.activity,
                };
            } else if (newRecvActivity) {
                let localMediaStream = state.localMediaStream;
                if (!newSendActivity && currentSendActivity) {
                    localMediaStream = undefined;
                }
                return {
                    ...state,
                    emphasis: PresentationEmphasis.Primary,
                    size: PresentationSize.Large,
                    localMediaStream,
                    activity: action.activity,
                };
            } else {
                return {
                    emphasis: undefined,
                    expandPrimary: undefined,
                    showSteal: undefined,
                    size: undefined,
                    localMediaStream: undefined,
                    remoteMediaStream: undefined,
                    activity: undefined,
                    presenterName: undefined,
                };
            }
        }
        default:
            throw new Error(
                `${action} not implemented in Presentation.reducer`,
            );
    }
};

const isPeerActive = (connectionState: RTCPeerConnectionState) =>
    connectionState === 'connecting' || connectionState === 'connected';

export const usePresentation = ({
    presentationConnectionStateChangeSignal,
    presentationPresenterNameSignal,
    presentationReceiveStreamSignal,
    present,
    stopPresentation,
    handleGetDisplayMedia,
    presentationStreamCleanup,
    handlaGetDisplayMediaError,
}: {
    presentationConnectionStateChangeSignal: Signal<{
        send: RTCPeerConnectionState;
        recv: RTCPeerConnectionState;
    }>;
    presentationPresenterNameSignal?: Signal<string>;
    presentationReceiveStreamSignal: Signal<MediaStream>;
    present: (stream: MediaStream) => void;
    stopPresentation: () => void;
    handleGetDisplayMedia: GetDisplayMedia;
    presentationStreamCleanup?: () => void;
    handlaGetDisplayMediaError?: (error: Error) => void;
}) => {
    const [
        {
            activity,
            emphasis,
            expandPrimary,
            showSteal,
            size,
            localMediaStream,
            remoteMediaStream,
            presenterName,
        },
        dispatch,
    ] = useReducer(presentationReducer, {});

    const remoteIsPresenting = !!activity?.recv;
    const localIsPresenting = !!activity?.send;
    const isPresenting = remoteIsPresenting || localIsPresenting;

    const isPrimaryExpanded = expandPrimary ?? false;
    const isShowSteal = showSteal ?? false;

    const handlePresentationStream = useCallback(
        (mediaStream?: MediaStream) => {
            if (mediaStream) {
                logger.debug({mediaStream}, 'Presentation stream acquired');
                mediaStream.getVideoTracks().forEach(track => {
                    // track.onended syntax doesn't seem to work
                    track.addEventListener('ended', () => {
                        logger.debug('Track ended. Stop presentation.');
                        stopPresentation();
                    });
                });
                present(mediaStream);

                dispatch({
                    type: PresentationAction.SetLocalMediaStream,
                    mediaStream,
                });
            }
        },
        [present, stopPresentation],
    );

    const setEmphasis = useCallback(
        (emphasis: PresentationEmphasis) =>
            dispatch({type: PresentationAction.SetEmphasis, emphasis}),
        [],
    );

    const setSize = useCallback(
        (size: PresentationSize) =>
            dispatch({type: PresentationAction.SetSize, size}),
        [],
    );

    const setExpandPrimary = useCallback((expandPrimary: boolean) => {
        dispatch({
            type: PresentationAction.SetExpandPrimary,
            expandPrimary,
        });
    }, []);

    const setShowSteal = useCallback((showSteal: boolean) => {
        dispatch({type: PresentationAction.SetShowSteal, showSteal});
    }, []);

    const startPresentation = useCallback(async () => {
        try {
            const stream = await handleGetDisplayMedia();
            if (stream) {
                handlePresentationStream(stream);
            }
        } catch (error) {
            if (handlaGetDisplayMediaError && error instanceof Error) {
                handlaGetDisplayMediaError(error);
            } else {
                logger.error({error}, `Can't start presentation`);
            }
        }
    }, [
        handleGetDisplayMedia,
        handlePresentationStream,
        handlaGetDisplayMediaError,
    ]);

    const handlePresentationRequest = useCallback(() => {
        if (remoteIsPresenting) {
            setShowSteal(true);
        } else {
            void startPresentation();
        }
    }, [remoteIsPresenting, setShowSteal, startPresentation]);

    useEffect(
        () =>
            presentationReceiveStreamSignal.add(mediaStream => {
                dispatch({
                    type: PresentationAction.SetRemoteMediaStream,
                    mediaStream,
                });
            }),
        [presentationReceiveStreamSignal],
    );

    useEffect(
        () =>
            presentationPresenterNameSignal?.add(presenterName => {
                dispatch({
                    type: PresentationAction.SetPresenterName,
                    presenterName,
                });
            }),
        [presentationPresenterNameSignal],
    );

    useEffect(
        () =>
            presentationConnectionStateChangeSignal.add(({send, recv}) => {
                const isSendActive = isPeerActive(send);
                const isReceiveActive = isPeerActive(recv);

                dispatch({
                    type: PresentationAction.ActivityChange,
                    activity: {
                        send: isSendActive,
                        recv: isReceiveActive,
                    },
                });
            }),
        [presentationConnectionStateChangeSignal],
    );

    useEffect(
        () => () => {
            logger.info({localMediaStream}, 'Stop Presentation stream');
            presentationStreamCleanup?.();
            stopMediaStream(localMediaStream);
        },
        [localMediaStream, presentationStreamCleanup],
    );

    const poppedOut = usePresentationPoppedOut();

    return {
        emphasis,
        isPrimaryExpanded,
        isShowSteal,
        isPresenting,
        size,
        localIsPresenting,
        localMediaStream,
        remoteIsPresenting,
        remoteMediaStream,
        activity,
        presenterName,
        handlePresentationRequest,
        setEmphasis,
        setExpandPrimary,
        setShowSteal,
        setSize,
        startPresentation,
        stopPresentation,
        poppedOut,
    };
};
