import {useContext} from 'react';
import type {Context} from 'react';

export const useAssertedContext = <T>(t: Context<T>) => {
    const context = useContext(t);
    if (!context) {
        throw new Error('UndefinedContextValue', {cause: t});
    }
    return context;
};
