import React from 'react';
import {useTranslation} from 'react-i18next';

import {ConfirmationModal} from '@pexip/components';

import {TestId} from '../../../test/testIds';

export const BreakoutRoomsGoBackToModeAssignmentModal: React.FC<{
    isOpen: boolean;
    onConfirm: () => void;
    onCancel: () => void;
}> = ({isOpen, onConfirm, onCancel}) => {
    const {t} = useTranslation();

    return (
        <ConfirmationModal
            isOpen={isOpen}
            title={t(
                'breakouts.go-back-to-mode-assignment-title',
                'Are you sure?',
            )}
            uniqueTitle={t(
                'breakouts.go-back-to-mode-assignment-title',
                'Are you sure?',
            )}
            description={t(
                'breakouts.go-back-to-mode-assignment-description',
                'You will lose the rooms you created.',
            )}
            confirmButtonText={t('common.ok', 'Ok')}
            confirmButtonTestid={
                TestId.ButtonConfirmGoBackToBreakoutRoomsModeAssignment
            }
            cancelButtonText={t('common.cancel', 'Cancel')}
            cancelButtonTestid={
                TestId.ButtonCancelGoBackToBreakoutRoomsModeAssignment
            }
            onConfirm={onConfirm}
            onCancel={onCancel}
        />
    );
};
