import React from 'react';
import cx from 'classnames';

import {IconTypes} from '../../elements/Icon/Icon';
import type {DropContentProps} from '../DropContent/DropContent';
import {DropContent} from '../DropContent/DropContent';
import type {ButtonProps} from '../../elements/Button/Button';
import {Button} from '../../elements/Button/Button';
import type {
    ButtonVariant,
    ColorScheme,
    TextVariant,
} from '../../../types/variants';
import {ThemeConsumer} from '../../../themes/ThemeContext';
import type {RoundIndicatorBaseTypeProps} from '../RoundIndicator/RoundIndicator';
import {RoundIndicator} from '../RoundIndicator/RoundIndicator';
import {getInitials} from '../../../utils/string';
import type {ButtonModifiersValues} from '../../../types/sizes';
import type {TooltipPosition} from '../../../types/propTypes';
import {Tooltip} from '../../elements/Tooltip/Tooltip';

import styles from './UserMenuDropContent.module.scss';

const inheritStylesFromParentButton = (): TextVariant => 'inherit';

export const UserMenuButton: React.FC<{
    isContentVisible: boolean;
    displayName?: string;
    variant?: ButtonVariant;
    title?: string;
    isDisabled?: boolean;
    modifier?: ButtonModifiersValues;
    colorScheme: ColorScheme;
}> = ({
    isContentVisible,
    displayName,
    variant = 'tertiary',
    isDisabled = false,
    modifier = 'disc',
    colorScheme,
    ...buttonProps
}) => {
    const getRoundIndicatorProperties = (): RoundIndicatorBaseTypeProps =>
        displayName
            ? {
                  type: 'text',
                  text: getInitials(displayName),
              }
            : {
                  type: 'icon',
                  iconType: IconTypes.IconSettings,
              };

    return (
        <Button
            {...buttonProps}
            isActive={isContentVisible}
            modifier={modifier}
            size="large"
            variant={variant}
            colorScheme={colorScheme}
            isDisabled={isDisabled}
            className={cx(
                styles.mobileButton,
                isContentVisible && styles.active,
                isDisabled && styles.disabled,
            )}
        >
            <RoundIndicator
                modifier="noBackground"
                textVariant={inheritStylesFromParentButton()}
                iconSize="small"
                {...getRoundIndicatorProperties()}
            />
        </Button>
    );
};

export const UserMenuDropContent: React.FC<
    DropContentProps & {
        displayName?: string;
        colorScheme?: ButtonProps['colorScheme'];
        variant?: ButtonVariant;
        modifier?: ButtonModifiersValues;
        title?: string;
        isDisabled?: boolean;
        onContentVisibilityChange?: (isVisible: boolean) => void;
        tooltipPosition?: TooltipPosition;
    }
> = ({
    className,
    contentClassName,
    displayName,
    colorScheme,
    variant = 'tertiary',
    title = 'Settings',
    buttonLabel = 'Toggle settings menu',
    isDisabled = false,
    onContentVisibilityChange,
    tooltipPosition = 'endBottom',
    ...dropContentProps
}) => {
    const [showTooltip, setShowTooltip] = React.useState(true);

    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <DropContent
                    closeOnEscape
                    className={className}
                    contentClassName={contentClassName}
                    buttonLabel={buttonLabel}
                    onContentVisibilityChange={isVisible =>
                        setShowTooltip(!isVisible)
                    }
                    interactiveElement={(buttonProps, isContentVisible) => {
                        onContentVisibilityChange?.(isContentVisible);
                        return (
                            <Tooltip
                                text={title}
                                position={tooltipPosition}
                                isTooltipEnabled={showTooltip}
                            >
                                <UserMenuButton
                                    {...buttonProps}
                                    isContentVisible={isContentVisible}
                                    variant={variant}
                                    colorScheme={
                                        colorScheme ?? defaultColorScheme
                                    }
                                    isDisabled={isDisabled}
                                    displayName={displayName}
                                />
                            </Tooltip>
                        );
                    }}
                    {...dropContentProps}
                />
            )}
        </ThemeConsumer>
    );
};

export type UserMenuDropContentProps = React.ComponentProps<
    typeof UserMenuDropContent
>;
