import {useEffect, useState} from 'react';

import type {Media} from '@pexip/media';

export const useMediaInputs = (media: Media) => {
    const [selectedVideoInput, setSelectedVideoInput] = useState(
        media.videoInput,
    );
    const [selectedAudioInput, setSelectedAudioInput] = useState(
        media.audioInput,
    );

    useEffect(() => {
        if (media.stream) {
            setSelectedVideoInput(
                media?.videoInput ?? media?.expectedVideoInput,
            );
            setSelectedAudioInput(
                media?.audioInput ?? media?.expectedAudioInput,
            );
        }
    }, [media]);

    return {selectedVideoInput, selectedAudioInput};
};
