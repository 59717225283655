import React from 'react';

import type {ChatMessage} from '../../types';
import {TestId} from '../../../test/testIds';

import {ChatMessageView} from './ChatMessage.view';
import {ChatActivityMessage} from './ChatActivityMessage.view';

import styles from './ChatPanelMessages.module.scss';

export const ChatPanelMessages: React.FC<{messages: ChatMessage[]}> = ({
    messages,
}) => (
    <div
        className={styles.chatPanelMessagesWrapper}
        data-testid={TestId.ChatMessages}
    >
        {messages.map(message =>
            message.type === 'activity' ? (
                <ChatActivityMessage key={message.id} message={message} />
            ) : (
                <ChatMessageView key={message.id} message={message} />
            ),
        )}
    </div>
);
