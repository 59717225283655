/**
 * A type utility function to check if the provided object has the prop, and
 * provide a proper type to workaround Typescript's limitation
 *
 * @param obj - The object to check
 * @param prop - The prop to be used for the checking
 */
export const hasOwn = <T extends object, K extends PropertyKey>(
    obj: T,
    prop: K,
): obj is T & Record<K, unknown> => {
    if ('hasOwn' in Object) {
        return Object.hasOwn(obj, prop);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment --- Support Chrome 87
    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access --- Support Chrome 87
    return Object.prototype.hasOwnProperty.call(obj, prop);
};
