import {useTranslation} from 'react-i18next';
import React from 'react';

import type {GroupProps} from '@pexip/components';
import {IconTypes, Group} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import {ResponsiveButton} from '../ResponsiveButton/ResponsiveButton.view';

/**
 * Configuration toolbar for breakout rooms.
 * One of the props `onAddRoomButtonClick`,
 * `onShuffledButtonClick` or `onSettingsButtonClick`
 * needs to be defined for the toolbar to be rendered.
 */
export const BreakoutRoomsConfigurationToolbar: React.FC<
    {
        onAddRoomButtonClick?: () => void;
        onShuffledButtonClick?: () => void;
        onSettingsButtonClick?: () => void;
        isShuffling?: boolean;
        hideAddRoomButtonText?: boolean;
        hideShuffleButtonText?: boolean;
    } & GroupProps
> = ({
    onAddRoomButtonClick,
    onShuffledButtonClick,
    onSettingsButtonClick,
    isShuffling = false,
    hideAddRoomButtonText = false,
    hideShuffleButtonText = false,
    ...props
}) => {
    const {t} = useTranslation();

    if (
        !onAddRoomButtonClick &&
        !onShuffledButtonClick &&
        !onSettingsButtonClick
    ) {
        return null;
    }

    return (
        <Group spacing="compact" {...props}>
            {onAddRoomButtonClick && (
                <ResponsiveButton
                    variant="bordered"
                    aria-label={t(
                        'breakouts.configuration-toolbar.add-room.aria-label',
                        'Add a breakout room',
                    )}
                    data-testid={TestId.ButtonBreakoutRoomAddRoom}
                    iconSource={IconTypes.IconAdd}
                    hideChildren={hideAddRoomButtonText}
                    onClick={onAddRoomButtonClick}
                >
                    {t(
                        'breakouts.configuration-toolbar.add-room.button-text',
                        'Add room',
                    )}
                </ResponsiveButton>
            )}
            {onShuffledButtonClick && (
                <ResponsiveButton
                    variant="bordered"
                    aria-label={t(
                        'breakouts.configuration-toolbar.shuffle.aria-label',
                        'Shuffle the participants',
                    )}
                    data-testid={TestId.ButtonBreakoutRoomShuffle}
                    iconSource={IconTypes.IconShuffle}
                    hideChildren={hideShuffleButtonText}
                    onClick={onShuffledButtonClick}
                    isLoading={isShuffling}
                >
                    {t(
                        'breakouts.configuration-toolbar.shuffle.button-text',
                        'Shuffle',
                    )}
                </ResponsiveButton>
            )}
            {onSettingsButtonClick && (
                <ResponsiveButton
                    variant="bordered"
                    hideChildren={true}
                    aria-label={t(
                        'breakouts.configuration-toolbar.settings.aria-label',
                        'Open the breakout rooms settings',
                    )}
                    data-testid={TestId.ButtonBreakoutRoomSettings}
                    iconSource={IconTypes.IconSettings}
                    onClick={onSettingsButtonClick}
                />
            )}
        </Group>
    );
};

export type BreakoutRoomsConfigurationBarProps = React.ComponentProps<
    typeof BreakoutRoomsConfigurationToolbar
>;
