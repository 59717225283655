import React from 'react';
import cx from 'classnames';

import {Box} from '../../elements/Box/Box';
import {Checkbox} from '../../elements/Checkbox/Checkbox';
import {Scrollbars} from '../../elements/Scrollbars/Scrollbars';
import {TestId} from '../../../utils/testIds';
import {withColorScheme} from '../../../themes/ThemeContext';
import type {MultiselectOption} from '../../../types/elements';

import type {MultiselectCoreProps} from './MultiselectCore';
import {MultiselectCore} from './MultiselectCore';

import styles from './Multiselect.module.scss';

const BoxLight = withColorScheme(Box, 'light');

export const Multiselect: React.FC<
    Omit<MultiselectCoreProps, 'menuComponent'> & {
        onSelectChange: (selection: MultiselectOption[]) => void;
        menuTestId?: string;
    }
> = ({
    isFullWidth,
    options,
    onSelectChange,
    menuTestId,
    value = [],
    ...props
}) => {
    const handleSelection = ({id, checked}: {id: string; checked: boolean}) => {
        let newSelection: string[] = [];

        if (!value.includes(id) && checked) {
            newSelection = [...value, id];
        } else if (value.includes(id) && !checked) {
            newSelection = value.filter(s => s !== id);
        }

        const result: MultiselectOption[] = newSelection.map(id => ({
            id,
            label: options.find(o => o.id === id)?.label ?? 'No label',
        }));

        onSelectChange(result);
    };

    return (
        <MultiselectCore
            options={options}
            value={value}
            isFullWidth={isFullWidth}
            menuComponent={
                <MultiselectMenu
                    isFullWidth={isFullWidth}
                    options={options}
                    selection={value}
                    onChange={handleSelection}
                    testId={menuTestId}
                />
            }
            {...props}
        />
    );
};

export type MultiselectProps = React.ComponentProps<typeof Multiselect>;

const MultiselectMenu: React.FC<{
    isFullWidth?: boolean;
    options: MultiselectOption[];
    selection: string[];
    testId?: string;
    onChange: ({id, checked}: {id: string; checked: boolean}) => void;
}> = ({isFullWidth = false, options, selection, onChange, testId}) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange({id: e.currentTarget.name, checked: e.currentTarget.checked});
    };

    return options.length > 0 ? (
        <BoxLight
            className={cx(
                styles.wrapper,
                {
                    [styles.fullWidth]: isFullWidth,
                },
                'py-3',
            )}
            data-testid={testId}
        >
            <Scrollbars className={cx(styles.menu, 'pl-4 pr-5 mr-1')}>
                {options
                    .filter(option => !option.isHidden)
                    .map((option, index) => (
                        <div
                            className={cx(styles.checkboxWrapper, {
                                'mb-2': index + 1 !== options.length,
                            })}
                            key={option.id}
                            data-testid={`${TestId.MultiselectItem}-${index}`}
                        >
                            <Checkbox
                                role="menuitemcheckbox"
                                name={option.id}
                                label={option.label}
                                checked={selection.includes(option.id)}
                                onChange={handleChange}
                                isDisabled={option.isDisabled}
                            />
                        </div>
                    ))}
            </Scrollbars>
        </BoxLight>
    ) : null;
};
