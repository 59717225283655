import {isImageSrcString} from '@pexip/components';

import type {Images} from '../branding';
import {getBrandingPath} from '../branding';
import {useBranding} from '../branding/Context';

export const useBrandImage = <T extends keyof Images>(key: T) => {
    const images = useBranding('images');
    const image = images[key];

    if (!image) {
        return;
    }

    if (isImageSrcString(image)) {
        return getBrandingPath(image);
    }

    return Object.fromEntries(
        Object.entries(image).map(([k, src]) => [k, getBrandingPath(src)]),
    ) as Images[T];
};
