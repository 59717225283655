import {useCallback} from 'react';

import type {BreakoutParticipants, BreakoutRoomName} from '../types';
import {isDuplicateRoomName} from '../utils/isDuplicateRoomName';
import {getBreakoutRooms} from '../utils/getBreakoutRooms';

export const useBreakoutAddRoom = ({
    setParticipants,
    existingBreakoutNames = [],
}: {
    setParticipants: React.Dispatch<React.SetStateAction<BreakoutParticipants>>;
    existingBreakoutNames?: BreakoutRoomName[];
}) =>
    useCallback(() => {
        setParticipants(prevParticipants => {
            const currentParticipants = new Map(prevParticipants.entries());
            let newRoom = `Room ${[...prevParticipants.keys()].length}`;
            if (
                isDuplicateRoomName(newRoom, [
                    ...getBreakoutRooms(prevParticipants),
                    ...existingBreakoutNames,
                ])
            ) {
                newRoom = `${newRoom} (duplicate)`;
            }
            return currentParticipants.set(newRoom, []);
        });
    }, [existingBreakoutNames, setParticipants]);
