import React from 'react';

import {useDelayedVisibility} from './useDelayedVisibility';

export interface WithTransitionProps {
    isVisible: boolean;
}

export function withTransition<P extends object>(
    Component: React.ComponentType<P>,
    timeout: number,
) {
    return function WrappedWithTransition(props: P & WithTransitionProps) {
        const {isVisible, ...restProps} = props as WithTransitionProps;

        const areChildrenVisible = useDelayedVisibility(isVisible, timeout);

        if (!areChildrenVisible) {
            return null;
        }

        return <Component {...(restProps as P)} />;
    };
}
