import React from 'react';
import cx from 'classnames';

import {TestId} from '../../../test/testIds';

import styles from './BreakoutRoomsPanelWrapper.module.scss';

export const BreakoutRoomsPanelWrapper: React.FC<{
    children: React.ReactNode;
    className?: string;
    isShown: boolean;
    animateIn?: boolean;
    animateOut?: boolean;
}> = ({children, className, isShown, animateIn, animateOut}) => {
    return (
        <div
            data-testid={TestId.BreakoutRoomsPanelWrapper}
            className={cx(
                styles.panel,
                styles.breakoutRoomsPanelWrapper,
                {
                    [styles.show]: isShown,
                    [styles.animateIn]: !!animateIn,
                    [styles.animateOut]: !!animateOut,
                },
                className,
            )}
        >
            {children}
        </div>
    );
};

export type BreakoutRoomsPanelWrapperProps = React.ComponentProps<
    typeof BreakoutRoomsPanelWrapper
>;
