/**
 * Test that a value `t` is set to something and return it, throws on undefined.
 *
 * This is handy when selecting using an index to eliminiate the infered undefined type.
 *
 * @example
 * console.log(isDefined('')); // =\> ''
 * console.log(isDefined(0)); // =\> 0
 * console.log(isDefined(false)); // =\> false
 * console.log(isDefined(null)); // =\> null
 * console.log(isDefined(undefined)); // will throw
 */
export function isDefined<T>(t?: T) {
    if (t === undefined) {
        throw new Error(`typeof t = ${typeof t}, expected value`);
    }
    return t;
}
