import {isMediaTrackConstraints} from './typeGuards';
import type {
    MediaDeviceInfoLike,
    MediaDeviceKinds,
    MediaDeviceRequest,
} from './types';
import {extractDeviceId} from './constraints';
import {MediaDeviceFailure} from './types';

interface ErrorFunction {
    fn: (...args: string[]) => boolean;
    type: MediaDeviceFailure;
}

const isDeviceInUseError = (error: string) => {
    return (
        error === (MediaDeviceFailure.NotReadableError as string) ||
        error === (MediaDeviceFailure.TrackStartError as string)
    );
};

const isPermissionDeniedError = (error: string) => {
    return (
        error === (MediaDeviceFailure.NotAllowedError as string) ||
        error === (MediaDeviceFailure.PermissionDeniedError as string)
    );
};
export interface InputNotFoundErrorParams {
    input: MediaTrackConstraints | boolean | undefined;
    kind: MediaDeviceKinds;
    devices: MediaDeviceInfoLike[];
}
export const isInputNotFoundError =
    ({input, kind, devices}: InputNotFoundErrorParams) =>
    (error: string) => {
        if (error === (MediaDeviceFailure.NotFoundError as string)) {
            const hasDevices = devices.some(d => d.kind === kind);
            if (typeof input === 'boolean') {
                if (!hasDevices && input) {
                    return true;
                }
                return input && !hasDevices;
            }
            if (isMediaTrackConstraints(input)) {
                const [deviceIds, requirement] = extractDeviceId(input);
                if (requirement === 'ideal') {
                    return !hasDevices;
                }
                if (requirement === 'exact') {
                    return !devices.some(device =>
                        deviceIds?.some(
                            id =>
                                device.kind === kind && id === device.deviceId,
                        ),
                    );
                }
            }
        }
        return false;
    };

const normalizeError = (
    errors: ErrorFunction[],
    parameters: string[] = [],
): MediaDeviceFailure | false => {
    const error = errors.find(e => e.fn(...parameters));
    if (error) {
        return error.type;
    }

    return false;
};

export const normalizeGetUserMediaError = (
    browserError: Error,
    constraints: MediaStreamConstraints,
    devices: MediaDeviceInfoLike[],
): MediaDeviceFailure | string => {
    const isAudioInputNotFoundError = isInputNotFoundError({
        input: constraints.audio,
        kind: 'audioinput' as MediaDeviceKinds,
        devices,
    });
    const isVideoInputNotFoundError = isInputNotFoundError({
        input: constraints.video,
        kind: 'videoinput' as MediaDeviceKinds,
        devices,
    });
    const areBothInputsNotFoundError = (error: string) =>
        isAudioInputNotFoundError(error) && isVideoInputNotFoundError(error);
    const errorsFn = [
        {
            fn: isDeviceInUseError,
            type: MediaDeviceFailure.NotReadableError, // For now just normalize to current spec
        },
        {
            fn: isPermissionDeniedError,
            type: MediaDeviceFailure.NotAllowedError,
        },
        {
            fn: areBothInputsNotFoundError,
            type: MediaDeviceFailure.AudioAndVideoDeviceNotFoundError,
        },
        {
            fn: isAudioInputNotFoundError,
            type: MediaDeviceFailure.AudioInputDeviceNotFoundError,
        },
        {
            fn: isVideoInputNotFoundError,
            type: MediaDeviceFailure.VideoInputDeviceNotFoundError,
        },
    ];
    const errorMsg =
        browserError.name === 'Error'
            ? browserError.message
            : browserError.name;
    const error = normalizeError(errorsFn, [errorMsg]);
    return error ? error : errorMsg;
};

export const normalizeDeviceError = ({
    audio,
    video,
    streamingAudioInput,
    streamingVideoInput,
}: {
    streamingAudioInput?: boolean;
    streamingVideoInput?: boolean;
} & MediaDeviceRequest) => {
    let audioError = false;
    let videoError = false;

    if (audio) {
        audioError = !streamingAudioInput;
    }

    if (video) {
        videoError = !streamingVideoInput;
    }

    const errorsFn = [
        {
            fn: () => audioError && videoError,
            type: MediaDeviceFailure.AudioAndVideoDeviceNotFoundError,
        },
        {
            fn: () => audioError,
            type: MediaDeviceFailure.AudioInputDeviceNotFoundError,
        },
        {
            fn: () => videoError,
            type: MediaDeviceFailure.VideoInputDeviceNotFoundError,
        },
    ];
    const error = normalizeError(errorsFn, []);
    return error ? error : false;
};
