export const getTimeLeft = (
    futureTimestampSeconds: number,
    earlierTimestampSeconds: number,
) => {
    const differenceInSeconds =
        futureTimestampSeconds - earlierTimestampSeconds;
    if (differenceInSeconds <= 0) {
        return '00:00';
    }

    let hoursLeft = String(Math.floor(differenceInSeconds / 60 / 60));
    hoursLeft = hoursLeft.length === 1 ? '0' + hoursLeft : hoursLeft;

    let minutesLeft = String(Math.floor((differenceInSeconds / 60) % 60));
    minutesLeft = minutesLeft.length === 1 ? '0' + minutesLeft : minutesLeft;

    let secondsLeft = String(Math.floor(differenceInSeconds % 60));
    secondsLeft = secondsLeft.length === 1 ? '0' + secondsLeft : secondsLeft;

    return `${
        hoursLeft === '00' ? '' : hoursLeft + ':'
    }${minutesLeft}:${secondsLeft}`;
};
