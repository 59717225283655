import type {TransceiverConfig} from '@pexip/peer-connection';
import type {Signal, SignalVariant} from '@pexip/signal';
import {combine, createSignal} from '@pexip/signal';
import {createStatsSignals} from '@pexip/peer-connection-stats';

import type {
    IceCandidate,
    CallSignals,
    InfinityCallSignalsOptional as CallSignalsOptional,
    InfinitySignals,
    InfinitySignalsOptional,
    PresentationEvent,
    InfinityEventSignalsOptional,
    EventSignals,
    RTCParticipantEvent,
    SignalName,
    CurrentServiceType,
    Client,
    Participant,
    MediaType,
    ConferenceStateEvent,
    ConferenceStatus,
    MainStatsSignals,
    NormalizedPresentationEvent,
} from './types';
import {CallType, ClientCallType} from './types';
import {logger} from './logger';

/**
 * Create a general signal with consistent scoped name
 *
 * @param name - Signal name
 * @param scope - The scope of the signal for better logging
 * @param variant - The variant of the signal @see Signal @defaultValue 'generic'
 */
const createInfinitySignal = <T = undefined>(
    name: SignalName,
    scope = '',
    variant: SignalVariant = 'generic',
) =>
    createSignal<T>({
        name: `infinity/${scope}:${name}`,
        allowEmittingWithoutObserver: allowEmittingWithoutObserver(name),
        variant,
    });

const allowEmittingWithoutObserver = (signal: SignalName) =>
    [
        'onRtcStats',
        'onCallQualityStats',
        'onCallQuality',
        'onRequestedLayout',
        'onStage',
        'onPresentationFrame',
    ].includes(signal);

const REQUIRED_INFINITY_SIGNAL_KEYS = [
    'onAuthenticatedWithConference',
    'onConnected',
    'onCallDisconnected',
    'onDisconnected',
    'onAnswer',
    'onPresentationAnswer',
    'onFailedRequest',
    'onRetryQueueFlushed',
    'onPinRequired',
    'onError',
    'onIdp',
    'onRedirect',
    'onParticipants',
    'onParticipantJoined',
    'onParticipantLeft',
    'onMessage',
    'onApplicationMessage',
    'onMe',
    'onMyselfMuted',
    'onRequestedLayout',
    'onLayoutOverlayTextEnabled',
    'onStage',
    'onConferenceStatus',
    'onTransfer',
    'onRaiseHand',
    'onLiveCaptions',
    'onSplashScreen',
    'onNewOffer',
    'onIceCandidate',
    'onUpdateSdp',
    'onPeerDisconnect',
    'onExtension',
    'onLayoutUpdate',
    'onServiceType',
    'onBreakoutEnd',
    'onBreakoutBegin',
    'onBreakoutRefer',
    'onFecc',
] as const;

/**
 * Create and return all required and optional (if specified with `more`),
 * signals for infinity client to work
 *
 * @param scope - any scope prefix for the generated signal name, @see Signal
 * @param more - Keys from `InfinitySignalsOptional`, @see InfinitySignalsOptional
 *
 * The following signals created by default
 * - 'onConnected',
 * - 'onAnswer',
 *
 * @see REQUIRED_INFINITY_SIGNAL_KEYS
 */
export const createInfinityClientSignals = <
    K extends keyof InfinitySignalsOptional,
>(
    more: K[],
    scope = '',
) => {
    const signalScope = scope && [scope, ':'].join('');
    type SignalKeys =
        | (typeof more)[number]
        | (typeof REQUIRED_INFINITY_SIGNAL_KEYS)[number];
    return [...REQUIRED_INFINITY_SIGNAL_KEYS, ...more].reduce(
        (signals, key) => ({
            ...signals,
            [key]: createInfinitySignal<
                InfinitySignals[typeof key] extends Signal<infer S> ? S : never
            >(key, signalScope),
        }),
        {} as Pick<Required<InfinitySignals>, SignalKeys>,
    );
};

const REQUIRED_CALL_SIGNAL_KEYS = [
    'onCallConnected',
    'onRemoteStream',
    'onRemotePresentationStream',
    'onPresentationConnectionChange',
    'onRtcStats',
    'onCallQualityStats',
    'onCallQuality',
    'onSecureCheckCode',
    'onReconnecting',
    'onReconnected',
] as const;

/**
 * Create and return all required and optional (if specified with `more`),
 * signals for call to work
 *
 * @param scope - any scope prefix for the generated signal name, @see Signal
 * @param more - Keys from `CallSignalsOptional`, @see CallSignalsOptional
 *
 * The following signals created by default
 * - 'onRemoteStream',
 *
 * @see REQUIRED_CALL_SIGNAL_KEYS
 */
export const createCallSignals = <K extends keyof CallSignalsOptional>(
    more: K[],
    scope = '',
) => {
    const callScope = 'call';
    const signalScope = scope && [scope, ':', callScope, ':'].join('');
    type SignalKeys =
        | (typeof more)[number]
        | (typeof REQUIRED_CALL_SIGNAL_KEYS)[number];
    return [...REQUIRED_CALL_SIGNAL_KEYS, ...more].reduce(
        (signals, key) => ({
            ...signals,
            [key]: createInfinitySignal<
                CallSignals[typeof key] extends Signal<infer S> ? S : never
            >(key, signalScope),
        }),
        {} as Pick<Required<CallSignals>, SignalKeys>,
    );
};

const REQUIRED_EVENT_SIGNAL_KEYS = [
    'onConnected',
    'onError',
    'onCallDisconnected',
    'onPresentationStart',
    'onPresentationStop',
    'onPresentationEnded',
    'onPresentationFrame',
    'onParticipantCreate',
    'onParticipantUpdate',
    'onParticipantDelete',
    'onMessage',
    'onConferenceUpdate',
    'onStageUpdate',
    'onLayoutUpdate',
    'onDisconnect',
    'onParticipantSyncBegin',
    'onParticipantSyncEnd',
    'onRefer',
    'onHold',
    'onFecc',
    'onRefreshToken',
    'onLiveCaptions',
    'onPeerDisconnect',
    'onNewOffer',
    'onUpdateSdp',
    'onNewCandidate',
    'onSplashScreen',
    'onBreakoutBegin',
    'onBreakoutEvent',
    'onBreakoutEnd',
    'onBreakoutRefer',
] as const;
/**
 * Create and return all required and optional (if specified with `more`),
 * signals for call to work
 *
 * @param scope - any scope prefix for the generated signal name, @see Signal
 * @param more - Keys from `InfinityEventSignalsOptional`, @see InfinityEventSignalsOptional
 *
 * The following signals created by default
 * - 'onRemoteStream',
 *
 * @see REQUIRED_EVENT_SIGNAL_KEYS
 */
export const createEventSignals = <
    K extends keyof InfinityEventSignalsOptional,
>(
    more: K[],
    scope = '',
) => {
    const callScope = 'events';
    const signalScope = scope && [scope, ':', callScope, ':'].join('');
    type SignalKeys =
        | (typeof more)[number]
        | (typeof REQUIRED_EVENT_SIGNAL_KEYS)[number];
    return [...REQUIRED_EVENT_SIGNAL_KEYS, ...more].reduce(
        (signals, key) => ({
            ...signals,
            [key]: createInfinitySignal<
                EventSignals[typeof key] extends Signal<infer S> ? S : never
            >(key, signalScope),
        }),
        {} as Pick<Required<EventSignals>, SignalKeys>,
    );
};

/**
 * Convert `RTCIceCandidate` into Infinity ICE candidate data structure.
 */
export const toIceCandidate = (
    iceCandidate: RTCIceCandidate | null,
): IceCandidate => ({
    candidate: iceCandidate?.candidate ?? '',
    mid: iceCandidate?.sdpMid ?? '0',
    ...(iceCandidate?.usernameFragment && {
        ufrag: iceCandidate.usernameFragment,
    }),
});

export const getPresenter = (presentationEvent: PresentationEvent) => {
    const {presenter_name, presenter_uri} = presentationEvent;
    if (presenter_name && presenter_uri) {
        return `${presenter_name} <${presenter_uri}>`;
    }

    return presentationEvent.presenter_uri ?? '';
};

export const normalizePresentationEvent = (event: PresentationEvent) => ({
    presenterDisplayName: getPresenter(event),
    presenterName: event.presenter_name,
    presenterUri: event.presenter_uri,
    presenterUuid: event.presenter_uuid,
});

/**
 * ```json
 * {"presenter_name": "", "presenter_uri": "", "presenter_uuid": ""}
 * ```
 * This is an artificial event send from mcu side which means that we only want
 * to stop current presentation if we are presenting and we shouldn't start
 * receiving presentation unless those properties are different than empty string.
 *
 * @returns if we should ignore receiving the presentation
 */
export const isPresentatioRevoked = (event: NormalizedPresentationEvent) =>
    event.presenterName === '' &&
    event.presenterUri === '' &&
    event.presenterUuid === '';

const canControl = (participant: RTCParticipantEvent) => {
    if (!participant.service_type) {
        return false;
    }

    return (
        ['conference', 'lecture'].includes(participant.service_type) &&
        participant.role === 'chair'
    );
};

const canChangeLayout = (participant: RTCParticipantEvent) => {
    if (!participant.service_type) {
        return false;
    }

    return (
        ['conference', 'lecture', 'gateway'].includes(
            participant.service_type,
        ) && participant.role === 'chair'
    );
};

export const isWaiting = (serviceType: CurrentServiceType) =>
    serviceType === 'waiting_room';

export const isGateway = (serviceType: CurrentServiceType) =>
    serviceType === 'gateway';

export const isConnecting = (serviceType: CurrentServiceType) =>
    serviceType === 'connecting';

export const normalizeParticipant = (
    participant: RTCParticipantEvent,
): Participant => ({
    callType:
        participant.is_audio_only_call === 'YES'
            ? CallType.audio
            : participant.is_video_call === 'YES'
              ? CallType.video
              : CallType.api,
    canControl: canControl(participant),
    canChangeLayout: canChangeLayout(participant),
    canDisconnect: participant.disconnect_supported === 'YES',
    canFecc: participant.fecc_supported === 'YES',
    canMute: participant.mute_supported === 'YES',
    canRaiseHand: participant.service_type !== 'gateway',
    canSpotlight: participant.service_type !== 'gateway',
    canTransfer: participant.transfer_supported === 'YES',
    displayName:
        participant.display_name || participant?.uri?.replace('sip:', ''),
    overlayText: participant.overlay_text,
    handRaisedTime: participant.buzz_time,
    identity: participant.uuid, //FIXME: Change to be userId
    isCameraMuted: participant.is_video_muted,
    isConjoined: Boolean(participant.is_conjoined),
    isConnecting: isConnecting(participant.service_type),
    isEndpoint: participant?.uri?.includes('sip'),
    isExternal: participant.is_external,
    isIdpAuthenticated: participant.is_idp_authenticated,
    isGateway: isGateway(participant.service_type),
    isHost: participant.role === 'chair',
    isMainVideoDroppedOut: Boolean(participant.is_main_video_dropped_out),
    isMuted: participant.is_muted === 'YES',
    isPresenting: participant.is_presenting === 'YES',
    isSpotlight: participant.spotlight > 0,
    isStreaming: participant.is_streaming_conference,
    isVideoSilent: participant.is_video_silent,
    isWaiting: isWaiting(participant.service_type),
    needsPresentationInMix: Boolean(participant.needs_presentation_in_mix),
    protocol: participant.protocol,
    raisedHand: participant.buzz_time > 0,
    role: participant.role,
    rxPresentation: participant.rx_presentation_policy === 'ALLOW',
    serviceType: participant.service_type,
    spotlightOrder: participant.spotlight,
    startAt: new Date(participant.start_time * 1000),
    startTime: participant.start_time,
    uri: participant.uri ?? '',
    uuid: participant.uuid,
    vendor: participant.vendor,
    rawData: participant,
});

/**
 * Convert @see {@link ClientCallType} to @see {@link RTCRtpTransceiverDirection}
 */
export const toRTCRtpTransceiverDirection = (
    callType: ClientCallType,
    kind: 'audio' | 'video',
): Exclude<RTCRtpTransceiverDirection, 'stopped'> | undefined => {
    const send =
        kind === 'video' ? isSendingVideo(callType) : isSendingAudio(callType);
    const recv =
        kind === 'video'
            ? isReceivingVideo(callType)
            : isReceivingAudio(callType);
    if (send) {
        if (recv) {
            return 'sendrecv';
        }
        return 'sendonly';
    }
    if (recv) {
        return 'recvonly';
    }
    return undefined;
};

/**
 * Get the direction based on the provided track and callType
 */
export const getDirection = (
    kind: 'audio' | 'video',
    callType: ClientCallType,
) => {
    return toRTCRtpTransceiverDirection(callType, kind);
};

/**
 * Convert `MediaType/call_type` to `ClientCallType`
 * @see {@link https://docs.pexip.com/api_client/api_rest.htm#request_token}
 */
export const toClientCallType = (mediaType: MediaType): ClientCallType => {
    switch (mediaType) {
        case 'audio': {
            return ClientCallType.Audio;
        }
        case 'video':
        case 'video-only':
        default: {
            return ClientCallType.AudioVideo;
        }
    }
};

export const normalizeConferenceState = (
    conferenceState: ConferenceStateEvent,
) => ({
    locked: conferenceState.locked,
    guestsMuted: conferenceState.guests_muted,
    allMuted: conferenceState.all_muted ?? false,
    started: conferenceState.started,
    liveCaptionsAvailable: conferenceState.live_captions_available ?? false,
    breakoutRooms: conferenceState.breakout_rooms ?? false,
    classification:
        conferenceState.classification as ConferenceStatus['classification'],
    directMedia: conferenceState.direct_media ?? false,
    presentationAllowed: conferenceState.presentation_allowed ?? false,
    breakout: conferenceState.breakout ?? false,
    breakoutName: conferenceState.breakout_name,
    breakoutDescription: conferenceState.breakout_description,
    endTime: conferenceState.end_time,
    endAction: conferenceState.end_action,
    guestsAllowedToLeave: conferenceState.breakout_guests_allowed_to_leave,
    breakoutbuzz:
        conferenceState.breakoutbuzz as ConferenceStatus['breakoutbuzz'],
    rawData: conferenceState,
});

export const captureNoRequestClient = () => {
    const errorMsg =
        'Attempted to create EventSource before RequestClient, or RequestClient has been already cleaned up. Aborting.';
    logger.warn(errorMsg);
    return;
};

export const isCriticalAction = (funcName: keyof Client) => {
    return ['call', 'sendOffer', 'ack', 'disconnect'].includes(funcName);
};

const toNumericalBool = (input: number): 1 | 0 => (input > 0 ? 1 : 0);

export const isReceivingAudio = (callType: ClientCallType): boolean =>
    Boolean(callType & ClientCallType.AudioRecvOnly);
export const isReceivingVideo = (callType: ClientCallType): boolean =>
    Boolean(callType & ClientCallType.VideoRecvOnly);
export const isReceivingAnyMedia = (callType: ClientCallType): boolean =>
    isReceivingAudio(callType) || isReceivingVideo(callType);
export const isSendingAudio = (callType: ClientCallType): boolean =>
    Boolean(callType & ClientCallType.AudioSendOnly);
export const isSendingVideo = (callType: ClientCallType): boolean =>
    Boolean(callType & ClientCallType.VideoSendOnly);
export const isSendingAnyMedia = (callType: ClientCallType): boolean =>
    isSendingAudio(callType) || isSendingVideo(callType);
export const isUnidirectionalAudio = (callType: ClientCallType) =>
    Boolean(
        toNumericalBool(callType & ClientCallType.AudioRecvOnly) ^
            toNumericalBool(callType & ClientCallType.AudioSendOnly),
    );
export const isUnidirectionalVideo = (callType: ClientCallType) =>
    Boolean(
        toNumericalBool(callType & ClientCallType.VideoRecvOnly) ^
            toNumericalBool(callType & ClientCallType.VideoSendOnly),
    );
export const isUnidirectional = (callType: ClientCallType) =>
    isUnidirectionalAudio(callType) || isUnidirectionalVideo(callType);

export const getBandwidth = (
    userPrefferedBandwidth?: number,
    maxBandwidth?: number,
) => {
    if (!maxBandwidth) {
        return userPrefferedBandwidth ?? 0;
    }

    if (!userPrefferedBandwidth) {
        return maxBandwidth ?? 0;
    }

    return Math.min(userPrefferedBandwidth, maxBandwidth);
};

export const isMainConfig = (config: TransceiverConfig) =>
    config.content === 'main';
export const isPresoConfig = (config: TransceiverConfig) =>
    config.content === 'slides';
export const isAudioConfig = (config: TransceiverConfig) =>
    config.kind === 'audio';
export const isVideoConfig = (config: TransceiverConfig) =>
    config.kind === 'video';
export const isPresoVideo = (config: TransceiverConfig) =>
    [isPresoConfig, isVideoConfig].every(fn => fn(config));
export type Filter = (config: TransceiverConfig) => boolean;

export const createMainStatsSignals = (): MainStatsSignals => {
    const audioIn = createStatsSignals('audio:inbound');
    const audioOut = createStatsSignals('audio:outbound');

    const videoIn = createStatsSignals('video:inbound');
    const videoOut = createStatsSignals('video:outbound');

    const presoVideoIn = createStatsSignals('preso:video:inbound');
    const presoVideoOut = createStatsSignals('preso:video:outbound');

    return {
        audioIn,
        audioOut,
        videoIn,
        videoOut,
        presoVideoIn,
        presoVideoOut,
        combinedRtcStatsSignal: combine(
            audioIn.onRtcStats,
            audioOut.onRtcStats,
            videoIn.onRtcStats,
            videoOut.onRtcStats,
            presoVideoIn.onRtcStats,
            presoVideoOut.onRtcStats,
        ),
        combinedCallQualitySignal: combine(
            audioIn.onCallQuality,
            audioOut.onCallQuality,
        ),
        combinedCallQualityStatsSignal: combine(
            audioIn.onCallQualityStats,
            audioOut.onCallQualityStats,
        ),
    };
};
