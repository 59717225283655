import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import {mergeTranslation} from './utils/mergeTranslation';
import {logger} from './logger';

export const brandingLngs = new Map<string, string>();

const DEFAULT_FALLBACK_LANGUAGES = ['en'];

// Languages without app translation that should fallback to a given app translation
export const FALLBACK_LANGUAGES = [
    'zh-TW',
    'zh-HK',
    'zh-CN',
    'zh',
    'nn',
    'fr',
    'pt',
    'en-GB',
    'en-US',
];

// Includes all languages that are valid in the app
export const ALLOWED_LANGUAGES = [
    ...FALLBACK_LANGUAGES,
    'en',
    'cs',
    'cy',
    'da',
    'de',
    'es-ES',
    'es-US',
    'fi',
    'fr-CA',
    'fr-FR',
    'ga',
    'id',
    'it',
    'ja',
    'ko',
    'nb',
    'nl',
    'pl',
    'pt-BR',
    'sv',
    'th',
    'vi',
    'zh-Hans',
    'zh-Hant',
];

const FALLBACK_LNG_MAP: Record<string | symbol, string[]> = {
    nn: ['nb'],
    fr: ['fr-FR'],
    pt: ['pt-BR'],
    'en-GB': ['en'],
    'en-US': ['en'],
    'zh-CN': ['zh-Hans'],
    zh: ['zh-Hans'],
    'zh-HK': ['zh-Hant'],
    'zh-TW': ['zh-Hant'],
    default: DEFAULT_FALLBACK_LANGUAGES,
};

const getFallbackLanguage = (key: string) => {
    const fallbackLanguage = FALLBACK_LNG_MAP[key];
    if (fallbackLanguage) {
        return fallbackLanguage;
    }
    return DEFAULT_FALLBACK_LANGUAGES;
};

const importDefaultTranslations = async (
    language: string,
    namespace: string,
) => {
    try {
        const response = await fetch(
            new URL(`./locales/${language}/${namespace}.json`, import.meta.url),
        );
        return response.json();
    } catch (error) {
        const fallbackLngs = getFallbackLanguage(language);
        for (const lng of fallbackLngs) {
            try {
                // There is a fallback
                const fallbackTranslations = (await importDefaultTranslations(
                    lng,
                    namespace,
                )) as JSON;
                return fallbackTranslations;
            } catch {
                continue;
            }
        }
        // Nothing found with all the fallback languages defined
        throw error;
    }
};

const importBackend = {
    type: 'backend' as const,
    read<Namespace extends string>(
        language: string,
        namespace: Namespace,
        callback: (errorValue: unknown, translations: unknown) => void,
    ) {
        const defaultTranslationsImport = importDefaultTranslations(
            language,
            namespace,
        ).catch(() => {
            logger.warn(
                {language, namespace, brandingLngs},
                'failed to load built-in language',
            );
            return {};
        });

        if (brandingLngs.has(language)) {
            return Promise.all([
                defaultTranslationsImport,
                fetch(`${brandingLngs.get(language)}`).then(r => r.json()),
            ])
                .then(([defaultTranslations, brandedTranslations]) => {
                    callback(
                        null,
                        mergeTranslation(
                            defaultTranslations,
                            brandedTranslations,
                        ),
                    );
                })
                .catch(err => callback(err, null));
        }

        defaultTranslationsImport
            .then(resources => callback(null, resources))
            .catch(err => callback(err, null));
    },
};

/**
 * @param supportedLngs - array of allowed languages (https://www.i18next.com/overview/configuration-options)
 * @param newInstance - optional boolean to avoid calling init multiple times (https://www.i18next.com/overview/api#createinstance)
 */
export function initI18next(supportedLngs: string[], newInstance?: boolean) {
    const i18nInstance = newInstance ? i18n.createInstance() : i18n;
    return i18nInstance
        .use(LanguageDetector)
        .use(importBackend)
        .use(initReactI18next)
        .init({
            supportedLngs,
            fallbackLng: FALLBACK_LNG_MAP,
            detection: {
                order: ['querystring', 'localStorage', 'navigator'],
                caches: ['localStorage'],
            },
            interpolation: {
                escapeValue: false,
            },
            react: {
                bindI18nStore: 'added',
                useSuspense: false,
            },
        });
}
