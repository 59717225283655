import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import type {ButtonVariant} from '@pexip/components';
import {NotificationTooltip} from '@pexip/components';
import type {AutoHideButtonCallbacks} from '@pexip/media-components';
import {InMeetingUIElements} from '@pexip/media-components';

import {skippedStepByStepSignal} from '../signals/StepByStep.signals';
import type {UserMenuStep} from '../types';
import {ConfirmationModalStates} from '../types';
import {useBranding} from '../branding/Context';
import {TopUserMenuDropContent} from '../views/TopUserMenuDropContent/TopUserMenuDropContent.view';
import {TestId} from '../../test/testIds';
import {enableInMeetingUIAutoHide} from '../signals/InMeeting.signals';

import {UserMenuHandler} from './UserMenuHandler.viewModel';

export const UserMenu: React.FC<{
    autoHideProps?: AutoHideButtonCallbacks;
    variant?: ButtonVariant;
    hasMedia?: boolean;
    isInMeeting?: boolean;
}> = ({isInMeeting = false, hasMedia = false, autoHideProps}) => {
    const {t} = useTranslation();

    const [
        isTestVideoAndSoundTooltipVisible,
        setIsTestVideoAndSoundTooltipVisible,
    ] = useState(false);

    const [userMenuStep, setUserMenuStep] = useState<UserMenuStep>('menu');
    const overlay = useBranding('overlay');

    const [confirmationState, setConfirmationState] = useState(
        ConfirmationModalStates.None,
    );

    const handleBack = useCallback(() => {
        setUserMenuStep('menu');
    }, []);

    useEffect(
        () =>
            skippedStepByStepSignal.add(() => {
                setIsTestVideoAndSoundTooltipVisible(true);
            }),
        [],
    );

    return (
        <>
            <NotificationTooltip
                shouldCloseOnOutsideClick
                position="bottomLeft"
                header={t(
                    'test-video-and-sound-menu-tooltip.title',
                    'Test video and sound',
                )}
                text={t(
                    'test-video-and-sound-menu-tooltip.description',
                    'You can test your video and sound anytime from the menu',
                )}
                controlledIsVisible={isTestVideoAndSoundTooltipVisible}
                controlledOnChange={setIsTestVideoAndSoundTooltipVisible}
            >
                <TopUserMenuDropContent
                    dropContentLabel={userMenuStep}
                    dropContentRole={
                        userMenuStep === 'menu' ? 'menu' : 'dialog'
                    }
                    canCloseOutside={
                        confirmationState === ConfirmationModalStates.None &&
                        userMenuStep !== 'stats'
                    }
                    onClose={handleBack}
                    colorScheme={overlay}
                    data-testid={TestId.UserMenuButton}
                    content={close => (
                        <UserMenuHandler
                            close={close}
                            onBackClick={handleBack}
                            isInMeeting={isInMeeting}
                            setUserMenuStep={setUserMenuStep}
                            userMenuStep={userMenuStep}
                            hasMedia={hasMedia}
                            confirmationState={confirmationState}
                            setConfirmationState={setConfirmationState}
                            {...autoHideProps}
                        />
                    )}
                    onContentVisibilityChange={isVisible =>
                        enableInMeetingUIAutoHide.emit({
                            type: InMeetingUIElements.userMenu,
                            isOpen: isVisible,
                        })
                    }
                    {...autoHideProps}
                />
            </NotificationTooltip>
        </>
    );
};
