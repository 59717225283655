import React from 'react';
import {useTranslation} from 'react-i18next';

import {Input} from '@pexip/components';

import {TestId} from '../../../test/testIds';

export const InvitationSearchInput: React.FC<{
    onValueChange: (value: string) => void;
    value: string;
}> = ({onValueChange, value}) => {
    const {t} = useTranslation();
    return (
        <Input
            data-testid={TestId.InvitationSearchInput}
            label={t(
                'invite.search-for-someone',
                'Search for someone to invite',
            )}
            labelModifier="hidden"
            name="search-someone-to-invite"
            onValueChange={onValueChange}
            placeholder={t(
                'invite.search-for-someone',
                'Search for someone to invite',
            )}
            showClearButton={!!value}
            sizeModifier="small"
            type="text"
            value={value}
        />
    );
};

export type InvitationSearchInputProps = React.ComponentProps<
    typeof InvitationSearchInput
>;
