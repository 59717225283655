import {useCallback} from 'react';

import {MainBreakoutRoomId} from '../constants';
import type {
    BreakoutRoomId,
    BreakoutRoomNames,
    BreakoutsEditPanelSave,
    ParticipantUuid,
} from '../types';

/**
 *
 * @param localParticipants - The local end result of the edit
 * @param liveParticipants - The back-end view of the participants. What everyone currently sees
 * @param localBreakoutNames - The local end result of editing the names of existing breakout rooms
 * @param liveBreakoutNames - The back-end view of existing breakout rooms. What everyone currently sees
 * @returns - Object with actions to perform in order to align the back-end state with the local state.
 */
export const useBreakoutEditDiff = ({
    localParticipants,
    liveParticipants,
    localBreakoutNames,
    liveBreakoutNames,
}: {
    localParticipants: Map<BreakoutRoomId, ParticipantUuid[]>;
    liveParticipants: Map<BreakoutRoomId, ParticipantUuid[]>;
    localBreakoutNames: BreakoutRoomNames;
    liveBreakoutNames: BreakoutRoomNames;
}) =>
    useCallback(() => {
        const actions: BreakoutsEditPanelSave = {
            move: {},
            addRoom: {},
            renameRoom: {},
            removeRoom: [],
        };
        const existingRoomIds = Object.keys(liveBreakoutNames);
        for (const existingRoomId of existingRoomIds) {
            if (
                !Array.from(localParticipants.keys()).includes(existingRoomId)
            ) {
                // add to removeRoom actions
                actions.removeRoom.push(existingRoomId);
                continue;
            }
            const localName = localBreakoutNames[existingRoomId];
            if (localName && localName !== liveBreakoutNames[existingRoomId]) {
                // add to rename actions
                actions.renameRoom[existingRoomId] = localName;
            }
        }
        for (const roomId of localParticipants.keys()) {
            if (roomId === MainBreakoutRoomId) {
                // main is not a breakout room
                continue;
            }
            /**
             * Note: The fact that we don't allow duplicate room names is what
             * saves us from a false positive where someone renamed a room whilst we were editing
             */
            if (!existingRoomIds.includes(roomId)) {
                // add to addRoom actions
                actions.addRoom[roomId] = {};
            }
        }
        for (const [roomId, participants] of localParticipants.entries()) {
            for (const localParticipant of participants) {
                for (const [
                    liveRoomId,
                    liveRoomParticipants,
                ] of liveParticipants.entries()) {
                    for (const liveParticipant of liveRoomParticipants) {
                        if (localParticipant === liveParticipant) {
                            if (roomId !== liveRoomId) {
                                // move that participant to roomId
                                if (
                                    Object.keys(actions.addRoom).includes(
                                        roomId,
                                    )
                                ) {
                                    // moving participants to a new room
                                    const newRoomParticipants =
                                        actions.addRoom[roomId];
                                    if (newRoomParticipants) {
                                        newRoomParticipants[liveRoomId]
                                            ? newRoomParticipants[
                                                  liveRoomId
                                              ]?.push(localParticipant)
                                            : (newRoomParticipants[liveRoomId] =
                                                  [localParticipant]);
                                    }
                                    continue;
                                }
                                // moving participants to an existing room
                                const oldRoom = actions.move[liveRoomId] ?? {};
                                const newRoom = oldRoom[roomId];
                                if (newRoom) {
                                    newRoom.push(localParticipant);
                                } else {
                                    oldRoom[roomId] = [localParticipant];
                                }
                                actions.move[liveRoomId] = oldRoom;
                            }
                        }
                    }
                }
            }
        }
        return actions;
    }, [
        liveBreakoutNames,
        localBreakoutNames,
        liveParticipants,
        localParticipants,
    ]);
