export const createMediaStreamTrackProcessor: {
    (
        init: MediaStreamAudioTrackProcessorInit,
    ): MediaStreamTrackProcessor<AudioData>;
    (
        init: MediaStreamVideoTrackProcessorInit,
    ): MediaStreamTrackProcessor<VideoFrame>;
} = init => {
    const processor = new MediaStreamTrackProcessor(init);
    return processor;
};
