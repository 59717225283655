import {createSignal} from '@pexip/signal';

import type {Url} from './url';
import {getCurrentHref as getHref} from './location';
import {createHistory} from './history';
import type {Router, RouterOptions} from './types';

/**
 * A factory function to create Router object, @see Router
 *
 * @param options - Options to set the dependencies of the router, e.g. `baseUri`
 */
export const createRouter = ({
    getCurrentHref = getHref,
    baseUri = '/',
    ...options
}: Partial<RouterOptions> = {}): Router => {
    const urlRequestSignal =
        options.urlRequestSignal ??
        createSignal<Url>({
            name: 'router:urlRequest',
        });
    const urlChangedSignal =
        options.urlChangedSignal ??
        createSignal<Url>({
            name: 'router:urlChanged',
        });

    const history = createHistory(urlRequestSignal.emit);

    return {
        get currentHref() {
            return getCurrentHref();
        },
        get baseUri() {
            return baseUri;
        },
        urlChangedSignal,
        urlRequestSignal,
        history,
    };
};
