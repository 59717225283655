import React, {Children} from 'react';
import cx from 'classnames';

import styles from './Scrim.module.scss';

export const Scrim: React.FC<
    React.ComponentProps<'div'> & {position?: 'top' | 'bottom'}
> = ({children, position = 'top', className}) => (
    <div className={cx(styles[position], className)}>
        {Children.count(children) > 0 ? (
            <span className={styles.content}>{children}</span>
        ) : (
            children
        )}
    </div>
);
