import React from 'react';
import {useTranslation} from 'react-i18next';

import type {ButtonProps} from '@pexip/components';
import {BadgeCounter, Icon, IconTypes} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import {InMeetingInputControl} from '../InputControl/InMeetingInputControl.view';

export const BreakoutRoomsPanelToggleView: React.FC<
    ButtonProps & {
        buttonAriaLabel: string;
        buttonText: string;
        isPanelOpen: boolean;
        breakoutActionsCount: number;
        tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
    }
> = ({
    breakoutActionsCount,
    isPanelOpen,
    buttonAriaLabel,
    tooltipPosition = 'top',
    ...props
}) => {
    const {t} = useTranslation();

    return (
        <BadgeCounter
            data-testid={TestId.BadgeCounterBreakoutRoomsPanelButton}
            number={breakoutActionsCount}
            isVisible={!isPanelOpen && breakoutActionsCount > 0}
            isTruncated={breakoutActionsCount > 9}
        >
            <InMeetingInputControl
                aria-label={buttonAriaLabel}
                data-testid={TestId.ButtonBreakoutRooms}
                tooltipText={t('breakout-toggle-tooltip', 'Breakout Rooms')}
                tooltipPosition={tooltipPosition}
                icon={
                    <Icon source={IconTypes.IconBreakoutRooms} size="small" />
                }
                isActive={isPanelOpen}
                {...props}
            />
        </BadgeCounter>
    );
};

export type BreakoutRoomsPanelToggleViewProps = React.ComponentProps<
    typeof BreakoutRoomsPanelToggleView
>;
