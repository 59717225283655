import * as sdpTransform from 'sdp-transform';

import type {PexipMediaLine, MediaEncodingParameters} from './types';

export interface SdpOptions {
    allow1080p?: boolean;
    allow4kPreso?: boolean;
    allowVP9?: boolean;
    allowCodecSdpMunging?: boolean;
    contents?: string[];
    sendEncodings?: MediaEncodingParameters[][];
    videoAS?: number;
    videoTIAS?: number;
}

enum Codec {
    VP8 = 'VP8',
    VP9 = 'VP9',
    H264 = 'H264',
}

export interface SdpManager {
    setSdp: (
        sdp: RTCSessionDescriptionInit,
        enrichOptions?: SdpOptions,
    ) => void;
    getSdp: () => RTCSessionDescriptionInit;
    enrichSdp: (options: SdpOptions) => void;
}

export interface Fingerprint {
    type: string;
    hash: string;
}

// https://tools.ietf.org/html/draft-holmer-rmcat-transport-wide-cc-extensions-01
export const TWCCExtensionUrl =
    'http://www.ietf.org/id/draft-holmer-rmcat-transport-wide-cc-extensions';

export class SdpTransformManager implements SdpManager {
    constructor(
        private sdp: RTCSessionDescriptionInit,
        enrichOptions?: SdpOptions,
    ) {
        this.setSdp(sdp, enrichOptions);
    }

    setSdp(sdp: RTCSessionDescriptionInit, enrichOptions?: SdpOptions) {
        this.sdp = sdp;
        if (enrichOptions) {
            this.enrichSdp(enrichOptions);
        }
    }

    getSdp() {
        return this.sdp;
    }

    getFingerprints(): Fingerprint[] {
        if (!this.sdp?.sdp) {
            return [];
        }
        const transformSdp = sdpTransform.parse(this.sdp.sdp);
        return [
            transformSdp.fingerprint,
            ...transformSdp.media.map(m => m.fingerprint),
        ].flatMap(fingerprint => (fingerprint ? [fingerprint] : []));
    }

    enrichSdp(options: SdpOptions) {
        let modifiedSdp = this.sdp;
        if (this.sdp?.sdp) {
            let transformSdp = sdpTransform.parse(this.sdp.sdp);
            if (options.contents) {
                transformSdp.media
                    .filter(
                        mline =>
                            mline.type === 'audio' || mline.type === 'video',
                    )
                    .forEach((mline: PexipMediaLine, idx) => {
                        mline.content = options.contents?.[idx];
                    });
            }

            if (options.sendEncodings) {
                transformSdp.media
                    .filter(
                        mline =>
                            mline.type === 'audio' || mline.type === 'video',
                    )
                    .forEach((mline: PexipMediaLine, idx) => {
                        const encodings = options.sendEncodings?.[idx];
                        if (mline.rids && encodings) {
                            mline.rids.forEach((rid, i) => {
                                const {maxWidth, maxHeight} =
                                    encodings?.[i] ?? {};
                                const paramArray = rid.params
                                    ? rid.params.split(';')
                                    : [];

                                if (mline.type === 'video') {
                                    if (
                                        maxHeight &&
                                        !paramArray.find(param =>
                                            param.includes('max-height='),
                                        )
                                    ) {
                                        paramArray.push(
                                            `max-height=${maxHeight}`,
                                        );
                                    }
                                }
                                if (
                                    maxWidth &&
                                    !paramArray.find(param =>
                                        param.includes('max-width='),
                                    )
                                ) {
                                    paramArray.push(`max-width=${maxWidth}`);
                                }

                                if (paramArray.length > 0) {
                                    rid.params = paramArray.join(';');
                                }
                            });
                        }
                    });
            }
            if (options.videoAS) {
                transformSdp = this.addBandwidthLine(
                    transformSdp,
                    options.videoAS,
                    options.videoTIAS,
                );
            }
            if (options.allowCodecSdpMunging && !options.allowVP9) {
                transformSdp = this.stripCodecs(transformSdp, [Codec.VP9]);
            }
            if (this.shouldAddSupportForHighQualityStream(options)) {
                transformSdp = this.addSupportForHighQualityStream(
                    transformSdp,
                    options.allow4kPreso,
                );
            }
            if (options.allowCodecSdpMunging) {
                transformSdp = this.chooseVideoPTs(transformSdp);
            }

            modifiedSdp = {
                sdp: sdpTransform.write(transformSdp),
                type: this.sdp.type,
            };
        }
        this.sdp = modifiedSdp;
    }

    isTWCCsupported = () => this.sdp.sdp?.includes(TWCCExtensionUrl);

    addContentAttribute = (mid: string, content: string) => {
        if (!this.sdp.sdp || !content) {
            return;
        }
        let modifiedSdp = this.sdp;
        const transformSdp = sdpTransform.parse(this.sdp.sdp);
        const mline = this.getMediaLine(transformSdp.media, mid);
        if (!mline) {
            return;
        }
        this.setContentAttribute(mline, content);
        modifiedSdp = {
            sdp: sdpTransform.write(transformSdp),
            type: this.sdp.type,
        };
        this.sdp = modifiedSdp;
    };

    addMsidToMline = (transceiver: RTCRtpTransceiver, msid: string) => {
        if (!this.sdp.sdp) {
            return;
        }
        let modifiedSdp = this.sdp;
        const transformSdp = sdpTransform.parse(this.sdp.sdp);
        const mLine = transformSdp.media.find(mline => {
            if (transceiver.mid) {
                return (
                    String(mline.mid) === transceiver.mid &&
                    mline.type ===
                        (transceiver.sender.track?.kind ??
                            transceiver.receiver.track.kind)
                );
            }
        });
        if (!mLine) {
            // No such media line
            return;
        }
        const msids = mLine.msid?.split(' ');
        // Associate the stream id to for streamless for just replace
        if (msids?.[0]) {
            msids[0] = msid;
        }
        mLine.msid = msids?.join(' ');
        modifiedSdp = {
            sdp: sdpTransform.write(transformSdp),
            type: this.sdp.type,
        };
        this.sdp = modifiedSdp;
    };

    private shouldAddSupportForHighQualityStream(options: SdpOptions) {
        return (
            options.contents?.includes('slides') ||
            (options?.allow1080p && options.videoAS && options.videoAS >= 2564)
        );
    }

    private setContentAttribute(
        mline: PexipMediaLine | undefined,
        content: string,
    ) {
        if (!mline || !content) {
            return;
        }
        mline.content = content;
    }

    private addBandwidthLine(
        sdp: sdpTransform.SessionDescription,
        videoAS: number,
        videoTIAS?: number,
    ) {
        const videoLines = this.getVideoLines(sdp.media);
        videoLines.forEach(videoLine => {
            if (!videoLine.bandwidth) {
                videoLine.bandwidth = [];
            }

            const TIAS = videoLine.bandwidth.find(({type}) => type === 'TIAS');
            const limit = Number(TIAS?.limit);
            if (limit && videoTIAS && limit < videoTIAS) {
                // Don't override upper limit provided by MCU
                return;
            } else if (videoTIAS === 0 && limit) {
                // Client's bandwidth is set to "Auto" (i.e. `b=TIAS:0`) which means no specific bandwidth limit is set for the video stream
                // In this case, we should not override the bandwidth limit set by the MCU
                return;
            }

            videoLine.bandwidth.push({
                type: 'AS',
                limit: videoAS,
            });
            if (videoTIAS) {
                // For FF we should include this to the media line
                // (required only for outgoing stream)
                if (TIAS) {
                    TIAS.limit = videoTIAS;
                } else {
                    videoLine.bandwidth.push({
                        type: 'TIAS',
                        limit: videoTIAS,
                    });
                }
            }
        });
        return sdp;
    }

    private addSupportForHighQualityStream(
        sdp: sdpTransform.SessionDescription,
        allow4kPreso = false,
    ) {
        const videoLines = this.getVideoLines(sdp.media);
        videoLines.forEach(videoLine => {
            const codecs = this.getCodecs(videoLine.rtp);

            videoLine.fmtp = videoLine.fmtp.map(fmtp => {
                if (fmtp.config.includes('max-fs')) {
                    return fmtp;
                }

                const codec = codecs[fmtp.payload];
                const is4kPreso = isPreso(videoLine) && allow4kPreso;
                if (codec === Codec.VP8 || codec === Codec.VP9) {
                    fmtp.config += this.getVPXConfigOverrides(is4kPreso);
                } else if (codec === Codec.H264) {
                    fmtp.config += this.getH264ConfigOverrides(is4kPreso);
                }

                return fmtp;
            });
        });

        return sdp;
    }

    private getVPXConfigOverrides(is4kEnabled = false) {
        return `;max-fs=${is4kEnabled ? '36864' : '8160'};max-fr=30`;
    }

    private getH264ConfigOverrides(is4kEnabled = false) {
        return is4kEnabled
            ? ';max-br=32768;max-mbps=2073600;max-fs=36864;max-smbps=2073600;max-fps=6000;max-fr=30'
            : ';max-br=3732;max-mbps=245760;max-fs=8192;max-smbps=245760;max-fps=3000;max-fr=30';
    }

    /**
     * This method makes sure we pick set of supported codecs with the right order
     */
    private chooseVideoPTs(sdp: sdpTransform.SessionDescription) {
        const videoLines = this.getVideoLines(sdp.media);
        videoLines.forEach(videoLine => {
            /**
             * To reject an offered
             * stream, the port number in the corresponding stream in the answer
             * MUST be set to zero. Any media formats listed are ignored.
             *
             * Ref: https://www.rfc-editor.org/rfc/rfc3264#section-6
             */
            if (videoLine.port === 0 && this.sdp.type === 'answer') {
                return;
            }
            const codecs = this.getCodecs(videoLine.rtp);
            let payloadTypes: number[] = [];

            for (const fmtp of videoLine.fmtp) {
                if (codecs[fmtp.payload] !== Codec.H264) {
                    continue;
                }

                const params = fmtp.config.split(';');
                /**
                 * The profile-level-id parameter indicates the default sub-
                 * profile (i.e., the subset of coding tools that may have been
                 * used to generate the stream or that the receiver supports) and
                 * the default level of the stream or the receiver supports.
                 *
                 * Ref: https://datatracker.ietf.org/doc/html/rfc6184#page-41 (Table 5)
                 */
                const [, profile] =
                    params
                        .find(param => param.includes('profile-level-id'))
                        ?.split('=') ?? [];

                /**
                 * The default sub-profile is indicated collectively by the
                 * profile_idc byte and some fields in the profile-iop byte.
                 *
                 * Ref: https://datatracker.ietf.org/doc/html/rfc6184#page-41 (Table 5)
                 */
                const profileIdc = profile?.substring(0, 2);
                const profileIop = profile?.substring(2, 4) ?? '';

                /**
                 * Profile      profile_idc         profile-iop
                 *              (hexadecimal)       (binary)
                 *
                 *  CB           42 (B)             x1xx0000
                 *      same as: 4D (M)             1xxx0000
                 *      same as: 58 (E)             11xx0000
                 *  B            42 (B)             x0xx0000
                 *      same as: 58 (E)             10xx0000
                 *
                 * Where:
                 * CB: Constrained Baseline profile
                 * B:  Baseline profile
                 */
                if (profileIdc !== '42') {
                    continue;
                }

                /**
                 * Add all B/CB keys unless we find CB key together with packetization-mode=1
                 * Then we use only that one
                 * Because there seems to be a bug with CB key and packetization-mode=0
                 */
                if (
                    parseInt('0x' + profileIop) & 0x40 && // CB otherwise B
                    fmtp.config.includes('packetization-mode=1')
                ) {
                    payloadTypes = [fmtp.payload];
                    break;
                }

                payloadTypes.push(fmtp.payload);
            }

            // now filter based on the above
            videoLine.rtp = videoLine.rtp.filter(({codec, payload}) => {
                if (codec === (Codec.H264 as string)) {
                    return payloadTypes.includes(payload);
                }

                const fmtp = videoLine.fmtp.find(
                    line => line.payload === payload,
                );
                return this.shouldStripH264Line(
                    fmtp?.config ?? '',
                    payloadTypes,
                    codecs,
                );
            });
            videoLine.fmtp = videoLine.fmtp.filter(({payload, config}) => {
                if (
                    codecs[payload] === Codec.H264 &&
                    !payloadTypes.includes(payload)
                ) {
                    return false;
                }

                return this.shouldStripH264Line(config, payloadTypes, codecs);
            });

            if (videoLine.rtcpFb) {
                videoLine.rtcpFb = videoLine.rtcpFb.filter(
                    ({payload}) =>
                        codecs[payload] !== Codec.H264 ||
                        (codecs[payload] === Codec.H264 &&
                            payloadTypes.includes(payload)),
                );
            }

            // Put higher priority on the payloadTypes
            const payloads = [
                ...payloadTypes,
                ...videoLine.rtp.flatMap(({payload}) =>
                    !payloadTypes.includes(Number(payload)) ? [payload] : [],
                ),
            ];

            // Make sure we only apply this logic if we found any payloads
            // otherwise we can hit validation error
            if (videoLine.payloads && payloads.length > 0) {
                videoLine.payloads = payloads.join(' ');
            }
        });

        return sdp;
    }

    private shouldStripH264Line(
        config: string,
        payloadTypes: number[],
        codecs: Record<string, string>,
    ) {
        const [name, value] = config.split('=');
        if (
            name === 'apt' &&
            value &&
            codecs[value] === Codec.H264 &&
            !payloadTypes.includes(Number(value))
        ) {
            return false;
        }

        return true;
    }

    private stripCodecs(
        sdp: sdpTransform.SessionDescription,
        disableCodecs: Codec[],
    ) {
        const videoLines = this.getVideoLines(sdp.media);
        for (const videoLine of videoLines) {
            if (videoLine) {
                const removePayloads = videoLine.rtp
                    .filter(({codec}) => disableCodecs.includes(codec as Codec))
                    .map(({payload}) => payload);

                if (removePayloads.length > 0) {
                    const rtxApts = removePayloads.map(item => `apt=${item}`);
                    const rtxPayloads = videoLine.fmtp.filter(item =>
                        rtxApts.includes(item.config),
                    );

                    removePayloads.push(
                        ...rtxPayloads.map(item => item.payload),
                    );
                }
                if (videoLine.payloads) {
                    for (const payload of removePayloads) {
                        videoLine.payloads = videoLine.payloads.replace(
                            `${payload} `,
                            '',
                        );
                    }
                }
                videoLine.rtp = videoLine.rtp.filter(
                    rtp => !removePayloads.includes(rtp.payload),
                );
                videoLine.fmtp = videoLine.fmtp.filter(
                    fmtp => !removePayloads.includes(fmtp.payload),
                );
                if (videoLine.rtcpFb) {
                    videoLine.rtcpFb = videoLine.rtcpFb.filter(
                        rtcpFb => !removePayloads.includes(rtcpFb.payload),
                    );
                }
            }
        }

        return sdp;
    }

    private getVideoLines(
        media: sdpTransform.SessionDescription['media'],
    ): PexipMediaLine[] {
        return media.filter(line => line.type === 'video');
    }

    private getMediaLine(
        media: sdpTransform.SessionDescription['media'],
        mid: string,
    ): PexipMediaLine | undefined {
        if (mid) {
            return media.find(
                line =>
                    (line.type === 'video' || line.type === 'audio') &&
                    String(line.mid) === mid,
            );
        }
    }

    private getCodecs(rtp: PexipMediaLine['rtp']) {
        return rtp.reduce(
            (acc, {codec, payload}) => {
                acc[payload] = codec;
                return acc;
            },
            {} as Record<string, string>,
        );
    }
}

export const hasICECandidates = (sdp?: string) => {
    if (!sdp) {
        return false;
    }
    const transformedSDP = sdpTransform.parse(sdp);
    return transformedSDP.media.some(
        m => m.candidates && m.candidates.length > 0,
    );
};

export const getMediaLines = (sdp?: string) => {
    if (!sdp) {
        return [];
    }
    return sdpTransform.parse(sdp).media;
};

export const isPreso = (media: PexipMediaLine) => media.content === 'slides';
