import {useCallback, useEffect, useState} from 'react';

import {isMobileDevice} from '../utils/isMobileDevice';
import {desktopToolbarWidth, interfacesMargin} from '../constants';

const coreHeaderHeight = 56;
const headerHeight = coreHeaderHeight + interfacesMargin;

const mobileFooterHeight = 80;
const desktopFooterHeight = 64;
const footerHeight = isMobileDevice()
    ? mobileFooterHeight
    : desktopFooterHeight;

export interface ControlsOverlap {
    desktopToolbar: boolean;
    header: boolean;
    footer: boolean;
}

export const isControlsOverlapTruthy = (controlsOverlap: ControlsOverlap) =>
    controlsOverlap.desktopToolbar ||
    controlsOverlap.header ||
    controlsOverlap.footer;

export const useControlsRelativePosition = (
    ref: React.RefObject<HTMLDivElement>,
) => {
    const [controlsOverlap, setControlsOverlap] = useState<ControlsOverlap>({
        desktopToolbar: false,
        header: false,
        footer: false,
    });

    const update = useCallback(
        (force?: ControlsOverlap) => {
            if (!ref.current) {
                setControlsOverlap({
                    desktopToolbar: false,
                    header: false,
                    footer: false,
                });
                return;
            }
            if (force) {
                setControlsOverlap(force);
                return;
            }
            const el = ref.current;
            const rect = el.getBoundingClientRect();

            const overlapsDesktopToolbar =
                !isMobileDevice() &&
                el.offsetLeft <= desktopToolbarWidth + interfacesMargin;
            const overlapsHeader = el.offsetTop <= headerHeight;
            const overlapsFooter =
                rect.bottom >= window.innerHeight - footerHeight;

            setControlsOverlap({
                desktopToolbar: overlapsDesktopToolbar,
                header: overlapsHeader,
                footer: overlapsFooter,
            });
        },
        [ref],
    );

    useEffect(() => {
        update();
    }, [update]);

    return {controlsOverlap, update};
};
