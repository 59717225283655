import {hasOwn} from '@pexip/utils';

import type {Logger} from './baseLogger';
import {createConsoleLogger} from './baseLogger';

export let logger = createConsoleLogger();

export function setLogger(newLogger: Logger) {
    logger = newLogger;
}

type Redactable = string & {_brand: 'redactable'};

export const isRedactable = (t: unknown): t is Redactable => {
    if (typeof t === 'string' && t) {
        return true;
    }
    return false;
};

/**
 * Redact the value if the T[K] is redactable
 */
export const redact = <T>(t: T, k: PropertyKey) => {
    if (t && hasOwn(t, k)) {
        const value = t[k];
        if (isRedactable(value)) {
            logger.redact(value);
        }
    }
};
