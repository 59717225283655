import {QUEUE_SIZE, QUEUE_DROP_LAST, createQueue} from './queue';

export const QUEUE_THROTTLE_IN_MS = 100;

export const QUEUE_DELAY_IN_MS = 0;

export type AsyncJob = () => Promise<void>;

export interface AsyncQueueOptions {
    /**
     * Throttle in mini-second
     */
    throttleInMS?: number;
    /**
     * How many mini-second to delay to run the next job
     */
    delayInMS?: number;
    /**
     * Max size of the queue, when it reaches this size, dropping mechanism
     * starts. Drop the first/last one when overflow
     */
    size?: number;
    /**
     * Drop the last one in the queue or the first one
     */
    dropLast?: boolean;
}

export const createAsyncQueue = ({
    throttleInMS = QUEUE_THROTTLE_IN_MS,
    delayInMS = QUEUE_DELAY_IN_MS,
    size = QUEUE_SIZE,
    dropLast = QUEUE_DROP_LAST,
}: AsyncQueueOptions = {}) => {
    if (size < 1) {
        throw new Error('InvalidQueueSize');
    }
    const queue = createQueue<AsyncJob>(size, [], dropLast);
    const props = {
        currentJob: undefined as undefined | AsyncJob,
        processId: 0,
        lastOperatingTime: 0,
    };
    const clearTimeout = () => {
        if (props.processId) {
            window.clearTimeout(props.processId);
            props.processId = 0;
        }
    };

    const execute = async () => {
        if (queue.size && !props.currentJob) {
            props.currentJob = queue.dequeue();
            if (props.currentJob) {
                const now = performance.now();
                try {
                    await props.currentJob();
                } catch (error) {
                    if (error instanceof Error) {
                        throw error;
                    }
                } finally {
                    const delay = Math.min(performance.now() - now, delayInMS);
                    props.currentJob = undefined;
                    if (queue.size > 0) {
                        await new Promise((resolve, reject) => {
                            props.processId = window.setTimeout(() => {
                                execute().then(resolve).catch(reject);
                            }, delay);
                        });
                    }
                }
            }
        }
    };

    return {
        get busy() {
            return !!props.currentJob;
        },
        execute,
        enqueue: (job: AsyncJob, runImmediately = true) => {
            clearTimeout();
            // Use Throttling
            if (throttleInMS > 0) {
                const now = window.performance.now();
                const diff = now - props.lastOperatingTime;
                if (diff <= throttleInMS) {
                    queue.enqueueAt(-1, job, true);
                } else {
                    props.lastOperatingTime = now;
                    queue.enqueue(job);
                }
            } else {
                queue.enqueue(job);
            }
            if (runImmediately) {
                void execute();
            }
        },
    };
};
