import {hasOwn} from '@pexip/utils';

import type {
    ConstraintDeviceParameters,
    DeviceConstraint,
    InputConstraintSet,
    FacingMode,
} from './types';
import {FACING_MODE} from './types';

export const isBoolean = (t: unknown): t is boolean => typeof t === 'boolean';

export const isUndefined = (t: unknown): t is undefined =>
    typeof t === 'undefined';

/**
 * Check if provided variable is of type number and is NOT NaN
 */
export const isNumber = (t: unknown): t is number =>
    typeof t === 'number' && !Number.isNaN(t);

/**
 * Check if provided variable is of type integer
 */
export const isInteger = (t: unknown): t is number => Number.isInteger(t);

/**
 * Check if provided variable is of type floating point
 */
export const isFloat = (t: unknown): t is number =>
    isNumber(t) && !Number.isInteger(t) && Number.isFinite(t);

/**
 * Reference https://w3c.github.io/mediacapture-main/#dom-mediatrackconstraintset
 */
export const CONSTRAIN_STRING_KEYS = [
    'facingMode',
    'resizeMode',
    'deviceId',
    'groupId',
] as const;
export const EXTENDED_CONSTRAIN_STRING_KEYS = [
    'videoSegmentation',
    'videoSegmentationModel',
    'backgroundImageUrl',
    'contentHint',
] as const;
export type ExtendedConstrainStringKeys =
    (typeof EXTENDED_CONSTRAIN_STRING_KEYS)[number];
export type ConstrainStringKeys = (typeof CONSTRAIN_STRING_KEYS)[number];
export const CONSTRAIN_U_LONG_KEYS = [
    'width',
    'height',
    'sampleRate',
    'sampleSize',
    'channelCount',
] as const;
export const EXTENDED_CONSTRAIN_U_LONG_KEYS = [
    'backgroundBlurAmount',
    'edgeBlurAmount',
] as const;
export type ExtendedConstrainULongKeys =
    (typeof EXTENDED_CONSTRAIN_U_LONG_KEYS)[number];
export type ConstrainULongKeys = (typeof CONSTRAIN_U_LONG_KEYS)[number];
export const CONSTRAIN_DOUBLE_KEYS = [
    'aspectRatio',
    'frameRate',
    'latency',
] as const;
export const EXTENDED_CONSTRAIN_DOUBLE_KEYS = [
    'foregroundThreshold',
    'maskCombineRatio',
] as const;
export type ExtendedConstrainDoubleKeys =
    (typeof EXTENDED_CONSTRAIN_DOUBLE_KEYS)[number];
export type ConstrainDoubleKeys = (typeof CONSTRAIN_DOUBLE_KEYS)[number];
export const CONSTRAIN_BOOLEAN_KEYS = [
    'echoCancellation',
    'autoGainControl',
    'noiseSuppression',
    'pan',
    'tilt',
    'zoom',
] as const;
/**
 * Extends boolean constraint keys for our own implementation of the media
 * feature
 */
export const EXTENDED_CONSTRAIN_BOOLEAN_KEYS = [
    // Voice Activity Detection
    'vad',
    // Audio Signal Detection
    'asd',
    // Mixing with another track
    'mixWithAdditionalMedia',
    // Noise Suppression using our own impl
    'denoise',
] as const;
export type ExtendedConstrainBooleanKeys =
    (typeof EXTENDED_CONSTRAIN_BOOLEAN_KEYS)[number];
export type ConstrainBooleanKeys = (typeof CONSTRAIN_BOOLEAN_KEYS)[number];

/**
 * The keys from the `MediaTrackConstraintSet`
 */
export const CONSTRAINT_SET_KEYS = [
    ...CONSTRAIN_DOUBLE_KEYS,
    ...CONSTRAIN_U_LONG_KEYS,
    ...CONSTRAIN_STRING_KEYS,
    ...CONSTRAIN_BOOLEAN_KEYS,
] as const;

export const isConstrainStringKeys = (t: unknown): t is ConstrainStringKeys =>
    CONSTRAIN_STRING_KEYS.includes(t as ConstrainStringKeys);
export const isExtendedConstrainStringKeys = (
    t: unknown,
): t is ExtendedConstrainStringKeys =>
    EXTENDED_CONSTRAIN_STRING_KEYS.includes(t as ExtendedConstrainStringKeys);
export const isConstrainULongKeys = (t: unknown): t is ConstrainULongKeys =>
    CONSTRAIN_U_LONG_KEYS.includes(t as ConstrainULongKeys);
export const isExtendedConstrainULongKeys = (
    t: unknown,
): t is ExtendedConstrainULongKeys =>
    EXTENDED_CONSTRAIN_U_LONG_KEYS.includes(t as ExtendedConstrainULongKeys);
export const isConstrainDoubleKeys = (t: unknown): t is ConstrainDoubleKeys =>
    CONSTRAIN_DOUBLE_KEYS.includes(t as ConstrainDoubleKeys);
export const isExtendedConstrainDoubleKeys = (
    t: unknown,
): t is ExtendedConstrainDoubleKeys =>
    EXTENDED_CONSTRAIN_DOUBLE_KEYS.includes(t as ExtendedConstrainDoubleKeys);
export const isConstrainBooleanKeys = (t: unknown): t is ConstrainBooleanKeys =>
    CONSTRAIN_BOOLEAN_KEYS.includes(t as ConstrainBooleanKeys);
export const isExtendedConstrainBooleanKeys = (
    t: unknown,
): t is ExtendedConstrainBooleanKeys =>
    EXTENDED_CONSTRAIN_BOOLEAN_KEYS.includes(t as ExtendedConstrainBooleanKeys);

export const isMediaTrackConstraintSetKey = (
    t: string,
): t is keyof MediaTrackConstraintSet =>
    (CONSTRAINT_SET_KEYS as Readonly<string[]>).includes(t);

export const isMediaTrackConstraintsKey = (
    t: string,
): t is keyof MediaTrackConstraints =>
    isMediaTrackConstraintSetKey(t) || t === 'advanced';

export const isMediaTrackConstraints = (
    t: unknown,
): t is MediaTrackConstraints => {
    if (!t || typeof t !== 'object' || Array.isArray(t) || t === null) {
        return false;
    }

    const keys = Object.keys(t);
    return (
        !!keys.length &&
        Object.keys(t).every(key => isMediaTrackConstraintsKey(key))
    );
};

/**
 * Check if provided is `MediaDeviceInfo`
 *
 * @beta
 */
export const isMediaDeviceInfo = (t: unknown): t is MediaDeviceInfo => {
    if (!t || typeof t !== 'object') {
        return false;
    }
    return !!t && 'deviceId' in t && 'kind' in t;
};

export const isMediaDeviceInfoArray = (t: unknown): t is MediaDeviceInfo[] => {
    if (Array.isArray(t) && t.length && t.some(isMediaDeviceInfo)) {
        return true;
    }
    return false;
};

export const isDeviceConstraint = (t: unknown): t is DeviceConstraint => {
    return isMediaDeviceInfo(t) || isMediaDeviceInfoArray(t);
};

export const isConstraintDOMString = (t: unknown): t is string | string[] =>
    (typeof t === 'string' && !!t) || (Array.isArray(t) && t.some(Boolean));

const CONSTRAIN_PARAM_KEYS = ['exact', 'ideal'] as const;
export type ConstrainParamKeys = (typeof CONSTRAIN_PARAM_KEYS)[number];
const CONSTRAIN_RANGE_KEYS = ['min', 'max'] as const;
export type ConstrainRangeKeys = (typeof CONSTRAIN_RANGE_KEYS)[number];
export type ConstrainRangeParamKeys = ConstrainParamKeys | ConstrainRangeKeys;
const CONSTRAIN_KEYS = [
    ...CONSTRAIN_PARAM_KEYS,
    ...CONSTRAIN_RANGE_KEYS,
] as const;

export const isConstrainDOMParameters =
    <R>(
        isType: (x: unknown) => boolean,
        keys: readonly ConstrainRangeParamKeys[] = CONSTRAIN_PARAM_KEYS,
    ) =>
    (t: unknown): t is R => {
        if (
            !t ||
            typeof t !== 'object' ||
            t === null ||
            Object.keys(t).length <= 0
        ) {
            return false;
        }
        return keys.some(key => hasOwn(t, key) && isType(t[key]));
    };

export const isConstrainDOMStringParameters =
    isConstrainDOMParameters<ConstrainDOMStringParameters>(
        isConstraintDOMString,
    );

export const isConstrainBooleanParameters =
    isConstrainDOMParameters<ConstrainBooleanParameters>(isBoolean);

/**
 * Check if provided var is a constraint object with `min` and/or `max` key only
 */
export const isConstrainRange = isConstrainDOMParameters<ConstrainDoubleRange>(
    t => isFloat(t) || isInteger(t),
    CONSTRAIN_RANGE_KEYS,
);

export const isConstrainDoubleRange =
    isConstrainDOMParameters<ConstrainDoubleRange>(isFloat, CONSTRAIN_KEYS);

export const isConstrainULongRange =
    isConstrainDOMParameters<ConstrainULongRange>(isInteger, CONSTRAIN_KEYS);

export const isConstraintDeviceParameters =
    isConstrainDOMParameters<ConstraintDeviceParameters>(
        t => isMediaDeviceInfo(t) || isMediaDeviceInfoArray(t),
    );

export const isConstraintSetDevice = (
    t: unknown,
): t is InputConstraintSet['device'] =>
    isMediaDeviceInfo(t) ||
    isMediaDeviceInfoArray(t) ||
    isConstraintDeviceParameters(t);

type ExtendedConstraintSet = Pick<
    InputConstraintSet,
    | ExtendedConstrainULongKeys
    | ExtendedConstrainDoubleKeys
    | ExtendedConstrainStringKeys
    | ExtendedConstrainBooleanKeys
    | 'device'
>;

export const isExtendedConstraint = (
    t: unknown,
): t is ExtendedConstraintSet => {
    if (typeof t !== 'object' || t === null) {
        return false;
    }
    if (hasOwn(t, 'device')) {
        const {device} = t;
        return isConstraintSetDevice(device);
    }
    return [
        ...EXTENDED_CONSTRAIN_DOUBLE_KEYS,
        ...EXTENDED_CONSTRAIN_STRING_KEYS,
        ...EXTENDED_CONSTRAIN_U_LONG_KEYS,
        ...EXTENDED_CONSTRAIN_BOOLEAN_KEYS,
    ].some(key => hasOwn(t, key));
};

export const isInputConstraintSet = (t: unknown): t is InputConstraintSet => {
    if (typeof t !== 'object' || t === null) {
        return false;
    }
    return isExtendedConstraint(t) || isMediaTrackConstraints(t);
};

/**
 * Check if provided is `MediaStreamTrack`
 *
 * @beta
 */
export const isMediaStreamTrack = (m: unknown): m is MediaStreamTrack => {
    if (m && typeof m === 'object') {
        return !!m && 'getSettings' in m;
    }
    return false;
};

export const isFacingMode = (s: unknown): s is FacingMode => {
    if (typeof s === 'string' && FACING_MODE.includes(s as FacingMode)) {
        return true;
    }
    return false;
};
