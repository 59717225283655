export enum InfoCardWidth {
    Narrow = '280px',
    NarrowTouch = '296px',
    Wide = '664px',
    HundredPercent = '100%',
}

export enum InfoCardType {
    Meeting = 'meeting',
    Lobby = 'lobby',
}
