import {useMemo} from 'react';

import type {ImageSrc} from '../types/images';
import {
    convertSrcSetToString,
    isImageSrcEmpty,
    isImageSrcSrcSet,
    isImageSrcString,
} from '../utils/imageSrc';

export const useLogo = (imageSrc: ImageSrc) =>
    useMemo(() => {
        const isLogoSrcString = isImageSrcString(imageSrc);
        const isLogoSrcSet = isImageSrcSrcSet(imageSrc);

        let logoSrcString,
            logoSrcSet = '';

        if (!isImageSrcEmpty(imageSrc)) {
            if (isLogoSrcString) {
                logoSrcString = imageSrc;
            }
            if (isLogoSrcSet) {
                logoSrcSet = convertSrcSetToString(imageSrc);
            }
        }

        return {
            logoSrcString,
            logoSrcSet,
            isLogoSrcSet,
            isLogoSrcString,
        };
    }, [imageSrc]);
