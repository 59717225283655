import {useCallback, useRef} from 'react';

import type {ModalHandle} from '../components/modules/Modal/Modal';

export const useModalHandle = () => {
    const modalHandleRef = useRef<ModalHandle>(null);

    const focusModal = useCallback(() => {
        /*
         * using setTimeout so that the Modal's
         * DOM ref is set before the focus function
         * is called.
         */
        window.setTimeout(() => {
            modalHandleRef.current?.focus();
        }, 0);
    }, [modalHandleRef]);

    return {
        modalHandleRef,
        focusModal,
    };
};
