import React from 'react';
import cx from 'classnames';

import type {ButtonProps} from '@pexip/components';

import type {LeaveButtonDesktopProps} from './Controls/LeaveButton.view';
import {LeaveButtonDesktop} from './Controls/LeaveButton.view';
import type {VideoButtonProps} from './Controls/VideoButton.view';
import {VideoButton} from './Controls/VideoButton.view';
import type {AudioButtonProps} from './Controls/AudioButton.view';
import {AudioButton} from './Controls/AudioButton.view';

import styles from './CallControls.module.scss';

export const CallControlsDesktopView: React.FC<
    React.ComponentProps<'div'> &
        LeaveButtonDesktopProps &
        VideoButtonProps &
        AudioButtonProps
> = ({
    isMicrophoneMuted,
    isVideoMuted,
    isVideoBtnDisabled,
    isAudioBtnDisabled,
    onLeave,
    onVideoBtnClick,
    onAudioBtnClick,
    iconSize,
    ...props
}) => {
    const buttonVariant: ButtonProps['variant'] = 'neutral';

    return (
        <div
            className={cx(styles.callControlsWrapper, styles.desktopWrapper)}
            {...props}
        >
            <div className={styles.desktopMediaBtnWrapper}>
                <AudioButton
                    variant={buttonVariant}
                    isMicrophoneMuted={isMicrophoneMuted}
                    isAudioBtnDisabled={isAudioBtnDisabled}
                    onAudioBtnClick={onAudioBtnClick}
                    iconSize={iconSize}
                    tooltipClassName={styles.desktopAudioButtonTooltip}
                />
                <VideoButton
                    variant={buttonVariant}
                    isVideoMuted={isVideoMuted}
                    isVideoBtnDisabled={isVideoBtnDisabled}
                    onVideoBtnClick={onVideoBtnClick}
                    iconSize={iconSize}
                    tooltipClassName={styles.desktopVideoButtonTooltip}
                />
            </div>
            <div className={styles.desktopLeaveBtnWrapper}>
                <LeaveButtonDesktop onLeave={onLeave} />
            </div>
        </div>
    );
};

export type CallControlsDesktopProps = React.ComponentProps<
    typeof CallControlsDesktopView
>;
