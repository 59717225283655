import type {DragEvent} from 'react';
import React from 'react';
import cx from 'classnames';

import {Box} from '../Box/Box';
import type {ColorScheme} from '../../../types/variants';
import {ThemeProvider} from '../../../themes/ThemeContext';

import styles from './DragAndDrop.module.scss';

export const DragAndDrop: React.FC<
    React.PropsWithChildren<{
        className?: string;
        colorScheme: ColorScheme;
        isDisabled?: boolean;
        hasError?: boolean;
        onDrop?: (
            e: DragEvent<HTMLDivElement>,
            files?: File[],
            items?: DataTransferItem[],
        ) => void;
        onDragOver?: (e: DragEvent<HTMLDivElement>) => void;
    }>
> = ({
    className,
    isDisabled,
    children,
    colorScheme,
    hasError,
    onDrop,
    onDragOver,
    ...props
}) => {
    const onDropCallback = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (isDisabled) {
            return;
        }

        onDrop?.(
            e,
            e.dataTransfer.files && Array.from(e.dataTransfer.files),
            e.dataTransfer.items && Array.from(e.dataTransfer.items),
        );
    };

    const onDragOverCallback = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (isDisabled) {
            return;
        }

        onDragOver?.(e);
    };

    const colorTheme = hasError
        ? styles.error
        : colorScheme === 'dark'
          ? styles.dark
          : styles.light;

    return (
        <ThemeProvider colorScheme={colorScheme}>
            <Box
                className={cx(
                    styles.dragAndDropWrapper,
                    colorTheme,
                    isDisabled && styles.disabled,
                    className,
                )}
                onDrop={onDropCallback}
                onDragOver={onDragOverCallback}
                isWithShadow={false}
                {...props}
            >
                {children}
            </Box>
        </ThemeProvider>
    );
};

export type DragAndDropProps = React.ComponentProps<typeof DragAndDrop>;
