import React from 'react';
import {useTranslation} from 'react-i18next';

import {UserMenuDropContent} from '@pexip/components';

import styles from './TopUserMenuDropContent.module.scss';

export const TopUserMenuDropContent: React.FC<
    React.ComponentProps<typeof UserMenuDropContent>
> = props => {
    const {t} = useTranslation();

    return (
        <UserMenuDropContent
            position="bottomRight"
            modifier="square"
            variant="translucent"
            title={t('settings.menu-button-tooltip', 'Settings')}
            buttonLabel={t(
                'settings.menu-button-label',
                'Toggle settings menu',
            )}
            contentClassName={styles.content}
            {...props}
        />
    );
};
