import type {Dispatch, EffectCallback, SetStateAction} from 'react';
import {useEffect, useState} from 'react';

import type {MediaDeviceInfoLike} from '@pexip/media-control';

export const useSelectedInput = (
    eventHandler: (
        callback: Dispatch<SetStateAction<MediaDeviceInfoLike | undefined>>,
    ) => ReturnType<EffectCallback>,
    expectedInput?: MediaDeviceInfoLike,
) => {
    const [selectedInput, setSelectedInput] = useState<
        MediaDeviceInfoLike | undefined
    >(expectedInput);
    useEffect(() => eventHandler(setSelectedInput), [eventHandler]);

    return [selectedInput, setSelectedInput] as const;
};
