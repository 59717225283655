import React from 'react';
import cx from 'classnames';

import type {VideoWrapperSizeModifier} from '../../../types/sizes';
import type {VideoWrapperVariant} from '../../../types/variants';

import styles from './VideoWrapper.module.scss';

export const VideoWrapper = React.forwardRef<
    HTMLDivElement,
    React.ComponentProps<'div'> & {
        size?: VideoWrapperSizeModifier;
        background?: VideoWrapperVariant;
        borderRadius?: 'none' | 'box';
        border?: boolean;
        alignItems?: 'center' | 'flex-end' | 'flex-start' | 'stretch';
        flexDirection?: 'column' | 'row';
        justifyContent?: 'flex-start' | 'flex-end' | 'center';
        isFullHeight?: boolean;
        isFullWidth?: boolean;
    }
>(
    (
        {
            className,
            children,
            background = 'none',
            size = 'medium',
            borderRadius = 'box',
            border,
            alignItems,
            flexDirection,
            justifyContent,
            isFullHeight = false,
            isFullWidth = false,
            ...props
        },
        ref,
    ) => {
        const isSmall = size === 'small';
        const isLarge = size === 'large';
        const isFull = size === 'full';

        const isBorderRadiusNone = borderRadius === 'none';

        const isBackgroundNone = background === 'none';
        const isBackgroundPrimary = background === 'primary';
        const isBackgroundSecondary = background === 'secondary';
        const isBackgroundBlack = background === 'black';
        const isBackgroundLight = background === 'light';
        return (
            <div
                className={cx(
                    styles.videoWrapper,
                    {
                        [styles.small]: isSmall,
                        [styles.large]: isLarge,
                        [styles.borderRadiusNone]: isBorderRadiusNone,
                        [styles.border]: border,
                        [styles.backgroundNone]: isBackgroundNone,
                        [styles.backgroundPrimary]: isBackgroundPrimary,
                        [styles.backgroundSecondary]: isBackgroundSecondary,
                        [styles.backgroundBlack]: isBackgroundBlack,
                        [styles.backgroundLight]: isBackgroundLight,
                        [styles.alignCenter]: alignItems === 'center',
                        [styles.alignEnd]: alignItems === 'flex-end',
                        [styles.alignStart]: alignItems === 'flex-start',
                        [styles.alignStretch]: alignItems === 'stretch',
                        [styles.flexColumn]: flexDirection === 'column',
                        [styles.flexRow]: flexDirection === 'row',
                        [styles.fullHeight]: isFullHeight || isFull,
                        [styles.fullWidth]: isFullWidth || isFull,
                        [styles.justifyEnd]: justifyContent === 'flex-end',
                        [styles.justifyCenter]: justifyContent === 'center',
                        [styles.justifyStart]: justifyContent === 'flex-start',
                    },
                    className,
                    'videoWrapperGlobal',
                )}
                {...props}
                ref={ref}
            >
                {children}
            </div>
        );
    },
);

VideoWrapper.displayName = 'VideoWrapper';
