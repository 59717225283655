import {UAParser} from 'ua-parser-js';

export const BROWSER_NAME = {
    Chrome: 'Chrome',
    Firefox: 'Firefox',
    Opera: 'Opera',
    Yandex: 'Yandex',
    Safari: 'Safari',
    InternetExplorer: 'Internet Explorer',
    Edge: 'Edge',
    Chromium: 'Chromium',
    Ie: 'IE',
    MobileSafari: 'Mobile Safari',
    EdgeChromium: 'Edge Chromium',
    MIUI: 'MIUI Browser',
    SamsungBrowser: 'Samsung Browser',
} as const;

export const SYSTEM_NAME = {
    IOS: 'iOS',
    Android: 'Android',
    WindowsPhone: 'Windows Phone',
    Windows: 'Windows',
    MAC_OS: 'Mac OS',
} as const;

export const DEVICE_TYPE = {
    Mobile: 'mobile',
    Tablet: 'tablet',
    SmartTv: 'smarttv',
    Console: 'console',
    Wearable: 'wearable',
    Embedded: 'embedded',
} as const;

export const I_PAD = 'iPad';

/**
 * Data set of user agent details and and boolean properties for quick browser and device checks
 */
export interface UserAgentsDetails {
    readonly browserName?: string;
    readonly browserVersion?: string;
    readonly systemName?: string;
    readonly systemVersion?: string;
    readonly deviceType?: string;
    readonly deviceVendor?: string;
    readonly deviceModel?: string;
    readonly isTouch: boolean;
    readonly isFirefox: boolean;
    readonly isChrome: boolean;
    readonly isEdge: boolean;
    readonly isSafari: boolean;
    readonly isSafariMobile: boolean;
    readonly isAndroid: boolean;
    readonly isMacOS: boolean;
    readonly isIOS: boolean;
    readonly isWindows: boolean;
    readonly isMobile: boolean;
    readonly isTablet: boolean;
    readonly isDesktop: boolean;
    /**
     * If the user agent does not contain an "iPad specific" string the detection is a guess based on user agent and touch capabilities
     */
    readonly isIPad: boolean;
}

/**
 * Returns a data set of user agent details and properties for quick browser and device checks
 * @param userAgent - The user agent string provided by client
 * @returns Returns a data set of user agent details and boolean properties for quick browser and device checks based on `userAgent`
 */
export const getUserAgentDetails = (userAgent: string): UserAgentsDetails => {
    // Init UA Parser
    const parser: UAParser.UAParserInstance = new UAParser();
    parser.setUA(userAgent);

    // Read metadata
    const {name: browserName, version: browserVersion} = parser.getBrowser();
    const {name: systemName, version: systemVersion} = parser.getOS();
    const {
        type: deviceType,
        vendor: deviceVendor,
        model: deviceModel,
    } = parser.getDevice();

    // Browser check
    const verifyFireFox = () => {
        return browserName === BROWSER_NAME.Firefox;
    };
    const verifyChrome = () => {
        return browserName === BROWSER_NAME.Chrome;
    };
    const verifySafari = () => {
        return browserName === BROWSER_NAME.Safari;
    };
    const verifyMobileSafari = () => {
        return browserName === BROWSER_NAME.MobileSafari;
    };
    const verifyEdge = () => {
        return browserName === BROWSER_NAME.Edge;
    };

    // System check
    const verifyAndroid = () => {
        return systemName === SYSTEM_NAME.Android;
    };
    const verifyMacMos = () => {
        return systemName === SYSTEM_NAME.MAC_OS;
    };
    const verifyIOs = () => {
        return systemName === SYSTEM_NAME.IOS;
    };

    const verifyWindows = () => {
        return systemName == SYSTEM_NAME.Windows;
    };

    // Device check
    const verifyMobile = () => {
        return deviceType === DEVICE_TYPE.Mobile;
    };
    const verifyTablet = () => {
        return deviceType === DEVICE_TYPE.Tablet;
    };
    const verifyIpad = () => {
        return (
            deviceModel === I_PAD ||
            ((verifyMacMos() || verifyIOs()) &&
                (verifyTablet() || verifyTouchDevice()))
        );
    };
    const verifyDesktop = () => {
        return deviceType === undefined;
    };

    return {
        // Metadata
        browserName,
        browserVersion,
        systemName,
        systemVersion,
        deviceType,
        deviceVendor,
        deviceModel,
        get isTouch() {
            return verifyTouchDevice();
        },
        // Browser check
        get isFirefox() {
            return verifyFireFox();
        },
        get isChrome() {
            return verifyChrome();
        },
        get isEdge() {
            return verifyEdge();
        },
        get isSafari() {
            return verifySafari();
        },
        get isSafariMobile() {
            return verifyMobileSafari();
        },
        // System check
        get isAndroid() {
            return verifyAndroid();
        },
        get isMacOS() {
            return verifyMacMos();
        },
        get isIOS() {
            return verifyIOs();
        },
        get isWindows() {
            return verifyWindows();
        },
        // Device check
        get isMobile() {
            return verifyMobile();
        },
        get isTablet() {
            return verifyTablet();
        },
        get isIPad() {
            return verifyIpad();
        },
        get isDesktop() {
            return verifyDesktop();
        },
    };
};

const verifyTouchDevice = () => {
    let result = false;
    if (window.PointerEvent && 'maxTouchPoints' in navigator) {
        // if Pointer Events are supported, just check maxTouchPoints
        if (navigator.maxTouchPoints > 0) {
            result = true;
        }
    } else {
        // no Pointer Events...
        if (window.matchMedia?.('(any-pointer:coarse)').matches) {
            // check for any-pointer:coarse which mostly means touchscreen
            result = true;
        } else if (window.TouchEvent || 'ontouchstart' in window) {
            // last resort - check for exposed touch events API / event handler
            result = true;
        }
    }
    return result;
};
