/**
 * An implementation of {@link https://en.wikipedia.org/wiki/Signals_and_slots | Signals and slots}.
 *
 * @packageDocumentation
 */

import {logger, flags} from './logger';
import {createBuffer} from './buffer';
import type {Observer, Signal, SignalOptions} from './types';

const NAMELESS_OBSERVER = 'Anonymous';
const NAMELESS_SIGNAL = 'AnonymousSignal';

function getName<T>(observer: Observer<T>) {
    return observer.name || NAMELESS_OBSERVER;
}

/**
 * A function to create a new {@link Signal}
 *
 * @param options - See {@link SignalOptions}
 *
 * @example
 *
 * ```typescript
 * const defaultSignal = createSignal<number>();
 *
 * // Behavior Signal, always emitted with the latest value when added
 * const behaviorSignal = createSignal<number>({variant: 'behavior'});
 *
 * // Replay Signal, replay the last values when added according to `bufferSize`
 * const replaySignal = createSignal<number>({variant: 'replay'});
 * ```
 */
export function createSignal<T = undefined>(
    options: SignalOptions = {},
): Signal<T> {
    const {
        allowEmittingWithoutObserver = false,
        variant = 'generic',
        bufferSize = 2,
        name = NAMELESS_SIGNAL,
    } = options;

    // Slots
    const observers = new Set<Observer<T>>();
    const contexts = new WeakMap<Observer<T>, ThisParameterType<Observer<T>>>();
    const onces = new WeakSet<Observer<T>>();
    const buffers =
        variant !== 'generic'
            ? createBuffer<T | undefined>(
                  variant === 'behavior' ? 1 : bufferSize,
              )
            : undefined;

    function emitOne(observer: Observer<T>, subject?: T) {
        try {
            const context = contexts.get(observer);
            logger.trace(
                {signal: name, subject, observer, context},
                `Emitting a subject to observer ${getName(observer)}`,
            );
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-invalid-void-type -- Needs any[] to accept optional args
            observer.call<unknown, any[], void>(context, subject);
            if (onces.has(observer)) {
                remove(observer);
            }
        } catch (e: unknown) {
            logger.error(
                {signal: name, error: e, observer, subject},
                `emit with error for observer ${getName(observer)}`,
            );
            if (e instanceof RangeError) {
                throw new RangeError(
                    `RangeError: Possible recursive call when calling ${getName(
                        observer,
                    )} for ${name}`,
                );
            }
            throw e;
        }
    }

    const add = (
        observer: Observer<T>,
        context?: ThisParameterType<Observer<T>>,
    ) => {
        if (observers.has(observer)) {
            const msg = `Observer ${getName(observer)} has already been added!`;
            logger.error({signal: name, observer}, msg);
            throw new Error(`DuplicatedObserver: ${msg}`);
        }
        logger.trace(
            {signal: name, observer},
            `Adding ${getName(observer)} to ${name}`,
        );
        observers.add(observer);
        if (context) {
            contexts.set(observer, context);
        }

        buffers?.forEach(subject => {
            emitOne(observer, subject);
        });

        return () => remove(observer);
    };

    const addOnce = (
        observer: Observer<T>,
        context?: ThisParameterType<Observer<T>>,
    ) => {
        if (onces.has(observer)) {
            const msg = `${getName(
                observer,
            )} has already been added once to ${name}!`;
            logger.error({signal: name, observer}, msg);
            throw new Error(`NoOnceAgain: ${msg}`);
        }
        onces.add(observer);
        return add(observer, context);
    };

    const remove = (observer: Observer<T>) => {
        if (!observers.delete(observer)) {
            logger.error(
                {signal: name, observer},
                `Unable to remove observer ${getName(observer)}`,
            );
            throw new Error(`UnableToRemove: ${getName(observer)}`);
        }
        onces.delete(observer);
        contexts.delete(observer);

        logger.trace(
            {signal: name, observer},
            `Removed ${getName(observer)} from ${name}`,
        );
    };

    const size = () => observers.size;

    function emit(subject?: T) {
        // Buffer the subject
        if (buffers) {
            buffers.add(subject);
        } else if (!observers.size && !allowEmittingWithoutObserver) {
            const {stack} = flags.debug ? new Error() : {stack: undefined};
            logger.warn(
                {signal: name, subject, stack},
                `Emitting ${name} without any observer! This may be a mistake.`,
            );
        }

        observers.forEach(obs => {
            emitOne(obs, subject);
        });
    }

    return {
        name,
        get size() {
            return size();
        },
        add,
        addOnce,
        remove,
        emit,
    };
}
