import React from 'react';
import cx from 'classnames';

import type {ColorScheme, TextVariant} from '../../../types/variants';
import type {HeadingElement} from '../../../types/elements';
import type {FontVariant} from '../../../../design-tokens/constants';
import {ThemeConsumer} from '../../../themes/ThemeContext';
import styles from '../Text/Text.module.scss';

export const TextHeading: React.FC<
    React.ComponentProps<HeadingElement> & {
        className?: string;
        colorScheme?: ColorScheme;
        htmlTag: HeadingElement;
        fontVariant?: FontVariant;
        isTruncated?: boolean;
        isUppercase?: boolean;
        variant?: TextVariant;
    }
> = ({
    children,
    className,
    colorScheme,
    fontVariant,
    htmlTag: headingLevel,
    isTruncated = false,
    isUppercase,
    variant = 'standard',
    ...props
}) => {
    const fontVariantStyle = fontVariant ?? headingLevel;

    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) =>
                React.createElement(
                    headingLevel,
                    {
                        className: cx(
                            styles[colorScheme ?? defaultColorScheme],
                            styles[variant],
                            styles[fontVariantStyle],
                            {
                                [styles.truncated]: isTruncated,
                                [styles.uppercase]: isUppercase,
                            },
                            className,
                        ),
                        ...props,
                    },
                    children,
                )
            }
        </ThemeConsumer>
    );
};

export type TextHeadingProps = React.ComponentProps<typeof TextHeading>;
