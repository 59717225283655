import {useCallback} from 'react';

import type {BreakoutParticipants} from '../types';

export const useBreakoutChangeParticipantRoom = ({
    participants,
    setParticipants,
}: {
    participants: BreakoutParticipants;
    setParticipants: React.Dispatch<React.SetStateAction<BreakoutParticipants>>;
}) =>
    useCallback(
        (targetParticipantUuid: string, targetRoomId?: string) => {
            participants.forEach((roomParticipants, roomId) => {
                roomParticipants.forEach((participant, pIndex) => {
                    if (participant.identity === targetParticipantUuid) {
                        setParticipants(participants => {
                            const newParticipants = new Map(
                                participants.entries(),
                            );
                            const newRoomParticipants = [...roomParticipants];
                            // remove participant from original room
                            newRoomParticipants?.splice(pIndex, 1);
                            newParticipants.set(roomId, newRoomParticipants);
                            if (targetRoomId) {
                                // put participant in new room
                                const targetRoomParticipants = [
                                    ...(newParticipants.get(targetRoomId) ??
                                        []),
                                ];
                                targetRoomParticipants.push(participant);
                                newParticipants.set(
                                    targetRoomId,
                                    targetRoomParticipants,
                                );
                            }
                            return newParticipants;
                        });
                    }
                });
            });
        },
        [participants, setParticipants],
    );
