import React, {useEffect, useState} from 'react';
import cx from 'classnames';

import {
    getCalculateAudioMeterStateFn,
    getLinearGradient,
} from '../../utils/audioMeter';

import {InputControl} from './InputControl.view';

import styles from './AudioMeterButton.module.scss';

const calculateAudioMeterState = getCalculateAudioMeterStateFn();

export const AudioMeterButton: React.FC<
    React.ComponentProps<typeof InputControl> & {
        level?: number;
        isMuted: boolean;
    }
> = ({level, hasAudioVideoError, hasError, isMuted, ...props}) => {
    const [linearGradient, setLinearGradient] = useState('');
    const [isHoveringButton, setIsHoveringButton] = useState(false);

    useEffect(() => {
        let ignore = false;

        void calculateAudioMeterState(level)
            .then(audioState => {
                if (ignore) {
                    return;
                }

                setLinearGradient(
                    getLinearGradient({
                        ...audioState,
                        enabled: !isMuted,
                        isHoveringButton,
                    }),
                );
            })
            .catch(() => {
                // prevents console promise error on cancel (reject)
            });

        return () => {
            ignore = true;
            calculateAudioMeterState?.cancel();
        };
    }, [isMuted, isHoveringButton, level]);

    return (
        <InputControl
            style={
                !(hasAudioVideoError || hasError)
                    ? {
                          backgroundImage: linearGradient,
                      }
                    : {}
            }
            className={cx(styles[!isMuted ? 'enabled' : 'disabled'])}
            variant="transparentAlternative"
            size="large"
            hasAudioVideoError={hasAudioVideoError}
            hasError={hasError}
            onMouseEnter={() => setIsHoveringButton(true)}
            onMouseLeave={() => setIsHoveringButton(false)}
            {...props}
        />
    );
};
