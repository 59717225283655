// MenuButton.tsx

import type {ReactElement} from 'react';
import React from 'react';
import type {CollectionChildren, Key} from '@react-types/shared';
import {useMenuTriggerState} from '@react-stately/menu';
import type {Item} from '@react-stately/collections';
import {useMenuTrigger} from '@react-aria/menu';
import {useButton} from '@react-aria/button';
import {mergeProps} from '@react-aria/utils';
import cx from 'classnames';
import type {Placement} from '@react-aria/overlays';

import {RefButton, type Button} from '../../elements/Button/Button';
import type {ColorScheme} from '../../../types/variants';

import type {AriaMenuItem} from './AriaMenu';
import {InnerAriaMenu} from './InnerAriaMenu';
import {InnerAriaMenuPopover} from './InnerAriaMenuPopover';

import styles from './AriaMenu.module.scss';

export const InnerAriaMenuButton: React.FC<{
    button: ReactElement<typeof Button>;
    onOpenChange?: (isOpen: boolean) => void;
    children: CollectionChildren<typeof Item>;
    itemMap: Map<Key, AriaMenuItem>;
    testId?: string;
    colorScheme?: ColorScheme;
    popoverPlacement?: Placement;
    popoverOffset?: number;
}> = ({
    button,
    onOpenChange,
    children,
    itemMap,
    testId,
    colorScheme,
    popoverPlacement,
    popoverOffset,
}) => {
    const state = useMenuTriggerState({onOpenChange});

    const DEFAULT_OFFSET = 8;

    const ref = React.useRef(null);

    const processOnAction = (key: Key) => {
        const item = itemMap.get(key);
        if (item) {
            item.onItemClick?.();
            if (item.closeMenuOnItemClick !== false) {
                state.close();
            }
        }
    };

    const {menuTriggerProps, menuProps} = useMenuTrigger<typeof Item>(
        {type: 'menu'},
        state,
        ref,
    );

    const {buttonProps} = useButton(menuTriggerProps, ref);

    const enrichButton = () => {
        if (React.isValidElement(button)) {
            const refButton = (
                <RefButton {...button.props} ref={ref}></RefButton>
            );
            return React.cloneElement(refButton, {
                ...mergeProps(buttonProps, menuTriggerProps),
                ref: ref,
            } as React.Attributes);
        }
        return button;
    };

    const processedButton = enrichButton();

    return (
        <div className={cx(styles.innerMenuButton)}>
            {processedButton}
            {state.isOpen && (
                <InnerAriaMenuPopover
                    state={state}
                    triggerRef={ref}
                    colorScheme={colorScheme}
                    placement={popoverPlacement ?? 'bottom start'}
                    offset={popoverOffset ?? DEFAULT_OFFSET}
                >
                    <InnerAriaMenu
                        itemMap={itemMap}
                        {...menuProps}
                        // Set onClose to undefine. This will prevent the menu from closing when the user clicks an item.
                        // The decision can be made in the onAction callback for each item individually.
                        onClose={undefined}
                        onAction={processOnAction}
                        testId={testId}
                    >
                        {children}
                    </InnerAriaMenu>
                </InnerAriaMenuPopover>
            )}
        </div>
    );
};
