import {hasOwn} from '@pexip/utils';

import type {SSOdMessage, MediaPriority} from './types';

export const isUndefined = <T>(t: T | undefined): t is undefined =>
    typeof t === 'undefined';

export const isNull = <T>(t: T | undefined | null): t is null =>
    typeof t === 'object' && !t;

export const isNotUndefined = <T>(t: T | undefined | null): t is T =>
    !isUndefined(t) && !isNull(t);

export const isSSOdMessage = (e: unknown): e is SSOdMessage => {
    if (typeof e !== 'object') {
        return false;
    }
    if (e === null) {
        return false;
    }
    if ('result' in e && ('token' in e || 'code' in e)) {
        return true;
    }
    return false;
};

const RTC_PRIORITY = ['very-low', 'low', 'medium', 'high'] as const;

export const isMediaPriority = (e: unknown): e is MediaPriority => {
    if (!e || typeof e !== 'object') {
        return false;
    }
    if (hasOwn(e, 'video') && hasOwn(e, 'audio') && hasOwn(e, 'presentation')) {
        return !Object.values(e).some(
            e => !RTC_PRIORITY.includes(e as RTCPriorityType),
        );
    }
    return false;
};
