import {useEffect, useState} from 'react';

import {Quality} from '@pexip/peer-connection-stats';
import type {Signal} from '@pexip/signal';

import type {StreamQuality} from '../types';
import {NetworkState} from '../types';
import {logger} from '../logger';

export const useCallQuality = ({
    getStreamQuality,
    callQualitySignal,
    networkState = NetworkState.Connected,
}: {
    getStreamQuality: () => StreamQuality;
    callQualitySignal: Signal<[Quality, Quality]>;
    networkState?: NetworkState;
}) => {
    const [quality, setQuality] = useState(Quality.GOOD);

    useEffect(() => {
        if (networkState !== NetworkState.Reconnecting) {
            return callQualitySignal.add(
                ([incomingQuality, outgoingQuality]) => {
                    const streamQuality = getStreamQuality();
                    logger.debug(
                        {
                            networkState,
                            incomingQuality,
                            outgoingQuality,
                            streamQuality,
                            stats: window.pexDebug?.stats,
                        },
                        'Call quality changed',
                    );

                    setQuality(
                        calculateMaxQuality(incomingQuality, outgoingQuality),
                    );
                },
            );
        }
    }, [networkState, getStreamQuality, callQualitySignal]);

    return quality;
};

const calculateMaxQuality = (inQuality = 0, outQuality = 0) =>
    Math.max(inQuality, outQuality);
