import React from 'react';
import cx from 'classnames';

import type {
    StandardSizeModifier,
    VideoWrapperSizeModifier,
} from '@pexip/components';
import {CenterLayout, Avatar, AvatarType} from '@pexip/components';

import styles from './Selfview.module.scss';

const UserAvatar: React.FC<
    React.PropsWithChildren<{
        displayName?: string;
        size: StandardSizeModifier;
    }>
> = ({displayName = 'Guest', size}) => (
    <CenterLayout>
        <Avatar
            type={AvatarType.Initials}
            translate="no"
            displayName={displayName}
            sizeModifier={size}
        />
    </CenterLayout>
);

export const SelfviewOverlay: React.FC<
    React.PropsWithChildren<{
        className?: string;
        showPlaceholder: boolean;
        sizeModifier?: VideoWrapperSizeModifier;
        username?: string;
        isVideoInitiallyShown?: boolean;
        isPhone?: boolean;
        isFlipped?: boolean;
        isMobileDevice?: boolean;
        showVideo?: boolean;
        showShadow?: boolean;
    }>
> = ({
    className,
    children,
    isPhone,
    showVideo,
    isMobileDevice,
    isVideoInitiallyShown,
    isFlipped,
    showPlaceholder,
    username,
    sizeModifier,
    showShadow = false,
}) => (
    <div
        className={cx(styles.overlayWrapper, className, {
            [styles.overlayWrapperShadow]: showShadow,
        })}
    >
        {showVideo && isMobileDevice && isVideoInitiallyShown && (
            <div
                className={cx(styles.flipper, {
                    [styles.flipped]: isFlipped,
                })}
            />
        )}

        <div
            className={cx(
                styles.childWrapper,
                'd-flex flex-direction-column align-items-stretch',
                {
                    'justify-content-center': isPhone,
                    'justify-content-end': !isPhone,
                },
            )}
        >
            {showPlaceholder && (
                <UserAvatar
                    displayName={username}
                    size={sizeModifier === 'small' ? sizeModifier : 'medium'}
                />
            )}
            {children}
        </div>
    </div>
);
