import React from 'react';
import cx from 'classnames';

import {Group} from '@pexip/components';

import styles from '../styles/CoreHeader.module.scss';

export const NonMeetingHeaderEndEnhancer: React.FC<{
    userMenu: React.ReactElement;
}> = ({userMenu}) => (
    <Group
        spacing="small"
        className={cx(styles.headerEnhancer, styles.enhancerEnd)}
    >
        {userMenu}
    </Group>
);
