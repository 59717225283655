import React, {useCallback} from 'react';

import {MeetingSettings} from '../views/MeetingSettings/MeetingSettings.view';
import {useLayoutOverlayEnabled, useMeetingContext} from '../hooks/meeting';
import {useConferenceStatus} from '../hooks/useConferenceStatus';

export const InMeetingSettings: React.FC<{
    onBackClick: () => void;
}> = ({onBackClick}) => {
    const meeting = useMeetingContext();
    const conferenceStatus = useConferenceStatus(
        meeting.getConferenceStatus,
        meeting.getRoomId,
    );
    const isEnableOverlayText = useLayoutOverlayEnabled(
        meeting.getLayoutOverlayEnabled,
    );

    const toggleLock = useCallback(() => {
        meeting.lock(!conferenceStatus?.locked);
    }, [meeting, conferenceStatus?.locked]);

    return (
        <MeetingSettings
            toggleLock={toggleLock}
            toggleEnableOverlayText={meeting.toggleLayoutOverlayTextEnabled}
            startConference={meeting.startConference}
            isLocked={Boolean(conferenceStatus?.locked)}
            isStarted={Boolean(conferenceStatus?.started)}
            isEnableOverlayText={isEnableOverlayText}
            onBackClick={onBackClick}
        />
    );
};
