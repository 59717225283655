import React from 'react';
import {useTranslation} from 'react-i18next';

import type {ButtonProps, LogoSizeModifier} from '@pexip/components';
import {Icon, IconTypes} from '@pexip/components';

import {InMeetingInputControl} from '../../InputControl/InMeetingInputControl.view';
import {TestId} from '../../../../test/testIds';

export const VideoButton: React.FC<
    ButtonProps & {
        isVideoMuted: boolean;
        onVideoBtnClick: () => void;
        isVideoBtnDisabled?: boolean;
        iconSize: LogoSizeModifier;
        tooltipClassName?: string;
    }
> = ({
    isVideoMuted,
    onVideoBtnClick,
    isVideoBtnDisabled = false,
    iconSize,
    tooltipClassName,
    ...props
}) => {
    const {t} = useTranslation();
    const videoInputAction = isVideoMuted
        ? t('media.turn-camera-on', 'Turn camera on')
        : t('media.turn-camera-off', 'Turn camera off');

    const testId = isVideoMuted
        ? TestId.ButtonMeetingVideoInputMuted
        : TestId.ButtonMeetingVideoInput;

    return (
        <InMeetingInputControl
            isDisabled={isVideoBtnDisabled}
            isActive={isVideoMuted}
            onClick={onVideoBtnClick}
            tooltipPosition="top"
            tooltipText={videoInputAction}
            tooltipClassName={tooltipClassName}
            data-testid={testId}
            aria-label={videoInputAction}
            icon={
                isVideoMuted ? (
                    <Icon source={IconTypes.IconVideoOff} size={iconSize} />
                ) : (
                    <Icon source={IconTypes.IconVideoOn} size={iconSize} />
                )
            }
            {...props}
        />
    );
};

export type VideoButtonProps = React.ComponentProps<typeof VideoButton>;
