import cx from 'classnames';
import React from 'react';

import {TestId} from '../../.././utils/testIds';
import {Divider} from '../Divider/Divider';
import type {StandardSizeModifier} from '../../../types/sizes';

import styles from './CoreHeader.module.scss';

export const CoreHeader: React.FC<
    React.ComponentProps<'div'> & {
        enhancerStart?: React.ReactElement;
        enhancerMiddle?: React.ReactElement;
        enhancerEnd?: React.ReactElement;
        enhancerFixedWidth?: boolean;
        hasBottomBorder?: boolean;
        position?: 'relative' | 'absolute';
        size?: StandardSizeModifier;
    }
> = ({
    enhancerStart,
    enhancerMiddle,
    enhancerEnd,
    enhancerFixedWidth = false,
    hasBottomBorder = true,
    position = 'relative',
    className,
    size = 'large',
    ...props
}) => (
    <div
        className={cx(
            styles.headerWrapper,
            styles[position],
            size && styles[size],
            className,
        )}
        data-testid={TestId.HeaderCore}
        {...props}
    >
        <div
            className={styles.headerInnerWrapper}
            data-testid={TestId.HeaderCoreEnhancers}
        >
            {enhancerFixedWidth ? (
                <>
                    <div className={styles.start}>{enhancerStart}</div>
                    {enhancerMiddle && (
                        <div className={styles.center}>{enhancerMiddle}</div>
                    )}
                    <div className={styles.end}>{enhancerEnd}</div>
                </>
            ) : (
                <>
                    {enhancerStart}
                    {enhancerMiddle}
                    {enhancerEnd}
                </>
            )}
        </div>
        {hasBottomBorder && <Divider data-testid={TestId.HeaderCoreDivider} />}
    </div>
);
