import React, {useContext} from 'react';

import {ConfirmationModal} from '@pexip/components';
import type {PopupRequest} from '@pexip/plugin-api';

import {Channels, Plugins} from '..';
import {usePopupRequest} from '../hooks/usePopupRequest';

export const PluginPrompts = () => {
    const channelsRef = useContext(Channels);
    const plugins = useContext(Plugins);
    const openPopup = usePopupRequest();
    const prompts = Object.values(plugins).flatMap(plugin => plugin.prompts);

    const sendInput = ({
        chanId,
        modalId,
        input,
        opensPopup,
    }: {
        chanId: string;
        modalId: string;
        input: string;
        opensPopup?: PopupRequest;
    }) => {
        if (opensPopup) {
            openPopup(chanId, opensPopup, {action: input});
        }
        channelsRef?.current.get(chanId)?.sendEvent({
            event: 'ui:prompt:input',
            payload: {
                modalId: modalId,
                input: input,
            },
        });
    };

    return (
        <>
            {prompts.map(
                ({chanId, id, title, description, prompt, opensPopup}) => {
                    return (
                        <Prompt
                            key={id}
                            primaryAction={prompt.primaryAction}
                            secondaryAction={prompt.secondaryAction}
                            title={title}
                            description={description}
                            onInput={input =>
                                sendInput({
                                    chanId,
                                    modalId: id,
                                    input,
                                    opensPopup,
                                })
                            }
                        />
                    );
                },
            )}
        </>
    );
};

const Prompt: React.FC<{
    primaryAction: string;
    secondaryAction?: string;
    title: string;
    description?: string;
    onInput: (decision: string) => void;
}> = ({primaryAction, secondaryAction, title, description, onInput}) => {
    return secondaryAction ? (
        <ConfirmationModal
            cancelButtonText={secondaryAction}
            onCancel={() => onInput(secondaryAction)}
            confirmButtonText={primaryAction}
            onConfirm={() => onInput(primaryAction)}
            title={title}
            description={description}
            descriptionWordBreak="overflowWrap"
            isOpen
            uniqueTitle={title}
        />
    ) : (
        <ConfirmationModal
            confirmButtonText={primaryAction}
            onConfirm={() => onInput(primaryAction)}
            title={title}
            description={description}
            descriptionWordBreak="overflowWrap"
            isOpen
            uniqueTitle={title}
        />
    );
};
