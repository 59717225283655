import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {FontVariant, Text} from '@pexip/components';

export const YouCanSayTitle: React.FC<{
    className?: string;
    titleVariant?: FontVariant;
    countTitleVariant?: FontVariant;
}> = ({
    className,
    titleVariant = FontVariant.H6,
    countTitleVariant = FontVariant.H5,
}) => {
    const {t} = useTranslation();

    return (
        <div className={className}>
            <Text fontVariant={titleVariant}>
                <Trans t={t} i18nKey="media.you-can-say-something-like">
                    You can say something like{' '}
                </Trans>
                <Text fontVariant={countTitleVariant}>
                    {t('media.testing-my-microphone', '"Testing 1, 2, 3"')}
                </Text>
            </Text>
        </div>
    );
};
