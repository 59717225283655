import {useEffect, useState} from 'react';

export const useDelayedVisibility = (isVisible: boolean, timeout: number) => {
    const [areChildrenVisible, setAreChildrenVisible] = useState(isVisible);

    useEffect(() => {
        let timeoutId: number;

        if (!isVisible) {
            timeoutId = window.setTimeout(() => {
                setAreChildrenVisible(false);
            }, timeout);
        } else {
            setAreChildrenVisible(true);
        }

        return () => window.clearTimeout(timeoutId);
    }, [isVisible, timeout]);

    return areChildrenVisible;
};
