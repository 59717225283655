import React from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import type {IScrollbars} from '@pexip/components';
import {Scrim} from '@pexip/components';

import {scrollToLastMessage} from '../../utils';
import type {ChatMessage} from '../../types';
import {TestId} from '../../../test/testIds';
import {ChatScrollbars} from '../ChatScrollbars/ChatScrollbars.view';
import {ChatPanelMessages} from '../ChatMessage/ChatPanelMessages.view';
import {ChatPanelInput} from '../ChatPanelInput/ChatPanelInput.view';
import {NewChatMessagePill} from '../NewChatMessagePill/NewChatMessagePill.view';

import styles from './ChatPanel.module.scss';

export const ChatPanelView: React.FC<{
    closeNewChatMessage: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
    displayNewMessageToast: boolean;
    isLastMessageVisible: boolean;
    handleSubmit: (message: string) => void;
    messages: ChatMessage[];
    newMessagesCount: number;
    onScroll: () => void;
    scrollbarRef: React.RefObject<IScrollbars>;
}> = ({
    closeNewChatMessage,
    displayNewMessageToast,
    isLastMessageVisible,
    messages,
    newMessagesCount,
    onScroll,
    scrollbarRef,
    handleSubmit,
}) => {
    const {t} = useTranslation();

    return (
        <div data-testid={TestId.ChatPanel} className={styles.chatWrapper}>
            <div className={styles.chatScrollbarsWrapper}>
                {messages.length > 0 && (
                    <ChatScrollbars ref={scrollbarRef} onScroll={onScroll}>
                        <ChatPanelMessages messages={messages} />
                    </ChatScrollbars>
                )}
                <Scrim
                    position="bottom"
                    className={cx(styles.scrim, {
                        [styles.visible]: !isLastMessageVisible,
                    })}
                />
            </div>
            <ChatPanelInput
                onSubmit={handleSubmit}
                placeholder={t('meeting.write-message', 'Write a message')}
            />
            {displayNewMessageToast && (
                <div className={styles.newMessagesPillWrapper}>
                    <NewChatMessagePill
                        newMessagesCount={newMessagesCount}
                        scrollToLastMessage={() =>
                            scrollToLastMessage(scrollbarRef)
                        }
                        closeNewChatMessage={closeNewChatMessage}
                    />
                </div>
            )}
        </div>
    );
};
