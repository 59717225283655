import React from 'react';
import cx from 'classnames';

import {Icon, IconTypes} from '../../elements/Icon/Icon';
import {Text} from '../../elements/Text/Text';
import {Pill} from '../../elements/Pill/Pill';

import styles from './InfoMessage.module.scss';

export const InfoMessage: React.FC<{className?: string; text?: string}> = ({
    className,
    text,
    ...props
}) => (
    <Pill
        className={cx('align-items-center', styles.pill, className)}
        {...props}
    >
        <div className={styles.iconWrapper}>
            <Icon
                className={styles.icon}
                source={IconTypes.IconInfoRound}
                colorScheme="light"
            />
        </div>
        <div className={styles.textWrapper}>
            <Text colorScheme="light" className={styles.text}>
                {text}
            </Text>
        </div>
    </Pill>
);

export type InfoMessageProps = React.ComponentProps<typeof InfoMessage>;
