import React from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {IconTypes, Bar} from '@pexip/components';
import type {Quality} from '@pexip/peer-connection-stats';

import {SelfviewButton} from '../../SelfviewButton/SelfviewButton.view';
import {stopMouseEventPropagation} from '../../../utils/domEventsHandles';
import {TestId} from '../../../../test/testIds';

import styles from './InMeetingSelfview.module.scss';

export const InMeetingSelfviewFooter: React.FC<{
    isAudioInputMuted?: boolean;
    isAudioInputMissing?: boolean;
    isMobileDevice?: boolean;
    quality: Quality;
    areEffectsEnabled: boolean;
    areEffectsApplied: boolean;
    onToggleAudioClick: () => void;
    toggleFacingMode?: () => void;
    openEffectsModal: () => void;
}> = ({
    isAudioInputMuted,
    isAudioInputMissing,
    isMobileDevice,
    areEffectsEnabled,
    areEffectsApplied,
    onToggleAudioClick,
    toggleFacingMode,
    openEffectsModal,
}) => {
    const {t} = useTranslation();

    return (
        <Bar className={styles.footer} position="absoluteBottom">
            <SelfviewButton
                ariaLabel={
                    isAudioInputMuted
                        ? t('media.unmute-microphone', 'Unmute microphone')
                        : t('media.mute-microphone', 'Mute microphone')
                }
                onClick={onToggleAudioClick}
                isActive={isAudioInputMuted}
                variant={isAudioInputMuted ? 'neutral' : 'translucent'}
                isDisabled={isAudioInputMissing}
                source={
                    isAudioInputMuted
                        ? IconTypes.IconMicrophoneOff
                        : IconTypes.IconMicrophoneOn
                }
                onPointerDownCapture={stopMouseEventPropagation(true)}
                isMobileDevice={isMobileDevice}
                className={cx(isMobileDevice && 'mr-1')}
            />
            {isMobileDevice && (
                <SelfviewButton
                    onClick={toggleFacingMode}
                    source={IconTypes.IconFlipCamera}
                    isMobileDevice={isMobileDevice}
                    iconClassName={styles.flipCameraIcon}
                />
            )}
            {areEffectsEnabled && (
                <SelfviewButton
                    ariaLabel={t(
                        'media.background-effects-action',
                        'Change background effect',
                    )}
                    onClick={openEffectsModal}
                    isActive={areEffectsApplied}
                    source={IconTypes.IconBackgroundBlur}
                    onPointerDownCapture={stopMouseEventPropagation(true)}
                    isMobileDevice={isMobileDevice}
                    className={cx(isMobileDevice && 'ml-1')}
                    data-testid={
                        areEffectsApplied
                            ? TestId.ButtonMeetingBackgroundEffectsOn
                            : TestId.ButtonMeetingBackgroundEffectsOff
                    }
                />
            )}
        </Bar>
    );
};
