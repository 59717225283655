import React from 'react';

import {mobileGetStartedNextSignal} from '../signals/MeetingFlow.signals';
import {BrandedInfoCard} from '../viewModels/BrandedInfoCard.viewModel';
import {MobileGetStartedStep} from '../views/MobileGetStartedStep/MobileGetStartedStep.view';

export const MobileGetStarted: React.FC = () => (
    <MobileGetStartedStep
        brandedInfoCard={<BrandedInfoCard />}
        onNextClick={() => {
            mobileGetStartedNextSignal.emit();
        }}
    />
);
