import {useSyncExternalStore} from 'react';

import {infinityClientSignals} from '../signals/InfinityClient.signals';
import type {Subscribe} from '../types';
import type {Meeting} from '../services/InfinityClient.service';

export const useConferenceStatus = (
    getStatus: Meeting['getConferenceStatus'],
    getRoomId: Meeting['getRoomId'],
    subscribe: Subscribe = infinityClientSignals.onConferenceStatus.add,
) =>
    useSyncExternalStore(subscribe, () => {
        return getStatus().get(getRoomId());
    });
