import {endsWithAnyOf, getBaseURI} from '@pexip/utils';

import {EXPRESS_PATH, STEP_BY_STEP_PATH} from '../constants';

export const isAppEntryUrl = (
    url: string,
    node: {baseURI: string} = document,
) => {
    const searchStrings = [
        '/' + EXPRESS_PATH,
        '/' + EXPRESS_PATH + '/',
        '/' + STEP_BY_STEP_PATH,
        '/' + STEP_BY_STEP_PATH + '/',
    ];
    const base = getBaseURI(node);
    if (base !== '/') {
        searchStrings.push(base);
    }
    // Check if the URL ends with any of the search strings without considering the query parameters
    return endsWithAnyOf(removeAllQueryParametersFromUrl(url), searchStrings);
};

const removeAllQueryParametersFromUrl = (url: string): string => {
    // This regular expression matches everything from the '?' character to the end of the string
    const regex = new RegExp('\\?.*', 'g');

    // Replace matches with an empty string
    const newUrl = url.replace(regex, '');

    return newUrl;
};
