import React from 'react';
import cx from 'classnames';

import styles from './CenterLayout.module.scss';

export const CenterLayout: React.FC<
    React.ComponentProps<'div'> & {
        isFullWidth?: boolean;
        centerText?: boolean;
        position?: 'relative' | 'absolute';
    }
> = ({
    centerText = false,
    children,
    className,
    isFullWidth,
    position = 'relative',
    ...props
}) => (
    <div
        className={cx(
            styles.centerLayout,
            styles[position],
            centerText && styles.centerText,
            {[styles.fullWidth]: isFullWidth},
            className,
        )}
        {...props}
    >
        {children}
    </div>
);

export type CenterLayoutProps = React.ComponentProps<typeof CenterLayout>;
