import type {DragEvent} from 'react';
import React, {forwardRef} from 'react';
import cx from 'classnames';

import {FontVariant} from '../../../../design-tokens/constants';
import type {ColorScheme} from '../../../types/variants';
import {DragAndDrop} from '../../elements/DragAndDrop/DragAndDrop.view';
import {FileUpload} from '../../elements/FileUpload/FileUpload.view';
import {Icon, IconTypes} from '../../elements/Icon/Icon';
import {Text} from '../../elements/Text/Text';
import {Row} from '../List/Row';

import styles from './DragAndUploadFiles.module.scss';

const fallbackSubTitle = 'or drag and drop';

export const DragAndUploadFiles = forwardRef<
    HTMLInputElement,
    {
        accept?: string;
        ariaLabel?: string;
        isDisabled?: boolean;
        hasError?: boolean;
        onChange: React.ChangeEventHandler<HTMLInputElement>;
        className?: string;
        colorScheme?: ColorScheme;
        onDrop?: (
            e: DragEvent<HTMLDivElement>,
            files?: File[],
            items?: DataTransferItem[],
        ) => void;
        multiple?: boolean;
        title?: string;
        subTitle?: string;
    }
>(
    (
        {
            accept,
            ariaLabel,
            colorScheme = 'dark',
            hasError,
            isDisabled,
            onChange,
            onDrop,
            multiple = false,
            title,
            subTitle = fallbackSubTitle,
            ...props
        },
        ref,
    ) => {
        const disabledStyles = isDisabled
            ? colorScheme === 'dark'
                ? styles.disabledDark
                : styles.disabledLight
            : '';

        return (
            <DragAndDrop
                onDrop={onDrop}
                colorScheme={colorScheme}
                hasError={hasError}
                isDisabled={isDisabled}
                {...props}
            >
                <FileUpload
                    accept={accept}
                    className={styles.fileUpload}
                    onChange={onChange}
                    multiple={multiple}
                    ref={ref}
                    ariaLabel={ariaLabel}
                    colorScheme={colorScheme}
                    isDisabled={isDisabled}
                >
                    <div className={styles.content}>
                        <div className={styles.button}>
                            <Icon
                                source={IconTypes.IconAdd}
                                size="small"
                                className={cx(
                                    disabledStyles,
                                    hasError && styles.error,
                                )}
                                colorScheme={colorScheme}
                            />
                        </div>
                        {title && (
                            <Row className="pb-0">
                                <Text
                                    fontVariant={FontVariant.H5}
                                    className={cx(
                                        disabledStyles,
                                        hasError && styles.error,
                                    )}
                                    colorScheme={colorScheme}
                                >
                                    {title}
                                </Text>
                            </Row>
                        )}
                        {subTitle && (
                            <Row className="pt-0">
                                <Text
                                    fontVariant={FontVariant.Body}
                                    className={cx(
                                        disabledStyles,
                                        hasError && styles.error,
                                    )}
                                    colorScheme={colorScheme}
                                >
                                    {subTitle}
                                </Text>
                            </Row>
                        )}
                    </div>
                </FileUpload>
            </DragAndDrop>
        );
    },
);

DragAndUploadFiles.displayName = 'DragAndUploadFiles';

export type DragAndUploadFilesProps = React.ComponentProps<
    typeof DragAndUploadFiles
>;
