import React from 'react';
import {useTranslation} from 'react-i18next';

import {ConfirmationModal} from '@pexip/components';

import {TestId} from '../../../test/testIds';

export const DuplicateRoomNameModal: React.FC<{
    isOpen: boolean;
    onConfirm: () => void;
}> = ({isOpen, onConfirm}) => {
    const {t} = useTranslation();

    return (
        <ConfirmationModal
            isOpen={isOpen}
            title={t(
                'breakouts.duplicate-room-name-title',
                'Duplicated room names',
            )}
            uniqueTitle={t(
                'breakouts.duplicate-room-name-title',
                'Duplicated room names',
            )}
            description={t(
                'breakouts.duplicate-room-name-description',
                'Two rooms can not have the same name',
            )}
            confirmButtonText={t('common.ok', 'Ok')}
            confirmButtonTestid={TestId.ButtonDuplicateRoomName}
            onConfirm={onConfirm}
        />
    );
};
