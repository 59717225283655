import cx from 'classnames';
import React, {
    forwardRef,
    useRef,
    useCallback,
    useImperativeHandle,
    useId,
} from 'react';

import type {ColorScheme} from '../../../types/variants';
import {Text} from '../Text/Text';
import {InputLabel} from '../InputLabel/InputLabel';
import {ThemeConsumer} from '../../../themes/ThemeContext';

import styles from './TextArea.module.scss';

type ResizeVariant = 'resizeNone' | 'resizeBoth' | 'resizeV' | 'resizeH';

export const TextArea = forwardRef<
    {
        focus: () => void;
    },
    Omit<React.ComponentPropsWithRef<'textarea'>, 'disabled'> & {
        colorScheme?: ColorScheme;
        errorText?: string;
        hasError?: boolean;
        id?: string;
        isDisabled?: boolean;
        label: string;
        isLabelHidden?: boolean;
        name: string;
        onValueChange?: (value: string) => void;
        value?: string;
        resize?: ResizeVariant;
        rows?: number;
    }
>(
    (
        {
            className,
            colorScheme,
            errorText,
            hasError,
            id,
            isDisabled,
            label,
            isLabelHidden = false,
            name,
            onChange: inheritedOnChange,
            onValueChange,
            value,
            resize = 'resizeNone',
            rows = 4,
            ...props
        },
        ref,
    ) => {
        const defaultId = useId();
        const effectiveId = id ?? defaultId;

        const textarea = useRef<HTMLTextAreaElement | null>(null);

        const onChange = useCallback(
            (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                if (onValueChange) {
                    onValueChange(e.currentTarget.value);
                }
                if (inheritedOnChange) {
                    inheritedOnChange(e);
                }
            },
            [inheritedOnChange, onValueChange],
        );

        const labelProps = {text: label, id: effectiveId};

        useImperativeHandle(ref, () => ({
            focus: () => {
                if (textarea.current) {
                    textarea.current.focus();
                }
            },
            value: textarea.current?.value,
        }));

        return (
            <ThemeConsumer>
                {({colorScheme: defaultColorScheme}) => (
                    <label
                        className={cx(
                            styles.wrap,
                            styles[colorScheme ?? defaultColorScheme],
                            {
                                [styles.disabled]: isDisabled,
                                [styles.error]: hasError && !isDisabled,
                            },
                            className,
                        )}
                    >
                        <InputLabel
                            isLabelHidden={isLabelHidden}
                            {...labelProps}
                        />

                        <textarea
                            rows={rows}
                            id={effectiveId}
                            name={name}
                            onChange={onChange}
                            ref={textarea}
                            value={value}
                            className={styles[resize]}
                            disabled={isDisabled}
                            {...props}
                        />
                        {hasError && errorText && (
                            <Text
                                className={cx('mt-2', styles.errorText)}
                                variant="danger"
                                role="alert"
                            >
                                {errorText}
                            </Text>
                        )}
                    </label>
                )}
            </ThemeConsumer>
        );
    },
);

TextArea.displayName = 'TextArea';

export type TextAreaProps = React.ComponentProps<typeof TextArea>;
