type ExtractObjectValueTypes<T extends object> = T[keyof T];

/**
 * The `DisplayCaptureSurfaceType` enumeration describes the different types of
 * display surface
 *
 * @see {@link https://w3c.github.io/mediacapture-screen-share/#displaycapturesurfacetype}
 */
export const DISPLAY_CAPTURE_SURFACE_TYPE = {
    Monitor: 'monitor',
    Window: 'window',
    Browser: 'browser',
} as const;
/**
 * The `DisplayCaptureSurfaceType` enumeration describes the different types of
 * display surface
 *
 * @see {@link https://w3c.github.io/mediacapture-screen-share/#displaycapturesurfacetype}
 */
export type DisplayCaptureSurfaceType = ExtractObjectValueTypes<
    typeof DISPLAY_CAPTURE_SURFACE_TYPE
>;

/**
 * The `CursorCaptureConstraint` enumerates the conditions under which the
 * cursor is captured.
 *
 * @see {@link https://w3c.github.io/mediacapture-screen-share/#cursorcaptureconstraint}
 */
export const CURSOR_CAPTURE_CONSTRAINT = {
    Never: 'never',
    Always: 'always',
    Motion: 'motion',
} as const;
/**
 * The `CursorCaptureConstraint` enumerates the conditions under which the
 * cursor is captured.
 *
 * @see {@link https://w3c.github.io/mediacapture-screen-share/#cursorcaptureconstraint}
 */
export type CursorCaptureConstraint = ExtractObjectValueTypes<
    typeof CURSOR_CAPTURE_CONSTRAINT
>;

export const INCLUDE_EXCLUDE_CONSTRAINT = {
    Include: 'include',
    Exclude: 'exclude',
} as const;

export type IncludeExcludeConstraint = ExtractObjectValueTypes<
    typeof INCLUDE_EXCLUDE_CONSTRAINT
>;
