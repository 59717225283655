import type {MediaDeviceRequest} from './types';
import {MediaDeviceFailure} from './types';
import {getDevices} from './devices';
import {getMediaConstraints, relaxInputConstraint} from './constraints';
import {normalizeGetUserMediaError} from './errors';
import {logger} from './logger';

/**
 * Get MediaStream with provided input constraints
 *
 * @returns MediaStream
 *
 * @beta
 */
export const getMediaStream = async (
    request: MediaDeviceRequest & {
        getDefaultConstraints: () => MediaStreamConstraints;
    },
) => {
    if (!request.audio && !request.video) {
        throw new Error(MediaDeviceFailure.MissingConstraintsError);
    }

    const devices = await getDevices();
    const audio = relaxInputConstraint(request.audio, devices);
    const video = relaxInputConstraint(request.video, devices);

    const constraints = getMediaConstraints({
        audio,
        video,
        defaultConstraints: request.getDefaultConstraints(),
    });

    logger.debug(
        {request, constraints, devices},
        'Constraints used for getting media',
    );

    try {
        // Track device changes before and after the getUserMedia call to keep
        // track of authorized/unauthorized devices
        navigator.mediaDevices.dispatchEvent(new Event('devicechange'));
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        navigator.mediaDevices.dispatchEvent(new Event('devicechange'));
        return stream;
    } catch (error: unknown) {
        if (error instanceof Error) {
            const devices = await getDevices();
            throw new Error(
                normalizeGetUserMediaError(error, constraints, devices),
            );
        }
        throw error;
    }
};
