import React from 'react';
import cx from 'classnames';

import type {ColorScheme} from '../../../types/variants';
import type {BaseSizeModifier} from '../../../types/sizes';

import {SVGUniqueId} from './SvgUniqueId';

import styles from './SvgImage.module.scss';

export const SvgImage: React.FC<
    React.ComponentProps<'svg'> & {
        colorScheme?: ColorScheme;
        size?: BaseSizeModifier;
        source: React.ElementType;
    }
> = ({
    size,
    source: Source,
    className: classNameArg,
    colorScheme,
    ...props
}) => {
    const className = cx(
        colorScheme && styles[colorScheme],
        size && styles[size],
        classNameArg,
    );

    // In order to have the svg children reachable in the react component we
    // have to render the component by just calling it:
    const ReactFn = Source as React.FC<React.SVGProps<SVGSVGElement>>;

    const svg =
        typeof ReactFn === 'function' ? (
            ReactFn({
                className,
                ...props,
            })
        ) : (
            <Source className={className} {...props} />
        );

    return <SVGUniqueId>{svg}</SVGUniqueId>;
};

export type SvgImageProps = React.ComponentProps<typeof SvgImage>;
