import {useSyncExternalStore} from 'react';

import type {createMediaSignals} from '@pexip/media';
import type {Signal} from '@pexip/signal';

type MediaSignals = ReturnType<typeof createMediaSignals>;
type MediaSignalKey = keyof MediaSignals;
type MediaSignalType<K extends MediaSignalKey> =
    MediaSignals[K] extends Signal<infer T> ? T : never;

interface MediaStateSignal<K extends MediaSignalKey> {
    initial: () => MediaSignalType<K>;
    subscribe: Signal<MediaSignalType<K>>['add'];
}

interface MediaHookBases {
    useLocalMedia: MediaStateSignal<'onMediaChanged'>;
    useDevices: MediaStateSignal<'onDevicesChanged'>;
    useStreamStatus: MediaStateSignal<'onStatusChanged'>;
}

type MediaHooksRequired = Pick<MediaHookBases, 'useLocalMedia'>;
type MediaHooksOptional = Omit<MediaHookBases, keyof MediaHooksRequired>;

type HookReturn<K extends keyof MediaHookBases> = ReturnType<
    MediaHookBases[K]['initial']
>;

const useMediaSignalHook = <K extends keyof MediaHookBases>(
    hook: MediaHookBases[K],
): HookReturn<K> => {
    const state = useSyncExternalStore(
        hook.subscribe,
        hook.initial as () => HookReturn<K>,
    );

    return state;
};

type MediaHook<K extends keyof MediaHookBases> = () => HookReturn<K>;

interface MediaHooks {
    useLocalMedia: MediaHook<'useLocalMedia'>;
    useDevices: MediaHook<'useDevices'>;
    useStreamStatus: MediaHook<'useStreamStatus'>;
}

export const generateMediaSignalHooks = (
    hooks: MediaHooksRequired & Partial<MediaHooksOptional>,
) =>
    Object.keys(hooks).reduce(
        (accm, key) => {
            const hookKey = key as keyof typeof hooks;
            const hook = hooks[hookKey];
            if (hook) {
                return {...accm, [hookKey]: () => useMediaSignalHook(hook)};
            }
            return accm;
        },
        {} as Pick<MediaHooks, keyof typeof hooks>,
    );
