import {useEffect, useLayoutEffect, useRef} from 'react';
import type {RefCallback} from 'react';

/**
 * Hook that only runs callback on click events outside of the referenced element
 *
 * @param fn - the function to be called on click outside
 * @returns a ref to the element where we are listening for clicks
 */
export function useOnClickOutside(fn: () => void) {
    const fnRef = useRef(fn);
    useLayoutEffect(() => {
        fnRef.current = fn;
    }, [fn]);

    const ref = useRef<HTMLElement | null>(null);
    const refCallback: RefCallback<HTMLElement> = element => {
        ref.current = element;
    };

    useEffect(() => {
        const handleOutside = (event: MouseEvent | TouchEvent) => {
            if (!ref.current || !event.target) {
                return;
            }

            // do nothing if the ref element is clicked
            if (ref.current.contains(event.target as Node)) {
                return;
            }

            // run the callback if something outside is clicked
            fnRef.current();
        };

        document.addEventListener('click', handleOutside, {capture: true});
        document.addEventListener('touchstart', handleOutside, {capture: true});
        return () => {
            document.removeEventListener('click', handleOutside, {
                capture: true,
            });
            document.removeEventListener('touchstart', handleOutside, {
                capture: true,
            });
        };
    }, []);

    return refCallback;
}
