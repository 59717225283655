import React, {useEffect, useState} from 'react';
import cx from 'classnames';

import {usePrevious} from '@pexip/hooks';

import {Text} from '../../elements/Text/Text';
import type {ColorScheme} from '../../../types/variants';
import {ThemeConsumer} from '../../../themes/ThemeContext';
import {FontVariant} from '../../../../design-tokens/constants';

import {AccordionButton} from './AccordionButton';

import styles from './Accordion.module.scss';

export const Accordion: React.FC<{
    buttonClassName?: string;
    canOnlyToggleWithTitle?: boolean;
    children: React.ReactNode;
    className?: string;
    colorScheme?: ColorScheme;
    contentClassName?: string;
    dataTestId?: string;
    enhancerEnd?: React.ReactNode;
    isExpanded?: boolean;
    meta?: string | React.ReactNode;
    onToggle?: (isOpen: boolean) => void;
    title: string | React.ReactNode;
}> = ({
    buttonClassName,
    canOnlyToggleWithTitle,
    children,
    className,
    colorScheme,
    contentClassName,
    dataTestId,
    enhancerEnd,
    isExpanded = false,
    meta,
    onToggle,
    title,
    ...props
}) => {
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(isExpanded);
    const previousIsAccordionExpanded = usePrevious(isAccordionExpanded);

    const handleExpand = (event: React.SyntheticEvent<HTMLElement>): void => {
        event.preventDefault();

        setIsAccordionExpanded(prevValue => !prevValue);
    };

    useEffect(() => {
        if (isAccordionExpanded !== (previousIsAccordionExpanded || false)) {
            onToggle?.(isAccordionExpanded);
        }
    }, [onToggle, isAccordionExpanded, previousIsAccordionExpanded]);

    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <div
                    role="group" // Based on implicit `details` role
                    className={cx(
                        styles[colorScheme ?? defaultColorScheme],
                        className,
                    )}
                    {...props}
                >
                    <AccordionButton
                        canOnlyToggleWithTitle={canOnlyToggleWithTitle}
                        meta={meta}
                        isExpanded={isAccordionExpanded}
                        onExpand={handleExpand}
                        className={buttonClassName}
                        enhancerEnd={enhancerEnd}
                        data-testid={dataTestId}
                    >
                        {typeof title === 'string' ? (
                            <Text fontVariant={FontVariant.BodyBold}>
                                {title}
                            </Text>
                        ) : (
                            title
                        )}
                    </AccordionButton>
                    <div
                        className={cx(
                            styles.content,
                            {[styles.expanded]: isAccordionExpanded},
                            contentClassName,
                        )}
                    >
                        {children}
                    </div>
                </div>
            )}
        </ThemeConsumer>
    );
};

export type AccordionProps = React.ComponentProps<typeof Accordion>;
