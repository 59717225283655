import React from 'react';
import cx from 'classnames';

import {Divider} from '@pexip/components';

import {ChatPanelInputSendButton} from './ChatPanelInputSendButton.view';

import styles from './ChatPanelInputButtons.module.scss';

export const ChatPanelInputButtons: React.FC<{
    isDisabled: boolean;
}> = ({isDisabled}) => (
    <div className={cx('pl-2', styles.wrapper)}>
        <Divider direction="vertical" className={styles.divider} />
        <ChatPanelInputSendButton disabled={isDisabled} />
    </div>
);
