import React from 'react';
import cx from 'classnames';

import type {ExtendedSizeModifier} from '../../../types/sizes';
import {sizeToPadding} from '../../../utils/helpers';

import styles from './Bar.module.scss';

export const Bar: React.FC<
    React.ComponentProps<'div'> & {
        position?: 'absoluteTop' | 'absoluteBottom' | 'relative';
        padding?: ExtendedSizeModifier;
        flexDirection?: 'row' | 'rowReverse' | 'column' | 'columnReverse';
        justifyContent?: 'spaceBetween' | 'center';
    }
> = ({
    children,
    className,
    position = 'relative',
    padding = 'none',
    flexDirection = 'row',
    justifyContent = 'spaceBetween',
    ...props
}) => (
    <div
        className={cx(
            styles.bar,
            styles[sizeToPadding(padding)],
            styles[position],
            styles[flexDirection],
            styles[justifyContent],
            className,
        )}
        {...props}
    >
        {children}
    </div>
);
