import type {ReactNode, ReactElement} from 'react';
import React, {isValidElement, Children, cloneElement, useId} from 'react';

const hasChildren = (
    element: ReactNode,
): element is ReactElement<{children: ReactNode | ReactNode[]}> =>
    isValidElement<{children?: ReactNode[]}>(element) &&
    Boolean(element.props.children);

const hasComplexChildren = (
    element: ReactNode,
): element is ReactElement<{children: ReactNode | ReactNode[]}> =>
    isValidElement(element) &&
    hasChildren(element) &&
    Children.toArray(element.props.children).reduce(
        (response: boolean, child: ReactNode): boolean =>
            response || isValidElement(child),
        false,
    );

const deepMap = (
    children: ReactNode | ReactNode[],
    deepMapFn: (
        child: ReactNode,
        index?: number,
        children?: ReactNode[],
    ) => ReactNode,
): ReactNode[] =>
    Children.toArray(children).map(
        (child: ReactNode, index: number, mapChildren: ReactNode[]) => {
            if (isValidElement(child) && hasComplexChildren(child)) {
                // Clone the child that has children and map them too
                return deepMapFn(
                    cloneElement(child, {
                        ...child.props,
                        children: deepMap(child.props.children, deepMapFn),
                    }),
                );
            }
            return deepMapFn(child, index, mapChildren);
        },
    );

const getHookedId = (
    componentId: string,
    id?: string | null,
): string | null => {
    if (typeof id === 'undefined') {
        return null;
    }

    return `${componentId}${id}`;
};

const fixPropWithUrl = (componentId: string, prop: string): string => {
    if (typeof prop !== 'string') {
        return prop;
    }

    const [_, id] = prop.match(/^url\(#(.*)\)$/) ?? [null, null];

    if (id === null) {
        return prop;
    }

    const fixedId = getHookedId(componentId, id);

    if (fixedId === null) {
        return prop;
    }

    return `url(#${fixedId})`;
};

interface OptionalProps {
    id?: string | null;
    xlinkHref?: string;
}

const isReactElementWithOptionalProps = (
    element: ReactNode,
): element is ReactElement<OptionalProps> => {
    return React.isValidElement(element) && typeof element.props === 'object';
};

export const SVGUniqueId: React.FC<
    React.PropsWithChildren<OptionalProps>
> = props => {
    const componentId = useId();
    return deepMap(props.children, child => {
        if (
            !child ||
            typeof child === 'string' ||
            typeof child === 'number' ||
            !isReactElementWithOptionalProps(child)
        ) {
            return child;
        }

        return React.cloneElement(child, {
            ...Object.entries(child.props).reduce(
                (acc, [key, value]) => {
                    acc[key] = fixPropWithUrl(componentId, value ?? '');
                    return acc;
                },
                {} as {[key: string]: string | null},
            ),
            id: getHookedId(componentId, child.props.id),
        });
    });
};
