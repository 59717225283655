import {useEffect, useState} from 'react';

import type {Signal} from '@pexip/signal';

import {NetworkState} from '../types';

export const STATE_RESET_TIMER = 2000; //ms

export const useNetworkState = (
    reconnectingSignal: Signal<undefined>,
    reconnectedSignal: Signal<undefined>,
) => {
    const [networkState, setNetworkState] = useState(NetworkState.Connected);

    useEffect(() => {
        let timeout: ReturnType<typeof setTimeout>;
        const detach = [
            reconnectingSignal.add(() => {
                clearTimeout(timeout);
                setNetworkState(NetworkState.Reconnecting);
            }),
            reconnectedSignal.add(() => {
                setNetworkState(NetworkState.Reconnected);
                timeout = setTimeout(() => {
                    setNetworkState(NetworkState.Connected);
                }, STATE_RESET_TIMER);
            }),
        ];

        return () => {
            detach.forEach(d => d());
            clearTimeout(timeout);
        };
    }, [reconnectingSignal, reconnectedSignal]);

    return networkState;
};
