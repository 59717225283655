import {useState, useEffect, useCallback} from 'react';

export const useSizeObserver = (element: HTMLElement) => {
    const [size, setSize] = useState<{
        width: number;
        height: number;
    }>({
        width: element.offsetWidth,
        height: element.offsetHeight,
    });

    const handleResize = useCallback(
        (entries: readonly ResizeObserverEntry[]) => {
            // Support observing only one element for now.
            const [firstEntry] = entries;

            if (!firstEntry) {
                return;
            }

            const borderBoxSize = firstEntry.borderBoxSize?.[0];

            if (firstEntry.target.tagName !== element.tagName) {
                return;
            }

            if (borderBoxSize) {
                setSize({
                    width: borderBoxSize.inlineSize,
                    height: borderBoxSize.blockSize,
                });
                return;
            }

            // Safari uses the (almost deprecated) contentRect
            if (firstEntry.contentRect) {
                setSize({
                    width: firstEntry.contentRect.width,
                    height: firstEntry.contentRect.height,
                });
            }
        },
        [element],
    );

    useEffect(() => {
        const observer = new ResizeObserver(handleResize);

        observer.observe(element);

        return () => {
            observer.disconnect();
        };
    }, [element, handleResize]);

    return size;
};
