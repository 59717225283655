import type {Logger} from './baseLogger';
import {createConsoleLogger} from './baseLogger';

export let logger = createConsoleLogger();

export function setLogger(newLogger: Logger) {
    logger = newLogger;
}

/**
 * A logger wrapper to always include some meta base data to the log
 *
 * @param metaBase - The meta data which will always be included into the log
 */
export const createModuleLogger = (metaBase: unknown): Logger => {
    return Object.keys(logger).reduce((log, key) => {
        const k = key as keyof Omit<Logger, 'redact'>;
        log[k] = (meta: unknown, msg?: string) => {
            if (typeof meta === 'string') {
                return logger[k](metaBase, meta);
            }
            if (typeof meta === 'object') {
                return logger[k]({...meta, meta: metaBase}, msg);
            }
            return logger[k]({meta: metaBase, context: meta}, msg);
        };
        return log;
    }, {} as Logger);
};

export const createLogProxyHandler = <T extends object>(
    logger: Logger,
    name: string,
    scope: string,
): ProxyHandler<T> => {
    return {
        get: (target, p, receiver) => {
            const value = Reflect.get(target, p, receiver);
            logger.debug(
                {scope, name, prop: p, value},
                `called get ${name}[${String(p)}]`,
            );
            return value;
        },
        // eslint-disable-next-line max-params --- from lib.es2015 and log
        set: (target, p, value, receiver) => {
            logger.debug(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- it's a log call...
                {scope, name, prop: p, value},
                `called set ${name}[${String(p)}]`,
            );
            return Reflect.set(target, p, value, receiver);
        },
    };
};

export const proxyWithLog =
    (logger: Logger, scope: string) =>
    <T extends object>(obj: T, name: string) => {
        return new Proxy(obj, createLogProxyHandler(logger, name, scope));
    };
