import {useMatchMedia} from '@pexip/hooks';

const maxWidth = 510;
const maxGridXs = 419;

export const useResponsiveStatsGrid = () => {
    const hideVisualizer = useMatchMedia(`(max-width: ${maxWidth}px)`);
    const isGridXs = useMatchMedia(`(max-width: ${maxGridXs}px)`);

    return {
        hideVisualizer,
        isGridXs,
    };
};
