import type {TextVariant} from '../../../types/variants';

import type {MultistepItem} from './MultistepIndicator.types';

/**
 * Returns the current step within the bounds between 1 and the steps ceiling
 *
 * @param stepNumber - Current step index
 * @param maxStepIndex - Steps length
 */
export const getCurrentStepBounds = (
    stepIndex: number,
    maxStepIndex: number,
) => {
    const minStep = 0;
    const currentStep = Math.max(minStep, stepIndex);
    return currentStep > maxStepIndex ? maxStepIndex : currentStep;
};

/**
 * Returns the given step index if it's available or gets the
 * next step that is enabled.
 *
 * ```
 * {...step, isDisabled: false}
 * ```
 */
export const getAvailableStepIndex = ({
    steps,
    stepIndex,
}: {
    steps: MultistepItem[];
    stepIndex: number;
}) => {
    const potentialNextIndex = steps.findIndex(
        (value, index) => index >= stepIndex && !value.isDisabled,
    );
    const nextIndex = ~potentialNextIndex ? potentialNextIndex : stepIndex;

    return getCurrentStepBounds(nextIndex, steps.length - 1);
};

/**
 * Returns the `TextVariant` to be used for the `Text` in `MultistepIndicator`
 */
export const getTextVariant = ({
    isActive,
    isDisabled,
}: {
    isActive?: boolean;
    isDisabled?: boolean;
}): Extract<TextVariant, 'standard' | 'disabled' | 'subtle'> => {
    if (isActive) {
        return 'standard';
    }

    if (isDisabled) {
        return 'disabled';
    }

    return 'subtle';
};
