import React, {useEffect, useState} from 'react';

import type {AudioQualityStats} from '@pexip/peer-connection-stats';
import type {Signal} from '@pexip/signal';

import {StatsModal} from '../views/StatsModal/StatsModal.view';
import type {NormalizedStats} from '../types';

import {AVQualityStats} from './AVQualityStats.viewModel';

export const Stats = ({
    cachedStats,
    statsSignal,
    callQualityStatsSignal,
    isOpen,
    onClose,
    secureCheckCode,
}: {
    cachedStats: NormalizedStats | undefined;
    statsSignal: Signal<NormalizedStats>;
    callQualityStatsSignal: Signal<{
        inbound: {audio: AudioQualityStats};
        outbound: {audio: AudioQualityStats};
    }>;
    isOpen?: boolean;
    onClose?: () => void;
    secureCheckCode?: string;
}) => {
    const [stats, setStats] = useState<NormalizedStats | undefined>(
        cachedStats,
    );

    useEffect(() => statsSignal.add(setStats), [statsSignal]);

    return (
        <StatsModal
            isOpen={isOpen}
            onClose={onClose}
            stats={stats}
            secureCheckCode={secureCheckCode}
        >
            <AVQualityStats callQualityStatsSignal={callQualityStatsSignal} />
        </StatsModal>
    );
};
