type Value = string | string[] | boolean | number;
export type Valid = Value | Record<string, Value>;

export const isString = (t: unknown): t is string => typeof t === 'string';

export const isBoolean = (t: unknown): t is boolean => typeof t === 'boolean';

export const isNumber = (t: unknown): t is number => typeof t === 'number';

export const isArray = (t: unknown): t is string[] =>
    Array.isArray(t) && t.every(isString);

const isValue = (t: unknown): t is Value =>
    [isString, isBoolean, isNumber, isArray].some(fn => fn(t));

export const isRecord = (t: unknown): t is Record<string, Valid> =>
    typeof t === 'object' &&
    t !== null &&
    !(t instanceof Map) &&
    !(t instanceof Set) &&
    !Array.isArray(t) &&
    Object.values(t).every(isValue);

export const isValid = (t: unknown): t is Valid =>
    [isValue, isRecord].some(fn => fn(t));

export const getTypeOf = (t: unknown): string => {
    if (t instanceof Map) {
        return 'Map';
    }
    if (t instanceof Set) {
        return 'Set';
    }
    if (t instanceof Function) {
        return 'Function';
    }
    if (t instanceof Object) {
        return getTypeOf(Object.values(t).filter(v => !isValid(v))[0]);
    }
    return typeof t;
};
