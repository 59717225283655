import {useEffect, useMemo, useRef} from 'react';

import {createSignal} from '@pexip/signal';

import {useLg, useMd, useSm, useXl, useXs} from './media-queries';

export const useBreakpointChange = () => {
    const isInitial = useRef(true);
    const isXs = useXs();
    const isSm = useSm();
    const isMd = useMd();
    const isLg = useLg();
    const isXl = useXl();
    const breakpointChangeSignal = useMemo(
        () =>
            createSignal({
                name: 'breakpoint:change',
            }),
        [],
    );

    useEffect(() => {
        const hasOneTruthyBreakpointInState =
            [isXs, isSm, isMd, isLg, isXl].filter(breakpoint => breakpoint)
                .length === 1;
        /**
         * We discard the initial execution of this useEffect, it will run
         * even though the breakpoint has not changed.
         *
         * We also check that only one of the isXs, isSm etc bools are truthy. When the breakpoint changes,
         * the breakpoint that was true before becomes false but at that point in the execution all of
         * them are false, then some milliseconds later the new breakpoint returns true and the useEffect
         * is run again (I'm not certain if this is always deterministic, possibly the new breakpoint
         * could return true before the other returns false).
         * We only want to emit the signal once (when only one changed boolean is true).
         */
        if (!isInitial.current && hasOneTruthyBreakpointInState) {
            breakpointChangeSignal.emit();
        } else {
            isInitial.current = false;
        }
    }, [breakpointChangeSignal, isXs, isSm, isMd, isLg, isXl]);

    return breakpointChangeSignal;
};
