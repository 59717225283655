import React from 'react';
import cx from 'classnames';

import IconTypes from '../../../../design-tokens/generated/Icons';
import type {IconSource} from '../../../../design-tokens/generated/Icons';
import type {LogoSizeModifier} from '../../../types/sizes';
import type {ColorScheme} from '../../../types/variants';
import {SVGUniqueId} from '../SvgImage/SvgUniqueId';

import styles from './Icon.module.scss';

export const Icon: React.FC<
    React.ComponentProps<'svg'> & {
        size?: LogoSizeModifier;
        colorScheme?: ColorScheme;
        source: IconSource;
    }
> = ({
    source,
    className: classNameArg,
    size = 'small',
    colorScheme,
    ...props
}) => {
    const IconComponent =
        size === 'mini' || size === 'compact'
            ? source.size16
            : size === 'small'
              ? source.size24
              : source.size32;

    const className = cx(
        colorScheme && styles[colorScheme],
        styles[size],
        classNameArg,
    );

    // In order to have the svg children reachable in the react component we
    // have to render the component by just calling it:
    const ReactFn = IconComponent;

    const svg =
        typeof ReactFn === 'function' ? (
            ReactFn({className, ...props})
        ) : (
            <IconComponent className={className} {...props} />
        );

    return <SVGUniqueId>{svg}</SVGUniqueId>;
};

export {IconTypes};
