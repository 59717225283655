import React from 'react';
import {useTranslation} from 'react-i18next';

import {Button, Icon, IconTypes, Text} from '@pexip/components';

import {MicrophoneMonitorContentBox} from './MicrophoneMonitorContentBox.view';

import styles from './MicrophoneMonitorModal.module.scss';

export const ReplayButton: React.FC<{replay?: () => void}> = ({replay}) => {
    const {t} = useTranslation();

    return (
        <MicrophoneMonitorContentBox>
            <div className="d-flex flex-direction-column align-items-center">
                <Button
                    onClick={replay}
                    className={styles.replayButton}
                    variant="tertiary"
                    size="none"
                >
                    <Icon source={IconTypes.IconRefresh} />
                </Button>
                <Text className={styles.replayText}>
                    {t('common.replay', 'Replay')}
                </Text>
            </div>
        </MicrophoneMonitorContentBox>
    );
};
