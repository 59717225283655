import React from 'react';
import {useTranslation} from 'react-i18next';

import {FontVariant, TextLink} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './AudioOutputTestButton.module.scss';

export const AudioOutputTestButton: React.FC<{
    onClick: () => void;
    shouldPlay: boolean;
}> = ({onClick, shouldPlay}) => {
    const {t} = useTranslation();
    return (
        <TextLink
            fontVariant={FontVariant.SmallLink}
            data-testid={TestId.LinkTestAudioOutput}
            onClick={onClick}
            className={styles.wrapper}
        >
            {shouldPlay
                ? t('settings.playing-sound', 'Playing sound...')
                : t('settings.test-your-sound', 'Test your sound')}
        </TextLink>
    );
};
