import {useEffect} from 'react';

const poorManRequestAnimationFrame: typeof window.requestAnimationFrame =
    callback => setTimeout(callback, performance.now());
const poorManCancelAnimationFrame: typeof window.cancelAnimationFrame =
    handle => clearTimeout(handle);

const requestAnimationFrame =
    window.requestAnimationFrame || poorManRequestAnimationFrame;

const cancelAnimationFrame =
    window.cancelAnimationFrame || poorManCancelAnimationFrame;

export const useAnimationFrame = (
    callback: FrameRequestCallback,
    shouldStop = false,
) => {
    useEffect(() => {
        let requestRef: number | undefined = undefined;
        let previousTimeRef: number | undefined = undefined;

        let ignore = shouldStop;

        const stopAnimate = () => {
            ignore = true;
            if (requestRef) {
                cancelAnimationFrame(requestRef);
            }
            requestRef = undefined;
            previousTimeRef = undefined;
        };

        const animate = (time: number) => {
            if (ignore) {
                return;
            }
            if (previousTimeRef !== undefined) {
                const deltaTime = time - previousTimeRef;
                callback(deltaTime);
            }
            previousTimeRef = time;
            requestRef = requestAnimationFrame(animate);
        };

        if (ignore) {
            stopAnimate();
        } else {
            requestRef = requestAnimationFrame(animate);
        }

        return stopAnimate;
    }, [callback, shouldStop]);
};
