import React from 'react';

import {CenterLayout} from '../CenterLayout/CenterLayout';
import {FullSizeWindow} from '../FullSizeWindow/FullSizeWindow';
import {Wrapper} from '../Wrapper/Wrapper';

export const FullscreenLayout: React.FC<React.PropsWithChildren> = ({
    children,
}) => (
    <FullSizeWindow padding="none" background="dark">
        <Wrapper modifier="fullWidth">
            <CenterLayout>{children}</CenterLayout>
        </Wrapper>
    </FullSizeWindow>
);
