import React from 'react';
import cx from 'classnames';

import type {VideoWrapperSizeModifier} from '../../../types/sizes';

import styles from './Wrapper.module.scss';

export const Wrapper: React.FC<
    React.ComponentProps<'div'> & {
        size?: VideoWrapperSizeModifier;
        modifier?: 'fullWidth' | 'mediumWidth';
        heightModifier?: 'autoHeight';
        justifyContent?: 'flexStart' | 'center';
        borderRadius?: 'none' | 'box';
    }
> = ({
    className,
    children,
    modifier,
    heightModifier,
    size = 'full',
    justifyContent = 'flexStart',
    borderRadius = 'none',
    ...props
}) => (
    <div
        className={cx(
            styles.wrapper,
            className,
            modifier && styles[modifier],
            styles[size],
            heightModifier && styles[heightModifier],
            borderRadius === 'box' && styles.roundedCorners,
            styles[justifyContent],
        )}
        {...props}
    >
        {children}
    </div>
);
