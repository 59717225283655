import type {ExtendedSizeModifier} from '../../../types/sizes';
import type {FontVariant} from '../../../../design-tokens/constants';

const mappingFontVariant = {
    none: 'none',
    compact: 'smallBold',
    small: 'bodyBold',
    medium: 'h5',
    large: 'h3',
};

export const getAvatarFontVariant = (
    size: ExtendedSizeModifier,
): FontVariant => {
    return mappingFontVariant[size] as FontVariant;
};
