import React from 'react';

import {PageNotFound} from '../pages/PageNotFound.page';
import {createRoutesHook} from '../router';

const LazyMeetingFlow = React.lazy(() =>
    import('../pages/MeetingFlow.page').then(({MeetingFlow}) => ({
        default: MeetingFlow,
    })),
);

const LazyHome = React.lazy(() =>
    import('../pages/Home.page').then(({Home}) => ({
        default: Home,
    })),
);

const LazyOauthRedirect = React.lazy(() =>
    import('../pages/Oauth2Redirect.page').then(({Oauth2Redirect}) => ({
        default: Oauth2Redirect,
    })),
);

export const useMainRoutes = createRoutesHook([
    {path: '/oauth-redirect', node: LazyOauthRedirect, exact: true},
    {
        path: '/m',
        node: LazyMeetingFlow,
    },
    {
        exact: true,
        path: '/',
        node: LazyHome,
    },
    {
        path: '',
        fallback: true,
        node: PageNotFound,
    },
]);
