import {useCallback} from 'react';

/**
 * This hook gives the ability to set the focus on an element
 * which is then blurred right away, thereby effectively resetting
 * the focus order. The next focusable element that comes after this element
 * will be focused on the next tab.
 * @param element - element which focus point should start from
 * @returns focusFrom function, when called sets the focus point to the given element
 */
export const useFocusFrom = (element: HTMLElement | null) => {
    const focusFrom = useCallback(() => {
        if (element) {
            const prevTabIndex = element.tabIndex;
            if (prevTabIndex === -1) {
                /*
                 * If the element has a tabIndex of -1 we need to
                 * temporarly set it to 0 so that it can be focused.
                 */
                element.tabIndex = 0;
            }
            element.focus();
            element.blur();
            element.tabIndex = prevTabIndex;
        }
    }, [element]);

    return {focusFrom};
};
