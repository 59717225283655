import React from 'react';
import {useTranslation} from 'react-i18next';

import type {ButtonProps} from '@pexip/components';
import {SvgImage} from '@pexip/components';

import {InMeetingInputControl} from '../../InputControl/InMeetingInputControl.view';

export const FeccButtonView: React.FC<
    ButtonProps & {
        isFeccHidden: boolean;
        iconSource: React.ElementType;
        tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
        onClickFecc: () => void;
    }
> = ({
    isFeccHidden,
    iconSource,
    tooltipPosition = 'top',
    onClickFecc,
    ...props
}) => {
    const {t} = useTranslation();

    if (isFeccHidden) {
        return null;
    }

    return (
        <InMeetingInputControl
            icon={<SvgImage size="small" source={iconSource} />}
            aria-label={t('fecc.camera-control', 'Camera control')}
            tooltipText={t('fecc.camera-control', 'Camera control')}
            tooltipPosition={tooltipPosition}
            onClick={onClickFecc}
            {...props}
        />
    );
};

export type FeccButtonViewProps = React.ComponentProps<typeof FeccButtonView>;
