/**
 * Unicode-aware first-letter-getter
 * Returns REPLACEMENT CHARACTER (�) for bad pairs
 * @param word -- the word to get the first letter from
 */
function getFirstLetter(word: string) {
    const charCode = word.charCodeAt(0);
    // Is it in the BMP?
    if (charCode < 0xd800 || charCode > 0xdfff) {
        return word.slice(0, 1);
    }
    // Surrogate pair, needs at least two 16-bit characters
    if (word.length < 2) {
        return '�';
    }
    // Expect high pair
    if (0xd800 <= charCode && charCode <= 0xdbff) {
        const low = word.charCodeAt(1);
        // Then a low pair
        if (low >= 0xdc00 && low <= 0xdfff) {
            return word.slice(0, 2);
        }
    }
    return '�';
}

export function getInitials(displayName: string, fallback = '') {
    if (!displayName) {
        return fallback;
    }

    const initials = displayName
        .replace(/\(.*?\)/gm, '')
        .split(' ')
        .filter(Boolean)
        .map(getFirstLetter);
    // Remove all but first and last element
    initials.splice(1, initials.length - 2);

    return initials.join('').toUpperCase();
}
