import React, {useState} from 'react';
import cx from 'classnames';

import {
    Button,
    FontVariant,
    InputLabel,
    NumberStepInput,
    RadioButton,
    Text,
    Panel,
    useSmUp,
} from '@pexip/components';

import {BreakoutRoomAssignmentMode} from '../../types';
import {PanelHeader} from '../PanelHeader/PanelHeader.view';
import {TestId} from '../../../test/testIds';

import styles from './BreakoutRoomsModeAssignment.module.scss';

export const BreakoutRoomsModeAssignment: React.FC<{
    headerText: string;
    nextButtonText: string;
    initialAssignmentMode: BreakoutRoomAssignmentMode;
    assignmentModeLabel: string;
    assignmentModeAutomaticallyLabel: string;
    assignmentModeAutomaticallyDescription: string;
    assignmentModeManuallyLabel: string;
    assignmentModeManuallyDescription: string;
    amountOfRoomsLabel: string;
    initialAmountOfRooms?: number;
    minAmountOfRooms?: number;
    maxAmountOfRooms?: number;
    onNextClick: ({
        assignmentMode,
        amountOfRooms,
    }: {
        assignmentMode: BreakoutRoomAssignmentMode;
        amountOfRooms: number;
    }) => void;
    onClosePanel: () => void;
}> = ({
    headerText,
    nextButtonText,
    initialAssignmentMode,
    assignmentModeLabel,
    assignmentModeAutomaticallyLabel,
    assignmentModeAutomaticallyDescription,
    assignmentModeManuallyLabel,
    assignmentModeManuallyDescription,
    amountOfRoomsLabel,
    initialAmountOfRooms = 5,
    minAmountOfRooms = 1,
    maxAmountOfRooms = 50,
    onNextClick,
    onClosePanel,
}) => {
    const isSmUp = useSmUp();
    const [assignmentMode, setAssignmentMode] = useState(initialAssignmentMode);
    const [amountOfRooms, setAmountOfRooms] = useState(initialAmountOfRooms);
    return (
        <Panel
            isRounded={isSmUp}
            headerPadding="none"
            headerContent={
                <PanelHeader title={headerText} onCloseClick={onClosePanel} />
            }
            footerContent={
                <Button
                    modifier="fullWidth"
                    onClick={() => {
                        onNextClick({
                            amountOfRooms,
                            assignmentMode,
                        });
                    }}
                    data-testid={TestId.GoToBreakoutSetupPanel}
                >
                    {nextButtonText}
                </Button>
            }
        >
            <div className={cx(styles.amountOfRooms, 'mb-7')}>
                <InputLabel className="mb-0" text={amountOfRoomsLabel} />
                <NumberStepInput
                    value={amountOfRooms}
                    name="roomsInput"
                    label={amountOfRoomsLabel}
                    minValue={minAmountOfRooms}
                    maxValue={maxAmountOfRooms}
                    onChange={setAmountOfRooms}
                />
            </div>
            <InputLabel className="mb-7" text={assignmentModeLabel} />
            <div className="mb-5">
                <RadioButton
                    groupName="mode"
                    checked={
                        assignmentMode ===
                        BreakoutRoomAssignmentMode.Automatically
                    }
                    label={assignmentModeAutomaticallyLabel}
                    value={BreakoutRoomAssignmentMode.Automatically}
                    onValueChange={checked =>
                        checked &&
                        setAssignmentMode(
                            BreakoutRoomAssignmentMode.Automatically,
                        )
                    }
                    data-testid={TestId.BreakoutAssignAutoBtn}
                />
                <Text
                    fontVariant={FontVariant.Body}
                    htmlTag="p"
                    className="pl-5 pt-1"
                >
                    {assignmentModeAutomaticallyDescription}
                </Text>
            </div>
            <div>
                <RadioButton
                    groupName="mode"
                    checked={
                        assignmentMode === BreakoutRoomAssignmentMode.Manually
                    }
                    label={assignmentModeManuallyLabel}
                    value={BreakoutRoomAssignmentMode.Manually}
                    onValueChange={checked =>
                        checked &&
                        setAssignmentMode(BreakoutRoomAssignmentMode.Manually)
                    }
                    data-testid={TestId.BreakoutAssignManuallyBtn}
                />
                <Text
                    fontVariant={FontVariant.Body}
                    htmlTag="p"
                    className="pl-5 pt-1"
                >
                    {assignmentModeManuallyDescription}
                </Text>
            </div>
        </Panel>
    );
};

export type BreakoutRoomsModeAssignmentProps = React.ComponentProps<
    typeof BreakoutRoomsModeAssignment
>;
