import {useEffect, useRef, useState} from 'react';

import {useSizeObserver} from './useSizeObserver';

const WIDE_CONTAINER_ASPECT_RATIO = 16 / 9;

export const useWideContainerObserver = () => {
    const [isContainerWide, setIsContainerWide] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const containerSize = useSizeObserver(
        containerRef.current || document.createElement('div'),
    );
    useEffect(() => {
        const aspectRatio = containerSize.width / containerSize.height;
        if (aspectRatio > WIDE_CONTAINER_ASPECT_RATIO) {
            setIsContainerWide(true);
        } else {
            setIsContainerWide(false);
        }
    }, [containerSize]);
    return {containerRef, isContainerWide};
};
