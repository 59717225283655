import React, {useContext} from 'react';

import {
    FontVariant,
    Modal,
    Text,
    TextHeading,
    URLToLink,
} from '@pexip/components';
import type {FormInput, PopupRequest} from '@pexip/plugin-api';

import {Plugins, Channels} from '../../index';
import {usePopupRequest} from '../../hooks/usePopupRequest';

import {FormWrapper} from './Form';

export const PluginForms: React.FC = () => {
    const channelsRef = useContext(Channels);
    const plugins = useContext(Plugins);
    const openPopup = usePopupRequest();
    const forms = Object.values(plugins).flatMap(plugin => plugin.forms);

    const closeModal = (chanId: string, modalId: string) => {
        sendInput({chanId, modalId});
    };

    const sendInput = ({
        chanId,
        modalId,
        input = {},
        opensPopup,
    }: {
        chanId: string;
        modalId: string;
        input?: FormInput;
        opensPopup?: PopupRequest;
    }) => {
        if (opensPopup) {
            openPopup(chanId, opensPopup, input);
        }
        channelsRef?.current.get(chanId)?.sendEvent({
            event: 'ui:form:input',
            payload: {
                modalId: modalId,
                input,
            },
        });
    };

    return (
        <>
            {forms.map(({chanId, id, title, description, form, opensPopup}) => {
                return (
                    <Modal
                        key={id}
                        isOpen
                        withCloseButton
                        closeOnOutsideClick={false}
                        onClose={() => closeModal(chanId, id)}
                    >
                        <TextHeading
                            className="mb-2"
                            htmlTag="h2"
                            fontVariant={FontVariant.H4}
                        >
                            {title}
                        </TextHeading>
                        <Text fontVariant={FontVariant.H6} variant="tinted">
                            <URLToLink text={description ?? ''} />
                        </Text>
                        <FormWrapper
                            elements={form.elements}
                            submitTitle={form.submitBtnTitle}
                            onSubmitInput={input =>
                                sendInput({
                                    chanId,
                                    modalId: id,
                                    input,
                                    opensPopup,
                                })
                            }
                        />
                    </Modal>
                );
            })}
        </>
    );
};
