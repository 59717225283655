import type {Url, BuildUrlOptions} from './url';
import {toUrl, buildUrl} from './url';

/**
 * History interface proxy returned from {@link createHistory}
 */
export interface BrowserHistory {
    /**
     * The state of the browser history
     */
    state: Url['state'];
    /**
     * Moving backward through the user's history
     * This acts exactly as if the user clicked on the Back button in their
     * browser toolbar
     */
    back: () => void;
    /**
     * Moving forward through the user's history is done using the {@link
     * BrowserHistory.back}
     *
     * Similar to the Forward button in the browser
     */
    forward: () => void;
    /**
     * Refresh the current document, similar to Refresh button in the browser
     */
    refresh: () => void;
    /**
     * Push a new history state with provided url
     *
     * This is the main feature drive the whole routing via links and signals
     */
    push: (urlOrLink: Url | string, options?: BuildUrlOptions) => void;
    /**
     * Replace the current history state with provided url
     */
    replace: (urlOrLink: Url | string, options?: BuildUrlOptions) => void;
    /**
     * Replace the state with provided key and value
     */
    replaceState: (key: string, value: unknown) => void;
}

/**
 * Create a History API proxy to send signals to observers
 *
 * @param history - Inject a custom history compatible with with History
 * interface for testing or other purposes, if not provided, a global `history`
 * object will be used.
 * @param location - Inject a custom location object for testing purpose
 *
 * @returns BrowserHistory See {@link BrowserHistory}
 */
export const createHistory = (
    onRequestToChangeUrl: (url: Url) => void,
    history: Pick<
        History,
        'go' | 'back' | 'pushState' | 'forward' | 'replaceState' | 'state'
    > = window.history,
    location: Pick<Location, 'replace' | 'href'> = window.location,
): BrowserHistory => {
    const back = () => {
        history.back();
    };
    const forward = () => {
        history.forward();
    };
    const refresh = () => {
        history.go();
    };
    const push: BrowserHistory['push'] = (urlOrLink, options) => {
        const url = toUrl(urlOrLink, options);

        // Signal url request before the execution
        onRequestToChangeUrl(url);

        // Internal Url
        if (url.isInternal()) {
            history.pushState(url.state, '', url.toInternalHref());
        } else {
            // External Url
            const href = url.toString();
            location.href = href;
        }
    };
    const replace: BrowserHistory['replace'] = (urlOrLink, options) => {
        const url = toUrl(urlOrLink, options);

        // Signal url request before the execution
        onRequestToChangeUrl(url);

        // Internal Url
        if (url.isInternal()) {
            history.replaceState(url.state, '', url.toInternalHref());
        } else {
            // External Url
            const href = url.toString();
            location.replace(href);
        }
    };

    const replaceState = (key: string, value: unknown) => {
        /* eslint-disable @typescript-eslint/no-unsafe-member-access -- this is from typescript */
        const newState =
            history.state?.[key] !== undefined
                ? Object.keys(history.state).reduce((prev, next) => {
                      if (next === key) {
                          return {
                              ...prev,
                              [key]: value,
                          };
                      }
                      return prev;
                  }, {})
                : (history.state as Url['state']);
        /* eslint-enable @typescript-eslint/no-unsafe-member-access -- this is from typescript */
        const url = buildUrl(location.href, {state: newState});
        replace(url);
    };

    return {
        get state() {
            return history.state as Url['state'];
        },
        back,
        forward,
        refresh,
        push,
        replace,
        replaceState,
    };
};
