import type {MeetingPanelType, MeetingPanelsState} from '../types';

export enum PanelAnimationTypes {
    NONE = 'none',
    BREAKOUT_ROOMS_IN = 'breakout-rooms-in',
    BREAKOUT_ROOMS_OUT = 'breakout-rooms-out',
    CHAT_IN = 'chat-in',
    CHAT_OUT = 'chat-out',
    PARTICIPANTS_IN = 'participants-in',
    PARTICIPANTS_OUT = 'participants-out',
    PLUGINS_IN = 'plugins-in',
    PLUGINS_OUT = 'plugins-out',
}

export const calculatePanelAnimation = (state: {
    currentBreakoutRooms: boolean;
    currentChat: boolean;
    currentParticipants: boolean;
    currentPlugins: boolean;
    nextBreakoutRooms: boolean;
    nextChat: boolean;
    nextParticipants: boolean;
    nextPlugins: boolean;
}) => {
    const {
        currentBreakoutRooms,
        currentChat,
        currentParticipants,
        currentPlugins,
        nextBreakoutRooms,
        nextChat,
        nextParticipants,
        nextPlugins,
    } = state;

    const areAllPanelsClosed =
        !currentChat &&
        !currentParticipants &&
        !currentBreakoutRooms &&
        !currentPlugins;
    const shouldPanelBeOpen =
        nextChat || nextParticipants || nextBreakoutRooms || nextPlugins;

    const shouldOpenBreakoutRooms = () =>
        areAllPanelsClosed && nextBreakoutRooms;
    const shouldOpenChat = () => areAllPanelsClosed && nextChat;
    const shouldOpenParticipants = () => areAllPanelsClosed && nextParticipants;
    const shouldOpenPlugins = () => areAllPanelsClosed && nextPlugins;

    const shouldCloseBreakoutRooms = () =>
        currentBreakoutRooms && !shouldPanelBeOpen;
    const shouldCloseChat = () => currentChat && !shouldPanelBeOpen;
    const shouldCloseParticipants = () =>
        currentParticipants && !shouldPanelBeOpen;
    const shouldClosePlugins = () => currentPlugins && !shouldPanelBeOpen;

    if (shouldOpenChat()) {
        return PanelAnimationTypes.CHAT_IN;
    }

    if (shouldOpenParticipants()) {
        return PanelAnimationTypes.PARTICIPANTS_IN;
    }

    if (shouldOpenBreakoutRooms()) {
        return PanelAnimationTypes.BREAKOUT_ROOMS_IN;
    }

    if (shouldOpenPlugins()) {
        return PanelAnimationTypes.PLUGINS_IN;
    }

    if (shouldCloseChat()) {
        return PanelAnimationTypes.CHAT_OUT;
    }

    if (shouldCloseParticipants()) {
        return PanelAnimationTypes.PARTICIPANTS_OUT;
    }

    if (shouldCloseBreakoutRooms()) {
        return PanelAnimationTypes.BREAKOUT_ROOMS_OUT;
    }

    if (shouldClosePlugins()) {
        return PanelAnimationTypes.PLUGINS_OUT;
    }

    return PanelAnimationTypes.NONE;
};

const calculateNextState = (
    panel: MeetingPanelType,
    {
        openChatPanel,
        openParticipantPanel,
        openBreakoutRoomsPanel,
        openPluginsPanel,
    }: {
        openChatPanel: boolean;
        openParticipantPanel: boolean;
        openBreakoutRoomsPanel: boolean;
        openPluginsPanel: boolean;
    },
) => {
    if (panel === 'chat') {
        return !openChatPanel;
    }
    if (panel === 'participants') {
        return !openParticipantPanel;
    }
    if (panel === 'plugins') {
        return !openPluginsPanel;
    }
    return !openBreakoutRoomsPanel;
};

/**
 * @returns `open` to indicate if one of the panels is open
 */
export const togglePanel =
    (panel: MeetingPanelType) =>
    (panelsState: MeetingPanelsState): [boolean, PanelAnimationTypes] => {
        const isOpen = calculateNextState(panel, {
            openChatPanel: panelsState.openChatPanel,
            openParticipantPanel: panelsState.openParticipantPanel,
            openBreakoutRoomsPanel: panelsState.openBreakoutRoomsPanel,
            openPluginsPanel: panelsState.openPluginsPanel,
        });

        const isChatToShow = panel === 'chat' && isOpen;
        const isParticipantsToShow = panel === 'participants' && isOpen;
        const isBreakoutRoomsToShow = panel === 'breakoutRooms' && isOpen;
        const isPluginsToShow = panel === 'plugins' && isOpen;

        const animationType = calculatePanelAnimation({
            currentBreakoutRooms: panelsState.openBreakoutRoomsPanel,
            currentChat: panelsState.openChatPanel,
            currentParticipants: panelsState.openParticipantPanel,
            currentPlugins: panelsState.openPluginsPanel,
            nextBreakoutRooms: isBreakoutRoomsToShow,
            nextChat: isChatToShow,
            nextParticipants: isParticipantsToShow,
            nextPlugins: isPluginsToShow,
        });

        return [isOpen, animationType];
    };
