import React from 'react';

import type {ColorScheme} from '@pexip/components';
import {FontVariant, ProgressBar, Text} from '@pexip/components';

import styles from './AudioMeterView.module.scss';

export const AudioMeterView: React.FC<{
    colorScheme?: ColorScheme;
    progress: number;
    text?: string;
    width?: number;
}> = ({colorScheme, progress, text, width, ...props}) => (
    <div className={styles.audioMeterWrapper} {...props}>
        <div style={{width: width ?? '100%'}}>
            <ProgressBar progress={progress} colorScheme={colorScheme} />
        </div>
        {text && (
            <div className="text-right">
                <Text fontVariant={FontVariant.Small}> {text}</Text>
            </div>
        )}
    </div>
);
