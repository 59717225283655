import {useState} from 'react';

export type ControlledTooltip = {
    controlledIsVisible?: boolean;
    controlledOnChange?: (isVisible: boolean) => void;
};

export const useTooltip = ({
    controlledIsVisible,
    controlledOnChange,
}: ControlledTooltip = {}) => {
    const [isVisibleLocal, setVisibleLocal] = useState(false);
    const isControlledValue = controlledIsVisible !== undefined;
    const isVisible = isControlledValue ? controlledIsVisible : isVisibleLocal;

    const setVisible = ({
        isVisible,
        isControlled = false,
    }: {
        isVisible: boolean;
        isControlled?: boolean;
    }) => {
        if (!isControlledValue) {
            setVisibleLocal(isVisible);
        } else {
            setVisibleLocal(false);
        }

        if (isControlled) {
            controlledOnChange?.(isVisible);
        }
    };

    return {
        isVisible,
        setVisible,
    };
};
