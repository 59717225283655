import type {MediaDeviceInfoLike} from './types';
import {MediaDeviceKinds} from './types';

export const isSameDeviceKind =
    (kind: MediaDeviceKinds) => (device: MediaDeviceInfoLike) =>
        device.kind === kind;

export const isAudioInput = isSameDeviceKind(MediaDeviceKinds.AUDIOINPUT);

export const isVideoInput = isSameDeviceKind(MediaDeviceKinds.VIDEOINPUT);

export const isAudioOutput = isSameDeviceKind(MediaDeviceKinds.AUDIOOUTPUT);

/**
 * Check if provided device is a permission-granted device by inspecting the
 * device label
 *
 * @param device - The device to check
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDeviceInfo/label#value}
 **/
export const isDeviceGranted = (device: MediaDeviceInfoLike) =>
    Boolean(device.label);

export const not =
    <T = void>(fn: (arg: T) => boolean) =>
    (arg: T) =>
        !fn(arg);

/**
 * Compare 2 devices to see if they are the same by `deviceId` or `label` as
 * a fallback
 *
 * @beta
 */
export const compareDevices =
    (
        deviceInfo: MediaDeviceInfoLike,
        key: Exclude<
            keyof MediaDeviceInfoLike,
            'kind' | 'settings'
        > = 'deviceId',
    ) =>
    (anotherDeviceInfo: MediaDeviceInfoLike) =>
        deviceInfo.kind === anotherDeviceInfo.kind &&
        deviceInfo[key] === anotherDeviceInfo[key];

/**
 * Lookup the the `device` from `devices` list
 *
 * @remarks
 * When we cannot find the device by `deviceId`, we compare the label as last
 * resort, and return the first one.
 *
 * @param deviceToFind - Provide a device info to be used for the searching
 * @param useFallback - Whether to use `label` as a fallback when there is no
 * match from using `deviceId`
 *
 * @beta
 */
export const findDevice =
    (deviceToFind: MediaDeviceInfoLike, useFallback = true) =>
    (devices: readonly MediaDeviceInfoLike[]) => {
        const compareIDTo = compareDevices(deviceToFind);
        const found = devices.find((device: MediaDeviceInfoLike) =>
            compareIDTo(device),
        );

        if (found || !useFallback) {
            return found;
        }

        // Fallback to use label for the comparison
        // and return the first matched, otherwise `undefined`
        const compareLabelTo = compareDevices(deviceToFind, 'label');
        return devices.find((device: MediaDeviceInfoLike) =>
            compareLabelTo(device),
        );
    };
