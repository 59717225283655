import React, {useCallback} from 'react';

import {useConfig} from '../config';
import {AccessibilitySettingsView} from '../views/AccessibilitySettings/AccessibilitySettings.view';

export const AccessibilitySettings: React.FC<{
    onBackClick: () => void;
}> = ({onBackClick}) => {
    const [alwaysDisplayUIInterfaces, setAlwaysDisplayUIInterfacesConfig] =
        useConfig('alwaysDisplayUIInterfaces');

    const toggleAlwaysDisplayUIInterfaces = useCallback(() => {
        setAlwaysDisplayUIInterfacesConfig(currentValue => !currentValue, true);
    }, [setAlwaysDisplayUIInterfacesConfig]);

    return (
        <AccessibilitySettingsView
            alwaysDisplayUIInterfaces={alwaysDisplayUIInterfaces}
            toggleAlwaysDisplayUIInterfaces={toggleAlwaysDisplayUIInterfaces}
            onBackClick={onBackClick}
        />
    );
};
