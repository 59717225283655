import type {ReactElement} from 'react';
import React from 'react';
import cx from 'classnames';

import {Text, TextHeading} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './VideoStatus.module.scss';

export const VideoStatus: React.FC<{
    title: string;
    body: string | ReactElement;
    isPhone: boolean;
}> = ({title, body, isPhone}) => (
    <div
        className={cx(styles.videoStatus, {
            [styles.phone]: isPhone,
            'pl-5': !isPhone,
            'px-4': isPhone,
        })}
    >
        <TextHeading
            className="pb-1"
            htmlTag="h5"
            data-testid={TestId.VideoStatusTitle}
        >
            {title}
        </TextHeading>
        <Text variant="tinted" data-testid={TestId.VideoStatusBody}>
            {body}
        </Text>
    </div>
);

export type VideoStatusProps = React.ComponentProps<typeof VideoStatus>;
