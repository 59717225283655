import {useMemo} from 'react';

import type {OverlayOpacity} from '../components/foundation/FullSizeWindow/FullSizeWindow';
import type {
    ColorScheme,
    FullSizeWindowBackgroundVariant,
} from '../types/variants';
import type {BackgroundImageSrc, BlendMode} from '../types/images';
import {
    getBackgroundImageCSSVariablesFromBreakpointImageSet,
    isBackgroundImageSrcBreakpointImageSet,
    isBackgroundImageSrcEmpty,
    isBackgroundImageSrcUrl,
} from '../utils/imageSrc';
import {RgbColor} from '../../design-tokens/generated/colors';

export const useFullSizeWindowBackground = ({
    background,
    backgroundImageSrc,
    backgroundBlendMode,
    overlayOpacity,
    overlay,
}: {
    background: 'image' | FullSizeWindowBackgroundVariant;
    backgroundImageSrc?: BackgroundImageSrc;
    backgroundBlendMode: BlendMode;
    overlayOpacity: OverlayOpacity;
    overlay: ColorScheme;
}) =>
    useMemo(() => {
        const isBackgroundImage =
            background === 'image' &&
            !isBackgroundImageSrcEmpty(backgroundImageSrc);
        const isBackgroundImageBreakpointImageSet =
            isBackgroundImage &&
            isBackgroundImageSrcBreakpointImageSet(backgroundImageSrc);
        const isBackgroundImageBlend =
            isBackgroundImage && backgroundBlendMode !== 'normal';
        const isBackgroundNone = background === 'none';
        const isBackgroundDark =
            background === 'dark' ||
            (background === 'image' && !isBackgroundImage);
        const isBackgroundLight = background === 'light';

        let backgroundStyleProps: Record<string, string> = {};
        if (isBackgroundImage) {
            if (isBackgroundImageSrcUrl(backgroundImageSrc)) {
                backgroundStyleProps = {
                    ...backgroundStyleProps,
                    backgroundImage: `url("${backgroundImageSrc}")`,
                };
            }
            if (isBackgroundImageSrcBreakpointImageSet(backgroundImageSrc)) {
                const backgroundImageCSSVariables =
                    getBackgroundImageCSSVariablesFromBreakpointImageSet(
                        backgroundImageSrc,
                    );
                backgroundStyleProps = {
                    ...backgroundStyleProps,
                    ...backgroundImageCSSVariables,
                };
            }
        }

        if (isBackgroundImageBlend) {
            backgroundStyleProps = {
                ...backgroundStyleProps,
                '--blend-mode': backgroundBlendMode,
                '--background-color-opacity': `${overlayOpacity}`,
                '--background-color-rgb': `${
                    overlay === 'light' ? RgbColor.White : RgbColor.Black
                }`,
            };
        }

        return {
            isBackgroundNone,
            isBackgroundDark,
            isBackgroundLight,
            isBackgroundImage,
            isBackgroundImageBreakpointImageSet,
            isBackgroundImageBlend,
            backgroundStyleProps,
        };
    }, [
        background,
        backgroundImageSrc,
        backgroundBlendMode,
        overlayOpacity,
        overlay,
    ]);
