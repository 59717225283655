import React, {useCallback, useState, useMemo} from 'react';

import type {BreakoutParticipants, BreakoutRoomNames} from '../types';
import {isDuplicateRoomName} from '../utils/isDuplicateRoomName';
import {getBreakoutRooms} from '../utils/getBreakoutRooms';
import {DuplicateRoomNameModal} from '../views/DuplicateRoomNameModal/DuplicateRoomNameModal.view';

export const useBreakoutRoomNameChange = ({
    participants,
    setParticipants,
    breakoutRoomsNames,
    setBreakoutRoomsNames,
}: {
    participants: BreakoutParticipants;
    setParticipants: React.Dispatch<React.SetStateAction<BreakoutParticipants>>;
    breakoutRoomsNames?: BreakoutRoomNames;
    setBreakoutRoomsNames?: React.Dispatch<
        React.SetStateAction<BreakoutRoomNames>
    >;
}) => {
    const [showDuplicateRoomNameModal, setShowDuplicateRoomNameModal] =
        useState(false);
    const handleBreakoutRoomNameChange = useCallback(
        (targetId: string, newName: string) => {
            if (
                isDuplicateRoomName(newName, [
                    ...getBreakoutRooms(participants),
                    ...(breakoutRoomsNames
                        ? Object.values(breakoutRoomsNames)
                        : []),
                ])
            ) {
                setShowDuplicateRoomNameModal(true);
                return false;
            }

            if (
                breakoutRoomsNames &&
                setBreakoutRoomsNames &&
                Object.keys(breakoutRoomsNames).includes(targetId)
            ) {
                // renaming existing room
                setBreakoutRoomsNames({
                    ...breakoutRoomsNames,
                    [targetId]: newName,
                });
            } else {
                // renaming a new room
                setParticipants(participants => {
                    return Object.entries(
                        Object.fromEntries(participants),
                    ).reduce<BreakoutParticipants>(
                        (acc, [id, participants]) => {
                            if (id === targetId) {
                                acc.set(newName, participants);
                            } else {
                                acc.set(id, participants);
                            }
                            return acc;
                        },
                        new Map(),
                    );
                });
            }
            return true;
        },
        [
            participants,
            setParticipants,
            breakoutRoomsNames,
            setBreakoutRoomsNames,
        ],
    );

    const duplicateRoomNameModal = useMemo(
        () => (
            <DuplicateRoomNameModal
                isOpen={showDuplicateRoomNameModal}
                onConfirm={() => setShowDuplicateRoomNameModal(false)}
            />
        ),
        [showDuplicateRoomNameModal],
    );

    return {handleBreakoutRoomNameChange, duplicateRoomNameModal};
};
