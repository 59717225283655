import {useContext} from 'react';

import type {PopupRequest} from '@pexip/plugin-api';

import {Channels} from '..';
import {logger} from '../../logger';

export const usePopupRequest = () => {
    const channelsRef = useContext(Channels);

    return (
        channelId: string,
        popupRequest: PopupRequest,
        context?: Record<string, unknown>,
    ) => {
        const pluginWindow = channelsRef?.current.get(channelId)?.targetWindow;
        if (pluginWindow) {
            // this will only be possible if the plugin is 'same-origin'
            try {
                pluginWindow.plugin.popupManager.open(
                    popupRequest.id,
                    popupRequest.openParams,
                    context,
                );
            } catch (e) {
                logger.warn(
                    e,
                    `Plugins not hosted as part of the branding need to use the 'window.open' API. End-users will receive a prompt to allow popups the first time one is shown using this approach.`,
                );
            }
        }
    };
};
