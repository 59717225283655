import type {ProcessStatus} from '../../common/constants';
import {
    PROCESSING_HEIGHT,
    PROCESSING_WIDTH,
    FOREGROUND_THRESHOLD,
    BACKGROUND_BLUR_AMOUNT,
    EDGE_BLUR_AMOUNT,
    MASK_COMBINE_RATIO,
} from '../../common/constants';
import {createCanvasRenderUtils} from '../../common/canvasRenderUtils';
import type {
    ProcessInputType,
    VideoFrameLike,
    ImageRecord,
} from '../../common/types/media';
import type {RendererOptions} from '../../common/types/render';
import {clamping} from '../../common/utils';

import type {Segmenter, SegmentationTransform} from './types';

const clampInRangeFrom0To1 = clamping(0, 1);
const clampInRangeFrom0To9 = clamping(0, 9);
interface Options extends RendererOptions {
    selfManageSegmenter?: boolean;
    outputCanvas?: HTMLCanvasElement;
    outputDelegate?: OffscreenCanvas;
    backgroundImage?: ImageRecord;
    width?: number;
    height?: number;
}

interface Props extends RendererOptions {
    outputCanvas?: HTMLCanvasElement;
    outputDelegate?: OffscreenCanvas;
    backgroundImage?: ImageRecord;
    segmenter: Segmenter;
    status: ProcessStatus;
    width: number;
    height: number;

    utils: ReturnType<typeof createCanvasRenderUtils>;
}

export const createTransform = (
    segmenter: Segmenter,
    {
        width = PROCESSING_WIDTH,
        height = PROCESSING_HEIGHT,
        foregroundThreshold = FOREGROUND_THRESHOLD,
        backgroundBlurAmount = BACKGROUND_BLUR_AMOUNT,
        edgeBlurAmount = EDGE_BLUR_AMOUNT,
        maskCombineRatio = MASK_COMBINE_RATIO,
        backgroundImageUrl = '',
        effects = 'none',
        selfManageSegmenter,
        ...options
    }: Partial<Options> = {},
): SegmentationTransform & {
    outputCanvas: HTMLCanvasElement | undefined;
    outputDelegate: OffscreenCanvas | undefined;
    backgroundImage?: ImageRecord;
} => {
    const props: Props = {
        segmenter,
        width,
        height,
        foregroundThreshold,
        backgroundBlurAmount,
        edgeBlurAmount,
        utils: createCanvasRenderUtils(width, height),
        effects,
        maskCombineRatio,
        backgroundImage: options.backgroundImage,
        status: 'new',
        backgroundImageUrl,
        outputCanvas: options.outputCanvas,
        outputDelegate: options.outputDelegate,
    };

    const toVideoFrameLike = (
        input: ProcessInputType | HTMLCanvasElement,
    ): VideoFrameLike => {
        // Standardize the input
        const image =
            input instanceof ImageData
                ? props.utils.renderImageDataToOffScreenCanvas(
                      input,
                      'drawImageDataCanvas',
                  )
                : input;
        // Pre-precessing the input to resize to the processing size
        const canvas = props.utils.renderImageToOffScreenCanvas(
            image,
            'inputCanvas',
        );
        if ('close' in input) {
            input.close();
        }
        return {
            frame: canvas.transferToImageBitmap(),
            width,
            height,
            timestamp: performance.now(),
        };
    };
    const processInput = async (
        input: ProcessInputType | HTMLCanvasElement,
    ) => {
        const renderOptions = {
            foregroundThreshold: props.foregroundThreshold,
            backgroundBlurAmount: props.backgroundBlurAmount,
            edgeBlurAmount: props.edgeBlurAmount,
            maskCombineRatio: props.maskCombineRatio,
            backgroundImageUrl: props.backgroundImageUrl,
            effects: props.effects,
        };
        if (
            props.segmenter.status === 'new' ||
            props.segmenter.status === 'closed'
        ) {
            await props.segmenter.open({
                output: props.outputDelegate,
                processingWidth: props.width,
                processingHeight: props.height,
                backgroundImage: props.backgroundImage,
                renderOptions,
            });
        }
        if (props.segmenter.status === 'opening') {
            return undefined;
        }
        const inputFrame = toVideoFrameLike(input);
        return props.segmenter.process(inputFrame, renderOptions);
    };

    return {
        get width() {
            return props.width;
        },
        get height() {
            return props.height;
        },
        get status() {
            return props.status;
        },
        get foregroundThreshold() {
            return props.foregroundThreshold;
        },
        get backgroundBlurAmount() {
            return props.backgroundBlurAmount;
        },
        get edgeBlurAmount() {
            return props.edgeBlurAmount;
        },
        get effects() {
            return props.effects;
        },
        get maskCombineRatio() {
            return props.maskCombineRatio;
        },
        set foregroundThreshold(value) {
            props.foregroundThreshold = clampInRangeFrom0To1(value);
        },
        set backgroundBlurAmount(value) {
            props.backgroundBlurAmount = clampInRangeFrom0To9(value);
        },
        set edgeBlurAmount(value) {
            props.edgeBlurAmount = clampInRangeFrom0To9(value);
        },
        set effects(value) {
            props.effects = value;
        },
        set backgroundImageUrl(value) {
            props.backgroundImageUrl = value;
        },
        get backgroundImageUrl() {
            return props.backgroundImageUrl;
        },
        set maskCombineRatio(value) {
            props.maskCombineRatio = clampInRangeFrom0To1(value);
        },
        get outputCanvas() {
            return props.outputCanvas;
        },
        get backgroundImage() {
            return props.backgroundImage;
        },
        set backgroundImage(image) {
            if (
                image &&
                image.key !== props.backgroundImage?.key &&
                image.image !== props.backgroundImage?.image
            ) {
                props.backgroundImage = image;
                if (
                    props.segmenter.status !== 'new' &&
                    props.segmenter.status !== 'closed'
                ) {
                    void props.segmenter.update({backgroundImage: image});
                }
            }
        },
        set outputCanvas(canvas) {
            props.outputCanvas = canvas;
        },
        get outputDelegate() {
            return props.outputDelegate;
        },
        set outputDelegate(canvas) {
            props.outputDelegate = canvas;
        },
        get segmenter() {
            return props.segmenter;
        },
        set segmenter(value) {
            if (value !== props.segmenter) {
                props.segmenter = value;
            }
        },
        init: () => {
            props.status = 'opened';
            return Promise.resolve();
        },
        transform: async (videoFrame, controller) => {
            switch (props.effects) {
                case 'overlay':
                case 'blur': {
                    const result = await processInput(videoFrame);
                    const processedFrame = props.outputCanvas ?? result?.frame;
                    if (
                        props.status === 'closed' ||
                        // Avoid processing disposed frame
                        !processedFrame ||
                        ('displayWidth' in processedFrame &&
                            processedFrame.displayWidth === 0) ||
                        ('width' in processedFrame &&
                            processedFrame.width === 0)
                    ) {
                        break;
                    }
                    controller.enqueue(props.outputCanvas ?? result?.frame);
                    result?.frame.close();
                    break;
                }
                case 'none': {
                    controller.enqueue(videoFrame);
                    break;
                }
            }
            props.status = 'processing';
        },
        close: () => {
            if (!selfManageSegmenter) {
                segmenter.close();
            }
            props.status = 'closed';
        },
        destroy: async () => {
            if (!selfManageSegmenter) {
                await segmenter.destroy();
            }
            props.status = 'destroyed';
        },
    };
};
