import {useEffect, useState} from 'react';
import i18n from 'i18next';

import type {TestIdValue} from '../types';
import {logger} from '../logger';
import {brandingLngs, ALLOWED_LANGUAGES, initI18next} from '../i18n';
import {config} from '../config';
import {SHADES} from '../constants';
import {setApplicationConfig} from '../applicationConfig';
import {transformHiddenFunctionalityArrayToObject} from '../utils/transformHiddenFunctionalityArrayToObject';
import {parseLegacyParams} from '../utils/parseLegacyParams';

import type {Favicon, Manifest} from '.';
import {getBrandingPath, DEFAULT, loadBranding} from '.';

const setBrandApplicationConfig = (
    brandingApplicationConfig: Manifest['applicationConfig'] = {},
) => {
    for (const [key, value] of Object.entries(brandingApplicationConfig)) {
        setApplicationConfig(
            key as keyof Manifest['applicationConfig'],
            key === 'hiddenFunctionality'
                ? transformHiddenFunctionalityArrayToObject(
                      value as TestIdValue[],
                  )
                : value,
        );
    }
};

const setBrandDefaultUserConfig = (
    defaultUserConfig: Manifest['defaultUserConfig'] = {},
) => {
    for (const [k, value] of Object.entries(defaultUserConfig)) {
        const key = k as keyof Manifest['defaultUserConfig'];
        // Apply only for unchanged defaults
        if (config.isDefaultValue(key)) {
            config.set({key, value});
        }
    }
};

const setBrandTranslations = (translations: Manifest['translations'] = {}) => {
    for (const [lng, path] of Object.entries(translations)) {
        brandingLngs.set(lng, getBrandingPath(path));
        // FIXME: find better way to reload languages
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- I know what Im doing (maybe)
        delete i18n.services.backendConnector?.state?.[`${lng}|translation`];
    }

    i18n.reloadResources().catch(() => {
        logger.warn(`Can't reload translation`);
    });
};

const setBrandPalette = (colorPalette: Manifest['colorPalette']) => {
    if (colorPalette && colorPalette.length > 0) {
        if (colorPalette.length !== SHADES.length) {
            logger.warn(
                'color palette has too many/few elements. check your branding manifest version.',
            );
        }
        colorPalette.forEach((color, idx) => {
            document.documentElement.style.setProperty(
                `--color-brand-${SHADES[idx]}`,
                color,
            );
        });
    } else {
        SHADES.forEach(shade =>
            document.documentElement.style.removeProperty(
                `--color-brand-${shade}`,
            ),
        );
    }
};

const setBrandBgColor = (backgroundColor: Manifest['backgroundColor']) => {
    if (backgroundColor) {
        document.documentElement.style.setProperty(
            '--color-brand-background',
            backgroundColor,
        );
    } else {
        document.documentElement.style.removeProperty(
            '--color-brand-background',
        );
    }
};

const setBrandApplicationTitle = (appTitle: Manifest['appTitle']) => {
    if (appTitle) {
        document.title = appTitle;
    }
};

const setBgReplacementAssets = (
    bgImageAssets: Manifest['applicationConfig']['bgImageAssets'],
    backgroundImageUrl: Manifest['defaultUserConfig']['bgImageUrl'],
) => {
    // Normalization of the old brands, makes sure we always have bgImageAssets if bgImageUrl is specified
    if (backgroundImageUrl && !bgImageAssets) {
        setApplicationConfig('bgImageAssets', [backgroundImageUrl]);
    }
};

const setFavicon = (favicon?: Favicon) => {
    if (!favicon || !('href' in favicon)) {
        return;
    }
    const link =
        (document.querySelector('link[rel=icon]') as HTMLLinkElement) ??
        document.createElement('link');
    link.href = getBrandingPath(favicon.href);
    document.head.appendChild(link);
};

const isValidAvailableLanguages = (
    availableLngs: string[] | undefined,
    allLngs: string[],
) => {
    if (!availableLngs || availableLngs.length === 0) {
        return false;
    }
    return availableLngs.some(lng => allLngs.includes(lng));
};

export const setLanguages = (
    availableLanguages: Manifest['availableLanguages'],
    translations: Manifest['translations'],
) => {
    let allLanguages = [
        ...ALLOWED_LANGUAGES,
        ...Object.keys(translations).filter(
            k => !ALLOWED_LANGUAGES.includes(k),
        ),
    ];

    // If available languages are defined: All languages = available languages
    if (isValidAvailableLanguages(availableLanguages, allLanguages)) {
        allLanguages = allLanguages.filter(lng =>
            availableLanguages?.includes(lng),
        );
    }

    void initI18next(allLanguages, true).then(() =>
        i18n.changeLanguage(undefined),
    ); // undefined triggers the language detector

    return allLanguages;
};

export function useBrandingLoader() {
    const [brand, setBrand] = useState<Manifest>();
    useEffect(() => {
        let ignore = false;
        // TODO: How do we handle missing branding?
        loadBranding()
            .then(brand => {
                if (!ignore) {
                    setBrand(brand);
                }
            })
            .catch((error: unknown) => {
                if (!ignore) {
                    setBrand(DEFAULT);
                }
                logger.warn({error}, 'failed to load branding');
            });
        return () => {
            ignore = true;
        };
    }, []);

    useEffect(() => {
        if (brand) {
            try {
                setBrandApplicationConfig(brand.applicationConfig);
                setFavicon(brand.favicon);
                setBrandDefaultUserConfig(brand.defaultUserConfig);
                setBrandApplicationTitle(brand.appTitle);
                setBrandPalette(brand.colorPalette);
                setBrandBgColor(brand.backgroundColor);
                setBrandTranslations(brand.translations);
                setBgReplacementAssets(
                    brand.applicationConfig?.bgImageAssets,
                    brand.defaultUserConfig?.bgImageUrl,
                );
                setLanguages(brand.availableLanguages, brand.translations);

                // always prioritise query params
                const paramsString =
                    location.search.substring(1) || location.hash.substring(3);
                if (!paramsString) {
                    return;
                }
                parseLegacyParams(paramsString);
            } catch (error: unknown) {
                setBrand(DEFAULT);
                logger.error({error}, 'branding manifest is invalid');
            }
        }
    }, [brand]);

    return brand;
}
