import React from 'react';
import {useTranslation} from 'react-i18next';

import type {ButtonProps, LogoSizeModifier} from '@pexip/components';
import {Icon, IconTypes} from '@pexip/components';

import {InMeetingInputControl} from '../../InputControl/InMeetingInputControl.view';
import {TestId} from '../../../../test/testIds';

export const RaiseHandButton: React.FC<
    ButtonProps & {
        isHandRaised: boolean;
        isRaiseHandHidden?: boolean;
        onRaiseHand: (raise: boolean) => void;
        iconSize: LogoSizeModifier;
        tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
    }
> = ({
    isHandRaised,
    isRaiseHandHidden = false,
    onRaiseHand,
    iconSize,
    tooltipPosition = 'top',
    ...props
}) => {
    const {t} = useTranslation();

    if (isRaiseHandHidden) {
        return null;
    }
    return (
        <InMeetingInputControl
            icon={<Icon source={IconTypes.IconRaiseHand} size={iconSize} />}
            aria-label={
                isHandRaised
                    ? t('common.lower-hand', 'Lower hand')
                    : t('common.raise-hand', 'Raise hand')
            }
            tooltipText={
                isHandRaised
                    ? t('common.lower-hand', 'Lower hand')
                    : t('common.raise-hand', 'Raise hand')
            }
            tooltipPosition={tooltipPosition}
            onClick={() => onRaiseHand(!isHandRaised)}
            isActive={isHandRaised}
            data-testid={
                isHandRaised ? TestId.ButtonLowerHand : TestId.ButtonRaiseHand
            }
            {...props}
        />
    );
};

export type RaiseHandButtonProps = React.ComponentProps<typeof RaiseHandButton>;
