import {useEffect, useMemo, useState} from 'react';

import type {MediaDeviceInfoLike} from '@pexip/media-control';
import {compareDevices} from '@pexip/media-control';

/**
 * Selects the saved audio output or a default if exist.
 *
 * It check first if a saved audio output `localStorage` is exist in current devices and
 * if it's not exist, it fetches the first audio output as default.
 */
export const useSelectAudioOutput = (
    devices: MediaDeviceInfoLike[],
    savedAudioOutput?: MediaDeviceInfoLike,
) => {
    const audioOutputs = useMemo(
        () => devices.filter(device => device.kind === 'audiooutput'),
        [devices],
    );
    const [selectedAudioOutput, setSelectedAudioOutput] = useState<
        MediaDeviceInfoLike | undefined
    >(getAudioOutput(audioOutputs, savedAudioOutput));

    useEffect(() => {
        const audioOutput = getAudioOutput(audioOutputs, savedAudioOutput)();

        if (audioOutput) {
            setSelectedAudioOutput(audioOutput);
        }
    }, [audioOutputs, savedAudioOutput]);

    return {
        selectedAudioOutput,
        setSelectedAudioOutput,
        savedAudioOutput,
    };
};

export const getPreferredAudioOutput = (
    devices: MediaDeviceInfoLike[],
    savedAudioOutput?: MediaDeviceInfoLike,
) => {
    if (savedAudioOutput) {
        return (
            devices.find(compareDevices(savedAudioOutput, 'label')) ||
            devices.find(compareDevices(savedAudioOutput, 'deviceId'))
        );
    }
};

const getAudioOutput =
    (devices: MediaDeviceInfoLike[], savedAudioOutput?: MediaDeviceInfoLike) =>
    () =>
        getPreferredAudioOutput(devices, savedAudioOutput) ||
        getDefaultAudioOutput(devices) ||
        getFirstAudioOutput(devices);

const getDefaultAudioOutput = (devices: MediaDeviceInfoLike[]) =>
    devices.find(device => device.deviceId === 'default');

const getFirstAudioOutput = (devices: MediaDeviceInfoLike[]) =>
    devices.find(device => device.kind === 'audiooutput');
