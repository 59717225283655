import React from 'react';
import {useTranslation} from 'react-i18next';

import type {ButtonProps, LogoSizeModifier} from '@pexip/components';
import {Button, Icon, IconTypes} from '@pexip/components';

import {ResponsiveButton} from '../../ResponsiveButton/ResponsiveButton.view';
import {TestId} from '../../../../test/testIds';
import styles from '../CallControls.module.scss';

export const LeaveButtonDesktop: React.FC<{
    onLeave: () => void;
}> = ({onLeave}) => {
    const {t} = useTranslation();

    return (
        <ResponsiveButton
            className={styles.desktopLeaveBtn}
            iconSource={IconTypes.IconPhoneHorisontal}
            onClick={onLeave}
            variant="danger"
            data-testid={TestId.ButtonLeave}
            hideChildren={false}
            size="large"
            aria-label={t('meeting.leave-aria-label', 'Leave meeting')}
            noEnhancerPadding={true}
        >
            <div className={styles.desktopLeaveText}>
                {t('meeting.leave', 'Leave')}
            </div>
        </ResponsiveButton>
    );
};

export const LeaveButtonTouch: React.FC<
    ButtonProps & {
        onLeave: () => void;
        iconSize: LogoSizeModifier;
    }
> = ({onLeave, iconSize, ...props}) => {
    const {t} = useTranslation();

    return (
        <Button
            onClick={onLeave}
            variant="danger"
            modifier="square"
            size="large"
            aria-label={t('meeting.leave-aria-label', 'Leave meeting')}
            data-testid={TestId.ButtonLeave}
            {...props}
        >
            <Icon source={IconTypes.IconPhoneHorisontal} size={iconSize} />
        </Button>
    );
};

export type LeaveButtonDesktopProps = React.ComponentProps<
    typeof LeaveButtonDesktop
>;
export type LeaveButtonTouchProps = React.ComponentProps<
    typeof LeaveButtonTouch
>;
