import React from 'react';
import cx from 'classnames';

import type {Cells} from '../Grid.types';
import {grid} from '../Grid.types';

import styles from './Cell.module.scss';

const isStyle = (
    t: string,
): t is keyof Omit<typeof styles, 'locals' | 'cell'> => t in styles;

const getStyles = (props: CellProps) =>
    grid.reduce((acc: string[], v) => {
        const size = `${v}${props[v] ?? ''}`;
        return isStyle(size) ? [...acc, styles[size]] : acc;
    }, []);

export const Cell: React.FC<
    React.ComponentProps<'div'> & {
        xs?: Cells;
        xsOffset?: Cells;
        sm?: Cells;
        smOffset?: Cells;
        md?: Cells;
        mdOffset?: Cells;
        lg?: Cells;
        lgOffset?: Cells;
        xl?: Cells;
        xlOffset?: Cells;
        hasVerticalGutter?: boolean;
    }
> = ({className, children, hasVerticalGutter, ...props}) => (
    <div
        className={cx(
            styles.cell,
            getStyles(props),
            {[styles.verticalGutter]: hasVerticalGutter},
            className,
        )}
    >
        {children}
    </div>
);

type CellProps = React.ComponentProps<typeof Cell>;
