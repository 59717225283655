import {isEmpty} from '@pexip/utils';

export const depthFirstSearch = <T extends {children?: T[]}>(
    nodes: T[],
    discover: (node: T) => boolean,
): T | undefined => {
    if (isEmpty(nodes)) {
        return;
    }
    for (const node of nodes) {
        const found = discover(node);
        if (found) {
            return node;
        }
        if (!isEmpty(node.children)) {
            const found = depthFirstSearch(node.children, discover);
            if (found) {
                return found;
            }
        }
    }
};

export function formatPath(
    data: TemplateStringsArray,
    ...interpolations: string[]
) {
    const out = [];
    for (let i = 0; i < data.length; i++) {
        out.push(data[i], encodeURIComponent(interpolations[i] ?? ''));
    }
    return out.join('');
}
