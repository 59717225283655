import React from 'react';

import {CircleCountDown, Row} from '@pexip/components';

import {YouCanSayTitle} from './YouCanSayTitle.view';
import {MicrophoneMonitorContentBox} from './MicrophoneMonitorContentBox.view';

const START_COUNT_FROM = 3;

export const GetReadyTestingYourMic: React.FC = () => (
    <div>
        <YouCanSayTitle className="mt-2 mb-4" />
        <Row>
            <MicrophoneMonitorContentBox>
                <CircleCountDown
                    shouldStartCountDown={true}
                    startCountFrom={START_COUNT_FROM}
                />
            </MicrophoneMonitorContentBox>
        </Row>
    </div>
);
