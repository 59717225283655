import {useEffect} from 'react';

import {useFocusFrom} from './useFocusFrom';

/**
 * Use this hook to set the focus point on an element when something
 * becomes opened, e.g. set focus on a panel container or menu container
 * when it becomes visible so that the next time the user hits on the
 * tab key the element that becomes focused on is potentially a tabbable
 * element inside the container element.
 * @param element - element which focus point should start from
 * @param isOpen - when isOpen is true the element will get the focus point
 */
export const useFocusFromOpened = (
    element: HTMLElement | null,
    isOpen: boolean,
) => {
    const {focusFrom} = useFocusFrom(element);

    useEffect(() => {
        if (isOpen) {
            focusFrom();
        }
    }, [isOpen, focusFrom]);
};
