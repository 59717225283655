import {useCallback, useRef, useState} from 'react';

import type {BreakoutParticipants} from '../types';

import type {useBreakoutChangeParticipantRoom} from './useBreakoutChangeParticipantRoom';

export const useBreakoutParticipantDrag = ({
    participants,
    changeParticipantRoom,
    setIsDragging,
}: {
    participants: BreakoutParticipants;
    changeParticipantRoom: ReturnType<typeof useBreakoutChangeParticipantRoom>;
    setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const roomElements = useRef<Record<string, HTMLDivElement>>({});
    const [targetRoomId, setTargetRoomId] = useState<string>();

    const getBreakoutRoomRefCallback =
        (roomId: string) => (element: HTMLDivElement | null) => {
            if (element) {
                roomElements.current[roomId] = element;
            }
        };

    const matchRoom = useCallback(
        (element: HTMLElement) => {
            const participantRect = element.getBoundingClientRect();
            for (const roomId in roomElements.current) {
                const newRoom = roomElements.current[roomId];
                if (!newRoom) {
                    return;
                }
                const roomRect = newRoom.getBoundingClientRect();
                const matchX =
                    participantRect.left >= roomRect.left &&
                    participantRect.left <= roomRect.right;
                const matchY =
                    participantRect.top > roomRect.top &&
                    participantRect.bottom < roomRect.bottom;

                if (matchX && matchY) {
                    if (
                        // if participant was dropped in the same room, don't do anything
                        participants
                            .get(roomId)
                            ?.find(
                                participant =>
                                    participant.identity === element.id,
                            )
                    ) {
                        return;
                    }
                    return roomId;
                }
            }
        },
        [participants],
    );

    const onParticipantDragging = useCallback(
        (element: HTMLElement) => {
            setIsDragging(true);
            setTargetRoomId(matchRoom(element));
        },
        [matchRoom, setIsDragging],
    );

    const onParticipantDrag = useCallback(
        (element: HTMLElement) => {
            setTargetRoomId(undefined);
            setIsDragging(false);
            const newRoomId = matchRoom(element);
            if (newRoomId) {
                changeParticipantRoom(element.id, newRoomId);
            }
        },
        [setIsDragging, matchRoom, changeParticipantRoom],
    );

    return {
        targetRoomId,
        onParticipantDragging,
        onParticipantDrag,
        getBreakoutRoomRefCallback,
    };
};
