import React from 'react';
import cx from 'classnames';

import type {TextVariant} from '../../../types/variants';
import type {IconSource} from '../../../../design-tokens/generated/Icons';
import type {InteractiveElementProps} from '../InteractiveElement/InteractiveElement';
import {Text} from '../../elements/Text/Text';
import {Icon} from '../../elements/Icon/Icon';
import type {BaseSizeModifier} from '../../../types/sizes';
import {ListBoxLink} from '../ListBoxLink/ListBoxLink';

import styles from './ListLink.module.scss';

export const ListLink: React.FC<
    InteractiveElementProps & {
        children: React.ReactNode;
        variant?: TextVariant;
        hasBorder?: boolean;
        icon?: IconSource;
        iconColor?: string;
        isFixedIcon?: boolean;
        isDisabled?: boolean;
        sizeModifier?: BaseSizeModifier;
        // Needs to be defined explicitly for TS to get the typing
        onClick?: (
            e:
                | React.MouseEvent<HTMLButtonElement>
                | React.MouseEvent<HTMLAnchorElement>
                | React.MouseEvent<HTMLDivElement>,
        ) => void;
    }
> = ({
    children,
    className,
    variant,
    hasBorder = false,
    icon,
    iconColor,
    isFixedIcon = false,
    sizeModifier = 'small',
    ...props
}) => (
    <ListBoxLink
        hasBorder={hasBorder}
        sizeModifier={sizeModifier}
        className={cx(styles.listLink, className)}
        {...props}
    >
        <Text variant={variant}>{children}</Text>
        {icon && (
            <Icon
                className={cx({
                    [styles.hoverIcon]: !isFixedIcon,
                })}
                color={iconColor}
                source={icon}
                size="compact"
            />
        )}
    </ListBoxLink>
);
