import React from 'react';

import {Stats} from '@pexip/media-components';

import {callSignals} from '../signals/Call.signals';
import {infinityService} from '../services/InfinityClient.service';
import {useSecureCheckCode} from '../hooks/useSecureCheckCode';

export const NerdStats: React.FC<{onClose: () => void}> = ({onClose}) => {
    const secureCheckCode = useSecureCheckCode();
    return (
        <Stats
            isOpen
            onClose={onClose}
            statsSignal={callSignals.onRtcStats}
            cachedStats={infinityService.latestStats}
            callQualityStatsSignal={callSignals.onCallQualityStats}
            secureCheckCode={secureCheckCode}
        />
    );
};
