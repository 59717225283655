import React from 'react';

import {Icon, Bar, FontVariant, List, Text} from '@pexip/components';
import type {IconSource} from '@pexip/components';

import {useResponsiveStatsGrid} from '../../hooks/useResponsiveStatsGrid';

export enum QualityNames {
    GOOD = 'Good',
    OK = 'Ok',
    BAD = 'Bad',
    TERRIBLE = 'Terrible',
}

const qualityPercentage = (value: number) => `${Math.round(value * 100)}%`;

export const CallQualityVisualizer = React.forwardRef<
    HTMLCanvasElement,
    {
        width: number;
        height: number;
        quality: {
            name: QualityNames;
            value: number;
        };
        icon: IconSource;
    }
>(({width, height, quality: {name, value}, icon}, ref) => {
    const isBad = name === QualityNames.BAD || name === QualityNames.TERRIBLE;
    const {isGridXs, hideVisualizer} = useResponsiveStatsGrid();
    return (
        <Bar>
            <List spacing="none">
                <Text
                    fontVariant={
                        isGridXs ? FontVariant.XSmallBold : FontVariant.BodyBold
                    }
                    variant={isBad ? 'danger' : undefined}
                >
                    {qualityPercentage(value)}
                </Text>

                <Text
                    fontVariant={
                        isGridXs ? FontVariant.XSmall : FontVariant.Body
                    }
                    variant={isBad ? 'danger' : undefined}
                    isTruncated
                >
                    {name} <Icon size="mini" source={icon} />
                </Text>
            </List>
            {!hideVisualizer && (
                <canvas ref={ref} width={width} height={height} />
            )}
        </Bar>
    );
});

CallQualityVisualizer.displayName = 'CallQualityVisualizer';

export type CallQualityVisualizerProps = React.ComponentProps<
    typeof CallQualityVisualizer
>;
