import React, {forwardRef, useId} from 'react';
import cx from 'classnames';

import type {ColorScheme} from '../../../types/variants';

import styles from './FileUpload.module.scss';

export const FileUpload = forwardRef<
    HTMLInputElement,
    {
        accept?: string;
        ariaLabel?: string;
        colorScheme: ColorScheme;
        children?: React.ReactNode;
        className?: string;
        id?: string;
        isDisabled?: boolean;
        multiple?: boolean;
        onChange: React.ChangeEventHandler<HTMLInputElement>;
        testId?: string;
    }
>(
    (
        {
            accept,
            ariaLabel = 'Upload files input',
            children,
            className,
            id,
            isDisabled,
            multiple,
            onChange,
            testId,
        },
        ref,
    ) => {
        const defaultId = useId();
        const uniqueId = id ?? defaultId;

        return (
            <>
                <input
                    className={styles.fileUploadInput}
                    onChange={onChange}
                    type="file"
                    accept={accept}
                    multiple={multiple}
                    ref={ref}
                    id={uniqueId}
                    disabled={isDisabled}
                />
                <label
                    className={cx(styles.fileUploadLabel, className)}
                    htmlFor={uniqueId}
                    aria-label={ariaLabel}
                    data-testid={testId}
                >
                    {children}
                </label>
            </>
        );
    },
);

FileUpload.displayName = 'FileUpload';

export type FileUploadProps = React.ComponentProps<typeof FileUpload>;
