export enum ParticipantIndicatorToShow {
    NONE = 'none',
    UNMUTE = 'unmute',
    REMOVE = 'remove',
    MUTED = 'muted',
    CAMERA_MUTED = 'camera-muted',
    SPEAKING = 'speaking',
    HOST_ACTIONS = 'host-actions',
}

export type RowActionState = {
    isHost: boolean;
    isCameraMuted: boolean;
    isPresenting: boolean;
    hasChildCalls: boolean;
    isCurrentPeer?: boolean;
    canPerformHostActions: boolean;
    isHover: boolean;
    isMuted: boolean;
    isSpeaking: boolean;
};

export const getShouldShowIndicators = ({
    canPerformHostActions,
    isInteracted,
}: Pick<RowActionState, 'canPerformHostActions' | 'isCurrentPeer'> & {
    isInteracted: boolean;
}) => !canPerformHostActions || (canPerformHostActions && !isInteracted);

export const getShouldShowHostActions = ({
    canPerformHostActions,
    isCurrentPeer,
    isHost,
    isInteracted,
}: Pick<
    RowActionState,
    'isHost' | 'isCurrentPeer' | 'canPerformHostActions'
> & {isInteracted: boolean}) =>
    isHost && isInteracted && !isCurrentPeer && canPerformHostActions;

export const getShouldShowSelfActions = (
    isCurrentPeer: boolean,
    isInteracted: boolean,
) => isCurrentPeer && isInteracted;

export const displayMuteHandCursor = (
    isHost: boolean,
    isCurrentPeer: boolean,
) => {
    if (isHost && !isCurrentPeer) {
        return true;
    }

    return false;
};
