import i18next from 'i18next';

let {format} = new Intl.DateTimeFormat(undefined, {
    hour: '2-digit',
    hour12: false,
    minute: '2-digit',
});

i18next.on('languageChanged', lng => {
    ({format} = new Intl.DateTimeFormat(lng, {
        hour: '2-digit',
        hour12: false,
        minute: '2-digit',
    }));
});

const toTime = format;
export {toTime};
