import type {EnableInMeetingUIAutoHideState} from '@pexip/media-components';
import {createSignal} from '@pexip/signal';

export const enableInMeetingUIAutoHide =
    createSignal<EnableInMeetingUIAutoHideState>({
        name: 'meeting:ui:auto-hide',
        allowEmittingWithoutObserver: true,
    });

export const openDTMFModalSignal = createSignal<string | undefined>({
    name: 'meeting:open-dtmf-modal',
});

export const openPinModalSignal = createSignal({
    name: 'meeting-open-pin-modal',
});

export const openFeccGwModalSignal = createSignal({
    name: 'meeting-open-fecc-gw-modal',
});

export const userInitiatedDisconnectSignal = createSignal({
    name: 'user-initiated-disconnect-signal',
});
