import React from 'react';
import cx from 'classnames';

import type {ColorScheme} from '../../../types/variants';
import type {SizeModifier} from '../../../types/sizes';
import {SvgImage} from '../SvgImage/SvgImage';

import spinner from './Spinner.svg';

import styles from './Spinner.module.scss';

export const spinnerLabel = 'Loading';

export const Spinner: React.FC<
    React.ComponentProps<'div'> & {
        className?: string;
        label?: string;
        sizeModifier?: SizeModifier;
        colorScheme?: ColorScheme;
    }
> = ({
    className,
    label = spinnerLabel,
    sizeModifier = 'medium',
    colorScheme,
    ...props
}) => (
    <div
        role="status"
        className={cx(
            styles.wrapper,
            colorScheme && styles[colorScheme],
            className,
        )}
        {...props}
    >
        <span className={styles.label}>{label}</span>
        <SvgImage
            source={spinner}
            className={cx(styles.spinner, styles[sizeModifier])}
        />
    </div>
);

export type SpinnerProps = React.ComponentProps<typeof Spinner>;
