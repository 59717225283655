import React, {useState} from 'react';
import cx from 'classnames';

import {useFocusFromOpened} from '@pexip/components';

import styles from './ChatPanelContentWrapper.module.scss';

export const ChatPanelContentWrapper: React.FC<
    React.ComponentProps<'div'> & {
        children: React.ReactNode;
        className?: string;
        isShown: boolean;
        animateIn?: boolean;
        animateOut?: boolean;
    }
> = ({children, className, isShown, animateIn, animateOut, ...props}) => {
    const [containerElement, setContainerElement] =
        useState<HTMLDivElement | null>(null);
    useFocusFromOpened(containerElement, isShown);

    return (
        <div
            ref={setContainerElement}
            className={cx(
                styles.panel,
                styles.wrapper,
                {
                    [styles.show]: isShown,
                    [styles.animateIn]: !!animateIn,
                    [styles.animateOut]: !!animateOut,
                },
                className,
            )}
            {...props}
        >
            {children}
        </div>
    );
};
