import React from 'react';
import {useTranslation} from 'react-i18next';

import {FontVariant, TextHeading} from '@pexip/components';

import {TestYourMicStages} from './MicrophoneMonitorModal.view';

/*
t('media.test-your-microphone-title-[before-testing]', 'Test your microphone')
t('media.test-your-microphone-title-[get-ready]', 'Get ready to say something')
t('media.test-your-microphone-title-[recording]', 'Recording your voice...')
t('media.test-your-microphone-title-[playing-back]', 'Playback')
t('media.test-your-microphone-title-[playback-finished]', 'Playback')
*/

export const MicrophoneMonitorTitle: React.FC<{
    currentStage: TestYourMicStages;
}> = ({currentStage}) => {
    const {t} = useTranslation();

    return (
        <TextHeading htmlTag="h3" fontVariant={FontVariant.H3}>
            {t(
                [
                    // FIXME: Cast is wrong, but the combination of an array as
                    // an argument to t and typed t doesn't seem to work very well
                    `media.test-your-microphone-title-[${
                        currentStage as
                            | TestYourMicStages.BeforeTesting
                            | TestYourMicStages.GetReady
                            | TestYourMicStages.Recording
                            | TestYourMicStages.PlayingBack
                            | TestYourMicStages.PlaybackFinished
                    }]`,
                    `media.test-your-microphone-title-[${TestYourMicStages.BeforeTesting}]`,
                ],
                'Test your microphone',
            )}
        </TextHeading>
    );
};
