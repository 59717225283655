import {createSignal} from '@pexip/signal';

export const renderUserNamePageSignal = createSignal<boolean>({
    name: 'shouldRender:UserNamePage',
});

export const mobileGetStartedNextSignal = createSignal({
    name: 'mobileGetStartedNextSignal',
});

export const renderCustomStepSignal = createSignal<boolean>({
    name: 'shouldRender:CustomStepPage',
});
