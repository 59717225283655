import {useMemo} from 'react';

import {FontVariant} from '../../../../design-tokens/constants';

export const MAX_NUMBER = 99;

export const useAvatarNumber = (
    participantCount: number,
    maxParticipants = MAX_NUMBER,
) => {
    const isMoreThanMaxNumber = useMemo(
        () => participantCount > maxParticipants,
        [participantCount, maxParticipants],
    );

    const fontVariant: FontVariant = useMemo(
        () =>
            participantCount > 9 ? FontVariant.SmallBold : FontVariant.BodyBold,
        [participantCount],
    );

    return {isMoreThanMaxNumber, fontVariant};
};
