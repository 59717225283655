export interface EmptyArray<A> extends Array<A> {
    0: never;
}

/**
 * Verify if the provided unknown object is empty
 *
 * Check the test cases for the assumptions
 *
 * @param obj - An object to be tested
 */
export const isEmpty = <T, K extends string | number | symbol>(
    obj: T[] | undefined | Partial<Record<K, unknown>>,
): obj is EmptyArray<T> | Record<K, never> | undefined => {
    if (Array.isArray(obj)) {
        return obj.length === 0;
    }
    if (typeof obj === 'object' && obj !== null) {
        return Object.keys(obj).length === 0;
    }
    return !obj;
};
