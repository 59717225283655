import React from 'react';
import cx from 'classnames';

import type {BackgroundImageSrc, BlendMode} from '../../../types/images';
import type {
    ColorScheme,
    FullSizeWindowBackgroundVariant,
} from '../../../types/variants';
import {useFullSizeWindowBackground} from '../../../hooks/useFullSizeWindowBackground';

import styles from './FullSizeWindow.module.scss';

export type OverlayOpacity =
    | 0
    | 0.1
    | 0.2
    | 0.3
    | 0.4
    | 0.5
    | 0.6
    | 0.7
    | 0.8
    | 0.9
    | 1;

export interface FullSizeWindowCommonProps {
    padding?: 'small' | 'none';
    flexDirection?: 'row' | 'rowReverse' | 'column' | 'columnReverse';
    justifyContent?: 'flexStart' | 'center';
    position?: 'fixed' | 'absolute';
}

export type FullSizeWindowColorBackgroundProps = {
    background?: FullSizeWindowBackgroundVariant;
    backgroundImageSrc?: never;
    backgroundBlendMode?: never;
    overlayOpacity?: never;
    overlay?: never;
};

export type FullSizeWindowImageBackgroundProps = {
    background: 'image';
    backgroundImageSrc: BackgroundImageSrc;
    backgroundBlendMode?: BlendMode;
    overlayOpacity?: OverlayOpacity;
    overlay?: ColorScheme;
};

type FullSizeWindowBackgroundProps =
    | FullSizeWindowColorBackgroundProps
    | FullSizeWindowImageBackgroundProps;

export type FullSizeWindowProps = FullSizeWindowCommonProps &
    FullSizeWindowBackgroundProps;

export const FullSizeWindow = React.forwardRef<
    HTMLDivElement,
    React.ComponentProps<'div'> & FullSizeWindowProps
>(
    (
        {
            children,
            className,
            padding = 'small',
            background = 'none',
            flexDirection = 'row',
            justifyContent = 'flexStart',
            backgroundImageSrc,
            backgroundBlendMode = 'normal',
            overlayOpacity = 0.7,
            overlay = 'dark',
            position = 'fixed',
            style,
            ...props
        },
        ref,
    ) => {
        const isPaddingSmall = padding === 'small';
        const isPaddingNone = padding === 'none';

        const {
            isBackgroundNone,
            isBackgroundDark,
            isBackgroundLight,
            isBackgroundImage,
            isBackgroundImageBreakpointImageSet,
            isBackgroundImageBlend,
            backgroundStyleProps,
        } = useFullSizeWindowBackground({
            background,
            backgroundImageSrc,
            backgroundBlendMode,
            overlayOpacity,
            overlay,
        });

        return (
            <div
                style={{...style, ...backgroundStyleProps}}
                className={cx(
                    styles.fullSizeWindow,
                    {
                        [styles.paddingNone]: isPaddingNone,
                        [styles.paddingSmall]: isPaddingSmall,
                        [styles.backgroundNone]: isBackgroundNone,
                        [styles.backgroundDark]: isBackgroundDark,
                        [styles.backgroundLight]: isBackgroundLight,
                        [styles.backgroundImage]: isBackgroundImage,
                        [styles.backgroundImageBreakpointImageSet]:
                            isBackgroundImageBreakpointImageSet,
                        [styles.backgroundBlend]:
                            isBackgroundImage && isBackgroundImageBlend,
                    },
                    styles[flexDirection],
                    styles[justifyContent],
                    styles[position],
                    className,
                )}
                ref={ref}
                {...props}
            >
                {children}
            </div>
        );
    },
);

FullSizeWindow.displayName = 'FullSizeWindow';
