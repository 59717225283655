import {logger} from './logger';

export const initialLog = () => {
    const version = process.env.BUILD_HASH_FULL ?? 'BUILD_HASH_FULL missing';
    const dirty = process.env.BUILD_DIRTY ? '*' : '';

    logger.info(
        {
            buildHash: `${version}${dirty}`,
            ua: navigator.userAgent,
        },
        'Jasså? Døkk sit og glor',
    );
};
