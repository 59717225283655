// FILE GENERATED BY packages/components/design-tokens/build-scripts/fonts.js DO NOT CHANGE

export enum FontVariant {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    H5 = 'h5',
    H6 = 'h6',
    BodyBold = 'bodyBold',
    Button = 'button',
    Body = 'body',
    BodyLink = 'bodyLink',
    SmallBold = 'smallBold',
    Small = 'small',
    SmallLink = 'smallLink',
    XSmallBold = 'xSmallBold',
    XSmall = 'xSmall',
    XSmallLink = 'xSmallLink',
}
