import type {MediaDeviceInfoLike} from './types';

/**
 * MediaStreamTrack Events
 *
 * @remarks
 * See MDN {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrack#Events | Events}
 *
 * @beta
 */
export enum MediaEventType {
    /**
     * Sent to the MediaStreamTrack when the value of the muted property is
     * changed to true, indicating that the track is unable to provide data
     * temporarily (such as when the network is experiencing a service
     * malfunction).
     */
    Mute = 'mute',
    /**
     * Sent to the track when data becomes available again, ending the muted state.
     */
    Unmute = 'unmute',
    /**
     * Sent when playback of the track ends (when the value readyState changes to
     * ended).
     */
    Ended = 'ended',
    /**
     * Fired when a media input or output device is attached to or removed from
     * the user's computer.
     *
     * @remarks
     * See MDN {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices#Events | Events}
     */
    DevicesChanged = 'devices:changed',
    /**
     * Found new devices from authorized device list
     */
    DevicesFound = 'devices:found',

    /**
     * Lost devices from authorized device list
     */
    DevicesLost = 'devices:lost',

    /**
     * Lost device from authorized device list
     */
    DeviceLost = 'device:lost',

    /**
     * Unauthorized devices
     */
    DevicesUnauthorized = 'devices:unauthorized',

    /**
     * No Input Devices, and no further device events will be emitted
     */
    NoInputDevices = 'devices:noinput',

    /**
     * Other errors
     */
    Error = 'error',
    /**
     * When stream
     */
    Stream = 'stream',
}

/**
 * Event object by MediaEventType
 *
 * @beta
 */
export type Events =
    | {id: string; type: MediaEventType.Mute}
    | {id: string; type: MediaEventType.Unmute}
    | {id: string; type: MediaEventType.Ended}
    | {devices: MediaDeviceInfoLike[]; type: MediaEventType.DevicesChanged}
    | {
          devices: MediaDeviceInfoLike[];
          authorizedDevices: MediaDeviceInfoLike[];
          unauthorizedDevices: MediaDeviceInfoLike[];
          type: MediaEventType.DevicesFound;
      }
    | {
          devices: MediaDeviceInfoLike[];
          authorizedDevices: MediaDeviceInfoLike[];
          unauthorizedDevices: MediaDeviceInfoLike[];
          type: MediaEventType.DevicesLost;
      }
    | {
          devices: MediaDeviceInfoLike[];
          authorizedDevices: MediaDeviceInfoLike[];
          type: MediaEventType.DevicesUnauthorized;
      }
    | {devices: MediaDeviceInfoLike[]; type: MediaEventType.NoInputDevices}
    | {device: MediaDeviceInfoLike; type: MediaEventType.DeviceLost}
    | {error: Error; type: MediaEventType.Error}
    | {
          facingMode: boolean;
          stream: MediaStream;
          type: MediaEventType.Stream;
          video: boolean;
      };

export type Dispatch = (event: Events) => void;
/**
 * Custom Media Event
 * @beta
 */
export type MediaEvent = CustomEvent<Events>;

export const eventEmitter = () => {
    const element = document.createElement('a');

    const createEvent = (data: Events) => {
        return new CustomEvent('data', {detail: data});
    };

    return {
        dispatch(event: Events) {
            element.dispatchEvent(createEvent(event));
        },
        subscriber(
            listener: (event: MediaEvent) => void,
            onUnsubscribe: () => void,
        ) {
            element.addEventListener(
                'data',
                listener as EventListenerOrEventListenerObject,
                false,
            );
            return () => {
                element.removeEventListener(
                    'data',
                    listener as EventListenerOrEventListenerObject,
                    false,
                );
                onUnsubscribe();
            };
        },
    };
};
