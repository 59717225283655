import React from 'react';

import styles from './LineHeading.module.scss';

export const LineHeading: React.FC<{children: React.ReactElement}> = ({
    children,
}) => (
    <div className={styles.lineHeading}>
        <div className={styles.heading}>{children}</div>
    </div>
);
