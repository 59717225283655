import React from 'react';
import cx from 'classnames';

import {FontVariant} from '../../../../design-tokens/constants';
import type {SimpleButtonProps} from '../SimpleButton/SimpleButton';
import {RefSimpleButton} from '../SimpleButton/SimpleButton';
import {Spinner} from '../Spinner/Spinner';
import {Text} from '../Text/Text';

import styles from './Button.module.scss';

export interface LoadingBoxProps {
    className?: string;
    isLoading?: boolean;
}

export const LoadingBox: React.FC<React.PropsWithChildren<LoadingBoxProps>> = ({
    children,
    className,
    isLoading,
}) => {
    return isLoading ? (
        <>
            <div className={cx(className, 'visibility-hidden')}>{children}</div>

            <div className={styles.loadingIcon}>
                <Spinner sizeModifier="compact" />
            </div>
        </>
    ) : className ? (
        <div className={className}>{children}</div>
    ) : (
        <>{children}</>
    );
};

export interface EnhancerProps {
    className?: string;
    enhancerEnd?: React.ReactNode;
    enhancerEndClassName?: string;
    enhancerStart?: React.ReactNode;
    enhancerStartClassName?: string;
    noEnhancerPadding?: boolean;
}

export const Enhancers: React.FC<React.PropsWithChildren<EnhancerProps>> = ({
    children,
    className,
    enhancerEnd,
    enhancerEndClassName,
    enhancerStart,
    enhancerStartClassName,
    noEnhancerPadding,
}) => {
    return (
        <div className={cx(className, styles.buttonContent)}>
            {enhancerStart && (
                <span
                    className={cx(
                        styles.enhancerStart,
                        {'pr-0': noEnhancerPadding},
                        enhancerStartClassName,
                    )}
                >
                    {enhancerStart}
                </span>
            )}

            <div className={styles.buttonChildren}>{children}</div>

            {enhancerEnd && (
                <span
                    className={cx(
                        styles.enhancerEnd,
                        {'pl-0': noEnhancerPadding},
                        enhancerEndClassName,
                    )}
                >
                    {enhancerEnd}
                </span>
            )}
        </div>
    );
};

export const ButtonText: typeof Text = ({
    children,
    className,
    fontVariant = FontVariant.BodyBold,
    variant = 'inherit',
    ...props
}) => (
    <Text
        className={cx(className, styles.textComponent)}
        fontVariant={fontVariant}
        variant={variant}
        {...props}
    >
        {children}
    </Text>
);

const ButtonContent = React.forwardRef<
    HTMLButtonElement | HTMLAnchorElement | HTMLDivElement,
    ButtonProps
>(
    (
        {
            children,
            colorScheme,
            enhancerEnd,
            enhancerEndClassName,
            enhancerStart,
            enhancerStartClassName,
            fontVariant,
            hideChildren,
            isDisabled,
            isLoading,
            noEnhancerPadding,
            textVariant,
            underlineText,
            useTextComponent,
            variant,
            ...props
        },
        ref,
    ) => {
        return (
            <RefSimpleButton
                colorScheme={colorScheme}
                isDisabled={Boolean(isDisabled) || isLoading}
                variant={variant}
                ref={ref}
                {...props}
            >
                <LoadingBox isLoading={isLoading}>
                    <Enhancers
                        className={cx({
                            [styles.textComponent]:
                                !useTextComponent &&
                                variant === 'text' &&
                                underlineText,
                        })}
                        enhancerEnd={enhancerEnd}
                        enhancerEndClassName={enhancerEndClassName}
                        enhancerStart={enhancerStart}
                        enhancerStartClassName={enhancerStartClassName}
                        noEnhancerPadding={noEnhancerPadding}
                    >
                        {!hideChildren &&
                            (useTextComponent ? (
                                <ButtonText
                                    colorScheme={colorScheme}
                                    fontVariant={fontVariant}
                                    variant={textVariant}
                                >
                                    {children}
                                </ButtonText>
                            ) : (
                                <>{children}</>
                            ))}
                    </Enhancers>
                </LoadingBox>
            </RefSimpleButton>
        );
    },
);

ButtonContent.displayName = 'ButtonContent';

export const Button: React.FC<
    SimpleButtonProps &
        EnhancerProps &
        LoadingBoxProps &
        Pick<ButtonTextProps, 'colorScheme' | 'fontVariant'> & {
            hideChildren?: boolean;
            textVariant?: ButtonTextProps['variant'];
            underlineText?: boolean;
            useTextComponent?: boolean;
        }
> = ({...props}) => {
    return <ButtonContent {...props}></ButtonContent>;
};

export const RefButton = React.forwardRef<
    HTMLButtonElement | HTMLAnchorElement | HTMLDivElement,
    SimpleButtonProps &
        EnhancerProps &
        LoadingBoxProps &
        Pick<ButtonTextProps, 'colorScheme' | 'fontVariant'> & {
            hideChildren?: boolean;
            textVariant?: ButtonTextProps['variant'];
            underlineText?: boolean;
            useTextComponent?: boolean;
        }
>(({...props}, ref) => {
    return <ButtonContent {...props} ref={ref}></ButtonContent>;
});

RefButton.displayName = 'RefButton';

export type ButtonTextProps = React.ComponentProps<typeof ButtonText>;

export type ButtonProps = React.ComponentProps<typeof Button>;
