import type {Buffer} from './types';

export const createBuffer = <T>(size: number): Buffer<T> => {
    let buffer = new Array<T>();

    return {
        add: value => {
            if (size === 0) {
                return [];
            } else if (buffer.length < size) {
                buffer.splice(size, 0, value);
            } else {
                buffer = [...buffer.slice(1), value];
            }
        },
        entries: () => buffer.slice(0),
        forEach: cb => {
            buffer.forEach(cb);
        },
    };
};
