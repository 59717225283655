import React from 'react';
import cx from 'classnames';

import {
    Icon,
    IconTypes,
    TextHeading,
    Text,
    FontVariant,
    ThemeConsumer,
} from '@pexip/components';

import styles from './BreakoutRoomsNameAndTimeLeft.module.scss';

export const BreakoutRoomsNameAndTimeLeftView: React.FC<{
    showBreakoutIcon?: boolean;
    isSmallText?: boolean;
    isMultiline?: boolean;
    roomName: string;
    timeLeft: string;
}> = ({
    showBreakoutIcon = false,
    isMultiline = false,
    isSmallText = false,
    roomName,
    timeLeft,
}) => (
    <div className={cx(styles.container, {[styles.isMultiline]: isMultiline})}>
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <>
                    <div className={styles.nameAndIcon}>
                        {showBreakoutIcon && (
                            <Icon
                                className={cx(styles.breakoutIcon, 'mr-2')}
                                source={IconTypes.IconBreakoutRooms}
                                colorScheme={defaultColorScheme}
                            />
                        )}
                        <TextHeading
                            htmlTag="h5"
                            fontVariant={
                                isSmallText ? FontVariant.Body : FontVariant.H5
                            }
                        >
                            {roomName}
                        </TextHeading>
                    </div>
                    {timeLeft && (
                        <Text
                            className={cx('ml-2', styles.timeLeft)}
                            fontVariant={
                                isSmallText ? FontVariant.Body : FontVariant.H6
                            }
                        >
                            {isMultiline ? timeLeft : `/ ${timeLeft}`}
                        </Text>
                    )}
                </>
            )}
        </ThemeConsumer>
    </div>
);

export type BreakoutRoomsNameAndTimeLeftViewProps = React.ComponentProps<
    typeof BreakoutRoomsNameAndTimeLeftView
>;
