import React from 'react';

import {CoreHeader} from '@pexip/components';

export const HeaderView: React.FC<
    React.ComponentProps<typeof CoreHeader>
> = props => (
    <CoreHeader enhancerFixedWidth hasBottomBorder={false} {...props} />
);

export type HeaderViewProps = React.ComponentProps<typeof HeaderView>;
