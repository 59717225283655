export class ValidationError extends Error {
    name = 'ValidationError';

    errors: unknown[];

    constructor(errors: unknown[]) {
        super(JSON.stringify(errors));
        this.errors = errors;
    }
}

export class NonJSONResponseError extends Error {
    name = 'NonJSONResponseError';

    contentType: string | null;
    response: string;

    constructor(contentType: string | null, response: string) {
        super(`Endpoint returned ${contentType} instead of json`);
        this.contentType = contentType;
        this.response = response;
    }
}
