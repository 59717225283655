import React from 'react';
import cx from 'classnames';

import styles from './Table.module.scss';

export const TableSection: React.FC<
    React.ComponentProps<'div'> & {
        position?: 'middle' | 'left-pinned' | 'right-pinned';
    }
> = ({className, position = 'middle', ...props}) => (
    <div
        className={cx(
            {
                [styles.leftPinnedTableSection]: position === 'left-pinned',
                [styles.middleTableSection]: position === 'middle',
                [styles.rightPinnedTableSection]: position === 'right-pinned',
            },
            className,
        )}
        {...props}
    />
);
