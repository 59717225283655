import React from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

import {TextLink, Divider, ThemeProvider} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './ParticipantsPanelFooter.module.scss';

export const ParticipantsPanelFooter: React.FC<{
    addParticipant: () => void;
}> = ({addParticipant}) => {
    const {t} = useTranslation();

    return (
        <ThemeProvider colorScheme="light">
            <div className={styles.wrapper}>
                <Divider />
                <TextLink
                    className={cx('p-3', 'text-center')}
                    isTruncated
                    title={t('add-participant.submit', 'Add participant')}
                    variant="inherit"
                    onClick={addParticipant}
                    data-testid={TestId.UserMenuAddParticipant}
                >
                    {t('add-participant.submit', 'Add participant')}
                </TextLink>
            </div>
        </ThemeProvider>
    );
};
