import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import cx from 'classnames';

import {
    Button,
    Checkbox,
    FontVariant,
    TextHeading,
    Text,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import {ResponsiveBox} from '../ResponsiveBox/ResponsiveBox.view';

import styles from './CustomStep.module.scss';

export const CustomStep: React.FC<{
    next?: () => void;
    isMobile?: boolean;
    source?: string;
    subTitle?: boolean;
    width?: string;
    height?: string;
    mobileHeight?: string;
    mobileWidth?: string;
    confirmation?: 'checkbox' | 'none';
}> = ({
    next,
    isMobile,
    source,
    subTitle,
    width,
    height,
    mobileHeight,
    mobileWidth,
    confirmation,
}) => {
    const [isChecked, setIsChecked] = useState(false);

    const {t} = useTranslation();

    // Select specific width and height for device type
    const {deviceTypeWidth, deviceTypeHeight} = isMobile
        ? {deviceTypeWidth: mobileWidth, deviceTypeHeight: mobileHeight}
        : {deviceTypeWidth: width, deviceTypeHeight: height};

    return (
        <ResponsiveBox
            style={{width: deviceTypeWidth, height: deviceTypeHeight}}
            className={width && cx(styles.outerWrapper)}
        >
            <div
                data-testid={TestId.CustomStep}
                className={cx(styles.innerWrapper)}
            >
                <TextHeading
                    htmlTag={FontVariant.H2}
                    fontVariant={FontVariant.H2}
                    className="mb-3"
                >
                    <Trans t={t} i18nKey={'custom-step.title'}>
                        Custom Step
                    </Trans>
                </TextHeading>
                {/* Subtitle */}
                {subTitle && (
                    <Text
                        fontVariant={FontVariant.Body}
                        className="mb-3 d-inline-block"
                    >
                        {t(
                            'custom-step.subtitle',
                            'Please provide a subtitle...',
                        )}
                    </Text>
                )}
                {/* Content frame */}
                {source && (
                    <iframe
                        title={t('custom-step.content-frame.title', 'Content')}
                        className={cx(styles.contentFrame)}
                        src={source}
                        sandbox={''}
                    />
                )}
                {/* Check box */}
                {confirmation === 'checkbox' && (
                    <div className={cx(styles.checkBoxWrapper, 'mb-4', 'mt-5')}>
                        <Checkbox
                            aria-label={t(
                                'custom-step.checkbox.aria-label',
                                'Click to agree',
                            )}
                            name="custom-step-checkbox"
                            checked={isChecked}
                            label={t('custom-step.checkbox.caption', 'I agree')}
                            onChange={e => setIsChecked(e.target.checked)}
                        />
                    </div>
                )}
                {/* Button wrapper */}
                <div className={cx(styles.buttonWrapper, 'mb-4', 'mt-4')}>
                    {/* Next button*/}
                    <Button
                        className={cx(styles.nextButton)}
                        modifier="fullWidth"
                        isDisabled={!isChecked && confirmation === 'checkbox'}
                        size="large"
                        data-testid={TestId.ButtonConfirmCustomStep}
                        onClick={next}
                    >
                        <Trans i18nKey={'custom-step.next'}>Next</Trans>
                    </Button>
                </div>
            </div>
        </ResponsiveBox>
    );
};
