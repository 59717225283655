type ExtractValueTypes<T> = T extends object ? T[keyof T] : never;

export const PROCESSING_WIDTH = 768;
export const PROCESSING_HEIGHT = 432;
export const FOREGROUND_THRESHOLD = 0.5;
export const BACKGROUND_BLUR_AMOUNT = 3; // Between 1 and 20
export const EDGE_BLUR_AMOUNT = 3; // Between 0 and 20
export const MASK_COMBINE_RATIO = 0.5;

export const PROCESS_STATUS = {
    New: 'new',
    Error: 'Error',
    Opened: 'opened',
    Opening: 'opening',
    Processing: 'processing',
    Idle: 'idle',
    Closing: 'closing',
    Closed: 'closed',
    Destroying: 'destroying',
    Destroyed: 'destroyed',
} as const;
export type ProcessStatus = ExtractValueTypes<typeof PROCESS_STATUS>;

export const RENDER_EFFECT = {
    None: 'none',
    Blur: 'blur',
    Overlay: 'overlay',
} as const;

export type RenderEffects = ExtractValueTypes<typeof RENDER_EFFECT>;
