import type {AudioNodeInit, AnalyzerNodeInit} from './types';

export const isAudioNode = (t: unknown): t is AudioNode => {
    if (typeof t === 'object' && t !== null) {
        return 'connect' in t && 'disconnect' in t;
    }
    return false;
};

export const isAudioParam = (t: unknown): t is AudioParam => {
    if (typeof t === 'object' && t !== null) {
        return 'setValueAtTime' in t;
    }
    return false;
};

export const isAudioNodeInit = (t: unknown): t is AudioNodeInit => {
    if (typeof t === 'object' && t !== null) {
        return 'audioNode' in t && 'create' in t && 'release' in t;
    }
    return false;
};

export const isAnalyzerNodeInit = (t: unknown): t is AnalyzerNodeInit => {
    if (isAudioNodeInit(t) && t.audioNode) {
        return 'getFloatTimeDomainData' in t.audioNode;
    }
    return false;
};
