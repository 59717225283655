import React from 'react';
import cx from 'classnames';

import type {StatIndicatorVariant} from '../../../types/variants';

import styles from './StatIndicator.module.scss';

export const StatIndicator: React.FC<
    React.ComponentProps<'div'> & {
        type?: 'circle' | 'arrowUp' | 'arrowDown';
        variant?: StatIndicatorVariant;
        color?: string;
        size?: 'small' | 'large';
    }
> = ({type = 'circle', variant, color, size = 'small', role}) => {
    const isArrow = type.includes('arrow');
    const customColorStyle = {
        circle: {
            backgroundColor: color,
            boxShadow: `0px 0px 10px ${color}`,
        },
        arrow: {
            borderColor: `transparent transparent ${color} transparent`,
        },
    };

    return (
        <div className={styles.wrap} role={role}>
            <div
                className={cx(
                    styles.indicator,
                    styles[size],
                    variant && styles[variant],
                    {
                        [styles.arrow]: isArrow,
                        [styles.down]: type === 'arrowDown',
                    },
                )}
                style={
                    color ? customColorStyle[isArrow ? 'arrow' : 'circle'] : {}
                }
                data-testid={`indicator-${type}`}
            />
        </div>
    );
};
