import React, {useState, useEffect} from 'react';

import {getTimeLeft} from '@pexip/utils';

import type {EpochTimeStampSeconds} from '../types';
import type {BreakoutRoomsNameAndTimeLeftViewProps} from '../views/BreakoutRoomsNameAndTimeLeft/BreakoutRoomsNameAndTimeLeft.view';
import {BreakoutRoomsNameAndTimeLeftView} from '../views/BreakoutRoomsNameAndTimeLeft/BreakoutRoomsNameAndTimeLeft.view';

export const BreakoutRoomsNameAndTimeLeft: React.FC<
    Omit<BreakoutRoomsNameAndTimeLeftViewProps, 'timeLeft'> & {
        roomEndAt?: EpochTimeStampSeconds;
    }
> = ({roomEndAt, ...props}) => {
    const [timeLeft, setTimeLeft] = useState('');

    useEffect(() => {
        let intervalID: number | undefined;
        const differenceInSeconds = roomEndAt ?? 0 - Date.now() / 1000;
        if (differenceInSeconds > 0) {
            const getAndSetTimeLeft = () => {
                setTimeLeft(getTimeLeft(roomEndAt ?? 0, Date.now() / 1000));
            };
            getAndSetTimeLeft();
            intervalID = window.setInterval(getAndSetTimeLeft, 1000);
        }
        return () => clearInterval(intervalID);
    }, [roomEndAt]);

    return <BreakoutRoomsNameAndTimeLeftView timeLeft={timeLeft} {...props} />;
};
