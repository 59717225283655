import React from 'react';
import {Trans} from 'react-i18next';

import {FontVariant, TextLink} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './TestYourMicLink.module.scss';

export const TestYourMicLink: React.FC<{
    onClick: () => void;
}> = ({onClick}) => {
    return (
        <TextLink
            fontVariant={FontVariant.SmallLink}
            data-testid={TestId.LinkTestAudioInput}
            onClick={onClick}
            className={styles.wrapper}
        >
            <Trans i18nKey="media.test-your-microphone">
                Test your microphone
            </Trans>
        </TextLink>
    );
};

export type TestYourMicLinkProps = React.ComponentProps<typeof TestYourMicLink>;
