import {useCallback, useEffect, useRef, useState} from 'react';

export type CountDownState = {
    startCountFrom?: number;
    msInterval?: number;
    enabled?: boolean;
};

export const useCountDown = (
    state: CountDownState,
    onCountDownFinished?: () => void,
) => {
    const {startCountFrom = 5, msInterval = 1000, enabled} = state;
    const [shouldStart, setShouldStart] = useState(false);
    const [currentCount, setCurrentCount] = useState(startCountFrom);
    const intervalRef = useRef<number>();

    const startCountDown = useCallback(() => {
        setCurrentCount(startCountFrom);
        setShouldStart(true);
    }, [startCountFrom]);

    useEffect(() => {
        const intervalCallback = () => {
            setCurrentCount(countDown => countDown - 1);
        };

        if (shouldStart && enabled) {
            intervalRef.current = window.setInterval(
                intervalCallback,
                msInterval,
            );
        }

        if (!enabled) {
            setCurrentCount(startCountFrom);
        }

        return () => {
            clearInterval(intervalRef.current);
        };
    }, [msInterval, enabled, shouldStart, startCountFrom]);

    useEffect(() => {
        if (currentCount <= 0) {
            clearInterval(intervalRef.current);
            setShouldStart(false);
            onCountDownFinished?.();
        }
    }, [currentCount, onCountDownFinished]);

    const cleanup = useCallback(() => {
        clearInterval(intervalRef.current);
        setShouldStart(false);
        setCurrentCount(startCountFrom);
    }, [startCountFrom]);

    return {
        startCountDown,
        currentCount,
        cleanup,
    };
};
