import React from 'react';
import {useTranslation} from 'react-i18next';

import {CustomStep} from '../views/CustomStep/CustomStep.view';
import {renderCustomStepSignal} from '../signals/MeetingFlow.signals';
import {useBranding} from '../branding/Context';
import {userAgentDetails} from '../App';
import {getBrandingPath} from '../branding';
export const CustomStepViewModel: React.FC = () => {
    const next = () => {
        renderCustomStepSignal.emit(false);
    };

    // Load configuration for custom step from manifest
    const customStepConfig = useBranding('customStepConfig');

    // Get the language context
    const {i18n} = useTranslation();

    //Include language context if available
    const contentPath =
        (i18n.language && customStepConfig?.source?.[i18n.language]) ??
        customStepConfig?.source?.default;

    // Build the actual resource path
    const source = contentPath ? getBrandingPath(contentPath) : undefined;

    return (
        <CustomStep
            next={next}
            isMobile={userAgentDetails.isMobile}
            source={source}
            subTitle={customStepConfig?.subTitle ?? true}
            width={customStepConfig?.width}
            height={customStepConfig?.height}
            mobileHeight={customStepConfig?.mobileHeight}
            mobileWidth={customStepConfig?.mobileWidth}
            confirmation={customStepConfig?.confirmation}
        />
    );
};
