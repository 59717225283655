import React, {forwardRef, useEffect, useRef} from 'react';
import SimpleBar, {type Props} from 'simplebar-react';
import type SimpleBarCore from 'simplebar-core';
import cx from 'classnames';

import styles from './Scrollbars.module.scss';

// FIXME: Optional: implement `AutoHideBehavior` as it was in https://kingsora.github.io/OverlayScrollbars/#!documentation/options
export type AutoHideBehavior = 'never' | 'scroll' | 'leave' | 'move';

export const Scrollbars = forwardRef<
    SimpleBarCore,
    Omit<Props, 'autoHide'> & {
        children?: React.ReactNode;
        autoHide?: AutoHideBehavior;
        scrollElementRef?: React.MutableRefObject<HTMLElement | null>;
        onScroll?: (e: Event) => void;
        className?: string;
    }
>(
    (
        {autoHide, children, className, onScroll, scrollElementRef, ...props},
        ref,
    ) => {
        const localRef = useRef<HTMLElement | null>(null);

        useEffect(() => {
            const localRefCopy = localRef;

            const scroll = (e: Event) => {
                onScroll?.(e);
            };

            localRefCopy.current?.addEventListener('scroll', scroll);

            return () =>
                localRefCopy.current?.removeEventListener('scroll', scroll);
        }, [localRef, onScroll]);

        return (
            <SimpleBar
                className={cx(styles.scrollbars, className)}
                ref={ref}
                scrollableNodeProps={{
                    ref: (el: HTMLElement) => {
                        localRef.current = el;
                        if (scrollElementRef) {
                            scrollElementRef.current = el;
                        }
                    },
                }}
                autoHide={autoHide !== 'never'}
                {...props}
            >
                {children}
            </SimpleBar>
        );
    },
);

Scrollbars.displayName = 'Scrollbars';

type IScrollbars = SimpleBarCore;

export type {IScrollbars};
