import type {Signal} from '@pexip/signal';
import {createSignal} from '@pexip/signal';

import type {StatsSignals} from './statsCollector.types';

const SIGNAL_PREFIX = 'call:peerConnection:stats';

const REQUIRED_STATS_SIGNAL_KEYS = [
    'onRtcStats',
    'onCallQualityStats',
    'onCallQuality',
] as const;

/**
 * Create and return all required and optional (if specified with `more`),
 * signals for infinity client to work
 *
 * @param scope - any scope prefix for the generated signal name, @see Signal
 * @param more - Keys from `InfinitySignalsOptional`, @see InfinitySignalsOptional
 *
 * The following signals created by default
 * - 'onConnected',
 * - 'onAnswer',
 *
 * @see REQUIRED_STATS_SIGNAL_KEYS
 */
export const createStatsSignals = (scope = '') => {
    const signalScope = scope && [scope, ':'].join('');
    return REQUIRED_STATS_SIGNAL_KEYS.reduce(
        (signals, key) => ({
            ...signals,
            [key]: createSignal<
                StatsSignals[typeof key] extends Signal<infer S> ? S : never
            >({
                name: `${SIGNAL_PREFIX}:${signalScope}:${key}`,
                allowEmittingWithoutObserver: true,
            }),
        }),
        {} as StatsSignals,
    );
};
