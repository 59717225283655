import type {ReleaseTokenMap} from '@pexip/infinity-api';
import {releaseToken, refreshToken, withToken} from '@pexip/infinity-api';
import {Backoff} from '@pexip/utils';

import {backoffBaseOptions} from './constants';
import {logger} from './logger';
import type {
    DisconnectReason,
    RequestClient,
    RequestClientOptions,
} from './types';

export const createRequestClient = ({
    conferenceAlias,
    backoff = new Backoff(backoffBaseOptions),
    expires = 120,
    fetcher = window.fetch,
    token,
    host,
    tokenExpiredCb,
}: RequestClientOptions): RequestClient => {
    let refreshPromise: Promise<void> | undefined;
    let lastRefreshTs = new Date().getTime();

    const refreshInterval = setInterval(
        () => {
            void handleRefresh();
        },
        (expires * 1000) / 3,
    );

    const handleRefresh = async () => {
        if (refreshPromise) {
            logger.debug(
                'Refresh token already in progress. Returning pending one.',
            );
            return refreshPromise;
        }
        refreshPromise = doRefresh();
        return refreshPromise;
    };

    const isTokenValid = () =>
        lastRefreshTs + expires * 1000 > new Date().getTime() - 5 * 1000; // 5 seconds buffer for request

    const doRefresh = async () => {
        while (isTokenValid()) {
            logger.debug('trying to refresh token');
            const res = await refreshToken({
                fetcher: withToken(fetcher, token),
                params: {
                    conferenceAlias,
                },
                host,
            }).catch(async error => {
                logger.warn(
                    'Failed to refresh token, retrying after backoff',
                    error,
                );
                await backoff.promise();
            });
            if (res?.status === 200) {
                lastRefreshTs = new Date().getTime();
                token = res.data.result.token;

                logger.debug('Token refreshed. Cleanup refreshPromise.');
                refreshPromise = undefined;
                backoff.reset();

                return;
            }
        }

        backoff.reset();
        tokenExpiredCb?.();

        throw new Error('Unable to update access token');
    };

    const fireAndForgetRefresh = async () => {
        if (!isTokenValid()) {
            logger.warn('Token has already expired. Cannot refresh.');
            return false;
        }
        const res = await refreshToken({
            fetcher: withToken(fetcher, token),
            params: {
                conferenceAlias,
            },
            host,
        }).catch(error => {
            logger.warn('Failed to refresh token. Error: ', error);
            return false;
        });
        if (typeof res === 'object' && res.status === 200) {
            lastRefreshTs = new Date().getTime();
            token = res.data.result.token;

            logger.debug('Token refreshed');
            return true;
        }
        logger.warn('Failed to refresh token.');
        return false;
    };

    const cleanup = async (reason?: DisconnectReason) => {
        lastRefreshTs = 0; // explicitly invalidates the token as we re cleaning up
        if (refreshInterval) {
            clearInterval(refreshInterval);
        }
        if (!token) {
            return;
        }
        const body: ReleaseTokenMap['Body'] = {};
        if (reason) {
            body.reason = reason;
        }

        if (reason === 'Browser closed') {
            const isQueued = navigator.sendBeacon(
                `${host}/api/client/v2/conferences/${conferenceAlias}/release_token?token=${token}`,
                new Blob([JSON.stringify(body)], {type: 'application/json'}),
            );
            if (!isQueued) {
                logger.debug('Failed to queue "release_token" beacon');
            }
        } else {
            await releaseToken({
                fetcher: withToken(fetcher, token),
                params: {conferenceAlias},
                body,
                host,
            });
        }
    };

    return {
        get token() {
            return token;
        },

        get expires() {
            return expires;
        },

        get fetcher() {
            return withToken(fetcher, token);
        },
        refreshToken: fireAndForgetRefresh,
        cleanup,
    };
};
