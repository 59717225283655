import React from 'react';
import cx from 'classnames';

import type {ColorScheme, TextVariant} from '../../../types/variants';
import type {InlineElement} from '../../../types/elements';
import {FontVariant} from '../../../../design-tokens/constants';
import {ThemeConsumer} from '../../../themes/ThemeContext';

import styles from './Text.module.scss';

export const Text: React.FC<
    React.ComponentProps<InlineElement> & {
        colorScheme?: ColorScheme;
        fontVariant?: FontVariant;
        htmlTag?: InlineElement;
        isTruncated?: boolean;
        isUnderlined?: boolean;
        isUppercase?: boolean;
        variant?: TextVariant;
        whiteSpace?: 'normal' | 'nowrap' | 'pre' | 'preWrap' | 'preLine';
        wordBreak?: 'normal' | 'breakAll' | 'keepAll' | 'overflowWrap';
    }
> = ({
    children,
    className,
    colorScheme,
    fontVariant = FontVariant.Body,
    htmlTag = 'span',
    isTruncated = false,
    isUnderlined,
    isUppercase,
    whiteSpace,
    wordBreak,
    variant = 'standard',
    ...props
}) => (
    <ThemeConsumer>
        {({colorScheme: defaultColorScheme}) =>
            React.createElement(
                htmlTag,
                {
                    className: cx(
                        styles.text,
                        styles[colorScheme ?? defaultColorScheme],
                        styles[variant],
                        styles[fontVariant],
                        whiteSpace && styles[`${whiteSpace}WhiteSpace`],
                        wordBreak && styles[`${wordBreak}WordBreak`],
                        {
                            [styles.truncated]: isTruncated,
                            [styles.uppercase]: isUppercase,
                            [styles.underline]: isUnderlined,
                        },
                        className,
                    ),
                    ...props,
                },
                children,
            )
        }
    </ThemeConsumer>
);

export type TextProps = React.ComponentProps<typeof Text>;
