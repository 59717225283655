import React from 'react';

import {Text} from '../../elements/Text/Text';
import {FontVariant} from '../../../../design-tokens/constants';
import {CenterLayout} from '../../foundation/CenterLayout/CenterLayout';
import {ThemeConsumer, ThemeProvider} from '../../../themes/ThemeContext';
import type {ColorScheme, TextVariant} from '../../../types/variants';

export const Section: React.FC<
    React.ComponentProps<'section'> & {
        colorScheme?: ColorScheme;
        subtitle: React.ReactNode;
        subtitleTestId?: string;
        title: string;
        titleTestId?: string;
        variant?: TextVariant;
    }
> = ({
    children,
    className,
    colorScheme,
    subtitle,
    subtitleTestId,
    title,
    titleTestId,
    variant,
    ...props
}) => (
    <ThemeConsumer>
        {({colorScheme: defaultColorScheme}) => (
            <ThemeProvider colorScheme={colorScheme ?? defaultColorScheme}>
                <section className={className} {...props}>
                    <CenterLayout>
                        <div className="mt-5">
                            <Text
                                fontVariant={FontVariant.H2}
                                variant={variant}
                                data-testid={titleTestId}
                            >
                                {title}
                            </Text>
                        </div>

                        <div className="mt-2">
                            <Text
                                fontVariant={FontVariant.H6}
                                variant={variant}
                                data-testid={subtitleTestId}
                            >
                                {subtitle}
                            </Text>
                        </div>

                        {children}
                    </CenterLayout>
                </section>
            </ThemeProvider>
        )}
    </ThemeConsumer>
);
