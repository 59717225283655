import {createContext, useContext} from 'react';

import type {Manifest} from '.';
import {DEFAULT} from '.';

export const BrandingContext = createContext<Manifest>(DEFAULT);

export function useBranding<K extends keyof Manifest>(key: K) {
    const brand = useContext(BrandingContext);
    return brand[key];
}
