import {useCallback, useState} from 'react';

export const useIsInWindowBottomHalf = () => {
    const [isInBottomHalf, setIsInBottomHalf] = useState(false);

    const update = useCallback((el: HTMLElement) => {
        const bottomHalf =
            el.offsetTop + el.offsetHeight / 2 > window.innerHeight / 2;
        setIsInBottomHalf(bottomHalf);
    }, []);

    return {isInBottomHalf, update};
};
