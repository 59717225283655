import type {Segmentation} from './types/media';
import {resize} from './utils';

interface InternalCanvases {
    drawImageDataCanvas?: OffscreenCanvas;
    maskCanvas?: OffscreenCanvas;
    blurredMaskCanvas?: OffscreenCanvas;
    blurredCanvas?: OffscreenCanvas;
    downScaledCanvas?: OffscreenCanvas;
    backgroundImageCanvas?: OffscreenCanvas;
    inputCanvas?: OffscreenCanvas;
}

interface InternalImages {
    backgroundImage?: ImageBitmap;
}

interface Props {
    downScaleFactor: number;
}

export interface Point {
    x: number;
    y: number;
}

export interface Size {
    width: number;
    height: number;
}

export type Rect = Point & Size;

/**
 * Context Attributes
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/OffscreenCanvas/getContext}
 */
interface CanvasRenderingContext2DOptions {
    alpha?: boolean;
}

export const getCanvasRenderingContext2D = (
    canvas: OffscreenCanvas,
    options?: CanvasRenderingContext2DOptions,
) => {
    const context = canvas.getContext('2d', options);
    if (!context) {
        throw new Error('Cannot get CanvasRenderingContext2D');
    }
    return context;
};

export const flipCanvasHorizontal = (canvas: OffscreenCanvas) => {
    const ctx = getCanvasRenderingContext2D(canvas);
    ctx.scale(-1, 1);
    ctx.translate(-canvas.width, 0);
};

export const getImageSize = (image: CanvasImageSource): Size => {
    if ('VideoFrame' in globalThis && image instanceof VideoFrame) {
        return {height: image.displayHeight, width: image.displayWidth};
    }
    if (
        'offsetHeight' in image &&
        typeof image.offsetHeight === 'number' &&
        image.offsetHeight !== 0 &&
        'offsetWidth' in image &&
        typeof image.offsetWidth === 'number' &&
        image.offsetWidth !== 0
    ) {
        return {height: image.offsetHeight, width: image.offsetWidth};
    }
    if (
        'videoHeight' in image &&
        typeof image.videoHeight === 'number' &&
        image.videoHeight !== 0 &&
        'videoWidth' in image &&
        typeof image.videoWidth === 'number' &&
        image.videoWidth !== 0
    ) {
        return {height: image.videoHeight, width: image.videoWidth};
    }
    if (
        'height' in image &&
        image.height !== 0 &&
        'width' in image &&
        image.width !== 0
    ) {
        return {height: image.height, width: image.width};
    }
    return {height: 0, width: 0};
};

/**
 * Create an OffscreenCanvas with provided width and height. When
 * OffscreenCanvas is not available, a Canvas element is returned.
 *
 * @param width - canvas.width
 * @param height - canvas.height
 */
export const createOffscreenCanvas = (width: number, height: number) => {
    const offscreen = new OffscreenCanvas(width, height);
    return offscreen;
};

/**
 * Compare the provided width and height to see if they are the same
 *
 * @param widthA - The width of A
 * @param heightA - The height of A
 * @param widthB - The width of B
 * @param heightB - The height of B
 */
export const isEqualSize = (
    widthA: number,
    heightA: number,
    widthB: number,
    heightB: number,
    // eslint-disable-next-line max-params -- avoid unnecessary object creation
): boolean => widthA === widthB && heightA === heightB;

export const clearCanvas = (canvas: OffscreenCanvas) => {
    const ctx = getCanvasRenderingContext2D(canvas);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
};

export const createCanvasRenderUtils = (
    processingWidth: number,
    processingHeight: number,
) => {
    const props: InternalCanvases & InternalImages & Props = {
        downScaleFactor: 3,
    };

    const getCanvas = (canvasName: keyof InternalCanvases): OffscreenCanvas => {
        const canvas = props[canvasName];
        if (!canvas) {
            const canvas = createOffscreenCanvas(
                canvasName === 'downScaledCanvas'
                    ? Math.trunc(processingWidth / props.downScaleFactor)
                    : processingWidth,
                canvasName === 'downScaledCanvas'
                    ? Math.trunc(processingHeight / props.downScaleFactor)
                    : processingHeight,
            );
            props[canvasName] = canvas;
            return canvas;
        }
        return canvas;
    };

    const renderImageDataToOffScreenCanvas = (
        image: ImageData,
        canvasName: keyof InternalCanvases,
    ) => {
        const canvas = getCanvas(canvasName);
        const context = getCanvasRenderingContext2D(canvas);
        context.putImageData(image, 0, 0);
        return canvas;
    };

    /**
     * Draw image on a 2D rendering context.
     */
    const drawImage = (
        ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
        image: CanvasImageSource,
        sx: number,
        sy: number,
        sw?: number,
        sh?: number,
        dx?: number,
        dy?: number,
        dw?: number,
        dh?: number,
        // eslint-disable-next-line max-params -- avoid unnecessary object creation
    ) => {
        const source =
            image instanceof ImageData
                ? renderImageDataToOffScreenCanvas(image, 'drawImageDataCanvas')
                : image;
        if (sw === undefined || sh === undefined) {
            ctx.drawImage(source, sx, sy);
        } else if (
            dx === undefined ||
            dy === undefined ||
            dw === undefined ||
            dh === undefined
        ) {
            ctx.drawImage(source, sx, sy, sw, sh);
        } else {
            ctx.drawImage(source, sx, sy, sw, sh, dx, dy, dw, dh);
        }
    };

    const renderImageToCanvas = (
        image: CanvasImageSource,
        canvas: OffscreenCanvas,
        dw = processingWidth,
        dh = processingHeight,
        options: CanvasRenderingContext2DOptions = {},
        // eslint-disable-next-line max-params -- avoid unnecessary object creation
    ) => {
        const {height, width} = getImageSize(image);
        const result = resize(width, height, dw, dh);
        const ctx = getCanvasRenderingContext2D(canvas, options);

        drawImage(
            ctx,
            image,
            result.sx,
            result.sy,
            result.sw,
            result.sh,
            result.dx,
            result.dy,
            result.dw,
            result.dh,
        );
    };

    const renderImageToOffScreenCanvas = (
        image: CanvasImageSource,
        canvasName: keyof InternalCanvases,
    ) => {
        const canvas = getCanvas(canvasName);
        renderImageToCanvas(image, canvas);
        return canvas;
    };

    const drawWithCompositing = (
        ctx: OffscreenCanvasRenderingContext2D,
        image: CanvasImageSource,
        compositeOperation: GlobalCompositeOperation,
    ) => {
        ctx.globalCompositeOperation = compositeOperation;
        drawImage(ctx, image, 0, 0);
    };

    // method copied from blur in https://codepen.io/zhaojun/pen/zZmRQe
    const cpuBlur = (
        canvas: OffscreenCanvas,
        image: CanvasImageSource,
        blur: number,
    ) => {
        const ctx = getCanvasRenderingContext2D(canvas);

        let sum = 0;
        const delta = 5;
        const alphaLeft = 1 / (2 * Math.PI * delta * delta);
        const step = blur < 3 ? 1 : 2;
        for (let y = -blur; y <= blur; y += step) {
            for (let x = -blur; x <= blur; x += step) {
                const weight =
                    alphaLeft *
                    Math.exp(-(x * x + y * y) / (2 * delta * delta));
                sum += weight;
            }
        }
        for (let y = -blur; y <= blur; y += step) {
            for (let x = -blur; x <= blur; x += step) {
                ctx.globalAlpha =
                    ((alphaLeft *
                        Math.exp(-(x * x + y * y) / (2 * delta * delta))) /
                        sum) *
                    blur;
                drawImage(ctx, image, x, y);
            }
        }
        ctx.globalAlpha = 1;
    };

    const drawAndBlurImageOnCanvas = ({
        image,
        blurAmount,
        canvas,
        preserveOldDrawing = true,
    }: {
        image: CanvasImageSource;
        blurAmount: number;
        canvas: OffscreenCanvas;
        preserveOldDrawing?: boolean;
    }) => {
        const {height, width} = getImageSize(image);
        const ctx = getCanvasRenderingContext2D(canvas);
        if (blurAmount <= 0) {
            return drawImage(ctx, image, 0, 0, width, height);
        }
        const halfCanvas = getCanvas('downScaledCanvas');
        const halfCtx = getCanvasRenderingContext2D(halfCanvas);
        if (!preserveOldDrawing) {
            halfCtx.clearRect(0, 0, halfCanvas.width, halfCanvas.height);
            ctx.clearRect(0, 0, width, height);
        }
        if ('filter' in ctx) {
            // Avoid the transparent edge by Gaussian blur
            halfCtx.filter = `blur(${blurAmount}px)`;
            drawImage(
                halfCtx,
                image,
                0,
                0,
                width,
                height,
                0,
                0,
                halfCanvas.width,
                halfCanvas.height,
            );
            drawImage(ctx, halfCanvas, 0, 0, width, height);
        } else {
            // Safari doesn't support filter
            // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/filter
            cpuBlur(canvas, image, blurAmount);
        }
    };

    const drawAndBlurImageOnOffScreenCanvas = ({
        image,
        blurAmount,
        offscreenCanvasName,
        preserveOldDrawing,
    }: {
        image: CanvasImageSource;
        blurAmount: number;
        offscreenCanvasName: keyof InternalCanvases;
        preserveOldDrawing?: boolean;
    }): OffscreenCanvas => {
        const canvas = getCanvas(offscreenCanvasName);
        clearCanvas(canvas);
        if (blurAmount === 0) {
            renderImageToCanvas(image, canvas);
        } else {
            drawAndBlurImageOnCanvas({
                image,
                blurAmount,
                canvas,
                preserveOldDrawing,
            });
        }
        return canvas;
    };

    const createPersonMask = (
        segmentation: Segmentation | Segmentation[],
        _foregroundThreshold: number,
        edgeBlurAmount: number,
    ): OffscreenCanvas => {
        const backgroundMaskImage = Array.isArray(segmentation)
            ? segmentation[0]?.canvas
            : segmentation.canvas;

        if (!backgroundMaskImage) {
            return getCanvas('maskCanvas');
        }

        const backgroundMask = drawAndBlurImageOnOffScreenCanvas({
            image: backgroundMaskImage,
            offscreenCanvasName: 'maskCanvas',
            blurAmount: 0,
        });
        if (edgeBlurAmount === 0) {
            return backgroundMask;
        } else {
            return drawAndBlurImageOnOffScreenCanvas({
                image: backgroundMask,
                blurAmount: edgeBlurAmount,
                offscreenCanvasName: 'blurredMaskCanvas',
                preserveOldDrawing: false,
            });
        }
    };

    const drawBokehEffect = (
        canvas: OffscreenCanvas,
        inputImage: CanvasImageSource,
        backgroundImage: CanvasImageSource,
        segmentations: Segmentation | Segmentation[],
        foregroundThreshold = 0.5,
        backgroundBlurAmount = 3,
        edgeBlurAmount = 3,
        flipHorizontal = false,
        // eslint-disable-next-line max-params -- avoid unnecessary object creation
    ) => {
        const blurredImage = drawAndBlurImageOnOffScreenCanvas({
            image: backgroundImage,
            blurAmount: backgroundBlurAmount,
            offscreenCanvasName: 'blurredCanvas',
        });

        const ctx = getCanvasRenderingContext2D(canvas);

        if (Array.isArray(segmentations) && segmentations.length === 0) {
            return drawImage(ctx, blurredImage, 0, 0);
        }

        const personMask = createPersonMask(
            segmentations,
            foregroundThreshold,
            edgeBlurAmount,
        );

        ctx.save();
        if (flipHorizontal) {
            flipCanvasHorizontal(canvas);
        }
        // draw the original image on the final canvas
        const {height, width} = getImageSize(inputImage);
        drawImage(ctx, inputImage, 0, 0, width, height);

        // "destination-in" - "The existing canvas content is kept where both the
        // new shape and existing canvas content overlap. Everything else is made
        // transparent."
        // crop what's not the person using the mask from the original image
        drawWithCompositing(ctx, personMask, 'destination-in');
        // "destination-over" - "The existing canvas content is kept where both the
        // new shape and existing canvas content overlap. Everything else is made
        // transparent."
        // draw the blurred background on top of the original image where it doesn't
        // overlap.
        drawWithCompositing(ctx, blurredImage, 'destination-over');
        ctx.restore();
    };

    const drawBlurEffect = (
        canvas: OffscreenCanvas,
        inputImage: CanvasImageSource,
        segmentations: Segmentation | Segmentation[],
        foregroundThreshold = 0.5,
        backgroundBlurAmount = 3,
        edgeBlurAmount = 3,
        flipHorizontal = false,
        // eslint-disable-next-line max-params -- avoid unnecessary object creation
    ) =>
        drawBokehEffect(
            canvas,
            inputImage,
            inputImage,
            segmentations,
            foregroundThreshold,
            backgroundBlurAmount,
            edgeBlurAmount,
            flipHorizontal,
        );

    const drawOverlayEffect = (
        canvas: OffscreenCanvas,
        inputImage: CanvasImageSource,
        backgroundImage: CanvasImageSource | OffscreenCanvas,
        segmentations: Segmentation | Segmentation[],
        foregroundThreshold = 0.5,
        backgroundBlurAmount = 0,
        edgeBlurAmount = 3,
        flipHorizontal = false,
        // eslint-disable-next-line max-params -- avoid unnecessary object creation
    ) =>
        drawBokehEffect(
            canvas,
            inputImage,
            backgroundImage,
            segmentations,
            foregroundThreshold,
            backgroundBlurAmount,
            edgeBlurAmount,
            flipHorizontal,
        );

    const evaluateInput = (inputImage: CanvasImageSource) => {
        const image = renderImageToOffScreenCanvas(inputImage, 'inputCanvas');
        return image;
    };

    return {
        evaluateInput,
        renderImageToCanvas,
        drawBlurEffect,
        drawOverlayEffect,
        renderImageToOffScreenCanvas,
        renderImageDataToOffScreenCanvas,
        drawAndBlurImageOnOffScreenCanvas,
    };
};
