import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {FontVariant, Text} from '@pexip/components';

export const MuteInputTooltipContent: React.FC<{
    isAudioMuted: boolean;
    muteText: string;
}> = ({isAudioMuted, muteText}) => {
    const {t} = useTranslation();
    return (
        <div className="p-2 d-flex flex-direction-column align-items-center">
            <Text>{`${muteText} (M)`}</Text>
            {isAudioMuted && (
                <Text fontVariant={FontVariant.Small} className="mt-1">
                    <Trans t={t} i18nKey="media.push-to-talk">
                        Push and hold your space bar to unmute yourself
                        temporarily
                    </Trans>
                </Text>
            )}
        </div>
    );
};

export type MuteInputTooltipContentProps = React.ComponentProps<
    typeof MuteInputTooltipContent
>;
