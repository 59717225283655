import {useCallback} from 'react';

import type {BreakoutParticipants} from '../types';
import {MainBreakoutRoomId} from '../constants';

export const useBreakoutRemoveRoom = ({
    setParticipants,
}: {
    setParticipants: React.Dispatch<React.SetStateAction<BreakoutParticipants>>;
}) =>
    useCallback(
        (roomId: string) => {
            setParticipants(current => {
                const newParticipants = new Map(current.entries());
                const mainParticipants = [
                    ...(newParticipants.get(MainBreakoutRoomId) ?? []),
                    ...(newParticipants.get(roomId) ?? []),
                ];
                newParticipants.set(MainBreakoutRoomId, mainParticipants);
                newParticipants.delete(roomId);
                return newParticipants;
            });
        },
        [setParticipants],
    );
