import React, {useState, useEffect, useMemo, useCallback} from 'react';
import cx from 'classnames';

import {useOnClickOutside} from '@pexip/hooks';

import {InteractiveElement} from '../../elements/InteractiveElement/InteractiveElement';
import {KEYBOARD_EVENT_KEY} from '../../../constants/keyboard';

import type {DropContentPosition, DropContentType} from './types';

import styles from './DropContent.module.scss';

export const DropContent: React.FC<
    Omit<React.ComponentProps<'button'>, 'children' | 'content'> & {
        buttonClassName?: string;
        buttonLabel?: string;
        canCloseOutside?: boolean;
        closeOnEscape?: boolean;
        content: DropContentType;
        children?: ((isOpen: boolean) => React.ReactNode) | React.ReactNode;
        contentClassName?: string;
        dropContentRole?: string;
        dropContentLabel?: string;
        isContentTextWrapped?: boolean;
        interactiveElement?: (
            buttonProps: React.ComponentProps<'button'>,
            isContentVisible: boolean,
        ) => React.ReactNode;
        isDisabled?: boolean;
        isOpen?: boolean;
        limitSize?: boolean;
        onClose?: () => void;
        onContainerClick?: () => void;
        onContentVisibilityChange?: (isVisible: boolean) => void;
        onOutsideClose?: () => void;
        position?: DropContentPosition;
        flexDirection?: 'row' | 'rowReverse' | 'column' | 'columnReverse';
    }
> = ({
    buttonClassName,
    buttonLabel = 'Toggle drop content',
    canCloseOutside = true,
    children,
    className,
    closeOnEscape = false,
    content,
    contentClassName,
    dropContentRole = 'menu',
    dropContentLabel,
    interactiveElement,
    isContentTextWrapped = false,
    isDisabled,
    isOpen = false,
    limitSize = false,
    onClose,
    onContainerClick,
    onContentVisibilityChange,
    onOutsideClose,
    position = 'bottomLeft',
    flexDirection = 'row',
    ...props
}) => {
    const [isContentVisible, setContentVisible] = useState(isOpen);

    const tryToClose = useCallback(() => {
        onClose?.();
        setContentVisible(false);
    }, [onClose]);

    const tryToCloseOutsideClick = useCallback(() => {
        if (canCloseOutside) {
            tryToClose();
            onOutsideClose?.();
        }
    }, [canCloseOutside, onOutsideClose, tryToClose]);

    useEffect(() => {
        const closeOnEscape = (event: KeyboardEvent) => {
            if (event.key === KEYBOARD_EVENT_KEY.escape) {
                tryToClose();
            }
        };

        if (closeOnEscape) {
            isContentVisible
                ? document.addEventListener('keydown', closeOnEscape, true)
                : document.removeEventListener('keydown', closeOnEscape, true);
        }

        return () =>
            closeOnEscape &&
            document.removeEventListener('keydown', closeOnEscape, true);
    }, [closeOnEscape, tryToClose, isContentVisible]);

    const handleMouseClick = useCallback(
        (e: React.SyntheticEvent<HTMLButtonElement>) => {
            onContainerClick?.();
            if (isContentVisible) {
                onClose?.();
            }

            setContentVisible(contentVisible => !contentVisible);
            e.stopPropagation();
        },
        [isContentVisible, onClose, onContainerClick],
    );

    const handleEventPropagation = (
        e: React.SyntheticEvent<HTMLButtonElement>,
    ) => e.stopPropagation();

    const close = (e: React.SyntheticEvent<HTMLElement>) => {
        e.stopPropagation();
        onClose?.();
        setContentVisible(false);
    };

    useEffect(() => {
        if (onContentVisibilityChange) {
            onContentVisibilityChange(isContentVisible);
        }
    }, [isContentVisible, onContentVisibilityChange]);

    const outsideClickRef = useOnClickOutside(tryToCloseOutsideClick);

    const renderChildren = useMemo(
        () => (
            <>
                {typeof children === 'function'
                    ? children(isContentVisible)
                    : children}
            </>
        ),
        [children, isContentVisible],
    );

    const renderInteractiveElement = useMemo(() => {
        return (
            interactiveElement?.(
                {
                    'aria-label': buttonLabel,
                    className: cx(styles.buttonContainer, buttonClassName),
                    onClick: handleMouseClick,
                    onKeyDown: handleEventPropagation,
                    children: renderChildren,
                    ...props,
                },
                isContentVisible,
            ) ?? (
                <InteractiveElement
                    aria-label={buttonLabel}
                    className={cx(styles.buttonContainer, buttonClassName)}
                    onClick={handleMouseClick}
                    onKeyDown={handleEventPropagation}
                    {...props}
                >
                    {renderChildren}
                </InteractiveElement>
            )
        );
    }, [
        buttonClassName,
        buttonLabel,
        handleMouseClick,
        interactiveElement,
        isContentVisible,
        props,
        renderChildren,
    ]);

    return isDisabled ? (
        <div className={cx(styles.wrap, className)} ref={outsideClickRef}>
            {renderChildren}
        </div>
    ) : (
        <div className={cx(styles.wrap, className)} ref={outsideClickRef}>
            {renderInteractiveElement}
            {isContentVisible && (
                <div
                    aria-label={dropContentLabel}
                    role={dropContentRole}
                    className={cx(
                        styles.dropContent,
                        styles[position],
                        styles[flexDirection],
                        {
                            [styles.contentTextNoWrap]: !isContentTextWrapped,
                            [styles.limitSize]: limitSize,
                        },
                        contentClassName,
                    )}
                >
                    {typeof content === 'function' ? content(close) : content}
                </div>
            )}
        </div>
    );
};

export type DropContentProps = React.ComponentProps<typeof DropContent>;
