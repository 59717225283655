export enum TestId {
    ActionEnterDTMF = 'action-enter-dtmf',
    ActionFecc = 'action-fecc',
    ActionMakeGuest = 'action-make-guest',
    ActionMakeHost = 'action-make-host',
    ActionRemoveSpotlight = 'action-remove-spotlight',
    ActionSpotlight = 'action-spotlight',
    ActionTransfer = 'action-transfer',
    AudioMeterSettings = 'audio-meter-settings',
    BadgeCounterParticipantPanelButton = 'badge-counter-participant-panel-button',
    BadgeCounterBreakoutRoomsPanelButton = 'badge-counter-breakout-rooms-panel-button',
    BreakoutRoomsPanelWrapper = 'breakout-rooms-panel-wrapper',
    ButtonAdmitParticipant = 'button-admit-participant',
    ButtonAudioMeter = 'button-audio-meter',
    ButtonAudioMeterMuted = 'button-audio-meter-muted',
    ButtonBackgroundEffectsOff = 'button-background-effects-off',
    ButtonBackgroundEffectsOn = 'button-background-effects-on',
    ButtonBreakoutRooms = 'button-breakout-rooms',
    ButtonBreakoutRoomAddRoom = 'button-breakout-rooms-add-room',
    ButtonBreakoutRoomEdit = 'button-breakout-rooms-edit',
    ButtonBreakoutRoomShuffle = 'button-breakout-rooms-shuffle',
    ButtonBreakoutRoomSettings = 'breakout-rooms-settings',
    ButtonBreakoutRoomHeader = 'button-breakout-rooms-edit-header',
    ButtonChat = 'button-chat',
    ButtonChatSend = 'button-chat-send',
    ButtonCloseInvitationModal = 'button-invitation-modal',
    ButtonCollapseSelfview = 'button-collapse-selfview',
    ButtonCopyMeetingLink = 'button-copy-meeting-link',
    ButtonDenyParticipant = 'button-deny-participant',
    ButtonDeviceSettings = 'button-device-settings',
    ButtonDismiss = 'button-dismiss',
    ButtonDragFoldedSelfview = 'button-drag-folded-selfview',
    ButtonDuplicateRoomName = 'button-duplicate-room-name',
    ButtonExpandSelfview = 'button-hide-selfview',
    ButtonFlipCamera = 'button-flip-camera',
    ButtonConfirmGoBackToBreakoutRoomsModeAssignment = 'button-confirm-go-back-to-breakout-rooms-mode-assignment',
    ButtonCancelGoBackToBreakoutRoomsModeAssignment = 'button-cancel-go-back-to-breakout-rooms-mode-assignment',
    ButtonInviteMemberSearchRow = 'button-invite-member-search-row',
    ButtonKickOffParticipant = 'button-kick-off-participant',
    ButtonLeave = 'button-leave',
    ButtonLowerHand = 'button-lower-hand',
    ButtonMeetingAudioInput = 'button-meeting-audioinput',
    ButtonMeetingAudioInputMuted = 'button-meeting-audioinput-muted',
    ButtonMeetingVideoInput = 'button-meeting-videoinput',
    ButtonMeetingVideoInputMuted = 'button-meeting-videoinput-muted',
    ButtonMoveToBreakoutNow = 'button-move-to-breakout-room-now',
    ButtonMuteParticipant = 'button-mute-participant',
    ButtonParticipants = 'button-participants',
    ButtonPeerMuted = 'button-peer-muted',
    ButtonPeerMutedCamera = 'button-peer-muted-camera',
    ButtonPeerPresenting = 'button-peer-presenting',
    ButtonPeerUnmuteCamera = 'button-peer-unmute-camera',
    ButtonQuality = 'button-quality',
    ButtonRaiseHand = 'button-raise-hand',
    ButtonRemoveUserCancel = 'button-remove-user-cancel',
    ButtonRemoveUserConfirm = 'button-remove-user-confirm',
    ButtonRequestPermissions = 'button-request-permissions',
    ButtonSelfviewQuality = 'button-selfview-quality',
    ButtonSettingsCancel = 'button-settings-cancel',
    ButtonSettingsSave = 'button-settings-save',
    ButtonStealPresentationCancel = 'button-steal-presentation-cancel',
    ButtonStartPresentation = 'button-start-presentation',
    ButtonStopPresentation = 'button-stop-presentation',
    ButtonStealPresentationConfirm = 'button-steal-presentation-confirm',
    ButtonTestAudioInputAgain = 'button-test-audio-input-again',
    ButtonTestAudioInputStart = 'button-test-audio-input-start',
    ButtonTestAudioInputTryAgain = 'button-test-audio-input-try-again',
    ButtonToggleAudioSelfview = 'button-toggle-audio-in-folded-selfview',
    ButtonToggleVideoSelfview = 'button-toggle-video-in-folded-selfview',
    ButtonTryAgain = 'button-try-again',
    ButtonVideoInput = 'button-videoinput',
    ButtonVideoinputMuted = 'button-videoinput-muted',
    ButtonMoreControls = 'button-more-controls',
    ChatActivityMessage = 'chat-activity-message',
    ChatActivityMessageDisplayName = 'chat-activity-message-display-name',
    ChatActivityMessageText = 'chat-activity-message-text',
    ChatEmptyActivityMessage = 'chat-empty-activity-message',
    ChatForm = 'chat-form',
    ChatInput = 'chat-input',
    ChatMessage = 'chat-message',
    ChatMessageLink = 'chat-message-link',
    ChatMessageName = 'chat-message-name',
    ChatMessages = 'chat-messages',
    ChatMessageText = 'chat-message-text',
    ChatPanel = 'chat-panel',
    ChatRemoveActivityMessage = 'chat-remove-activity-message',
    CheckEverythingWorksText = 'check-that-everything-works',
    ConfirmationModalCancelButton = 'confirmation-modal-cancel-button',
    ConfirmationModalConfirmButton = 'confirmation-modal-confirm-button',
    DevicesSelection = 'devices-selection',
    DevicesSelectionBlocked = 'devices-selection-blocked',
    FoldedSelfview = 'folded-selfview',
    HeaderLogo = 'header-logo',
    HeaderSubTitle = 'header-subtitle',
    HeaderTitle = 'header-title',
    InvitationSearchInput = 'invitation-search-input',
    JoinCallDetailsWrapper = 'join-call-details-wrapper',
    JoinBreakoutRoom = 'join-breakout-room',
    ButtonJoinMeeting = 'button-join-meeting',
    Layout = 'layout',
    LayoutLabel = 'layout-label',
    LinkMuteAllGuests = 'link-mute-all-guests',
    LinkOpenMeetingSettings = 'link-open-meeting-settings',
    LinkTestAudioInput = 'link-test-audio-input',
    LinkTestAudioOutput = 'link-test-audio-output',
    LinkUnmuteAllGuests = 'link-unmute-all-guests',
    MeetingLinkText = 'meeting-link-text',
    MeetingVideoFull = 'meeting-video-large',
    MeetingVideoPip = 'meeting-video-pip',
    MeetingWrapper = 'meeting-wrapper',
    MetricAudio = 'metric-audio',
    MetricAudioIn = 'metric-audio-in',
    MetricAudioOut = 'metric-audio-out',
    MetricPreso = 'metric-preso',
    MetricPresoIn = 'metric-preso-in',
    MetricPresoOut = 'metric-preso-out',
    MetricVideo = 'metric-video',
    MetricVideoIn = 'metric-video-in',
    MetricVideoOut = 'metric-video-out',
    MissingAudioDeviceAlert = 'missing-audio-device-alert',
    MissingVideoDeviceAlert = 'missing-video-device-alert',
    ModalInvitation = 'modal-invitation',
    ModalLayoutChangeConfirmation = 'modal-layout-change-confirmation',
    ModalMoveToRoom = 'modal-move-to-breakout-room',
    ModalRemoveUser = 'modal-remove-user',
    ModalRoomSettings = 'modal-room-settings',
    ModalStealPresentation = 'modal-steal-presentation',
    ModalOpenRoomsError = 'modal-open-rooms-error',
    NetworkConnectedState = 'network-connected-state',
    NetworkReconnectedState = 'network-reconnected-state',
    NetworkReconnectingState = 'network-reconnecting-state',
    NotificationToolTipAudioCloseButton = 'notification-tooltip-audio-close-button',
    NotificationToolTipAudioProblem = 'notification-tooltip-audio-problem',
    NotificationToolTipDeviceError = 'notification-tooltip-device-error',
    NotificationTooltipPreviouslyBlocked = 'notification-tooltip-previously-blocked',
    NotificationTooltipSelfviewHidden = 'notification-tooltip-selfview-hidden',
    NotificationToolTipVideoCloseButton = 'notification-tooltip-video-close-button',
    NotificationToolTipVideoProblem = 'notification-tooltip-video-problem',
    ParticipantPanel = 'participant-panel',
    ParticipantRow = 'participant-row',
    ParticipantMenuButton = 'participant-menu-button',
    PermissionsRequestMessageWrapper = 'permissions-request-message-wrapper',
    PillNewChatMessage = 'new-chat-message-pill',
    PresentationFull = 'presentation-large',
    PresentationPip = 'presentation-pip',
    SearchRow = 'search-row',
    SelectAudioInput = 'select-audio-input',
    SelectAudioInputErrorText = 'select-audio-input-error-text',
    SelectAudioOutput = 'select-audio-output',
    SelectTestAudioInput = 'select-test-audio-input',
    SelectTestAudioInputPlayback = 'select-test-audio-input-playback',
    SelectTestAudioInputSpeakerPlayback = 'select-test-audio-input-speaker-playback',
    SelectVideoInput = 'select-video-input',
    SelectVideoInputErrorText = 'select-video-input-error-text',
    SettingsVideoSelfview = 'settings-video-selfview',
    SpeakingIndicator = 'speaking-indicator',
    StatsModal = 'stats-modal',
    TestAudioInputIndicator = 'test-audio-input-indicator',
    TextLinkLearnDismissRequestPermissions = 'text-link-dismiss-learn-request-permissions',
    TextLinkLearnRequestPermissions = 'text-link-learn-request-permissions',
    TextLinkTryAgainRequestPermissions = 'text-link-try-again-request-permissions',
    TextLinkUnblockPermissionsVideo = 'text-link-unblock-permissions-video',
    TextNoStatsAvailable = 'text-no-stats-available',
    ToastYouAreTemporarilyUnmuted = 'toast-you-are-temporarily-unmuted',
    ToggleFecc = 'toggle-fecc',
    ToggleNoiseSuppression = 'toggle-noise-suppression',
    TogglePreferPresInMix = 'toggle-prefer-in-mix',
    TogglePrioritizeSharingMotion = 'toggle-prioritize-sharing-motion',
    TooltipDeniedDevice = 'tooltip-denied-device',
    VideoMeeting = 'video-meeting',
    VideoPresentation = 'video-presentation',
    VideoSelfview = 'video-selfview',
    VideoSelfviewWrapper = 'video-selfview-wrapper',
    VideoStatus = 'video-status',
    VideoStatusBody = 'video-status-body',
    VideoStatusTitle = 'video-status-title',
    OpenBreakoutRooms = 'open-breakout-rooms',
    CloseBreakoutRooms = 'close-breakout-rooms',
    CloseBreakoutRoom = 'close-breakout-room',
    RemoveBreakoutRoom = 'remove-breakout-room',
    GoToBreakoutSetupPanel = 'go-to-breakout-setup-panel',
    SaveBreakoutSettings = 'save-breakout-settings',
    CancelBreakoutSettings = 'cancel-breakout-settings',
    BreakoutSetupPanelBackBtn = 'breakout-setup-panel-back-btn',
    BreakoutAssignManuallyBtn = 'breakout-assign-manually-btn',
    BreakoutAssignAutoBtn = 'breakout-assign-auto-btn',
    ButtonMeetingBackgroundEffectsOff = 'button-meeting-background-effects-off',
    ButtonMeetingBackgroundEffectsOn = 'button-meeting-background-effects-on',
    UserMenuAddParticipant = 'user-menu-add-participant',
}
