import React from 'react';
import {useTranslation} from 'react-i18next';

import {ConfirmationModal} from '@pexip/components';

import type {RemoveParticipantSignal} from '../../hooks/useRemoveParticipant';
import {TestId} from '../../../test/testIds';

export const RemoveUserConfirmation: React.FC<
    RemoveParticipantSignal & React.ComponentProps<typeof ConfirmationModal>
> = ({isOpen, userName, description, onCancel, onConfirm, ...props}) => {
    const {t} = useTranslation();

    return (
        <ConfirmationModal
            {...props}
            cancelButtonTestid={TestId.ButtonRemoveUserCancel}
            cancelButtonText={t('common.cancel', 'Cancel')}
            confirmButtonTestid={TestId.ButtonRemoveUserConfirm}
            confirmButtonText={t('common.remove', 'Remove')}
            data-testid={TestId.ModalRemoveUser}
            description={description}
            isOpen={isOpen}
            onCancel={onCancel}
            onConfirm={onConfirm}
            title={t('meeting.remove-user-confirmation', {
                defaultValue:
                    'Are you sure you want to remove {{userName}} from this meeting?',
                userName,
            })}
            uniqueTitle={t('meeting.remove-user-confirmation', {
                defaultValue:
                    'Are you sure you want to remove {{userName}} from this meeting?',
                userName,
            })}
        />
    );
};
