import React from 'react';

import {useMdDown} from '@pexip/components';

import styles from './PreflightLayout.module.scss';

export const PreflightLayout: React.FC<{
    startContent: React.ReactElement;
    endContent: React.ReactElement;
}> = ({startContent, endContent}) => {
    const isMdDown = useMdDown();
    return (
        <div className={styles.preMeetingLayout}>
            {!isMdDown && (
                <div className={styles.startContent}>{startContent}</div>
            )}
            <div className={styles.endContent}>{endContent}</div>
        </div>
    );
};

export type PreMeetingLayoutProps = React.ComponentProps<
    typeof PreflightLayout
>;
