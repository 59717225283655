import React, {useEffect, useMemo} from 'react';

import {useCountDown} from '@pexip/hooks';

import {Text} from '../../elements/Text/Text';
import {FontVariant} from '../../../../design-tokens/constants';

import styles from './CircleCountDown.module.scss';

const Circle: React.FC<{animationStyle: string; className: string}> = ({
    animationStyle,
    className,
}) => (
    <circle
        className={className}
        r="35"
        cx="40"
        cy="40"
        style={{
            animation: animationStyle,
        }}
    />
);

export const CircleCountDownCore: React.FC<
    React.ComponentProps<typeof CircleCountDown> & {
        currentCount?: number;
    }
> = ({currentCount = 0, startCountFrom = 5, shouldStartCountDown}) => {
    const animationStyle = useMemo(
        () =>
            shouldStartCountDown && currentCount > 0
                ? `${styles.countdown} ${startCountFrom}s linear forwards`
                : '',
        [currentCount, startCountFrom, shouldStartCountDown],
    );

    return (
        <div className={styles.countdownWrapper}>
            <Text
                className={styles.countdownNumber}
                colorScheme="light"
                fontVariant={FontVariant.H1}
            >
                {currentCount}
            </Text>
            <svg className={styles.svg}>
                <Circle
                    className={styles.circleBackground}
                    animationStyle={animationStyle}
                />
                <Circle
                    className={styles.circleMain}
                    animationStyle={animationStyle}
                />
            </svg>
        </div>
    );
};

export const CircleCountDown: React.FC<{
    startCountFrom?: number;
    msInterval?: number;
    shouldStartCountDown?: boolean;
}> = ({startCountFrom = 5, msInterval = 1000, shouldStartCountDown}) => {
    const {startCountDown, currentCount} = useCountDown({
        startCountFrom,
        enabled: shouldStartCountDown,
        msInterval,
    });

    useEffect(() => {
        if (shouldStartCountDown) {
            startCountDown();
        }
    }, [shouldStartCountDown, startCountDown]);

    return (
        <CircleCountDownCore
            startCountFrom={startCountFrom}
            shouldStartCountDown={shouldStartCountDown}
            currentCount={currentCount}
        />
    );
};

export type CircleCountDownCoreProps = React.ComponentProps<
    typeof CircleCountDownCore
>;
export type CircleCountDownProps = React.ComponentProps<typeof CircleCountDown>;
