import * as Sentry from '@sentry/react';

import {createLogger} from '@pexip/logger';
import {setLogger as setMediaControlLogger} from '@pexip/media-control';
import {setLogger as setMediaLogger} from '@pexip/media';
import {setLogger as setSignalLogger} from '@pexip/signal';
import {setLogger as setMediaComponentsLogger} from '@pexip/media-components';
import {setLogger as setPeerConnectionLogger} from '@pexip/peer-connection';
import {setLogger as setInfinityLogger} from '@pexip/infinity';

export const rootLogger = createLogger({
    fileName: 'infinity-connect',
});

// Expose to window object
if (!window.pexDebug) {
    Object.defineProperty(window, 'pexDebug', {
        writable: true,
        value: {
            get logEvents() {
                return rootLogger.logEvents;
            },
            dumpLog: () => rootLogger.downloadLog(),
        },
    });
}

export const logger = rootLogger.child({name: 'infinity-connect'});

const createSentryCapture =
    (moduleName: string, level: Sentry.SeverityLevel) =>
    (context: Record<string, unknown>, msg: string | undefined) => {
        Sentry.withScope(scope => {
            scope.setTag('module', moduleName);
            scope.setLevel(level);
            if (context.error) {
                Sentry.captureException(context.error);
            }
            if (context.event) {
                Sentry.captureEvent(context.event);
            }
            if (msg) {
                Sentry.captureMessage(msg);
            }
        });
    };
const logWithSentry = (
    moduleName: string,
): ReturnType<typeof rootLogger.child> => {
    const logger = rootLogger.child({name: moduleName});
    const {fatal, error} = logger;
    const captureFatal = createSentryCapture(moduleName, 'fatal');
    const captureError = createSentryCapture(moduleName, 'error');
    return Object.defineProperties(logger, {
        fatal: {
            value: (
                context: Record<string, unknown>,
                msg: string | undefined,
            ) => {
                captureFatal(context, msg);
                fatal(context, msg);
            },
        },
        error: {
            value: (
                context: Record<string, unknown>,
                msg: string | undefined,
            ) => {
                captureError(context, msg);
                error(context, msg);
            },
        },
    });
};

setPeerConnectionLogger(logWithSentry('peer-connection'));
setMediaControlLogger(logWithSentry('media-control'));
setMediaComponentsLogger(logWithSentry('media-components'));
setMediaLogger(logWithSentry('media'));
setSignalLogger(rootLogger.child({name: 'signal'}));
setInfinityLogger(logWithSentry('infinity'));
