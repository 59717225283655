import React from 'react';

import {imageStore} from '../services/Image.service';

import {ImageStoreContext} from './imageStore.context';

export const ImageStoreContextProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    return (
        <ImageStoreContext.Provider value={imageStore}>
            {children}
        </ImageStoreContext.Provider>
    );
};
