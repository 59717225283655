import type {MultistepItem} from './MultistepIndicator.types';

const applyStepStatus = (
    step: MultistepItem,
    options: Pick<
        MultistepItem,
        | 'isCompleted'
        | 'isFailed'
        | 'isDisabled'
        | 'isActive'
        | 'url'
        | 'onClick'
        | 'isSelectable'
    >,
) => ({
    ...step,
    ...options,
});

export type MultistepIndicatorActions =
    | {type: 'set-all-steps'; steps: MultistepItem[]}
    | {type: 'set-step'; stepIndex: number; step: MultistepItem}
    | {
          type: 'complete-step';
          stepIndex: number;
          options: {
              activateStep: boolean;
              isCompleted: boolean;
              isSelectable?: boolean;
          };
      }
    | {
          type: 'fail-step';
          stepIndex: number;
          options: {
              activateStep: boolean;
              isFailed: boolean;
              isSelectable?: boolean;
          };
      }
    | {type: 'disable-step'; stepIndex: number; isDisabled: boolean}
    | {type: 'activate-step'; stepIndex: number; isActive: boolean}
    | {
          type: 'leave-step';
          stepIndex: number;
          options: {
              activateStep?: boolean;
              isSelectable?: boolean;
              isCompleted?: boolean;
              isFailed?: boolean;
          };
      };

export const multistepIndicatorReducer = (
    state: MultistepItem[] = [],
    action: MultistepIndicatorActions,
) => {
    switch (action.type) {
        case 'set-all-steps': {
            return action.steps;
        }
        case 'set-step': {
            if (state[action.stepIndex]) {
                state[action.stepIndex] = action.step;
            }
            return state;
        }
        case 'complete-step': {
            const {stepIndex, options} = action;
            const steps = [...state];
            const existingStep = steps[stepIndex];
            if (existingStep) {
                steps[stepIndex] = applyStepStatus(existingStep, {
                    isCompleted: options.isCompleted,
                    isFailed: options.isCompleted
                        ? false
                        : existingStep.isFailed,
                    isActive: options.activateStep,
                    isSelectable: options.isSelectable,
                    url: !options.activateStep ? existingStep.url : undefined,
                });
            }
            return steps;
        }
        case 'fail-step': {
            const {stepIndex, options} = action;
            const steps = [...state];
            const existingStep = steps[stepIndex];
            if (existingStep) {
                steps[stepIndex] = applyStepStatus(existingStep, {
                    isFailed: options.isFailed,
                    isCompleted: options.isFailed
                        ? false
                        : existingStep.isCompleted,
                    isActive: options.activateStep,
                    isSelectable: options.isSelectable,
                    url: !options.activateStep ? existingStep.url : undefined,
                });
            }
            return steps;
        }
        case 'disable-step': {
            const {stepIndex, isDisabled} = action;
            const steps = [...state];
            const existingStep = steps[stepIndex];
            if (existingStep) {
                steps[stepIndex] = applyStepStatus(existingStep, {
                    isDisabled,
                });
            }
            return steps;
        }
        case 'activate-step': {
            const {stepIndex, isActive} = action;
            const steps = state.map(item => ({
                ...item,
                isActive: false,
            })) as MultistepItem[];
            const existingStep = steps[stepIndex];
            if (existingStep) {
                steps[stepIndex] = applyStepStatus(existingStep, {
                    isActive,
                });
            }
            return steps;
        }
        case 'leave-step': {
            const {stepIndex, options} = action;
            const steps = [...state];
            const existingStep = steps[stepIndex];
            if (existingStep) {
                steps[stepIndex] = applyStepStatus(existingStep, {
                    isActive: options.activateStep,
                    isSelectable: options.isSelectable,
                    isCompleted:
                        options.isCompleted ?? existingStep?.isCompleted,
                    isFailed: options.isFailed ?? existingStep?.isFailed,
                });
            }
            return steps;
        }
    }
};
