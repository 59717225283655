import {ValidationError, NonJSONResponseError} from '../errors';

import type {Endpoints} from './types';
import {
    validateDialBody,
    validateDial200,
    validateConferenceStatus200,
    validateLock200,
    validateUnlock200,
    validateStartConference200,
    validateMuteguests200,
    validateUnmuteguests200,
    validateDisconnect200,
    validateMessageConferenceBody,
    validateMessageConference200,
    validateParticipants200,
    validateTransformLayoutBody,
    validateTransformLayout200,
    validateClearallbuzz200,
    validateSilentVideoDetectionBody,
    validateSilentVideoDetection200,
    validateTheme200,
    validateTheme204,
    validateAvailableLayouts200,
    validateLayoutSvgs200,
    validateGuestBreakoutBuzz200,
    validateGuestClearBreakoutBuzz200,
} from './validation';

type DialResponse = {status: 200; data: Endpoints['dial']['200']};
export async function dial({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['dial']['Params'];
    body: Endpoints['dial']['Body'];
    host: string;
}): Promise<DialResponse> {
    if (!validateDialBody(body)) {
        throw new ValidationError(validateDialBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/dial`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateDial200(data)) {
                throw new ValidationError(validateDial200.errors);
            }
            return {status: res.status, data: data as Endpoints['dial']['200']};
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type DialMap = Endpoints['dial'];

type ConferenceStatusResponse = {
    status: 200;
    data: Endpoints['conference_status']['200'];
};
export async function conferenceStatus({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['conference_status']['Params'];
    host: string;
}): Promise<ConferenceStatusResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/conference_status`,
        {
            ...init,
            method: 'get',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateConferenceStatus200(data)) {
                throw new ValidationError(validateConferenceStatus200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['conference_status']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type ConferenceStatusMap = Endpoints['conference_status'];

type LockResponse = {status: 200; data: Endpoints['lock']['200']};
export async function lock({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['lock']['Params'];
    host: string;
}): Promise<LockResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/lock`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateLock200(data)) {
                throw new ValidationError(validateLock200.errors);
            }
            return {status: res.status, data: data as Endpoints['lock']['200']};
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type LockMap = Endpoints['lock'];

type UnlockResponse = {status: 200; data: Endpoints['unlock']['200']};
export async function unlock({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['unlock']['Params'];
    host: string;
}): Promise<UnlockResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/unlock`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateUnlock200(data)) {
                throw new ValidationError(validateUnlock200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['unlock']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type UnlockMap = Endpoints['unlock'];

type StartConferenceResponse = {
    status: 200;
    data: Endpoints['start_conference']['200'];
};
export async function startConference({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['start_conference']['Params'];
    host: string;
}): Promise<StartConferenceResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/start_conference`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateStartConference200(data)) {
                throw new ValidationError(validateStartConference200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['start_conference']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type StartConferenceMap = Endpoints['start_conference'];

type MuteguestsResponse = {status: 200; data: Endpoints['muteguests']['200']};
export async function muteguests({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['muteguests']['Params'];
    host: string;
}): Promise<MuteguestsResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/muteguests`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateMuteguests200(data)) {
                throw new ValidationError(validateMuteguests200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['muteguests']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type MuteguestsMap = Endpoints['muteguests'];

type UnmuteguestsResponse = {
    status: 200;
    data: Endpoints['unmuteguests']['200'];
};
export async function unmuteguests({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['unmuteguests']['Params'];
    host: string;
}): Promise<UnmuteguestsResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/unmuteguests`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateUnmuteguests200(data)) {
                throw new ValidationError(validateUnmuteguests200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['unmuteguests']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type UnmuteguestsMap = Endpoints['unmuteguests'];

type DisconnectResponse = {status: 200; data: Endpoints['disconnect']['200']};
export async function disconnect({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['disconnect']['Params'];
    host: string;
}): Promise<DisconnectResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/disconnect`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateDisconnect200(data)) {
                throw new ValidationError(validateDisconnect200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['disconnect']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type DisconnectMap = Endpoints['disconnect'];

type MessageConferenceResponse = {
    status: 200;
    data: Endpoints['message_conference']['200'];
};
export async function messageConference({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['message_conference']['Params'];
    body: Endpoints['message_conference']['Body'];
    host: string;
}): Promise<MessageConferenceResponse> {
    if (!validateMessageConferenceBody(body)) {
        throw new ValidationError(validateMessageConferenceBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/message`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateMessageConference200(data)) {
                throw new ValidationError(validateMessageConference200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['message_conference']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type MessageConferenceMap = Endpoints['message_conference'];

type ParticipantsResponse = {
    status: 200;
    data: Endpoints['participants']['200'];
};
export async function participants({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['participants']['Params'];
    host: string;
}): Promise<ParticipantsResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/participants`,
        {
            ...init,
            method: 'get',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateParticipants200(data)) {
                throw new ValidationError(validateParticipants200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['participants']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type ParticipantsMap = Endpoints['participants'];

type TransformLayoutResponse = {
    status: 200;
    data: Endpoints['transform_layout']['200'];
};
export async function transformLayout({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['transform_layout']['Params'];
    body: Endpoints['transform_layout']['Body'];
    host: string;
}): Promise<TransformLayoutResponse> {
    if (!validateTransformLayoutBody(body)) {
        throw new ValidationError(validateTransformLayoutBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/transform_layout`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateTransformLayout200(data)) {
                throw new ValidationError(validateTransformLayout200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['transform_layout']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type TransformLayoutMap = Endpoints['transform_layout'];

type ClearallbuzzResponse = {
    status: 200;
    data: Endpoints['clearallbuzz']['200'];
};
export async function clearallbuzz({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['clearallbuzz']['Params'];
    host: string;
}): Promise<ClearallbuzzResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/clearallbuzz`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateClearallbuzz200(data)) {
                throw new ValidationError(validateClearallbuzz200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['clearallbuzz']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type ClearallbuzzMap = Endpoints['clearallbuzz'];

type SilentVideoDetectionResponse = {
    status: 200;
    data: Endpoints['silent_video_detection']['200'];
};
export async function silentVideoDetection({
    fetcher,
    init,
    params,
    body,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['silent_video_detection']['Params'];
    body: Endpoints['silent_video_detection']['Body'];
    host: string;
}): Promise<SilentVideoDetectionResponse> {
    if (!validateSilentVideoDetectionBody(body)) {
        throw new ValidationError(validateSilentVideoDetectionBody.errors);
    }
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/silent_video_detection`,
        {
            ...init,
            method: 'post',

            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateSilentVideoDetection200(data)) {
                throw new ValidationError(
                    validateSilentVideoDetection200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['silent_video_detection']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type SilentVideoDetectionMap = Endpoints['silent_video_detection'];

type ThemeResponse =
    | {status: 200; data: Endpoints['theme']['200']}
    | {status: 204; data: Endpoints['theme']['204']};
export async function theme({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['theme']['Params'];
    host: string;
}): Promise<ThemeResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/theme/`,
        {
            ...init,
            method: 'get',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateTheme200(data)) {
                throw new ValidationError(validateTheme200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['theme']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }

    if (res.status === 204) {
        const data = await res.text();
        if (!validateTheme204(data)) {
            throw new ValidationError(validateTheme204.errors);
        }
        return {status: res.status, data};
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type ThemeMap = Endpoints['theme'];

type AvailableLayoutsResponse = {
    status: 200;
    data: Endpoints['available_layouts']['200'];
};
export async function availableLayouts({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['available_layouts']['Params'];
    host: string;
}): Promise<AvailableLayoutsResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/available_layouts`,
        {
            ...init,
            method: 'get',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateAvailableLayouts200(data)) {
                throw new ValidationError(validateAvailableLayouts200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['available_layouts']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type AvailableLayoutsMap = Endpoints['available_layouts'];

type LayoutSvgsResponse = {status: 200; data: Endpoints['layout_svgs']['200']};
export async function layoutSvgs({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['layout_svgs']['Params'];
    host: string;
}): Promise<LayoutSvgsResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/layout_svgs`,
        {
            ...init,
            method: 'get',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateLayoutSvgs200(data)) {
                throw new ValidationError(validateLayoutSvgs200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['layout_svgs']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type LayoutSvgsMap = Endpoints['layout_svgs'];

type GuestBreakoutBuzzResponse = {
    status: 200;
    data: Endpoints['guest_breakout_buzz']['200'];
};
export async function guestBreakoutBuzz({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['guest_breakout_buzz']['Params'];
    host: string;
}): Promise<GuestBreakoutBuzzResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/breakoutbuzz`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateGuestBreakoutBuzz200(data)) {
                throw new ValidationError(validateGuestBreakoutBuzz200.errors);
            }
            return {
                status: res.status,
                data: data as Endpoints['guest_breakout_buzz']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type GuestBreakoutBuzzMap = Endpoints['guest_breakout_buzz'];

type GuestClearBreakoutBuzzResponse = {
    status: 200;
    data: Endpoints['guest_clear_breakout_buzz']['200'];
};
export async function guestClearBreakoutBuzz({
    fetcher,
    init,
    params,
    host,
}: {
    fetcher: typeof fetch;
    init?: RequestInit;
    params: Endpoints['guest_clear_breakout_buzz']['Params'];
    host: string;
}): Promise<GuestClearBreakoutBuzzResponse> {
    const res = await fetcher(
        `${host}/api/client/v2/conferences/${encodeURIComponent(params.conferenceAlias)}/clearbreakoutbuzz`,
        {
            ...init,
            method: 'post',
        },
    );

    if (res.status === 200) {
        if (res.headers.get('content-type')?.startsWith('application/json')) {
            const data: unknown = await res.json();
            if (!validateGuestClearBreakoutBuzz200(data)) {
                throw new ValidationError(
                    validateGuestClearBreakoutBuzz200.errors,
                );
            }
            return {
                status: res.status,
                data: data as Endpoints['guest_clear_breakout_buzz']['200'],
            };
        } else {
            throw new NonJSONResponseError(
                res.headers.get('content-type'),
                await res.text(),
            );
        }
    }
    throw new Error(`Unexpected status ${res.status}`);
}
export type GuestClearBreakoutBuzzMap = Endpoints['guest_clear_breakout_buzz'];
