export const sizes = ['xs', 'sm', 'md', 'lg', 'xl'] as const;
export const offsets = [
    'xsOffset',
    'smOffset',
    'mdOffset',
    'lgOffset',
    'xlOffset',
] as const;
export const grid = [...sizes, ...offsets] as const;
export type Cells = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
