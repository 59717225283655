import React from 'react';
import {useTranslation} from 'react-i18next';

import type {ButtonProps} from '@pexip/components';
import {Icon, IconTypes} from '@pexip/components';

import {InMeetingInputControl} from '../../InputControl/InMeetingInputControl.view';
import {TestId} from '../../../../test/testIds';

export const PresentationButton: React.FC<
    ButtonProps & {
        isMobileDevice: boolean;
        localIsPresenting: boolean;
        tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
        startPresentation: () => void;
        stopPresentation: () => void;
    }
> = ({
    isMobileDevice,
    localIsPresenting,
    tooltipPosition = 'top',
    startPresentation,
    stopPresentation,
    ...props
}) => {
    const {t} = useTranslation();

    const nextAction = localIsPresenting ? 'stop' : 'start';
    const isSharing = nextAction === 'stop';
    const presentationAriaLabel = isSharing
        ? t('media.presentation.stop-share-screen', 'Stop sharing')
        : t('media.presentation.share-screen', 'Share screen');

    return (
        <InMeetingInputControl
            onClick={() =>
                localIsPresenting ? stopPresentation() : startPresentation()
            }
            aria-label={presentationAriaLabel}
            tooltipText={presentationAriaLabel}
            tooltipPosition={tooltipPosition}
            icon={<Icon source={IconTypes.IconShare} />}
            isActive={localIsPresenting}
            shouldShowTooltip={!isMobileDevice}
            data-testid={
                isSharing
                    ? TestId.ButtonStopPresentation
                    : TestId.ButtonStartPresentation
            }
            {...props}
        />
    );
};

export type PresentationButtonProps = React.ComponentProps<
    typeof PresentationButton
>;
