import React from 'react';

import type {ColorScheme} from '@pexip/components';
import type {AnalyzerNodeInit} from '@pexip/media-processor';

import {useAudioMeter} from '../hooks/useAudioMeter';
import {AudioMeterView} from '../views/AudioMeter/AudioMeter.view';

export const AudioMeter: React.FC<{
    analyzer: AnalyzerNodeInit;
    colorScheme?: ColorScheme;
    text?: string;
    width?: number;
}> = ({analyzer, colorScheme, text, width, ...props}) => {
    const max = useAudioMeter(analyzer);

    return (
        <AudioMeterView
            progress={max}
            colorScheme={colorScheme}
            text={text}
            width={width}
            {...props}
        />
    );
};
