import type {Placement} from '@react-aria/overlays';

import type {TooltipPosition} from '../../../types/propTypes';

const positionMap: Record<TooltipPosition, Placement> = {
    top: 'top',
    topLeft: 'top left',
    topRight: 'top right',
    topStart: 'top start',
    bottom: 'bottom',
    bottomLeft: 'bottom left',
    bottomRight: 'bottom right',
    bottomStart: 'bottom start',
    endBottom: 'end bottom',
    right: 'right',
    left: 'left',
};

/**
 * The helper function maps the internal TooltipPosition to Placement which is used by react-aria.
 * We have introduced that to avoid breaking changes for the Tooltip component.
 * see https://github.com/adobe/react-spectrum/blob/main/packages/%40react-types/overlays/src/index.d.ts
 * @param position - The position of the tooltip
 * @returns The mapped placement for react-aria
 */
export const mapTooltipPosition = (position: TooltipPosition): Placement => {
    return positionMap[position] || 'top';
};
