import React, {useState, useMemo} from 'react';
import type {ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

import type {SelectProps} from '@pexip/components';
import {List, IconTypes, Row} from '@pexip/components';
import type {MediaDeviceInfoLike} from '@pexip/media-control';
import {
    findAudioInputDevices,
    findAudioOutputDevices,
    findVideoInputDevices,
} from '@pexip/media-control';

import {TestId} from '../../../test/testIds';
import {DeviceSelect} from '../DeviceSelect/DeviceSelect.view';
import {MissingDeviceAlert} from '../DeviceSelect/MissingDeviceAlert.view';
import type {BlockedBrowserPermissionsInfoType, DeviceError} from '../../types';

import styles from './DevicesList.module.scss';

export const DevicesList: React.FC<
    {
        requestedAudio?: boolean;
        requestedVideo?: boolean;
        videoInput?: MediaDeviceInfoLike;
        audioInput?: MediaDeviceInfoLike;
        audioOutput?: MediaDeviceInfoLike;
        devices: MediaDeviceInfoLike[];
        videoInputError: DeviceError;
        audioInputError: DeviceError;
        onAudioInputChange: (device: MediaDeviceInfoLike) => void;
        onAudioOutputChange: (device: MediaDeviceInfoLike) => void;
        onVideoInputChange: (device: MediaDeviceInfoLike) => void;
        isLoading?: boolean;
        inputAudioTester?: ReactNode;
        outputAudioTester?: ReactNode;
        learnHowToGrantAccessURL?: string;
        permissionInfoType?: BlockedBrowserPermissionsInfoType;
        setShowHelpVideo?: React.Dispatch<React.SetStateAction<boolean>>;
        showTooltip?: boolean;
    } & Pick<SelectProps, 'sizeModifier'>
> = ({
    audioInput,
    audioInputError,
    audioOutput,
    devices,
    isLoading = false,
    requestedAudio = true,
    requestedVideo = true,
    onAudioInputChange,
    onAudioOutputChange,
    onVideoInputChange,
    inputAudioTester,
    outputAudioTester,
    videoInput,
    videoInputError,
    learnHowToGrantAccessURL,
    permissionInfoType,
    sizeModifier,
    setShowHelpVideo,
    showTooltip = false,
}) => {
    const {t} = useTranslation();
    const [showErrorTooltip, setShowErrorTooltip] = useState({
        audio: false,
        video: false,
    });

    const videoTooltipClick = (visible: boolean) => {
        setShowErrorTooltip({
            audio: false,
            video: visible,
        });
    };

    const audioTooltipClick = (visible: boolean) => {
        setShowErrorTooltip({
            audio: visible,
            video: false,
        });
    };
    const videoInputs = useMemo(() => {
        if (!requestedVideo) {
            return [];
        }
        return findVideoInputDevices(devices);
    }, [devices, requestedVideo]);
    const audioInputs = useMemo(() => {
        if (!requestedAudio) {
            return [];
        }
        return findAudioInputDevices(devices);
    }, [devices, requestedAudio]);
    const audioOutputs = useMemo(() => {
        return findAudioOutputDevices(devices);
    }, [devices]);

    const shouldShowMissingVideoAlert =
        requestedVideo &&
        (videoInputError.deniedDevice ||
            (videoInputError.title && !videoInputs.length));
    const shouldShowMissingAudioAlert =
        requestedAudio &&
        (audioInputError.deniedDevice ||
            (audioInputError.title && !audioInputs.length));
    const shouldShowAudioOutput =
        audioOutputs.length > 0 && !audioInputError?.title;

    return (
        <List spacing="none" className={styles.deviceListWrapper}>
            {shouldShowMissingVideoAlert ? (
                <MissingDeviceAlert
                    className="mb-3"
                    title={videoInputError.title}
                    tooltip={videoInputError.description}
                    deniedDevice={videoInputError.deniedDevice}
                    learnHowToGrantAccessURL={learnHowToGrantAccessURL}
                    permissionInfoType={permissionInfoType}
                    data-testid={TestId.MissingVideoDeviceAlert}
                    setIsTooltipVisible={videoTooltipClick}
                    isTooltipVisible={showErrorTooltip.video}
                    setShowHelpVideo={setShowHelpVideo}
                    showTooltip={showTooltip}
                />
            ) : (
                <DeviceSelect
                    className="mb-3"
                    isDisabled={!requestedVideo || isLoading}
                    label={t(
                        'settings.device-select.select-camera',
                        'Select camera',
                    )}
                    data-testid={TestId.SelectVideoInput}
                    errorText={videoInputError.title}
                    errorTextTestId={TestId.SelectVideoInputErrorText}
                    hasError={requestedVideo && !!videoInputError.title}
                    onDeviceChange={onVideoInputChange}
                    iconType={IconTypes.IconVideoOn}
                    mediaDeviceInfoLike={videoInput}
                    devices={videoInputs}
                    sizeModifier={sizeModifier}
                />
            )}
            {shouldShowMissingAudioAlert ? (
                <MissingDeviceAlert
                    className="mb-2"
                    title={audioInputError.title}
                    tooltip={audioInputError.description}
                    deniedDevice={audioInputError.deniedDevice}
                    learnHowToGrantAccessURL={learnHowToGrantAccessURL}
                    permissionInfoType={permissionInfoType}
                    data-testid={TestId.MissingAudioDeviceAlert}
                    setIsTooltipVisible={audioTooltipClick}
                    isTooltipVisible={showErrorTooltip.audio}
                    setShowHelpVideo={setShowHelpVideo}
                    showTooltip={showTooltip}
                />
            ) : (
                <DeviceSelect
                    className={inputAudioTester ? 'mb-2' : 'mb-3'}
                    isDisabled={!requestedAudio || isLoading}
                    label={t(
                        'settings.device-select.select-microphone',
                        'Select microphone',
                    )}
                    data-testid={TestId.SelectAudioInput}
                    errorText={audioInputError.title}
                    errorTextTestId={TestId.SelectAudioInputErrorText}
                    hasError={requestedAudio && !!audioInputError.title}
                    onDeviceChange={onAudioInputChange}
                    iconType={IconTypes.IconMicrophoneOn}
                    mediaDeviceInfoLike={audioInput}
                    devices={audioInputs}
                    sizeModifier={sizeModifier}
                />
            )}
            {inputAudioTester && !audioInputError?.title && (
                <div
                    className={cx(
                        'p-0',
                        'mb-2',
                        'd-flex',
                        'flex-direction-column',
                        'align-items-end',
                    )}
                >
                    {audioInput && inputAudioTester}
                    {!shouldShowAudioOutput && outputAudioTester}
                </div>
            )}
            {shouldShowAudioOutput && (
                <>
                    <DeviceSelect
                        isDisabled={isLoading}
                        label={t(
                            'settings.device-select.select-speaker',
                            'Select speaker',
                        )}
                        data-testid={TestId.SelectAudioOutput}
                        onDeviceChange={onAudioOutputChange}
                        iconType={IconTypes.IconSpeakerOn}
                        mediaDeviceInfoLike={audioOutput}
                        devices={audioOutputs}
                        sizeModifier={sizeModifier}
                        className="m-0"
                    />
                    {outputAudioTester && (
                        <Row className={'p-0 mt-2 justify-content-end'}>
                            {outputAudioTester}
                        </Row>
                    )}
                </>
            )}
        </List>
    );
};
