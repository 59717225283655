import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {
    FontVariant,
    IconTypes,
    Select,
    Text,
    TextHeading,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import {StreamQuality} from '../../types';

export const QualityList: React.FC<{
    setStreamQuality: (quality: StreamQuality) => void;
    streamQuality: StreamQuality;
}> = ({setStreamQuality, streamQuality}) => {
    const {t} = useTranslation();
    return (
        <>
            <TextHeading
                className="mb-1"
                fontVariant={FontVariant.H5}
                htmlTag="h3"
            >
                <Trans t={t} i18nKey="quality.quality">
                    Connection quality
                </Trans>
            </TextHeading>

            <Text fontVariant={FontVariant.Small} htmlTag="p">
                <Trans t={t} i18nKey="quality.info">
                    This setting affects the amount of bandwidth you’ll use for
                    this meeting. Adjust to a lower value if your internet
                    connection is slow or if your computer’s performance is
                    badly affected.
                </Trans>
            </Text>

            <Select
                className="mb-5 mt-4"
                data-testid={TestId.ButtonQuality}
                iconType={IconTypes.IconBandwidth}
                isFullWidth
                label={t('quality.select-quality', 'Select meeting quality')}
                labelModifier="hidden"
                options={[
                    {
                        id: StreamQuality.Low,
                        label: t('quality.low', 'Low'),
                    },
                    {
                        id: StreamQuality.Medium,
                        label: t('quality.medium', 'Medium'),
                    },
                    {
                        id: StreamQuality.High,
                        label: t('quality.high', 'High'),
                    },
                    {
                        id: StreamQuality.VeryHigh,
                        label: t('quality.very-high', 'Very High'),
                    },
                    {
                        id: StreamQuality.Auto,
                        label: t('quality.auto', 'Auto'),
                    },
                ]}
                onValueChange={(id: string) => {
                    setStreamQuality(id as StreamQuality);
                }}
                sizeModifier="small"
                value={streamQuality}
            />
        </>
    );
};
