import React, {useCallback, useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {
    FontVariant,
    Button,
    ThemeProvider,
    Text,
    Bar,
    ConfirmationModal,
} from '@pexip/components';

import {SettingsPanel} from '../SettingsPanel/SettingsPanel.view';
import {PanelHeader} from '../PanelHeader/PanelHeader.view';
import {VideoLayoutOptionValues} from '../../types';
import {
    ImgLayoutThumbnail,
    LayoutThumbnail,
    Thumbnail,
} from '../LayoutThumbnail/LayoutThumbnail.view';
import {TestId} from '../../../test/testIds';

import styles from './MeetingLayout.module.scss';

const LAYOUT_MAPPING = new Map<string, string[]>([
    [
        VideoLayoutOptionValues.AdaptiveComposition,
        [
            '5:7',
            'ac_presentation_in_mix',
            'ac_presentation_in_mix_group',
            'ac_extended',
        ],
    ],
    [VideoLayoutOptionValues.Equal22, ['4:0']],
    [VideoLayoutOptionValues.Equal33, ['9:0']],
    [VideoLayoutOptionValues.Equal44, ['16:0']],
    [VideoLayoutOptionValues.Equal55, ['25:0']],
]);

const isActive = (layout: string, layoutSelection: string | undefined) => {
    if (layoutSelection === layout) {
        return true;
    }

    // FIXME: workaround because of #35453
    return Boolean(
        layoutSelection &&
            LAYOUT_MAPPING.get(layout)?.includes(layoutSelection),
    );
};

export const MeetingLayout: React.FC<{
    currentLayout?: string;
    close: (e: React.SyntheticEvent<HTMLElement>) => void;
    setLayout: (
        layout: string | undefined,
        onDone?: () => void,
        onFail?: (error: unknown) => void,
    ) => void;
    setShowLayoutChangeConfirmationModal: (
        showLayoutChangeConfirmationModal: boolean,
    ) => void;
    showLayoutChangeConfirmationModal: boolean;
    isConfirmationOpen: boolean;
    openConfirmation: () => void;
    closeConfirmation: () => void;
    layouts: Map<string, {primary: string; active: string}>;
    onBackClick: () => void;
}> = ({
    currentLayout,
    close,
    setLayout,
    setShowLayoutChangeConfirmationModal,
    showLayoutChangeConfirmationModal,
    isConfirmationOpen,
    openConfirmation,
    closeConfirmation,
    layouts,
    onBackClick,
}) => {
    const {t} = useTranslation();
    const [isSaving, setIsSaving] = useState(false);
    const [layoutChanged, setLayoutChanged] = useState(false);
    const [layoutSelection, setLayoutSelection] = useState<string | undefined>(
        currentLayout,
    );

    const handleSave = useCallback(() => {
        setShowLayoutChangeConfirmationModal(false);
        setIsSaving(true);
        if (layoutSelection) {
            setLayout(
                layoutSelection,
                () => setIsSaving(false),
                () => setIsSaving(false),
            );
        }
        closeConfirmation();
    }, [
        layoutSelection,
        closeConfirmation,
        setLayout,
        setShowLayoutChangeConfirmationModal,
    ]);

    const getLayoutThumbnail = (layout: string) => {
        return (
            <LayoutThumbnail
                active={isActive(layout, layoutSelection)}
                className={styles.layoutOption}
                layout={layout}
                key={layout}
                selectLayout={setLayoutSelection}
            />
        );
    };

    const getDefaultLayouts = () => (
        <>
            <Text fontVariant={FontVariant.BodyBold} htmlTag="p">
                <Trans t={t} i18nKey="settings.layout-speaker-focused">
                    Speaker focused
                </Trans>
            </Text>
            <div className={styles.layoutSettingsContent}>
                {[
                    VideoLayoutOptionValues.AdaptiveComposition,
                    VideoLayoutOptionValues.Speaker7,
                    VideoLayoutOptionValues.Speaker21,
                    VideoLayoutOptionValues.Speaker221,
                    VideoLayoutOptionValues.Speaker33,
                ].map(getLayoutThumbnail)}
            </div>
            <Text fontVariant={FontVariant.BodyBold} htmlTag="p">
                <Trans t={t} i18nKey="settings.layout-equal">
                    Equal
                </Trans>
            </Text>
            <div className={styles.layoutSettingsContent}>
                {[
                    VideoLayoutOptionValues.Highlight,
                    VideoLayoutOptionValues.Equal22,
                    VideoLayoutOptionValues.Equal33,
                    VideoLayoutOptionValues.Equal44,
                    VideoLayoutOptionValues.Equal55,
                ].map(getLayoutThumbnail)}
            </div>
        </>
    );

    const getLayouts = () => (
        <div className={styles.layoutSettingsContent}>
            {Array.from(layouts.entries()).map(([layout, svg]) =>
                svg.primary && svg.active ? (
                    <Thumbnail
                        key={layout}
                        layout={layout}
                        selectLayout={setLayoutSelection}
                    >
                        <ImgLayoutThumbnail
                            name={layout}
                            svg={svg}
                            active={isActive(layout, layoutSelection)}
                        />
                    </Thumbnail>
                ) : (
                    getLayoutThumbnail(layout)
                ),
            )}
        </div>
    );

    useEffect(() => {
        setLayoutChanged(currentLayout !== layoutSelection);
    }, [currentLayout, layoutSelection]);

    useEffect(() => {
        setLayoutSelection(currentLayout);
    }, [currentLayout]);

    const enableSave = !isSaving && layoutChanged;

    return (
        <>
            <SettingsPanel
                headerContent={
                    <ThemeProvider colorScheme="light">
                        <PanelHeader
                            title={`${t(
                                'settings.meeting-layout',
                                'Meeting layout',
                            )}`}
                            onBackClick={onBackClick}
                        />
                    </ThemeProvider>
                }
                footerContent={
                    <ThemeProvider colorScheme="light">
                        <Bar>
                            <Button
                                onClick={close}
                                variant="secondary"
                                size="medium"
                                modifier="fullWidth"
                                isDisabled={isSaving}
                                data-testid={TestId.ButtonSettingsCancel}
                            >
                                <Trans t={t} i18nKey="settings.cancel">
                                    Cancel
                                </Trans>
                            </Button>
                            <Button
                                onClick={() => {
                                    if (!showLayoutChangeConfirmationModal) {
                                        handleSave();
                                    } else {
                                        openConfirmation();
                                    }
                                }}
                                type="submit"
                                modifier="fullWidth"
                                className="ml-2"
                                isLoading={isSaving}
                                isDisabled={!enableSave}
                                data-testid={TestId.ButtonSettingsSave}
                            >
                                <Trans t={t} i18nKey="settings.save">
                                    Save
                                </Trans>
                            </Button>
                        </Bar>
                    </ThemeProvider>
                }
            >
                <ThemeProvider colorScheme="light">
                    <Text fontVariant={FontVariant.Small} htmlTag="p">
                        <Trans t={t} i18nKey="settings.layout-changes-info">
                            Changes you make to the video layout will be the
                            same for every participant.
                        </Trans>
                    </Text>

                    {layouts.size > 0 ? getLayouts() : getDefaultLayouts()}
                </ThemeProvider>
            </SettingsPanel>
            <ConfirmationModal
                closeOnOutsideClick={false}
                cancelButtonTestid={TestId.ConfirmationModalCancelButton}
                cancelButtonText={t('common.cancel', 'Cancel')}
                confirmButtonTestid={TestId.ConfirmationModalConfirmButton}
                confirmButtonText={t('common.change', 'Change')}
                data-testid={TestId.ModalLayoutChangeConfirmation}
                description={t(
                    'settings.layout-confirmation-desc',
                    'You’re about to change the layout for everyone in the meeting, not just yourself.',
                )}
                isOpen={isConfirmationOpen}
                onCancel={closeConfirmation}
                onClose={closeConfirmation}
                onConfirm={handleSave}
                title={t(
                    'settings.layout-confirmation-title',
                    'Changing layouts will affect everyone',
                )}
                uniqueTitle={t(
                    'settings.layout-confirmation-title',
                    'Changing layouts will affect everyone',
                )}
            />
        </>
    );
};
