import React from 'react';
import cx from 'classnames';

import {Box, FontVariant, Text} from '@pexip/components';

import type {ChatMessage, ChatActivityType} from '../../types';
import {TestId} from '../../../test/testIds';

import styles from './ChatMessage.module.scss';

const getTestId = (chatActivityType?: ChatActivityType) => {
    switch (chatActivityType) {
        case 'removed':
            return TestId.ChatRemoveActivityMessage;

        case 'empty-chat':
            return TestId.ChatEmptyActivityMessage;

        default:
            return TestId.ChatActivityMessage;
    }
};

export const ChatActivityMessage: React.FC<{message: ChatMessage}> = ({
    message,
}) => (
    <Box
        padding="compact"
        className={cx('pt-0', styles.activityMessageWrapper)}
        key={message.id}
        data-testid={getTestId(message.activityType)}
        hasBackground={false}
    >
        <div className={styles.messageText}>
            <Text htmlTag="p" className={styles.messageText}>
                <Text data-testid={TestId.ChatActivityMessageText}>
                    {message.message}
                </Text>
                {message.activityType === 'removed' && (
                    <Text
                        fontVariant={FontVariant.BodyBold}
                        className={styles.messageName}
                        translate="no"
                    >
                        {message.removedParticipant?.displayName}
                    </Text>
                )}
            </Text>
            {(message.activityType === 'joined' ||
                message.activityType === 'left') && (
                <div className={styles.scrim}>
                    <Text
                        variant="subtle"
                        fontVariant={FontVariant.Small}
                        className={styles.timestamp}
                    >
                        {message.timestamp}
                    </Text>
                </div>
            )}
        </div>
    </Box>
);

export type ChatActivityMessageProps = React.ComponentProps<
    typeof ChatActivityMessage
>;
