import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Form, InputLabel} from '@pexip/components';

import {TestId} from '../../../test/testIds';
import {CHARACTER_LIMIT} from '../../constants';

import {ChatPanelInputButtons} from './ChatPanelInputButtons.view';

import styles from './ChatPanelInput.module.scss';

const formId = 'chatInputForm';
const inputId = 'chatTextInput';

export const ChatPanelInput: React.FC<{
    isDisabled?: boolean;
    onSubmit: (message: string) => void;
    placeholder: string;
    className?: string;
}> = ({onSubmit, isDisabled = false, placeholder, className, ...props}) => {
    const {t} = useTranslation();
    const [value, setValue] = useState('');

    return (
        <Form
            onSubmit={e => {
                e.preventDefault();
                onSubmit(value);
                setValue('');
            }}
            id={formId}
            className={className}
            data-testid={TestId.ChatForm}
        >
            <InputLabel
                id={inputId}
                form={formId}
                className={styles.inputLabel}
                text={t('meeting.chat-input', 'Chat text input')}
            />
            <label className={styles.wrapper}>
                <input
                    type="text"
                    id={inputId}
                    className={styles.input}
                    onChange={e => setValue(e.target.value)}
                    value={value}
                    placeholder={placeholder}
                    disabled={isDisabled}
                    autoComplete="off"
                    data-testid={TestId.ChatInput}
                    maxLength={CHARACTER_LIMIT}
                    {...props}
                />
                <ChatPanelInputButtons isDisabled={!value || isDisabled} />
            </label>
        </Form>
    );
};
