import React from 'react';

import {AspectRatio} from '@pexip/components';

import styles from './PreflightContent.module.scss';

export const PreflightContent: React.FC<{
    media: React.ReactElement;
    children: React.ReactElement;
}> = ({media, children}) => (
    <div className={styles.wrapper}>
        <div className={styles.inner}>
            <div className={styles.selfviewWrapper}>
                <AspectRatio className={styles.aspectRatio}>
                    {media}
                </AspectRatio>
            </div>
            <div className={styles.joinWrapper}>{children}</div>
        </div>
    </div>
);

export type PreflightContentProps = React.ComponentProps<
    typeof PreflightContent
>;
