import React from 'react';

import {Spinner} from '../../../components/elements/Spinner/Spinner';
import {FullscreenLayout} from '../FullscreenLayout/FullscreenLayout';

export const FullscreenSpinner: React.FC = props => (
    <FullscreenLayout>
        <Spinner colorScheme="dark" {...props} />
    </FullscreenLayout>
);
