import React from 'react';

import {
    FontVariant,
    TextHeading,
    Button,
    Icon,
    IconTypes,
} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import styles from './PanelHeader.module.scss';

export const PanelHeader: React.FC<{
    backButtonAriaLabel?: string;
    closeButtonAriaLabel?: string;
    closeButtonTestId?: string;
    title?: string;
    onBackClick?: () => void;
    onCloseClick?: () => void;
}> = ({
    backButtonAriaLabel = 'Go back',
    closeButtonAriaLabel = 'Close panel',
    closeButtonTestId = '',
    title,
    onCloseClick,
    onBackClick,
}) => {
    return (
        <div className={styles.container}>
            <div className={styles.startContent}>
                {onBackClick && (
                    <Button
                        aria-label={backButtonAriaLabel}
                        onClick={onBackClick}
                        className="mr-1"
                        size="none"
                        variant="translucent"
                        data-testid={TestId.BreakoutSetupPanelBackBtn}
                    >
                        <Icon
                            size="small"
                            source={IconTypes.IconArrowLeftRegular}
                        ></Icon>
                    </Button>
                )}
                {title && (
                    <TextHeading htmlTag="h5" fontVariant={FontVariant.H5}>
                        {title}
                    </TextHeading>
                )}
            </div>
            {onCloseClick && (
                <Button
                    aria-label={closeButtonAriaLabel}
                    onClick={onCloseClick}
                    size="compact"
                    variant="translucent"
                    data-testid={closeButtonTestId}
                >
                    <Icon size="compact" source={IconTypes.IconClose}></Icon>
                </Button>
            )}
        </div>
    );
};

export type PanelHeaderProps = React.ComponentProps<typeof PanelHeader>;
