import type {DisplayMediaOptions} from './types';
import {stopMediaStream} from './devices';

/**
 * A fallback implementation of `monitorTypeSurfaces` limitation when it is not
 * supported by the current browser and the source of the `displaySurface` is
 * available.
 *
 * @param constraints - The constraints used to request display media.
 * @param stream - The MediaStream returned from the requesting display media.
 */
export const limitSharingMonitorInterface = (
    constraints: DisplayMediaOptions,
    stream: MediaStream,
) => {
    // Fallback implementation of `monitorTypeSurfaces`
    if (
        !constraints.monitorTypeSurfaces ||
        constraints.monitorTypeSurfaces !== 'exclude'
    ) {
        return stream;
    }
    for (const track of stream.getTracks()) {
        const {displaySurface} = track.getSettings();
        if (!displaySurface || displaySurface !== 'monitor') {
            continue;
        }
        stopMediaStream(stream);
        throw new TypeError('MonitorSharingNotAllowed', {
            cause: {
                monitorTypeSurfaces: constraints.monitorTypeSurfaces,
                track,
                displaySurface,
            },
        });
    }
    return stream;
};
