import type {IScrollbars} from '@pexip/components';
import {lastOr} from '@pexip/utils';

import type {ChatMessage} from '../types';

export enum NewChatMessageAction {
    ScrollToLastMessage = 'scroll-to-last-message',
    DisplayNewMessageToast = 'display-new-message-toast',
}

/**
 * calculates wether the new chat messages Pill should be displayed
 *
 * @param isLastMessageRead - boolean
 * @param currentUserId - string
 * @param messageAuthorId - string
 */
export const calculateNewChatMessageAction = (
    isMessageByCurrentUser: boolean,
    isLastMessageVisible: boolean,
) => {
    if (!isLastMessageVisible && !isMessageByCurrentUser) {
        return NewChatMessageAction.DisplayNewMessageToast;
    }

    return NewChatMessageAction.ScrollToLastMessage;
};

/**
 * detect wether the last message posted by current user
 *
 * @param messages - `Messages[]` chat messages
 * @param currentUserId - `string` the current user id
 */
export const isLastMessageByCurrentUser = (
    messages: ChatMessage[],
    currentUserId: string,
) => lastOr(messages, {} as ChatMessage).userId === currentUserId;

/**
 * Scrolls to last chat message
 *
 * @param scrollbarRef - RefObject<IScrollbars>
 */
export const scrollToLastMessage = (
    scrollbarRef: React.RefObject<IScrollbars>,
    animateScroll = true,
) => {
    const scrolElement = scrollbarRef.current?.getScrollElement();
    if (!scrolElement) {
        return;
    }
    const top = scrolElement.scrollHeight;

    scrolElement.scrollTo({
        top,
        behavior: animateScroll ? 'smooth' : 'auto',
    });
};

/**
 * Return function to detect wether the last chat message is visible to the user.
 */
export const getIsLastNewMessageVisibleFn =
    (bottomOffset = 0) =>
    (scrollbars?: IScrollbars | null) => {
        const scrollElement = scrollbars?.getScrollElement();

        if (scrollElement) {
            const fullScrollContentHeight = scrollElement.scrollHeight;
            const yPosition = scrollElement.scrollTop;
            const visibleScrollContentHeight = scrollElement.clientHeight;

            return (
                fullScrollContentHeight - yPosition <=
                visibleScrollContentHeight + bottomOffset
            );
        }

        return false;
    };

/**
 * calculate how many unread chat messages
 *
 * @param messages - `Messages[]` chat messages
 * @param lastVisibleMessageId - boolean
 */
export const calculateHowManyUnreadMessages = (
    messages: ChatMessage[],
    lastVisibleMessageId: string,
) => {
    const lastVisibleMessageIndex = messages.findIndex(
        message => message.id === lastVisibleMessageId,
    );

    if (lastVisibleMessageIndex === -1) {
        return 0;
    }

    return messages.length - (lastVisibleMessageIndex + 1);
};

/**
 * Check if we should ignore incoming message when it arrives
 */
export const shouldEmitNewMessage = (message: string, limit: number) =>
    message && message.length < limit;
