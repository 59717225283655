import React from 'react';
import cx from 'classnames';

import type {InteractiveElementProps} from '../InteractiveElement/InteractiveElement';
import {InteractiveElement} from '../InteractiveElement/InteractiveElement';
import type {ColorScheme} from '../../../types/variants';
import type {StandardSizeModifier} from '../../../types/sizes';
import {Row} from '../../modules/List/Row';
import {ThemeConsumer} from '../../../themes/ThemeContext';

import styles from './ListBoxLink.module.scss';

export const ListBoxLink: React.FC<
    InteractiveElementProps & {
        children: React.ReactNode;
        colorScheme?: ColorScheme;
        hasBorder?: boolean;
        isDisabled?: boolean;
        isHovered?: boolean;
        sizeModifier?: StandardSizeModifier;
        // Needs to be defined explicitly for TS to get the typing
        onClick?: (
            e:
                | React.MouseEvent<HTMLButtonElement>
                | React.MouseEvent<HTMLAnchorElement>
                | React.MouseEvent<HTMLDivElement>,
        ) => void;
    }
> = ({
    children,
    className,
    colorScheme,
    hasBorder = true,
    isDisabled,
    isHovered,
    sizeModifier = 'large',
    href,
    onClick,
    ...props
}) => (
    <ThemeConsumer>
        {({colorScheme: defaultColorScheme}) => (
            <InteractiveElement
                className={cx(
                    styles.wrapper,
                    styles[colorScheme ?? defaultColorScheme],
                    sizeModifier && styles[sizeModifier],
                    {[styles.disabled]: isDisabled},
                    {[styles.hovered]: isHovered},
                    className,
                )}
                href={href}
                onClick={onClick}
                htmlTag={!href && !onClick ? 'div' : undefined}
                disabled={isDisabled}
                {...props}
            >
                <Row
                    spacing="none"
                    className={cx(styles.inner, {
                        [styles.border]: hasBorder,
                    })}
                >
                    {children}
                </Row>
            </InteractiveElement>
        )}
    </ThemeConsumer>
);

export type ListBoxLinkProps = React.ComponentProps<typeof ListBoxLink>;
