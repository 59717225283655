import {createPortal} from 'react-dom';
import {useEffect, useRef, useState} from 'react';

export const PopOutWindow: React.FC<{
    children: React.ReactNode;
    remotePopOut?: Window;
    title?: string;
    onUnload?: () => void;
}> = ({children, remotePopOut, title, onUnload}) => {
    const [container] = useState(() => document.createElement('div'));
    const popOutRef = useRef<Window | null>();

    // make sure we have a ref to a popOut
    useEffect(() => {
        if (remotePopOut) {
            popOutRef.current = remotePopOut;
        }
        if (!popOutRef.current) {
            popOutRef.current = window.open('', '');
        }
        if (popOutRef.current) {
            popOutRef.current.document.title = title ?? 'Pexip One';
        }
    }, [remotePopOut, title]);

    useEffect(() => {
        const popOutCopy = popOutRef.current;

        const releasePopOut = () => {
            if (!popOutCopy) {
                return;
            }
            popOutCopy.removeEventListener('beforeunload', releasePopOut);
            window.removeEventListener('beforeunload', releasePopOut);
            if (!popOutCopy?.closed) {
                popOutCopy.close();
            }
            if (typeof onUnload === 'function') {
                onUnload();
            }
        };
        if (popOutCopy) {
            popOutCopy.document.body.appendChild(container);
            copyStyles(popOutCopy);
            popOutCopy.addEventListener('beforeunload', releasePopOut);
            window.addEventListener('beforeunload', releasePopOut);
        }

        return () => releasePopOut();
    }, [container, onUnload]);

    if (!container) {
        return null;
    }
    return createPortal(children, container);
};

const copyStyles = (popOut: Window) => {
    // Copy the app's styles into the new window
    const stylesheets = Array.from(document.styleSheets);
    stylesheets.forEach(stylesheet => {
        if (stylesheet.href) {
            const newStyleElement = document.createElement('link');
            newStyleElement.rel = 'stylesheet';
            newStyleElement.href = stylesheet.href;
            popOut.document.head.appendChild(newStyleElement);
        } else if (stylesheet.cssRules && stylesheet.cssRules.length > 0) {
            const newStyleElement = document.createElement('style');
            Array.from(stylesheet.cssRules).forEach(rule => {
                newStyleElement.appendChild(
                    document.createTextNode(rule.cssText),
                );
            });
            popOut.document.head.appendChild(newStyleElement);
        }
    });
};
