import type {ImageRecord as BitmapRecord} from '@pexip/media-processor';

import type {ImageRecord} from '../types';

/**
 * Converts an ImageRecord to a BitmapRecord.
 */
export const toImageBitmapRecord = async (
    imageRecord: ImageRecord,
): Promise<BitmapRecord> => {
    const image = await createImageBitmap(imageRecord.data);
    return {key: imageRecord.name, image};
};
